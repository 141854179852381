import React from "react";
import { Tooltip } from "react-tooltip";

const CountDownTimer = (props) => {

    const {
        formatTime,
        remainingTime
    } = props;

    return (
        <div
            data-tooltip-id="server-restart-timer"
            data-tooltip-content="System Restart Timer"
            className="flex gap-3 text-lg"
        >
            <code className="space-x-1">
                <span className="countdown text-2xl">
                    <span>{formatTime(remainingTime)?.hrs}</span>
                </span>
                <span className="text-sm">hrs</span>
            </code>
            <code className="space-x-1">
                <span className="countdown text-2xl">
                    <span>{formatTime(remainingTime)?.min}</span>
                </span>
                <span className="text-sm">min</span>
            </code>
            <code className="space-x-1">
                <span className="countdown text-2xl">
                    <span>{formatTime(remainingTime)?.sec}</span>
                </span>
                <span className="text-sm">sec</span>
            </code>
            <Tooltip id="server-restart-timer" />
        </div>

        // <div className="grid grid-flow-col gap-2 text-center auto-cols-max">

        //     <div className="flex flex-col p-5 bg-gray-500 rounded-xl">
        //         <span className="countdown font-mono text-3xl">
        //             <span>{formatTime(remainingTime)?.hrs}</span>
        //         </span>
        //         hrs
        //     </div>
        //     <div className="flex flex-col p-5 bg-gray-500 rounded-xl">
        //         <span className="countdown font-mono text-3xl">
        //             <span>{formatTime(remainingTime)?.min}</span>
        //         </span>
        //         min
        //     </div>

        // </div>
    );
};

export default CountDownTimer;