// subscription status style
export const getStyle = (style) => {
    switch (style) {
        case "freeAccess":
            return "bg-secondary text-white";
        case "expiredSubscription":
            return "bg-danger text-white";
        case "subscribed":
            return "bg-success text-gray-700";
        case "cancelled":
            return "bg-warning text-gray-700";
        case "trial":
            return "bg-primary text-white";
        default:
            return "";
    }

};

// subscription status
export const getStatus = (status) => {
    switch (status) {
        case "freeAccess":
            return "Unlimited";
        case "expiredSubscription":
            return "Expired";
        case "subscribed":
            return "Active";
        case "cancelled":
            return "Cancelled";
        case "trial":
            return "Trial";
        default:
            return "";
    }

};

// user nick name
export const checkName = (firstName, lastName) => {
    if (firstName === "NA") {
        return lastName;
    } else if (lastName === "NA") {
        return firstName;
    } else if (!firstName && !lastName) {
        return "Shinefy";
    } else {
        return `${firstName} ${lastName}`;
    }

};