// libraries
import React, { useContext, useState } from "react";

// custom components
import consoleLog from "../../functions/consoleLog";
import UserContext from "../../components/UserContext";
import PriceOptions from "./Tools/Components/Pricing/PriceOptions";

const Pricing = ({ productDetails }) => {

    // context method
    const {

        // Others
        userData,
        productData,
        subscriptionData,
        appConfigData,

    } = useContext(UserContext);

    // userData extracted data
    const email = userData?.UserInfo?.email;

    // subscriptionData extracted values
    const subsProductID = subscriptionData?.status?.item_id;
    const subscriptionStatus = subscriptionData?.status?.status;
    const subsPrice = subscriptionData?.status?.amount;

    // use state
    const [isActivePricingPlan, setIsActivePricingPlan] = useState(appConfigData[0]?.isActivePricingPlan);
    const [planInterval, setPlanInterval] = useState("month");

    // css styles
    const currentPlanStyle = "shadow-[inset_0_0_0_5px_rgba(14,165,233,1)] border border-sky-600";

    // console log activity
    // consoleLog("productData", productData);
    // consoleLog("subscriptionData", subscriptionData);

    return (
        <>
            {/* <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
                    Shinefy Subscription Pricing Plans
                </h2>
                <p className="mb-5 font-normal sm:text-xl text-gray-400">
                    Canceling a subscription stops future payments immediately. You'll still have access until the current billing period ends.
                </p>
            </div> */}

            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 rounded-lg mb-4">
                <div className="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">

                    <div>
                        <h5 className="mr-3 font-semibold dark:text-white">
                            Shinefy Monthly Subscription Pricing Plans
                        </h5>
                        <p className="text-gray-500 dark:text-gray-400">
                            Canceling a subscription stops future payments immediately. You'll still have access until the current billing period ends.
                        </p>
                    </div>

                    <div className="inline-flex flex-col w-full rounded-md shadow-sm md:w-auto md:flex-row" role="group">
                        <button
                            onClick={() => setPlanInterval("month")}
                            type="button"
                            className={`${planInterval === "month" && "z-10 ring-2 ring-primary-700 dark:ring-primary-500"} px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-t-lg md:rounded-tr-none md:rounded-l-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`}
                        >
                            Monthly
                        </button>
                        <button
                            onClick={() => setPlanInterval("year")}
                            type="button"
                            className={`${planInterval === "year" && "z-10 ring-2 ring-primary-700 dark:ring-primary-500"} px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg md:rounded-bl-none md:rounded-r-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`}
                        >
                            Yearly
                        </button>
                    </div>

                </div>
            </div>

            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-7 lg:space-y-0">

                {productData?.map((data, idx) => {

                    // product data id
                    const productID = data?.productData?.id;
                    const interval = data?.productPrice?.recurring?.interval;
                    const productPrice = data?.productPrice?.unit_amount;

                    if (planInterval === interval && data?.productObject === "product") {

                        return (
                            <div key={idx} className={`${subsProductID === productID ? currentPlanStyle : "shadow border border-gray-600"} flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-white xl:p-8`}>
                                <PriceOptions
                                    product={data}
                                    email={email}
                                    subscriptionData={subscriptionData}
                                    isActivePricingPlan={isActivePricingPlan}
                                    setIsActivePricingPlan={setIsActivePricingPlan}
                                    userData={userData}
                                    subscriptionStatus={subscriptionStatus}
                                    buttonLabel={Number(subsPrice) > productPrice ? "Downgrade Plan" : "Upgrade Plan"}
                                />
                            </div>
                        );
                    }

                })}

            </div>
        </>
    );
};

export default Pricing;