// libraries
import { Button, Chip, Input } from "@nextui-org/react";
import React from "react";

const Tab_LinkGrabber = () => {
    return (
        <div>
            <Input
                size="sm"
                type="text"
                label="Link source"
                isClearable
            />

            <div className="px-8 mt-5">
                <ol className="relative border-s-2 border-gray-400/50 dark:border-gray-600">

                    <li className="mb-10 ms-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 dark:bg-blue-900 rounded-full -start-3 ring-4 ring-white dark:ring-gray-800">
                            <i className="fa-solid fa-circle-check fa-lg text-success" />
                        </span>
                        <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                            Grabbing data...
                            {/* <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">Latest</span> */}
                        </h3>
                        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Started on January 13th, 2022</time>
                    </li>

                    <li className="ms-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 dark:bg-blue-900 rounded-full -start-3 ring-4 ring-white dark:ring-gray-800">
                            <i className="fa-solid fa-cog fa-lg fa-spin" />
                        </span>
                        <h3 className="flex items-center justify-between mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                            Data is ready...
                            <Chip color="success" variant="flat" radius="sm">Success</Chip>
                        </h3>
                        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Started on January 13th, 2022</time>
                        {/* <a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">
                            <svg class="w-3.5 h-3.5 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                                <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                            </svg>
                            Download file
                        </a> */}
                        <Button
                            size="md"
                            variant="faded"
                            startContent={<i className="fa-solid fa-download" />}
                        >
                            Download file
                        </Button>
                    </li>

                </ol>
            </div>

        </div>
    );
};

export default Tab_LinkGrabber;