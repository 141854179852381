import NotAvailableImage from "../shinefy/Tools/Images/NotAvailable.png";

const MaintenancePage = () => {
    return (
        <section className="h-screen bg-gradient-to-tr from-blue-800 to-purple-950 flex items-center justify-center">
            <div className="text-center flex flex-col items-center">
                <div className="mb-4">
                    <img src={NotAvailableImage} alt="Not Available" />
                </div>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-200 md:text-4xl">Under Maintenance</p>
                <p className="mb-4 text-lg text-gray-400 font-medium">Shinefy is currently undergoing maintenance.</p>
                {/* <a href="/" className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Back to Homepage</a> */}
            </div>
        </section>
    );
};

export default MaintenancePage;