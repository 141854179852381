import { useNavigate } from "react-router-dom";

const MenuCard = (props) => {

    // props
    const {
        title,
        toolIcon,
        statusText,
        statusIcon,
        mainText,
        boldText,
        buttonText,
        pageRedirect,
        isAnchorInvalid,
        isButtonDisabled,
        isHot,
        isNew,
        isComingSoon,
    } = props;

    // react components
    const navigate = useNavigate();

    // handles redirect function
    const handleRedirect = () => {
        if (!isButtonDisabled) {
            navigate(pageRedirect);
        }
    };

    return (
        <div
            onClickCapture={handleRedirect}
            className={`${isButtonDisabled && "disabled-link"} ${(isHot || isNew || isComingSoon) && "glow-border"} flex flex-col justify-between py-8 px-12 cardCustom z-10 w-full cursor-pointer`}
            onClick={(event) => {
                if (isButtonDisabled) {
                    event.preventDefault();
                }
            }}
        >
            <div>
                {/* HOT label added if isHot = true */}
                <div className="absolute transform -rotate-[25deg] -translate-x-[60px] -translate-y-[30px] text-[17px]">
                    {isHot && (
                        <div className="bg-red-600 w-20 text-center border rounded-md">
                            <span className=" font-bold text-white">HOT</span>
                        </div>
                    )}

                    {isNew && (
                        <div className="bg-green-600 w-20 text-center border rounded-md">
                            <span className=" font-bold text-white">NEW</span>
                        </div>
                    )}

                    {isComingSoon && (
                        <div className="bg-yellow-400 w-20 text-center border rounded-md">
                            <span className=" font-bold text-white">Soon</span>
                        </div>
                    )}
                </div>

                {/* statusIcon - icon inside the status component */}
                {/* statusText - text inside the status component */}
                <div className="flex justify-between items-center mb-2 text-gray-500 z-20">
                    <span
                        className={`statusIcon ${statusText === "Not Available"
                            ? "text-red-500 border border-red-500"
                            : statusText === "Coming Soon"
                                ? "text-blue-800 border border-yellow-200"
                                : "text-blue-800"
                            } bg-blue-100 inline-flex items-center px-2 py-1 rounded font-semibold`}
                    >
                        {statusIcon}
                        {statusText}
                    </span>
                </div>
                <h2 className=" title-font mb-3 text-2xl font-bold tracking-tight text-white z-2">
                    {/* isAnchorInvalid - boolean value if the href is invalid in react. this is for avoiding href error. set this to true if href = # */}
                    {isAnchorInvalid && (
                        <>{/* eslint-disable jsx-a11y/anchor-is-valid */}</>
                    )}

                    {/* title - title of the card component */}
                    {/* <i className="fa-solid fa-desktop"></i> */}
                    <div className="flex items-center">
                        {toolIcon}
                        {title}
                    </div>
                </h2>
            </div>

            {/* mainText - the main description in the card content. */}
            <p className="main-text mb-5 font-light text-md text-white z-20">
                {mainText}
            </p>

            {/* boldText - the bolded text in the card content. */}
            <p className="mb-5 font-bold text-md text-black">{boldText}</p>

            <div className="flex justify-between items-center z-20">
                <div className="flex  items-center space-x-4"></div>

                {/* Button */}
                <div className="button-icon flex justify-center menuButtons inline-flex items-center font-medium">
                    {/* buttonText - The text inside the button */}
                    <span className="buttonText">{buttonText}</span>
                    <i className="fa-solid fa-chevron-right ml-1"></i>
                </div>
            </div>
        </div>
    );
};

export default MenuCard;
