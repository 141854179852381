import React, { useContext, useEffect, useState } from "react";

// components
import BreadCrumbs from "../../components/Profile/BreadCrumbs";
import LanguageTime from "../../components/Profile/LanguageTime";
import OtherAccounts from "../../components/Profile/OtherAccounts";
import Information from "../../components/Profile/Information";
import Session from "../../components/Profile/Session";
import Password from "../../components/Profile/Password";
import Social from "../../components/Profile/Social";
import Avatar from "../../components/Profile/Avatar";
import Options from "../../components/Profile/Options";
import AdvanceSettings from "../../components/Profile/AdvanceSettings";
import consoleLog from "../../functions/consoleLog";
import { processLinkAccount } from "../../API/server";
import { ToastEffect } from "../../functions/ToastEffect";
import UserContext from "../../components/UserContext";
import { useNavigate } from "react-router-dom";

const Profile = (props) => {

    // props
    const { logout } = props;

    // context method
    const {
        discordData,
    } = useContext(UserContext);

    // use navigate
    const navigate = useNavigate();

    // Get the URLSearchParams object from the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // params
    const requestAction = urlParams.get("request");
    const linkCode = urlParams.get("link");
    const successMessage = urlParams.get("link_success");
    const errorMessage = urlParams.get("link_error");

    // react useState
    const [isLinking, setIsLiking] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    // console log activity
    // consoleLog("requestAction", requestAction);
    // consoleLog("linkCode", linkCode);
    // consoleLog("successMessage", successMessage);

    // catch response messages
    useEffect(() => {
        if (successMessage) {
            setSuccessMsg(successMessage);

            // Delay the navigate by 2 seconds (2000 milliseconds)
            setTimeout(() => {
                navigate("/account");
            }, 10000);
        }

        if (errorMessage) {
            setErrorMsg(errorMessage);

            // Delay the navigate by 2 seconds (2000 milliseconds)
            setTimeout(() => {
                navigate("/account");
            }, 10000);
        }

    }, [successMessage, errorMessage, navigate]); // Add navigate to the dependency array


    // useEffect - process linking discord account to shinefy
    useEffect(() => {

        // if no data existed do nothing.
        if (!requestAction && !linkCode) return;

        // function that process the linking of the account
        const functionHandler = async () => {

            // trigger set is processing
            setIsLiking(true);

            // if process is for discord
            if (requestAction === "discord") {
                await processLinkAccount({ linkCode: linkCode })
                    .then((response) => {
                        // console log activity
                        consoleLog("response", response?.data);
                    });

                // disable set is processing
                setIsLiking(false);
            }
        };
        functionHandler(); // call function
    }, []);

    return (
        <div className="grid grid-cols-1 px-6 pt-16 xl:grid-cols-3 xl:gap-4">
            {/* <BreadCrumbs /> */}
            <div className="col-span-full xl:col-auto">
                <Avatar />
                {/* <LanguageTime /> */}
                <Social
                    successMessage={successMsg}
                    errorMessage={errorMsg}
                />
                <AdvanceSettings />
                {/* <Options /> */}
                {/* <OtherAccounts /> */}
            </div>
            <div className="col-span-2">
                <Information />
                <Password logout={logout} />
                {/* <Session /> */}
            </div>
        </div>
    );
};

export default Profile;