import React, { useEffect, useRef, useState } from "react";

// image
import shinefy_img from "../../constant/images/Shinefy.png";
import ChatUser from "./ChatUser";
import ChatAI from "./ChatAI";
import { fetchAIChat } from "../../API/chatAPI";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import ChatLoading from "./ChatLoading";

const ChatBox = (props) => {

    // Props
    const {
        sending,
        debouncedHandleScroll,
        autoScroll,
        handleSideMenu,
        isMobile,
        AIChat,
        userData,
        sessionID,
        setAIChat,
        isAllFetched,
        setIsAllFetched,
        chatPage,
        setChatPage
    } = props;

    // console.log("AIChat", AIChat);

    // use ref
    const endOfMessagesRef = useRef(null);

    // use state
    const [isFetchingData, setIsFetchingData] = useState(false);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [autoScroll, sending]);

    // re-fetch chats from the server
    const refetchAIChatsOnTopScroll = async () => {

        setIsFetchingData(true);

        await fetchAIChat(userData?.userData?.UserInfo?.email, sessionID, chatPage)
            .then((response) => {

                if (response?.data.length === 0) {
                    setIsAllFetched(true);
                }

                // console.log("email", userData?.userData?.UserInfo?.email);
                // console.log("sessionID", sessionID);
                // console.log("page", chatPage);

                setAIChat((prevMessages) => [...response?.data, ...prevMessages]);
                setIsFetchingData(false);


                consoleLog("pages > ChatAI.jsx > refetchAIChats > response", response?.data);

            }).catch((error) => {
                ToastEffect("error", "Something went wrong! #refetchAIChats");
                consoleLog("pages > ChatAI.jsx > refetchAIChats > error", error);
            });
    };

    useEffect(() => {
        async function handleScroll(event) {
            const container = event.target;

            if (container.scrollTop === 0 && !isAllFetched) {

                if (!isFetchingData) {
                    // If at the top, scroll down slightly
                    container.scrollTop = 30;  // Adjust this value to set the amount you want to scroll down
                }

                setChatPage(prevPage => prevPage + 1);
                refetchAIChatsOnTopScroll();
            }
        }

        const chatContainer = document.getElementById('chatContainer');
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [userData, chatPage, isAllFetched, sessionID]);

    return (
        // <div onScroll={debouncedHandleScroll} className="flex-1 overflow-hidden">
        <div onScroll={debouncedHandleScroll} id="chatContainer" className="flex-1 overflow-x-auto">
            <div className="react-scroll-to-bottom--css-inxvr-79elbk h-full">
                <div className="react-scroll-to-bottom--css-inxvr-1n7m0yu">

                    {isMobile && (
                        <button onClick={handleSideMenu} className="absolute z-20 top-2 right-3 text-lg px-2 text-gray-100 hover:text-gray-200 border border-gray-300 rounded-md">
                            <i className="fa-regular fa-window-maximize fa-rotate-90" />
                        </button>
                    )}

                    <div className="flex flex-col pb-9 text-sm text-gray-100">

                        <div className={` ${!isFetchingData && "hidden"} inset-0 flex items-center justify-center`}>
                            <i className="fa-solid fa-spinner fa-spin-pulse text-blue-600 dark:text-white text-3xl" />
                        </div>

                        <div className="sticky top-0 mb-1.5 flex items-center justify-between z-10 h-14 p-2 font-semibold bg-token-main-surface-primary">
                            {/* <div className="absolute left-1/2 -translate-x-1/2" />
                            <div className="flex items-center gap-2">
                                <div className="group flex cursor-pointer items-center gap-1 rounded-xl py-2 px-3 text-lg font-medium hover:bg-token-main-surface-secondary radix-state-open:bg-token-main-surface-secondary" type="button" id="radix-:rv9:" aria-haspopup="menu" aria-expanded="false" data-state="closed">
                                    <div>ChatGPT <span className="text-token-text-secondary">3.5</span></div>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" className="text-token-text-tertiary">
                                        <path d="M11.3346 7.83203L8.00131 11.1654L4.66797 7.83203" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex gap-2 pr-1">
                                <button className="btn relative btn-neutral btn-small flex h-9 w-9 items-center justify-center whitespace-nowrap rounded-lg">
                                    <div className="flex w-full gap-2 items-center justify-center">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L13 5.41421V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V5.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289ZM4 13C4.55228 13 5 13.4477 5 14V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V14C3 13.4477 3.44772 13 4 13Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </button>
                            </div> */}
                        </div>

                        {AIChat.map((data, idx) => {
                            const isLastMessage = idx === AIChat.length - 1;
                            if (data.sender === "user") {
                                return (
                                    <ChatUser // User Chat
                                        userData={userData}
                                        key={idx}
                                        isMobile={isMobile}
                                        data={data}
                                        ref={isLastMessage ? endOfMessagesRef : null}
                                    />
                                );
                            } else {
                                return (
                                    <ChatAI // AI Response
                                        key={idx}
                                        isMobile={isMobile}
                                        shinefy_img={shinefy_img}
                                        data={data}
                                        ref={isLastMessage ? endOfMessagesRef : null}
                                    />
                                );
                            }
                        })}

                        {sending && (
                            <ChatLoading shinefy_img={shinefy_img} />
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatBox;