// libraries
import React from "react";
import axios from "axios";
import moment from "moment";
import momentTz from 'moment-timezone';
import validator from "validator";

// custom components
import { getLiveCreditBalance } from "../API/account";
import consoleLog from "./consoleLog";

export const handlePurchaseCredits = async (navigate) => {
    navigate("../my_plan?card=buy-credits");
};

export const catchURL = () => {
    // catch URL
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    return queryParams.get('card');
};

export const getPlatFormKind = (platFormID) => {
    switch (platFormID) {
        case "thrivecart_platform": return "ThriveCart";
        case "stripe_platform": return "Stripe";
        default: return "Unknown";
    }
};

/** check if tools server is on */
export const checkAPIStatus = async (url) => {
    try {
        await axios.get(url);
        return true; // If the request is successful, set isOnline to true
    } catch (error) {
        return false;  // If there is any error (including network or server issues), set isOnline to false
    }
};

/** truncate value to 2 decimal value without rounding off */
export const treuncateTo2Decimal = (value) => {
    const truncatedValue = Math.floor(value * 100) / 100;
    // Convert to string with two decimal places
    const formattedValue = truncatedValue.toFixed(2);
    // Convert to locale string if necessary
    return Number(formattedValue).toLocaleString();
};

/** get live credits balance function */
export const handleLiveCreditBalance = async (userData) => {

    // capture live balance from the server
    const liveCreditBalance = await getLiveCreditBalance(userData?.UserInfo);
    return liveCreditBalance?.data?.balance;
};

/** tools status processor  */
export const updateStatusArray = (statusArray, data, setRefetchData) => {
    const typesToCheck = ["image", "custom_image", "custom_video"];
    const isSpecialType = typesToCheck.includes(data?.tools_status?.type);

    // Trigger refetch if status is done or error
    if (data?.tools_status?.status === "done" || data?.tools_status?.status === "error") {
        setRefetchData(oldVal => oldVal + 1);
    }

    let existingIndex;

    // Determine the index based on type
    if (isSpecialType) {
        // If type matches the specified ones, check both task_id and number
        existingIndex = statusArray.findIndex(item =>
            item?.tools_status?.task_id === data?.tools_status?.task_id &&
            item?.tools_status?.number === data?.tools_status?.number
        );
    } else {
        // If type does not match, check only task_id
        existingIndex = statusArray.findIndex(item =>
            item?.tools_status?.task_id === data?.tools_status?.task_id
        );
    }

    if (existingIndex !== -1) {
        // If an existing entry is found, update it
        const updatedArray = [...statusArray];
        updatedArray[existingIndex] = { ...updatedArray[existingIndex], ...data };
        return updatedArray;
    } else {
        // If no existing entry is found, add the new data
        return [...statusArray, data];
    }
};

// save configuration data to local storage
export const cachedToolsConfiguration = async (configKey, configData) => {
    localStorage.setItem(configKey, configData);
};

// get configurations
export const getCachedToolsConfiguration = (configKey, configData) => {

    // fetch data from local storage
    const cachedConfiguration = localStorage.getItem(configKey);

    // if data exist
    if (cachedConfiguration) {

        // return data
        return {
            config: (cachedConfiguration)?.toLocaleString()?.toLowerCase(), // lower case letters | for config
            normal_config: cachedConfiguration, // normal letters || for normal_config
            content: cachedConfiguration // normal letters || for content
        };

    } else { // if it does not exist

        // variable
        let updatedConfigKey = configKey;

        // condition for other values
        if (configKey === "customImageThemePrompt") {
            updatedConfigKey = "imageTheme";
        } else if (configKey === "customVideoThemePrompt") {
            updatedConfigKey = "videoTheme";
        } else if (configKey === "userIdeaContent") {
            updatedConfigKey = "idea";
        } else if (configKey === "userScriptContent") {
            updatedConfigKey = "script";
        }

        // fetch data from database
        const accuracyConfig = configData?.find(item => item?.dataID === updatedConfigKey);

        // if data exist
        if (accuracyConfig) {

            // return data
            return {
                config: (accuracyConfig?.link)?.toLocaleString()?.toLowerCase(), // for config
                normal_config: accuracyConfig?.link, // for none lowered case letter
                content: accuracyConfig?.content // for content
            };

        } else { // if it does not exist

            // return null
            return null;
        }
    }
};

// capitalize the first letter from the words
export const capitalizeFirstLetter = (string) => {
    return string
        ?.split(' ') // Split the string into an array of words
        ?.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        ?.join(' '); // Join the array back into a single string
};

// capture option using value
export const findOptionByValue = (arrayData, value) => {
    const item = arrayData.find(item => item.value === value);
    return item ? item.option : 'Option not found';
};

// Debounce function using closure to maintain the timer state. this is to prevent from spamming config saving
export const debounce = (callback, delay = 1000) => {
    let timers = {};
    let resolveQueues = {};

    return (key, ...args) => {
        if (!resolveQueues[key]) resolveQueues[key] = [];

        return new Promise((resolve, reject) => {
            if (timers[key]) clearTimeout(timers[key]);

            timers[key] = setTimeout(async () => {
                try {
                    const result = await callback(...args);  // Ensure the callback executes and resolves
                    resolveQueues[key].forEach(res => res(result));  // Resolve all promises in the queue with the result
                    resolveQueues[key] = [];  // Clear the queue after execution
                } catch (error) {
                    resolveQueues[key].forEach(rej => rej(error));  // Reject all promises in the queue with the error
                    resolveQueues[key] = [];
                } finally {
                    clearTimeout(timers[key]);
                }
            }, delay);

            resolveQueues[key].push(resolve);
        });
    };
};

// export const debounce = (callback, delay = 1000) => {
//     const timers = {};  // Object to store timers based on key
//     const resolveQueues = {};  // Object to store resolve functions queues based on key

//     return (key, ...args) => {
//         return new Promise((resolve, reject) => {
//             // Clear the existing timer if there is one
//             if (timers[key]) clearTimeout(timers[key]);

//             // Set a new timer
//             timers[key] = setTimeout(() => {
//                 try {
//                     // Ensure the callback returns a promise
//                     const result = callback(...args);
//                     if (result && typeof result.then === 'function') {
//                         result.then(resolve).catch(reject);
//                     } else {
//                         throw new Error("Callback function does not return a promise");
//                     }
//                 } catch (error) {
//                     reject(error);
//                 } finally {
//                     // Resolve all promises in the queue for this key
//                     if (resolveQueues[key]) {
//                         resolveQueues[key].forEach(r => r());
//                         resolveQueues[key] = [];  // Clear the queue after execution
//                     }
//                 }
//             }, delay);

//             // Initialize the queue for this key if it doesn't exist
//             if (!resolveQueues[key]) resolveQueues[key] = [];
//             resolveQueues[key].push(resolve);
//         });
//     };
// };

// export const debounce = (callback, delay = 1000) => {
//     const timers = {};  // Use an object to store timers for different contexts

//     return (key, ...args) => {
//         if (timers[key]) clearTimeout(timers[key]);
//         timers[key] = setTimeout(() => {
//             callback(...args);
//         }, delay);
//     };
// };

// update data from array 
export const updateArrayData = async (array, dataID, param, value) => {
    return array.map(item => {
        if (item._id === dataID) {
            return { ...item, [param]: value }; // Add the report_id property
        }
        return item;
    });
};

// remove a data from the array
export const removeDataFromArray = async (array, dataID) => {
    return array.filter(item => item._id !== dataID);
};

// count how many data from array conditionally
export const countDataFromArray = (array, param, value) => {
    return array?.filter(item => item?.[param] === value)?.length;
};

// marker for loading effect
export const addMarkerToData = async (reactUseState, dataID) => {
    reactUseState(prevState => ({ ...prevState, [dataID]: dataID }));
};

// remove marked data for done process
export const removeMarkedData = async (reactUseState, dataID) => {
    reactUseState(prevState => {
        const { [dataID]: _, ...rest } = prevState;
        return rest;
    });
};

// convert date time format
export const convertDateTime = (dateTime) => {
    return moment(dateTime).format('MMMM DD, YYYY - hh:mm:ss A');
};

// Format number ex 1000 to 1,000
export const formatNumberValue = (number) => {
    return Number(number || 0)?.toLocaleString();
};

// clean string and remove quote
export const removeQuotes = (str) => {
    return str?.replace(/['"]+/g, '');
};

// check a video aspect ratio
export const checkVideoAspectRatio = (sourceMediaFile) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        // Append video to the body to ensure it loads in some environments
        document.body.appendChild(video);
        video.style.display = 'none'; // Hide the video element

        video.addEventListener('loadedmetadata', () => {
            const width = video.videoWidth;
            const height = video.videoHeight;
            const aspectRatio = width / height;
            let orientation;

            if (aspectRatio > 1) {
                orientation = "Landscape";
            } else if (aspectRatio < 1) {
                orientation = "Portrait";
            } else {
                orientation = "Square";
            }

            // Clean up by removing the video element once it's no longer needed
            document.body.removeChild(video);
            URL.revokeObjectURL(video.src);

            // Resolve the promise with the orientation result
            resolve(orientation);
        });

        video.addEventListener('error', (e) => {
            console.error('Error loading video:', e);
            // Reject the promise if there is an error loading the video
            reject(e);
        });

        video.src = URL.createObjectURL(sourceMediaFile[0]);
    });
};

// convert data to .SRT
const convertToSRT = (data, isWords) => {
    if (!data || data.length === 0) return '';

    return data.map((item, index) => {
        const start = item.start;
        const end = item.end;
        const text = isWords ? item.word : item.text;

        const formatTime = (time) => {
            const hours = Math.floor(time / 3600).toString().padStart(2, '0');
            const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
            const seconds = Math.floor(time % 60).toString().padStart(2, '0');
            const milliseconds = Math.floor((time % 1) * 1000).toString().padStart(3, '0');
            return `${hours}:${minutes}:${seconds},${milliseconds}`;
        };

        return `${index + 1}\n${formatTime(start)} --> ${formatTime(end)}\n${text}\n`;
    }).join('\n');
};

// download in .SRT format
export const downloadSRT = async (data, fileName, isWords) => {
    const srtContent = convertToSRT(data, isWords);
    const blob = new Blob([srtContent], { type: 'text/srt;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// get current time now in timestamp
export const getCurrentTimestamp = () => {
    return moment().unix();
};

// convert the result from "convertToCustomerLocalTime" to moments format
export const convertToMomentFormat = (dateString) => {
    const date = moment(dateString, "MMMM D, YYYY h:mm:ss A [GMT]Z");

    if (!date.isValid()) {
        return null;
    }

    return date;
};

// convert server time to clients local time
export const convertToCustomerLocalTime = (dateTime) => {

    // Get the client's local time zone
    const clientTimeZone = momentTz.tz.guess();

    // Ensure the created time is interpreted correctly as UTC
    const datetime = momentTz.utc(dateTime).tz(clientTimeZone).format('MMMM DD, YYYY - hh:mm:ss A');

    // Ensure the created time is interpreted correctly as UTC
    const moment = momentTz.utc(dateTime).tz(clientTimeZone).fromNow();

    return { moment: moment, dateTime: datetime };
};

// check if data contains URL or a link
export const containsUrl = (string) => {
    if (!string) return false; // Return false if the string is empty or null
    return validator.isURL(string);
};

// get configurations from local storage
export const getConfigValue = (configData, configKey) => {

    // fetch data from local storage
    const cachedConfiguration = localStorage?.getItem(configKey);

    if (cachedConfiguration) { // local cached exist return config data

        // return result
        return cachedConfiguration;

    } else { // Extract config data from the fetched data from the database.

        if (configData?.length) {
            // extract the config data via config key
            const config = configData?.find(config => config?.key === configKey);

            // return config value
            return config?.value;
        } else {

            return null;
        }

    }
};

// save data to chache for local fetching
export const cachedConfig = async (configKey, configData) => {
    localStorage.setItem(configKey, configData);
};

// check if empty string contains tab and space
export const isOnlySpaceOrTab = (testParam) => {
    // Check if testParam consists only of spaces or tabs
    return /^[\s\t]*$/.test(testParam);
};

// format number count
export const formatNumberCount = (value) => {

    // variable
    let finalVal = 0;

    // conditionaly check value
    if (value > 99) {
        return finalVal = "99+";
    } else {
        return finalVal = value;
    }

};

// format and limit text
export const truncateString = (stringData, maxLength = 10) => {
    if (stringData.length > maxLength) {
        return stringData.substring(0, maxLength) + '...';
    }
    return stringData;
};