export const avatarList = [
  {
    "id": "Andrew_public_pro1_20230614",
    "avatar_name": "Andrew",
    "normal_preview": "https://files.movio.la/avatar/v3/ed0577c3046545018aade5e35fc6e491_2750/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/1ea493e901024034900422df18dbee1a.wav",
    "video_url": "https://files.movio.la/avatar/v3/ed0577c3046545018aade5e35fc6e491_2750/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Brian_public_lite1_20230601",
    "avatar_name": "Brian",
    "normal_preview": "https://files.movio.la/avatar/v3/8cf8ec8d3ca84bd489150779b16a6861_2624/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/da4e501e123e40838ba09046e32603d0.wav",
    "video_url": "https://files.movio.la/avatar/v3/8cf8ec8d3ca84bd489150779b16a6861_2624/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Eric_public_pro1_20230601",
    "avatar_name": "Eric",
    "normal_preview": "https://files.movio.la/avatar/v3/15e5253e9ae94a08a720b3ba45522eb5_2606/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/636527f370a148348f787b45df35a585.wav",
    "video_url": "https://files.movio.la/avatar/v3/15e5253e9ae94a08a720b3ba45522eb5_2606/preview_video_talk_1.mp4",
    "gender": "male"
  },
  {
    "id": "josh_lite3_20230714",
    "avatar_name": "Josh_landscape",
    "normal_preview": "https://files.movio.la/avatar/v3/10063c743f114722ab6538b35905c51c_3013/preview_target.webp",
    "tts_audio_url": "",
    "video_url": "https://files.movio.la/avatar/v3/10063c743f114722ab6538b35905c51c_3013/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "josh_lite_20230714",
    "avatar_name": "Joshworking",
    "normal_preview": "https://files.movio.la/avatar/v3/2072d60780d042b0908b09ea4fc1c6b7_3011/preview_target.webp",
    "tts_audio_url": "",
    "video_url": "https://files.movio.la/avatar/v3/2072d60780d042b0908b09ea4fc1c6b7_3011/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Lily_public_lite1_20230601",
    "avatar_name": "Lily",
    "normal_preview": "https://files.movio.la/avatar/v3/b596c0849b7942778ad27f63c5995e33_2626/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/c4b35a0a22c14fc5abea338d4e8617d9.wav",
    "video_url": "https://files.movio.la/avatar/v3/b596c0849b7942778ad27f63c5995e33_2626/preview_video_talk_1.mp4",
    "gender": "female"
  },
  {
    "id": "Angela-inblackskirt-20220820",
    "avatar_name": "angela",
    "normal_preview": "https://files.movio.la/avatar/v3/dc4c9111fa61481c9c09aad80d697fb2_1053/preview_talk_2.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/7dd0a767d6a747e3a73a64a5a504eecc.wav",
    "video_url": "https://files.movio.la/avatar/v3/dc4c9111fa61481c9c09aad80d697fb2_1053/preview_video_talk_2.mp4",
    "gender": "female"
  },
  {
    "id": "Aurelien_public_pro4_20230601",
    "avatar_name": "aurelien",
    "normal_preview": "https://files.movio.la/avatar/v3/49b25ad2cbfe4f8ea1259998f77cd54b_2633/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/06290b62fb7246fe8a18cd8ab41deba0.wav",
    "video_url": "https://files.movio.la/avatar/v3/49b25ad2cbfe4f8ea1259998f77cd54b_2633/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Ben-pro-insuit-20221207",
    "avatar_name": "bill",
    "normal_preview": "https://files.movio.la/avatar/v3/4ac4f5fc2d134073b090005f3331752c_1413/preview_talk_4.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/cc57d9cb0c034d118999e813f7089402.wav",
    "video_url": "https://files.movio.la/avatar/v3/4ac4f5fc2d134073b090005f3331752c_1413/preview_video_talk_4.mp4",
    "gender": "male"
  },
  {
    "id": "Derek-inshirt-200220816",
    "avatar_name": "derek",
    "normal_preview": "https://files.movio.la/avatar/v3/44f9a21dcbba41dfa5eec508f7682da3_1075/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/8d4babc923a94030a42485ef1564389c.wav",
    "video_url": "https://files.movio.la/avatar/v3/44f9a21dcbba41dfa5eec508f7682da3_1075/preview_video_talk_1.mp4",
    "gender": "male"
  },
  {
    "id": "Eva-black-20220601",
    "avatar_name": "eva",
    "normal_preview": "https://files.movio.la/avatar/v3/0d2b8d5b1d5d4d18a9ae029acf642e06_1416/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/903463fcd86b4d8fa75a9ff61684bc3f.wav",
    "video_url": "https://files.movio.la/avatar/v3/0d2b8d5b1d5d4d18a9ae029acf642e06_1416/preview_video_target.mp4",
    "gender": "female"
  },
  {
    "id": "Jake-inshirt-20220721",
    "avatar_name": "jake",
    "normal_preview": "https://files.movio.la/avatar/v3/2fe505d2f4f546b7addd4fa083149284_1060/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/123d3a7177b64727b3d7a982441a2633.wav",
    "video_url": "https://files.movio.la/avatar/v3/2fe505d2f4f546b7addd4fa083149284_1060/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Jeff-incasualsuit-20220722",
    "avatar_name": "jeff",
    "normal_preview": "https://files.movio.la/avatar/v3/a43b84ae994d4776a64aba7dc40ad9c9_1126/preview_talk_6.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/8016f816e719414781f495dbd18cf872.wav",
    "video_url": "https://files.movio.la/avatar/v3/a43b84ae994d4776a64aba7dc40ad9c9_1126/preview_video_talk_6.mp4",
    "gender": "male"
  },
  {
    "id": "Jerome-inwhiteTshirt-20220722",
    "avatar_name": "jerome",
    "normal_preview": "https://files.movio.la/avatar/v3/05c935f6bd9b48d696f75cfa7efb2714_1065/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/6d58ceca4e9848509a4a6c70ee663707.wav",
    "video_url": "https://files.movio.la/avatar/v3/05c935f6bd9b48d696f75cfa7efb2714_1065/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Joon-inblackshirt-20220821",
    "avatar_name": "joon",
    "normal_preview": "https://files.movio.la/avatar/v3/f4712d6634514500b2cbcacd3aaef64b_1058/preview_talk_2.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/6a6e5c5bfec243ac989d900f8404f5cc.wav",
    "video_url": "https://files.movio.la/avatar/v3/f4712d6634514500b2cbcacd3aaef64b_1058/preview_video_talk_2.mp4",
    "gender": "male"
  },
  {
    "id": "Kayla-incasualsuit-20220818",
    "avatar_name": "kayla",
    "normal_preview": "https://files.movio.la/avatar/v3/bf83f0f830794d90b509ba6524962e05_1079/preview_talk_2.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/39beb20432a741b5a35340bb8d57d0d3.wav",
    "video_url": "https://files.movio.la/avatar/v3/bf83f0f830794d90b509ba6524962e05_1079/preview_video_talk_2.mp4",
    "gender": "female"
  },
  {
    "id": "Kent-inpolo-20220728",
    "avatar_name": "kent",
    "normal_preview": "https://files.movio.la/avatar/v3/f539d51cc23d462f84c68354064dc76d_1057/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/9a5d2b9182324fe09c755af3fb7e258d.wav",
    "video_url": "https://files.movio.la/avatar/v3/f539d51cc23d462f84c68354064dc76d_1057/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Luna-Tshirt-20220601",
    "avatar_name": "luna",
    "normal_preview": "https://files.movio.la/avatar/v3/8b7f5cf5c7c54bbe9276d9ce8cffd1e4_1461/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/0868a1239d9948d98490aec1191f7d43.wav",
    "video_url": "https://files.movio.la/avatar/v3/8b7f5cf5c7c54bbe9276d9ce8cffd1e4_1461/preview_video_talk_1.mp4",
    "gender": "female"
  },
  {
    "id": "Mark-blueshirt-20220601",
    "avatar_name": "mark",
    "normal_preview": "https://files.movio.la/avatar/v3/ff904fb4c1fa463b95ed26b56e114ce6_1437/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/c890562f20ce447aa6043275bff7fe58.wav",
    "video_url": "https://files.movio.la/avatar/v3/ff904fb4c1fa463b95ed26b56e114ce6_1437/preview_video_target.mp4",
    "gender": "male"
  },
  {
    "id": "Mido-lite-20221128",
    "avatar_name": "matthew",
    "normal_preview": "https://files.movio.la/avatar/v3/5c304ab1e7534e2c887e2f795fbe6568_1354/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/251413a389714f299555e1838be754db.wav",
    "video_url": "https://files.movio.la/avatar/v3/5c304ab1e7534e2c887e2f795fbe6568_1354/preview_video_talk_1.mp4",
    "gender": "male"
  },
  {
    "id": "Monica_inskirt_20220819",
    "avatar_name": "monica",
    "normal_preview": "https://files.movio.la/avatar/v3/6250f18607cc49939541e189b2764248_1157/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/4ef99fdefec3406cafe7eb8182db8ac2.wav",
    "video_url": "https://files.movio.la/avatar/v3/6250f18607cc49939541e189b2764248_1157/preview_video_target.mp4",
    "gender": "female"
  },
  {
    "id": "Peter-Tshirt-20220608",
    "avatar_name": "peter",
    "normal_preview": "https://files.movio.la/avatar/v3/66bf19f5623a4f86a9bc9717c4d635c3_1483/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/d48304a61ae64a8492c7ba952759f1dd.wav",
    "video_url": "https://files.movio.la/avatar/v3/66bf19f5623a4f86a9bc9717c4d635c3_1483/preview_video_talk_1.mp4",
    "gender": "male"
  },
  {
    "id": "Selina-abayaskirt-20220609",
    "avatar_name": "selina",
    "normal_preview": "https://files.movio.la/avatar/v3/1785a16a98b8432fb5a0f40c3bbc4daf_1639/preview_talk_3.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/31dccee936a141c88d4b00eda13f63fe.wav",
    "video_url": "https://files.movio.la/avatar/v3/1785a16a98b8432fb5a0f40c3bbc4daf_1639/preview_video_talk_3.mp4",
    "gender": "female"
  },
  {
    "id": "Tatiana-pro-Vskirt-20221213",
    "avatar_name": "tanya",
    "normal_preview": "https://files.movio.la/avatar/v3/2f2769e0040f4037a6a16f59ad3cc1a2_1456/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/63540b7dab534a008b1b5c80de47342c.wav",
    "video_url": "https://files.movio.la/avatar/v3/2f2769e0040f4037a6a16f59ad3cc1a2_1456/preview_video_target.mp4",
    "gender": "female"
  },
  {
    "id": "Thomas-abayaphone-20220601",
    "avatar_name": "thomas",
    "normal_preview": "https://files.movio.la/avatar/v3/7990225acbd24311957170f40f8fe2ac_1415/preview_talk_3.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/fa8cff504a3b4f3d801b8a0260353dc5.wav",
    "video_url": "https://files.movio.la/avatar/v3/7990225acbd24311957170f40f8fe2ac_1415/preview_video_talk_3.mp4",
    "gender": "male"
  },
  {
    "id": "Tina-inStripedshirt-20220821",
    "avatar_name": "tina",
    "normal_preview": "https://files.movio.la/avatar/v3/129be09bde4d46799b935cd982fa28e5_1002/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/d730a9efaed04b989c4e943b01a18d7e.wav",
    "video_url": "https://files.movio.la/avatar/v3/129be09bde4d46799b935cd982fa28e5_1002/preview_video_target.mp4",
    "gender": "female"
  },
  {
    "id": "Vanessa-inskirt-20220722",
    "avatar_name": "vanessa",
    "normal_preview": "https://files.movio.la/avatar/v3/93649b8ef2764e1da827dbd1d34967e3_1115/preview_talk_9.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/e5f9ad565b1d4980b85d8813ad568e59.wav",
    "video_url": "https://files.movio.la/avatar/v3/93649b8ef2764e1da827dbd1d34967e3_1115/preview_video_talk_9.mp4",
    "gender": "female"
  },
  {
    "id": "Vlada-lite-20221129",
    "avatar_name": "vera",
    "normal_preview": "https://files.movio.la/avatar/v3/7e22ce3be20042a5baa33d83997cb305_1366/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/a2a4ee52cb604615bb9ffe3b0ad0c78c.wav",
    "video_url": "https://files.movio.la/avatar/v3/7e22ce3be20042a5baa33d83997cb305_1366/preview_video_target.mp4",
    "gender": "female"
  },
  {
    "id": "Wilson-insuit-20220722",
    "avatar_name": "wilson",
    "normal_preview": "https://files.movio.la/avatar/v3/56218cddfa2d4681bf95f98eb4658675_1280/preview_talk_1.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/d98bac59ade6402bbeb3f201ee7ca1e9.wav",
    "video_url": "https://files.movio.la/avatar/v3/56218cddfa2d4681bf95f98eb4658675_1280/preview_video_talk_1.mp4",
    "gender": "male"
  },
  {
    "id": "Zoey-inlongsleeves-20220816",
    "avatar_name": "zoey",
    "normal_preview": "https://files.movio.la/avatar/v3/f1ac9458a4cb49bea6d80a8e54eea24a_1113/preview_target.webp",
    "tts_audio_url": "https://files.movio.la/avatar/default_voice/2aab28968dc146a3bb49538055105c97.wav",
    "video_url": "https://files.movio.la/avatar/v3/f1ac9458a4cb49bea6d80a8e54eea24a_1113/preview_video_target.mp4",
    "gender": "female"
  }
];