import axios from "axios";
import { ToastEffect } from "../functions/ToastEffect";
import consoleLog from "../functions/consoleLog";

/** Send message to AI */
export const sendMessageToAI = async (email, session, type, message, audio) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/send_ai_msg`, {
            email: email,
            session: session,
            type: type,
            message: message,
            audio: audio
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #sendMessageToAI");
        consoleLog("API > chatAPI.js > sendMessageToAI", error);
    }
};

/** Fetch all AI chat session data from MongoDB */
export const fetchAIChatSessions = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/get_ai_chat_sessions`, {
            email: email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAIChatSessions");
        consoleLog("API > chatAPI.js > fetchAIChatSessions", error);
    }
};

/** Get adavance configs from MongoDB */

export const fetchAdvanceConfigs = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/get_advance_configs`, {
            email: email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAdvanceConfigs");
        consoleLog("API > chatAPI.js > fetchAdvanceConfigs", error);
    }
};

/** Fetch All AI chat from the specific sessionID */
export const fetchAIChat = async (email, sessionID, page) => {
    try {
        // console.log("email", email);
        // console.log("sessionID", sessionID);
        // console.log("page", page);
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/get_ai_chat`, {
            email: email,
            session: sessionID,
            page: page
        });
        // consoleLog("API > chatAPI.js > fetchAIChat", response);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAIChat");
        consoleLog("API > chatAPI.js > fetchAIChat", error);
    }
};

/** Update chat AI session title */
export const updateSessionTitle = async (email, sessionID, title) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/update_session_title`, {
            email: email,
            session: sessionID,
            title: title
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateSessionTitle");
        consoleLog("API > chatAPI.js > updateSessionTitle", error);
    }
};

/** Remove chat AI session */
export const removeSession = async (email, sessionID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/remove_session`, {
            email: email,
            session: sessionID
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #removeSession");
        consoleLog("API > chatAPI.js > removeSession", error);
    }
};

/** Send audio to AI */
export const sendAudiotoAI = async (formData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/send_audio`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #sendAudiotoAI");
        consoleLog("API > chatAPI.js > sendAudiotoAI", error);
    }
};

/** Send image to AI */
export const sendImagetoAI = async (formData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/send_image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #sendAudiotoAI");
        consoleLog("API > chatAPI.js > sendAudiotoAI", error);
    }
};

/** Get audio from server */
export const getAudio = async (audioName) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SHINEFY_SERVER}/file/audio/${audioName}`,
            { responseType: 'arraybuffer' }
        );
        const blob = new Blob([response.data], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(blob);
        // const audio = new Audio(audioUrl);
        // audio.play();
        return audioUrl;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getAudio");
        consoleLog("API > chatAPI.js > getAudio", error);
    }
};

/** Get image from server */
// export const getImage = async (imageName) => {
//   try {
//     const response = await axios.get(`${process.env.REACT_APP_SHINEFY_SERVER}/file/image/${imageName}`);
//     return response;
//   } catch (error) {
//     ToastEffect("error", "Something went wrong! #getAudio");
//     consoleLog("API > chatAPI.js > getAudio", error);
//   }
// };

/** save advance option config */
export const saveAdvanceConfig = async (email, kind, value) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/chat/save_advance_config`, {
            email: email,
            kind: kind,
            value: value
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getAudio");
        consoleLog("API > chatAPI.js > getAudio", error);
    }
};