export const checkLoginResponse = (response) => {
  switch (response) {
    case "notExist":
      return "Invalid username or password.";
    case "invalidCredentials":
      return "Invalid username or password.";
    case "banned":
      return "Unable to access using this account";
    case "notActivated":
      return "Activate your account first.";
  }
};