import React, { useEffect, useState } from "react";
import { saveAdvanceConfig } from "../../API/chatAPI";

const CustomPrompts = (props) => {

  const {
    refetchAdvanceConfig,
    userData,
    advanceConfigs
  } = props;

  const [customPrompt, setCustomPrompt] = useState(advanceConfigs[0]?.prompt);
  const [newdata, setNewData] = useState({});
  const [debouncedValue, setDebouncedValue] = useState(newdata.value);

  // console.log(userData);

  const handleCustomPrompt = (event) => {
    const newValue = event.target.value;
    setCustomPrompt(newValue);
    if (newValue) {
      debounceFunction({ value: newValue, kind: "prompt" });
    }
  };

  const debounceFunction = (data) => {
    setNewData(data);
  };

  // Update debouncedValue after 500ms the user has stopped typing
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(newdata.value);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [newdata]);

  // Call handleMidjourneyAPIKey when debouncedValue changes
  useEffect(() => {
    handleDebounceFunction(debouncedValue);
  }, [debouncedValue]);

  // save debounced value
  const handleDebounceFunction = (value) => {

    if (!value) {
      return;
    }

    const email = userData?.userData?.UserInfo?.email;
    saveAdvanceConfig(email, newdata.kind, value);
    refetchAdvanceConfig();

  };

  return (
    <textarea
      onChange={handleCustomPrompt}
      value={customPrompt}
      id="message"
      rows="8"
      className="block mt-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="Your custom prompts here..."
    />
  );
};

export default CustomPrompts;