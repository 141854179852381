import Swal from "sweetalert2";
import formatNumber from "../../../../../functions/calculations";
import { toolPrices } from "../../../../../Configs/toolPrices";
import consoleLog from "../../../../../functions/consoleLog";

export const alertBeforeAction = async (handleFunction1, handleFunction2, kind, others) => {

    let color = "";

    if (localStorage.theme === "dark") {
        color = "#1F2937";
    } else {
        color = "";
    }

    function getDisplayTitle(kind) {
        switch (kind) {
            case "re-generate-image-prompts": return "Re-Generate Image Prompts?";
            case "re-generate-image": return "Re-Generate Image?";
            case "re-generate-audio": return "Re-Generate Audio?";
            case "re-generate-options": return "Re-Generate Option";
            case "Generate-Video": return "Generate Video";
            case "batch-regenrate-images": return "Bulk Generate Images";
            case "News-Letter": return "News Letter";
            case "create-custom-video": return "Create Custom Video";
            case "Low-Credits": return "Not enough credits";
            case "cancel-subscription": return "Cancel Subscription?";
            case "continue-subscription": return "Continue Subscription?";
            case "accuracy-generation": return "Accuracy Generation";
            default: return "Invalid Title";
        }
    }

    function generationKind(kind) {
        switch (kind) {
            case "create_video": return `
                <span>
                <div class="">
                    <i class="fa-solid fa-coins text-lg text-yellow-300"></i> 
                    <code>${formatNumber(others?.creditCalculation)}</code>
                </div> 
                Create Video
                </span>
            `;
            case "custom_video": return `
                <span>
                    <div class="">
                        <i class="fa-solid fa-coins text-lg text-yellow-300"></i> 
                        <code>${formatNumber(others?.customCreditCalculation)}</code>
                    </div>
                    Custom Video
                </span>
            `;
            case "News-Letter": return `
                <span>
                    <div class="">
                        <i class="fa-brands fa-discord text-2xl w-9 h-9 text-gray-100"></i> Authenticate
                    </div>
                </span>
            `;
            case "Low-Credits": return `
                <span>
                    <div class="">
                        Buy Credits
                    </div>
                </span>
            `;
            case "cancel-subscription": return `
                <span>
                    <div class="">
                        Confirm Cancellation
                    </div>
                </span>
            `;
            case "continue-subscription": return `
                <span>
                    <div class="">
                        Confirm Continuation
                    </div>
                </span>
            `;
            case "accuracy-generation": return `
                <span>
                    <div class="">
                        Create Video
                    </div>
                </span>
            `;
            default: return `<span><i class="fa-solid fa-coins text-lg"></i> 0.00 Invalid</span>`;
        }
    }

    function getDisplayDescription(kind) {
        switch (kind) {
            case "re-generate-image-prompts": return `
                <p class="text-gray-700 dark:text-gray-300">
                    This <b>action</b> will regenerate new prompts
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    and erase all existing prompts.
                </p>
            `;
            case "re-generate-image": return `
                <p class="text-gray-700 dark:text-gray-300">
                    This <b>action</b> will regenerate new image
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    and erase the existing image.
                </p>
            `;
            case "re-generate-audio": return `
                <p class="text-gray-700 dark:text-gray-300">
                    This <b>action</b> will regenerate new audio
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    and erase the existing audio.
                </p>
            `;
            case "re-generate-options": return `
                <p class="text-gray-700 dark:text-gray-300">
                    Select action for <b>Re-Generate</b>
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    this actions will repeat the process
                </p>
            `;
            case "Generate-Video": return `
                <p class="text-gray-700 dark:text-gray-300">
                    Select action for <b>Generate Video</b>
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    <b>Create Video</b> for automatic video generation.
                    <br><b>Custom Video</b> to customize before generating.
                </p>
            `;
            case "batch-regenrate-images": return `
                <p class="text-gray-700 dark:text-gray-300">
                    This <b>action</b> will regenerate all images
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    only for data that doesn't have an image.
                </p>
            `;
            case "News-Letter": return `
                <p class="text-gray-700 dark:text-gray-300">
                    <b>Welcome!</b> Interested in our latest updates?
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    Subscribe for our newsletter now!
                </p>
            `;
            case "create-custom-video": return `
                <p class="text-gray-700 dark:text-gray-300">
                    This <b>action</b> will regenerate a new video
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    and erase the existing video
                </p>
            `;
            case "Low-Credits": return `
                <p class="text-gray-700 dark:text-gray-300">
                    It seems you've run out of <b>credits</b>
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    You can buy more to continue using our tools.
                </p>
            `;
            case "cancel-subscription": return `
                <p class="text-gray-700 dark:text-gray-300">
                    Canceling a subscription is <b>irreversible</b>.
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    This action will cancel your current subscription.
                </p>
            `;
            case "continue-subscription": return `
                <p class="text-gray-700 dark:text-gray-300">
                    Continue subscription <b>anytime.</b>.
                </p>` +
                `<p class="text-gray-700 dark:text-gray-500">
                    This action will continue your current subscription.
                </p>
            `;
            case "accuracy-generation": return `
                <p class="text-gray-700 dark:text-gray-300">
                    Using the accuracy option costs <b>0.2 credits</b> per image.
                </p>` +
                `<p class="text-gray-700 dark:text-gray-400">
                    ${others?.videoData?.image_total_count} images require
                    <i class="fa-solid fa-coins text-lg text-yellow-300"></i> 
                    <b><code>${formatNumber(others?.videoData?.image_total_count * toolPrices("image"))}</code></b> 
                    extra credits, totaling 
                    <i class="fa-solid fa-coins text-lg text-yellow-300"></i> 
                    <b><code>${formatNumber(Number(others?.videoData?.amount) + (others?.videoData?.image_total_count * toolPrices("image")))}</code></b>
                    credits by accuracy calculation.
                </p>
            `;
            default: return "Invalid Description";
        }
    }

    // css style
    const inputStyle = 'width: 1rem; height: 1rem; color: #3b82f6; border-width: 1px; border-color: #d1d5db; border-radius: 0.25rem; box-shadow: 0 0 0 2px transparent; outline: 2px solid transparent; outline-offset: 2px;';
    const selectStyle = 'color: #111827; background-color: #f9fafb; border: 1px solid #d1d5db; border-radius: 0.5rem;';

    const result = await Swal.fire({
        background: color,
        customClass: 'my-swal',
        input: kind === "News-Letter" ? 'checkbox' : kind === "cancel-subscription" ? 'select' : false,
        inputAttributes: {
            autocapitalize: "off",
            style: (kind === "cancel-subscription" || kind === "continue-subscription") ? selectStyle : inputStyle,
        },
        title: `<strong class="text-gray-700 dark:text-gray-200">${getDisplayTitle(kind)}</strong>`,
        inputPlaceholder: kind === "News-Letter" ? '<span class="font-semibold text-gray-700 dark:text-gray-300">Subscribe for our newsletter</span>' : false,
        html: getDisplayDescription(kind),
        inputOptions: {
            "default": "Reason for Cancellation",
            "customer_service": "Customer service was less than expected",
            "low_quality": "Quality was less than expected",
            "missing_features": "Some features are missing",
            "switched_service": "I'm switching to a different service",
            "too_complex": "Ease of use was less than expected",
            "too_expensive": "It's too expensive",
            "unused": "I don`t use the service enough",
            "other": "Other reason",
        },

        // --------------- Others ---------------
        // sweet alert icon
        icon: kind === "Low-Credits" ? "error" :
            (kind === "cancel-subscription" || kind === "continue-subscription") ? "question" :
                kind === "Generate-Video" ? "info" : "warning",

        // --------------- confirm button ---------------
        // show confirm button
        showConfirmButton: true,

        // focus confirm button
        focusConfirm: (kind === "cancel-subscription" || kind === "continue-subscription") ? false :
            kind === "accuracy-generation" ? false : true,
        confirmButtonText: kind === "re-generate-options" ? "Re-Generate Images" :
            kind === "Generate-Video" ? generationKind("create_video") :
                kind === "News-Letter" ? generationKind("News-Letter") :
                    kind === "Low-Credits" ? generationKind("Low-Credits") :
                        kind === "cancel-subscription" ? generationKind("cancel-subscription") :
                            kind === "continue-subscription" ? generationKind("continue-subscription") :
                                kind === "accuracy-generation" ? generationKind("accuracy-generation") :
                                    "Re-Generate",

        // confirm button label
        confirmButtonAriaLabel: 'Thumbs up, great!',

        // confirm button color
        confirmButtonColor: kind === "News-Letter" ? "#6C2BD9" :
            (kind === "cancel-subscription" || kind === "continue-subscription") ? "#0D8E3A" :
                kind === "re-generate-options" || "Generate-Video" && "#0D8E3A",

        // --------------- deny button ---------------
        // show deny button
        showDenyButton: kind === "re-generate-options" || kind === "Generate-Video" ? true :
            (kind === "cancel-subscription" || kind === "continue-subscription") ? true :
                kind === "accuracy-generation" ? true : false,

        // focus deny button
        focusDeny: (kind === "cancel-subscription" || kind === "continue-subscription") ? true :
            kind === "accuracy-generation" ? true : false,

        denyButtonText: kind === "Generate-Video" ? generationKind("custom_video") :
            (kind === "cancel-subscription" || kind === "continue-subscription") ? "Cancel" :
                kind === "accuracy-generation" ? "Cancel" : "Re-Generate Prompts",

        // deny button color
        denyButtonColor: (kind === "cancel-subscription" || kind === "continue-subscription") ? "F05252" : "#205EB5",

    });

    if (result.isConfirmed) {

        // Execute handleGenerateImages if the confirm button is clicked
        if (kind === "re-generate-image") {
            handleFunction1(others.data, others.idx);
        } else if (kind === "re-generate-image-prompts") {
            handleFunction1("re-new");
        } else if (kind === "re-generate-audio") {
            handleFunction1();
        } else if (kind === "re-generate-options") {
            // console.log("re-generate-images");
            handleFunction2("re-new");
        } else if (kind === "Generate-Video") {
            handleFunction1(); // Generate Video Automatically
        } else if (kind === "batch-regenrate-images") {
            handleFunction1(
                others?.toolsDataImage,
                others?.handleGenerateImage,
                others?.setIsBulkGeneratingImages
            ); // bul generate images
        } else if (kind === "News-Letter") {
            handleFunction1(result.value);
        } else if (kind === "create-custom-video") {
            handleFunction1();
        } else if (kind === "Low-Credits") {
            handleFunction1(others?.navigate);
        } else if (kind === "cancel-subscription") {
            handleFunction1(result.value); // execute cancell function
        } else if (kind === "continue-subscription") {
            handleFunction1(); // execute continue function
        } else if (kind === "accuracy-generation") {
            handleFunction2([others?.id, ...others?.processAccuracyVideo]); // push new ID that is being processed
            handleFunction1({ ...others?.videoData, _id: others?.id, amount_status: others?.amount_status }); // execute create video with accuracy
            // consoleLog("videoData", { ...others?.videoData, _id: others?.id }); // console log activity
        }

    } else if (result.isDenied) {

        if (kind === "Generate-Video") {
            handleFunction2();
        }

    } else if (
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.close
    ) {
        return "Modal was closed";
    }
};
