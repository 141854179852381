// libraries
import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@nextui-org/react";

// custom components
import { MultipleOption, SingleOption } from "./LeftSideNavPages/SideNavOptions";

const LeftSideNav = (props) => {

    // props
    const {
        toggleShowSideNav,
        handleTheme,
        currentTheme,
    } = props;

    // react dom router navigate
    const navigate = useNavigate();

    return (
        <aside
            id="sidebar"
            className={`fixed flex ${toggleShowSideNav ? "show" : "hidden"} top-0 left-0 z-20 flex-col flex-shrink-0 w-64 h-full pt-16 font-normal duration-75 lg:flex transition-width`}
            aria-label="Sidebar"
        >
            <div
                className="relative flex flex-col flex-1 min-h-0 pt-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
                    <div
                        className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">

                        {/*Top option  */}
                        <ul className="pb-2 space-y-2">
                            {/* Search form mobile view*/}
                            {/* <li>
                                <form action="#" method="GET" className="lg:hidden">
                                    <label htmlFor="mobile-search" className="sr-only">Search</label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <i className="fa-solid fa-magnifying-glass fa-lg text-gray-500" />
                                        </div>
                                        <input
                                            type="text"
                                            name="email"
                                            id="mobile-search"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Search"
                                        />
                                    </div>
                                </form>
                            </li> */}

                            <SingleOption
                                optionName="Dashboard"
                                optionIcon="fa-solid fa-gauge-high"
                                optionLocation="/admin"
                            />

                            <SingleOption
                                optionName="Discord"
                                optionIcon="fa-brands fa-discord"
                                optionLocation="/discord"
                            />

                            <SingleOption
                                optionName="Accounts"
                                optionIcon="fa-solid fa-users"
                                optionLocation="/user-accounts"
                            />

                            {/* <MultipleOption
                                optionName="Blog Posting"
                                optionIcon="fa-solid fa-blog"
                                optiondropDown={[
                                    { label: "Create Blog", type: "", icon: "fa-solid fa-feather", location: "/create-blog" },
                                    { label: "Manage Blog", type: "", icon: "fa-solid fa-pen-to-square", location: "/manage-blog" }
                                ]}
                            /> */}

                        </ul>

                        {/* Top Buttom Option */}
                        {/* <ul className="pt-2 space-y-2">

                            <SingleOption
                                optionName="Settings"
                                optionIcon="fa-solid fa-gear"
                                optionLocation="/settings"
                            />

                        </ul> */}
                    </div>
                </div>

                {/* <div className="absolute bottom-0 w-full p-4 bg-white dark:bg-gray-800">
                    <ul className="pb-2 space-y-2">
                        <SingleOption
                            optionName="Application"
                            optionIcon="fa-solid fa-earth-americas"
                            optionLocation="/menu"
                        />
                    </ul>
                </div> */}

                {/* Buttom options */}
                <div className="absolute bottom-0 left-0 justify-center hidden w-full p-4 space-x-2 bg-white lg:flex dark:bg-gray-800">

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content="App website"
                    >
                        <div onClick={() => window.location.href = "/menu"} className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="text-2xl">
                                <i className="fa-solid fa-globe" />
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content={`Switch to ${currentTheme === "dark" ? "light" : "dark"} mode`}
                    >
                        <div onClick={handleTheme} className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="text-2xl">
                                <i className={`fa-solid ${currentTheme === "dark" ? "fa-sun" : "fa-moon"}`} />
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content="Notifications"
                    >
                        <div onClick={() => navigate("/admin-notif")} className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="text-2xl">
                                <i className="fa-solid fa-bell" />
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content="Settings"
                    >
                        <div className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="text-2xl">
                                <i className="fa-solid fa-gear" />
                            </span>
                        </div>
                    </Tooltip>

                    {/* <div id="tooltip-settings" role="tooltip"
                        className="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                        data-popper-placement="top"
                        // style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(71.5385px, -63.4615px);"
                        style={{ position: 'absolute', inset: 'auto auto 0px 0px', margin: '0px', transform: 'translate(71.5385px, -63.4615px)' }}
                    >
                        Settings page
                        <div
                            className="tooltip-arrow"
                            data-popper-arrow=""
                            // style="position: absolute; left: 0px; transform: translate(54.2308px, 0px);"
                            style={{ position: 'absolute', left: '0px', transform: 'translate(54.2308px, 0px)' }}

                        >
                        </div>
                    </div> */}

                </div>

            </div>
        </aside>
    );
};

export default LeftSideNav;