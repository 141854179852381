// libraries
import React, { useContext, useMemo, useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, Spinner } from "@nextui-org/react";

// custom components
import AdminContext from "../../../components/Admin/AdminContext";
import { convertToCustomerLocalTime } from "../../../functions/general";
import { getStatusColor } from "../../../functions/adminFunctions";

const BillingActivities = () => {

    // context method
    const {

        // billing activities
        allBillingActivities,
        isFetchingBillingActivities,

    } = useContext(AdminContext);

    const [page, setPage] = useState(1);


    // console log activity
    // consoleLog("allBillingActivities", allBillingActivities);

    const rowsPerPage = 10;

    const pages = Math.ceil(allBillingActivities?.message?.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return allBillingActivities?.message?.slice(start, end);
    }, [page, allBillingActivities]);

    return (
        <>

            <Table
                aria-label="Billing Activities"
                bottomContent={
                    <div className="flex w-full justify-center">
                        {pages >= 1 && (
                            <Pagination
                                // isCompact
                                // showControls
                                // showShadow
                                // color="primary"
                                page={page}
                                total={pages}
                                onChange={(page) => setPage(page)}
                                classNames={{
                                    wrapper: "gap-0 overflow-visible h-8 rounded border border-divider",
                                    item: "w-8 h-8 text-small rounded-none bg-transparent",
                                    cursor:
                                        "bg-gradient-to-b shadow-lg from-default-500 to-default-800 dark:from-default-300 dark:to-default-100 text-white font-bold",
                                }}
                            />
                        )}
                    </div>
                }
                classNames={{
                    wrapper: ["h-full", "w-full", "dark:bg-gray-800"],
                    th: ["bg-transparent", "text-default-500", "border-b", "border-divider"],
                    td: [
                        // changing the rows border radius
                        // first
                        "group-data-[first=true]:first:before:rounded-none",
                        "group-data-[first=true]:last:before:rounded-none",
                        // middle
                        "group-data-[middle=true]:before:rounded-none",
                        // last
                        "group-data-[last=true]:first:before:rounded-none",
                        "group-data-[last=true]:last:before:rounded-none",
                    ],
                }}
            >
                <TableHeader classNames="text-orange-400">
                    <TableColumn key="even_id" className="text-lg">Event ID</TableColumn>
                    <TableColumn key="even_name" className="text-lg">Event Name</TableColumn>
                    <TableColumn key="email_and_name" className="text-lg">Email/Name</TableColumn>
                    <TableColumn key="date_and_time" className="text-lg">Created Date&Time</TableColumn>
                </TableHeader>
                <TableBody
                    isLoading={isFetchingBillingActivities ? true : false}
                    loadingContent={<Spinner color="warning" size="lg" label="Loading..." />}
                    emptyContent={"No data found"}
                >
                    {items?.map((item) => {

                        const condition1 = ["payment_method.card_automatically_updated", "charge.succeeded", "charge.failed", "payment_method.attached"];
                        const condition2 = ["payment_intent.succeeded", "payment_intent.canceled"];
                        const condition3 = ["setup_intent.setup_failed"];
                        const condition4 = ["payment_intent.payment_failed"];
                        const condition5 = ["charge.dispute.closed", "charge.dispute.funds_withdrawn"];

                        // console log activity
                        // consoleLog("truncateText", truncateText(item?.activityID));

                        const customerEmail = condition1.includes(item?.eventName) ? item?.activityData?.billing_details?.email || item?.activityData?.billing_details?.name || "Stripe Activity" :
                            condition2.includes(item?.eventName) ? item?.activityData?.charges?.data[0]?.billing_details?.email || item?.activityData?.charges?.data[0]?.billing_details?.name || "Stripe Activity" :
                                condition3.includes(item?.eventName) ? item?.activityData?.last_setup_error?.payment_method?.billing_details?.email || "Stripe Activity" :
                                    condition4.includes(item?.eventName) ? item?.activityData?.last_payment_error?.payment_method?.billing_details?.email || "Stripe Activity" :
                                        condition5.includes(item?.eventName) ? item?.activityData?.evidence?.customer_email_address || "Stripe Activity" :
                                            item?.activityData?.customer_email || item?.activityData?.email || item?.activityData?.customer_details?.email || "Stripe Activity";

                        return (
                            <TableRow key={item?._id}>
                                <TableCell key="even_id" className="text-lg">{item?.activityID || "Stripe System"}</TableCell>
                                <TableCell key="even_name" className={`text-lg ${getStatusColor(item?.eventName)?.color}`}>{item?.eventName}</TableCell>
                                <TableCell key="email_and_name" className="text-lg text-primary-700">{customerEmail}</TableCell>
                                <TableCell key="date_and_time" className="text-lg">{convertToCustomerLocalTime(item?.createdAt)?.moment}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>


        </>
    );
};

export default BillingActivities;