// libraries
import React from "react";

const VG_TableCell4 = (props) => {

    // props
    const {
        data,
        progressstyle,
        filtered_generateVideoStatus,
        processAccuracyVideo,
        handleGenerateAccurateVideo,
    } = props;

    return (
        <div>

            {/* <Chip
                className="capitalize font-semibold"
                color={statusColorMap[data.status]}
                size="base"
                variant="flat"
            >
                {cellValue}
            </Chip> */}

            {filtered_generateVideoStatus?.tools_status?.task_id === data?.task_id && filtered_generateVideoStatus?.tools_status?.status === "processing" ? (
                <span className={progressstyle.processsing_v2} >
                    <span className="relative capitalize text-center px-3 w-auto py-0.5 transition-all ease-in duration-75 bg-white dark:bg-gray-800 rounded-md">
                        {filtered_generateVideoStatus?.tools_status?.message}
                    </span>
                </span>
            ) : data?.content_url === "accuracy_video" ? (
                <button
                    disabled={processAccuracyVideo?.includes(data?._id) || data?.status === "processing" ? true : false}
                    onClick={() => handleGenerateAccurateVideo(data)}
                    type="button"
                    className="text-gray-900 bg-gradient-to-r px-3 py-1.5 from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm text-center me-2 mb-2"
                >
                    Create Video
                </button>
            ) : (
                <span className={data?.report_id ? progressstyle.status : data?.status === "completed" ? progressstyle.complete_v2 : data?.status === "processing" ? progressstyle.processsing_v2 : progressstyle.fail_v2} >
                    <span className="relative capitalize text-center px-3 w-[6rem] py-0.5 transition-all ease-in duration-75 bg-white dark:bg-gray-800 rounded-md">
                        {data?.report_id ? "Reported" : data?.status}
                    </span>
                </span>
            )}
        </div>
    );
};

export default VG_TableCell4;