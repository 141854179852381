// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, CircularProgress, Divider, Image, Link, Progress, Spinner, Tooltip, useDisclosure } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

// custom image
// import NotAvailableImage from "../../pages/shinefy/Tools/Images/NotAvailable.png";
import NotAvailableImage from "../../Images/NotAvailable.png";
import noImageLandScape from "../../Images/no_image_landscape.png";
import noImagePortrait from "../../Images/no_image_portrait.png";

// custom components
import { getCreditBalance, payVideo, recordCredits } from "../../../../../API/account";
import { toolPrices } from "../../../../../Configs/toolPrices";
import { adjustCreditsView } from "../../../../../functions/itemWithExpiry";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import ToolTipDisplay from "../../Components/ToolTip/ToolTipDisplay";
import { alertBeforeAction } from "../../Components/Transcriber/AlertBeforeAction";
import { createCustomMedia, createFinalCustomVideo, generateTheImage, handleUploadMediaSource } from "../../API/toolsAPI";
import consoleLog from "../../../../../functions/consoleLog";
import { checkAllImagesAvailability, checkAudioAvailability, containsUrl, getAspectRatioType } from "../../functions/general";
import { checkVideoAspectRatio, handlePurchaseCredits } from "../../../../../functions/general";
import UserContext from "../../../../../components/UserContext";
import ImagePromptEditor from "../../Components/Modals/ImagePromptEditor";
import MediaView from "../../../../../components/Modals/MediaView";
import VideoTrimmer from "../../Components/Modals/VideoTrimmer";
import PreviewVideo from "../../../../../components/Modals/PreviewVideo";
import VG_CustomMediaGallery from "./VG_CustomMediaGallery";

const VG_CustomMediaSource = (props) => {

    // props
    const {
        setRefetchData,
        accuracyOwnAPI,
        subscriptionData,
        videoData,
        isOldUser,
        enableAccuracy,
        midjourneyAPIKey,
        toolsDataImage,
        isMobile,
        showImageRedMark,
        imageTheme,
        customImageThemePrompt,
        setToolsDataImage,
        handleDownloadMediaFile,
        isDownloading,
        images,
        audioSrc,
        toolsDataAudio,
        setShowImageRedMark,
        avatarOption,
        enableAvatar,
        avatarId,
        selectedTalkingPhoto,
        enableVoice,
        enableLanguange,
        languageID,
        languageName,
        createCreditCalculation,
        videoAspectRatio,
        addAnimation,
        enableCaption,
        backgroundMusic,
        setRefetchToolsData,
        videoSlideOption,
        imagesLoading,
        isCheckedCustomBGM,
        filteredOwnBGMData,
    } = props;

    // context method
    const {

        // Others
        appConfigData,
        toolsDataStatus,
        refetchData: refetchMediaData

    } = useContext(UserContext);

    // appConfigData extracted values
    const config_image = appConfigData[0]?.image;
    const config_caption = appConfigData[0]?.caption;

    // extracted date from videoData
    const aspectRatio = videoData?.aspect_ratio;
    const task_id = videoData?.task_id;
    const videoID = videoData?._id;
    const email = videoData?.email;
    const videoPrompt = videoData?.prompt;
    const videoSource = videoData?.content_url;
    const captionData = videoData?.caption;
    const captionSegments = videoData?.caption?.caption_segments; // always use caption segment for custom media.

    // use refs and navigates
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const mediasourceRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const divRef = useRef(null);

    // local storage
    const selectedRow = localStorage?.getItem("selectedTableRowVideoEdit");

    // next ui components
    const { isOpen: isOpenIPE, onOpen: onOpenIPE, onClose: onCloseIPE } = useDisclosure(); // IPE - (Image Prompt Editor)
    const { isOpen: isOpenMV, onOpen: onOpenMV, onClose: onCloseMV } = useDisclosure(); // MV - (Media View)
    const { isOpen: isOpenVT, onOpen: onOpenVT, onClose: onCloseVT } = useDisclosure(); // VT - (Video Trimmer)
    const { isOpen: isOpenPV, onOpen: onOpenPV, onClose: onClosePV } = useDisclosure(); // PV - (Preview Video)

    // react components
    const [clickedTableRow, setClickedTableRow] = useState(selectedRow ? selectedRow : -1);
    const [imageData, setImageData] = useState([]);
    const [isGeneratingImage, setIsGeneratingImage] = useState([]);
    const [showTrimmerModal, setShowTrimmerModal] = useState(false);
    const [sourceMediaFile, setSourceMediaFile] = useState([]);
    const [isTrimmingVideo, setIsTrimmingVideo] = useState(false);
    const [isUploadingMedia, setIsUploadingMedia] = useState(false);
    const [sourceMdiaStatus, setSourceMdiaStatus] = useState([]);
    const [videoAspecRatio, setVideoAspecRatio] = useState("");
    const [isCreatingVideo, setIsCreatingVideo] = useState(false);
    const [filteredVideoStatus, setFilteredVideoStatus] = useState({});
    const [filteredCustomMediaStatus, setFilteredCustomMediaStatus] = useState({});
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [playPreview, setPlayPreview] = useState(false);
    const [width, setWidth] = useState(0);
    const [isGeneratingCustomMedia, setIsGeneratingCustomMedia] = useState(false);

    // Console log activity
    // consoleLog("aspectRatio", aspectRatio);
    // consoleLog("videoData", videoData);
    // consoleLog("toolsDataStatus", toolsDataStatus);
    // consoleLog("filteredVideoStatus", filteredVideoStatus);
    // consoleLog("filteredCustomMediaStatus", filteredCustomMediaStatus);

    useEffect(() => {
        const handleResize = (entries) => {
            for (let entry of entries) {
                setWidth(entry.contentRect.width);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                resizeObserver.unobserve(divRef.current);
            }
        };
    }, []);

    // check media if video
    const isVideo = (media) => {

        if (media?.image) {
            // execute media checker
            return media?.image?.endsWith('.mp4');
        } else {
            return "";
        }
    };

    // handle edit image function
    const handleEditImageData = (data) => {
        setImageData(data);
        onOpenIPE();
    };

    // handle view image function
    const handlePlayVideo = () => {
        setImageData(videoData);
        onOpenMV(); // open modal
    };

    // filter get rebuild video status from toolsDataStatus via task_id from videoData
    useEffect(() => {
        const filterVideoData = () => {
            const viedoStatus = toolsDataStatus.find((status) => status?.tools_status?.task_id === videoID); // filter data and only extract via task_id
            const custom_media_status = toolsDataStatus.find((status) => status?.tools_status?.type === "create_custom_media" && status?.tools_status?.task_id === task_id); // filter custom media status process
            if (viedoStatus) { setFilteredVideoStatus(viedoStatus?.tools_status); } // set extracted value to "setFilteredVideoStatus" useState
            if (custom_media_status) { setFilteredCustomMediaStatus(custom_media_status?.tools_status); }
        };
        filterVideoData(); // call function
    }, [toolsDataStatus]);

    useEffect(() => {
        let timer;
        if (filteredVideoStatus?.status === "done" && refetchMediaData) {
            timer = setTimeout(() => { // 5 secs delay
                setFilteredVideoStatus({}); // clean the status data
                handlePlayVideo(); // open mediaview modal
            }, 5000); // 1000 milliseconds = 1 second
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [filteredVideoStatus, refetchMediaData]);

    // use effect for toolsDataStatus
    useEffect(() => {
        setSourceMdiaStatus(toolsDataStatus);
    }, [toolsDataStatus]);

    // refetch image data 
    useEffect(() => {
        const refetchImageData = () => {
            setRefetchData(oldVal => oldVal + 1);
        };
        refetchImageData();
    }, [refetchMediaData]);

    // handle selected row
    const handleClickedTableRow = (idx) => {
        setClickedTableRow(idx);
        localStorage?.setItem("selectedTableRowVideoEdit", idx);
    };

    // Function to handle the transition to the next media item in the slideshow.
    const handleMediaChange = () => {
        if (!isLoading) {
            const nextIndex = (currentImageIndex + 1) % images.length;
            setCurrentImageIndex(nextIndex);
        }
    };

    // Effect to manage image transitions based on the duration specified for each image.
    useEffect(() => {
        if (audioLoaded && audioRef.current && images.length > 0) {
            const currentDuration = images[currentImageIndex].total_seconds * 1000;
            const intervalId = setTimeout(handleMediaChange, currentDuration);

            return () => clearTimeout(intervalId);
        }
    }, [audioLoaded, images, currentImageIndex, isLoading]);

    // Effect to control audio playback depending on loading state.
    useEffect(() => {
        if (audioLoaded && audioRef.current) {
            isLoading ? audioRef.current.pause() : audioRef.current.play();
        }
    }, [isLoading, audioLoaded]);

    // Effect to toggle audio playback when 'playPreview' state changes.
    useEffect(() => {
        if (audioRef.current) {
            if (playPreview) {
                audioRef.current.play().catch(consoleLog);
                setAudioLoaded(true);
            } else {
                audioRef.current.pause();
                setAudioLoaded(false);
            }
        }
    }, [playPreview]);

    // Effect to control playback for both audio and video based on 'playPreview' state.
    useEffect(() => {
        // Control audio
        const audioElement = audioRef.current;
        if (audioElement) {
            if (playPreview) {
                audioElement.play().catch(consoleLog);
                setAudioLoaded(true);
            } else {
                audioElement.pause();
                setAudioLoaded(false);
            }
        }

        // Control video
        const videoElement = videoRef.current;
        if (videoElement) {
            if (playPreview) {
                videoElement.play().catch(consoleLog);
            } else {
                videoElement.pause();
            }
        }
    }, [playPreview]);

    // Assuming this is already handling image loading
    useEffect(() => {
        const videoElement = videoRef.current;

        const handleVideoLoaded = () => {
            setIsLoading(false);
            setHasError(false);
        };

        const handleVideoError = () => {
            setIsLoading(false);
            setHasError(true);
        };

        if (videoElement) {
            videoElement.onloadeddata = handleVideoLoaded;
            videoElement.onerror = handleVideoError;
        }

        // Cleanup function
        return () => {
            if (videoElement) {
                videoElement.onloadeddata = null;
                videoElement.onerror = null;
            }
        };
    }, [videoRef]);

    // Function to toggle the 'playPreview' state indicating whether the preview is playing or paused.
    const handlePlayPreview = () => {

        // check if audio is available
        if (!checkAudioAvailability(toolsDataAudio)) {
            ToastEffect("error", "Generate an audio first.");
            return;
        }

        // check if all imges are available
        if (!checkAllImagesAvailability(toolsDataImage)) {
            setShowImageRedMark(true);
            ToastEffect("error", "Incomplete media source.");
            return;
        }

        setPlayPreview(prev => !prev);
    };

    // ----------------------------------------------------------------
    // --- Handle Generate image functionn
    // ----------------------------------------------------------------
    const handleGenerateImage = async (image_data) => {

        // get image price
        const imageCost = config_image;

        // start the partial loading process effect
        setIsGeneratingImage(prevVal => [...prevVal, image_data.number]); // for button loading effect

        // check if no Midjourney API key is provided
        if (enableAccuracy && !midjourneyAPIKey && accuracyOwnAPI) {
            setIsGeneratingImage(prevVal => prevVal.filter(index => index !== image_data.number)); // for button loading effect
            ToastEffect("error", "Please provide the MidJourney auth key first.");
            return;
        }

        // --------------------------------
        // --- Check credit balance first
        // --------------------------------
        if (subscriptionData?.message !== "freeAccess") {

            const ceditBalance = await getCreditBalance(email);

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;
            // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            // console log activity
            // consoleLog("totalCredits", totalCredits);
            // consoleLog("imageCost", imageCost);
            // consoleLog("ceditBalance", ceditBalance);

            if (totalCredits < imageCost) {
                setIsGeneratingImage(prevVal => prevVal.filter(index => index !== image_data.number)); // for button loading effect
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        let midjourney_APIKey;
        let custom_image_theme_config = "";
        let custom_image_theme_content = "";

        // check if own midjourney API key
        if (accuracyOwnAPI && enableAccuracy) {
            midjourney_APIKey = midjourneyAPIKey;
        } else {
            midjourney_APIKey = "";
        }

        // check if image theme is custom
        if (imageTheme === "Custom Theme") {
            custom_image_theme_config = imageTheme;
            custom_image_theme_content = customImageThemePrompt;
        } else {
            custom_image_theme_config = imageTheme;
            custom_image_theme_content = "";
        }

        // dictionary format
        const data_dict = [{
            number: image_data.number,
            text: image_data.content,
            total_seconds: image_data.duration
        }];
        // convert dictinary to string
        const image_prompt = JSON.stringify(data_dict);

        // required param
        const data = {
            image_id: image_data?._id,
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            task_id: task_id,
            kind: "",
            amount: imageCost,
            image_prompt: image_prompt,
            script: videoPrompt,
            midjourney_APIKey: midjourney_APIKey,
            aspect_ratio: image_data.ratio,
            image_number: image_data.number,
            image_theme_config: custom_image_theme_config,
            image_theme_content: custom_image_theme_content
        };

        // --------------------------------
        // --- Console log values
        // --------------------------------
        // consoleLog("handleGenerateImage_data", data);
        // consoleLog("image_data", image_data);
        // consoleLog("check1", enableAccuracy);
        // consoleLog("check2", midjourneyAPIKey);
        // consoleLog("check3", accuracyOwnAPI);

        try {

            // --------------------------------
            // --- process image generation
            // --------------------------------
            await generateTheImage(data)
                .then(async (response) => {

                    // extract task id
                    const taskId = response?.data?.task_id;

                    // set new updated tools ID
                    // const newToolsDataImage = updateToolsDataImage(toolsDataImage, taskId, data?.image_id);
                    // setToolsDataImage(newToolsDataImage);

                    // consoleLog("taskId", taskId);
                    // consoleLog("data", data);
                    // consoleLog("toolsDataImage", toolsDataImage);

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        ToastEffect("error", "Maximum request limit reached.");
                        setIsGeneratingImage(prevVal => prevVal.filter(index => index !== image_data.number)); // for button loading effect
                        return;
                    }

                    // --------------------------------
                    // -- check if taskId did not exist and throw error message 
                    // --------------------------------
                    // if (!taskId) {
                    //     // setIsBulkGeneratingImages(false);
                    //     setIsGeneratingImage(prevVal => prevVal.filter(index => index !== image_data.number));
                    //     ToastEffect("error", "No task ID received!");
                    //     return;
                    // }

                    // --------------------------------
                    // --- set progress task ID
                    // --------------------------------
                    // const newProgress = { task_id: taskId, current: image_data.number };
                    // setTaskIdsInProgress((prevState) => {
                    //     const filteredState = prevState.filter((item) => item.task_id !== taskId);
                    //     return [...filteredState, newProgress];
                    // });

                    // end the partial loading process effect
                    setIsGeneratingImage(prevVal => prevVal.filter(index => index !== image_data.number)); // for button loading effect

                    // --------------------------------
                    // --- payment, record payment, balance adjustment, function 
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        // --------------------------------
                        // --- record credit cost
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_GenerateImage", paymentKind, imageCost);
                    } else {

                        // --------------------------------
                        // --- pay video
                        // --------------------------------
                        await payVideo(email, imageCost, "VG_GenerateImage");

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_GenerateImage", "payment", imageCost);
                        adjustCreditsView("tokenBalance", "pay", imageCost);
                    }

                    // refetch source media data
                    setRefetchData(oldVal => oldVal + 1);
                });

        } catch (error) {
            consoleLog("Error! #generateTheImage", error);
            setIsGeneratingImage(prevVal => prevVal.filter(index => index !== image_data.number)); // for button loading effect
            return;
        }

    };

    // upload custom_image file
    const handleUploadSourceFile = async (sourceMediaFile) => {

        // // log events
        // consoleLog("imageData", imageData);

        // Extract file extension
        const fileExtension = sourceMediaFile[0]?.name?.split(".").pop().toLowerCase();

        // Check file size
        const sizeInMB = sourceMediaFile[0]?.size / (1024 * 1024); // check file size

        // Define the video extensions
        const videoExtensions = ['mp4', 'mov', 'avi', 'wmv'];

        // Define the image extensions
        const imageExtensions = ['png', 'jpg', 'jpeg'];

        // check if file size
        if (sizeInMB > 25) {
            ToastEffect("error", "File size exceeds 25MB");
            return;
        }

        // Check aspect ratio for videos
        if (videoExtensions.includes(fileExtension)) {
            // call the aspect ration checker
            const reseponse = await checkVideoAspectRatio(sourceMediaFile);
            setVideoAspecRatio(reseponse); // set returned data
        }

        // print source data
        // consoleLog("data", data);
        // consoleLog("sourceMediaFile", sourceMediaFile);
        // consoleLog("fileExtension", fileExtension);
        // consoleLog("data_image_id", data?.id);

        // --------------------------------
        // --- Process custom_video
        // --------------------------------
        if (videoExtensions.includes(fileExtension)) { // Check if file is in the allowed type

            // type custom_video
            setShowTrimmerModal(true);
            onOpenVT(); // open modal video trimmer
            setSourceMediaFile(sourceMediaFile[0]);
            // setRefetchData(oldVal => oldVal + 1);

            // --------------------------------
            // --- Process custom_image
            // --------------------------------
        } else if (imageExtensions.includes(fileExtension)) { // Check if file is in the allowed type

            // type custom_image
            setIsUploadingMedia(true);

            // start the partial loading process effect
            setIsGeneratingImage(prevVal => [...prevVal, imageData?.number]); // for button loading effect

            // --------------------------------
            // --- Handle upload media source
            // --------------------------------
            await handleUploadMediaSource(sourceMediaFile[0], "custom_image", imageData, 0, 0, 0, false, imageData?._id)
                .then(async (response) => {

                    // log events
                    // consoleLog("response", response);

                    // const taskId = response?.data?.task_id;

                    // set new updated tools ID
                    // const newToolsDataImage = updateToolsDataImage(toolsDataImage, taskId, imageData?.id);
                    // setToolsDataImage(newToolsDataImage);

                    // --------------------------------
                    // -- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        ToastEffect("error", "Maximum request limit reached.");
                        setIsGeneratingImage(prevVal => prevVal.filter(index => index !== imageData?.number));
                        setIsUploadingMedia(false);
                        return;
                    }

                    // --------------------------------
                    // -- check if taskId did not exist and throw error message 
                    // --------------------------------
                    // if (!taskId) {
                    //     setIsUploadingMedia(false);
                    //     setIsGeneratingImage(prevVal => prevVal.filter(index => index !== imageData?.number));
                    //     ToastEffect("error", "No task ID received!");
                    //     return;
                    // }

                    // --------------------------------
                    // --- set progress task ID
                    // --------------------------------
                    // const newProgress = { task_id: taskId, current: imageData?.number };
                    // setTaskIdsInProgress((prevState) => {
                    //     const filteredState = prevState.filter((item) => item.task_id !== taskId);
                    //     return [...filteredState, newProgress];
                    // });

                    // end the partial loading process effect
                    setIsGeneratingImage(prevVal => prevVal.filter(index => index !== imageData?.number));

                    // refetch source media data
                    setRefetchData(oldVal => oldVal + 1);

                }).catch((error) => {
                    ToastEffect("error", "#handleUploadMediaSource > error", error);
                    setIsGeneratingImage(prevVal => prevVal.filter(index => index !== imageData?.number));
                    setIsUploadingMedia(false);
                });

        } else {
            ToastEffect("error", "Unsupported file format.");
            setIsUploadingMedia(false);
            setIsGeneratingImage(prevVal => prevVal.filter(index => index !== imageData?.number));
            return;
        }
    };

    // handle click upload
    const handleClickUpload = (data) => {
        document.getElementById("up_file").value = ''; // Reset the input value
        document.getElementById("up_file").click();
        setImageData(data);
    };

    // handle preview video
    const handlePreviewVideo = () => {
        // check if audio is available
        if (!checkAudioAvailability(toolsDataAudio)) {
            ToastEffect("error", "Generate an audio first.");
            return;
        }

        // check if all imges are available
        if (!checkAllImagesAvailability(toolsDataImage)) {
            setShowImageRedMark(true);
            ToastEffect("error", "Incomplete media source.");
            return;
        }

        onOpenPV(); // open partial preview video modal
    };

    // ----------------------------------------------------------------
    // --- handle create custom video
    // ----------------------------------------------------------------
    const handleCreateVideo = async () => {

        let avatar_id = "";
        let talking_photo_id = "";
        let language_id = "";
        let language_name = "";
        let BGM_source = "";

        // check if audio is available
        if (!checkAudioAvailability(toolsDataAudio)) {
            ToastEffect("error", "Generate an audio first.");
            return;
        }

        // check if all imges are available
        if (!checkAllImagesAvailability(toolsDataImage)) {
            setShowImageRedMark(true);
            ToastEffect("error", "Incomplete media source.");
            return;
        }

        // check if audio source is valid
        if (!containsUrl(audioSrc)) {
            ToastEffect("error", "Audio source is not valid");
            setIsGeneratingCustomMedia(false);
            return;
        }

        // check if own BGM
        if (isCheckedCustomBGM) {
            BGM_source = filteredOwnBGMData?.media_link || "";
        } else {
            BGM_source = backgroundMusic || "";
        }

        // check if its avatar or image
        if (avatarOption === "avatar") {
            if (enableAvatar) {
                avatar_id = avatarId;
            }
        } else {
            if (enableAvatar) {
                talking_photo_id = selectedTalkingPhoto;
            }
        }

        // check if voice is on
        if (!enableVoice || !enableLanguange) {
            language_id = "";
            language_name = "";
        } else {
            language_id = languageID;
            language_name = languageName;
        }

        // required parameter data
        const data = {
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            task_id: task_id,
            amount: createCreditCalculation,
            dataID: videoID,
            images: images,
            audio: audioSrc,
            // aspect_ratio: videoAspectRatio,
            aspect_ratio: aspectRatio,
            animation: addAnimation,
            caption: enableCaption,
            bg_music: BGM_source,
            isOwnBGM: isCheckedCustomBGM,
            avatar: enableAvatar,
            avatar_id: avatar_id,
            talking_photo_id: talking_photo_id,
            language: enableLanguange,
            languageName: language_name,
            languageID: language_id,
            slide_option: videoSlideOption,
        };

        // set creatring loading effect
        setIsCreatingVideo(true);

        // print data (to be removed)
        // consoleLog("data", data);
        // setIsCreatingVideo(false);

        try {

            // --------------------------------
            // --- Check credit balance first
            // --------------------------------
            // if (subscriptionData?.message !== "freeAccess") {

            //     const ceditBalance = await getCreditBalance(email);

            //     // --------------------------------
            //     // --- Extracting repeated variable calls
            //     // --------------------------------
            //     const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            //     const paidCredits = ceditBalance?.data?.message?.paid || 0;

            //     // --------------------------------
            //     // --- Calculating the total credits and total price
            //     // --------------------------------
            //     const totalCredits = (subscriptionCredits + paidCredits);

            //     if (totalCredits < createCreditCalculation) {
            //         setIsCreatingVideo(false);
            //         await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
            //         return;
            //     }
            // }

            // --------------------------------
            // --- process creating the final custom video || re create video
            // --------------------------------
            await createFinalCustomVideo(data)
                .then(async (response) => {

                    const taskId = response?.data?.task_id;

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        setIsCreatingVideo(false);
                        ToastEffect("error", "Maximum request limit reached.");
                        return;
                    }

                    // --------------------------------
                    // -- check if taskId did not exist and throw error message 
                    // --------------------------------
                    // if (!taskId) {
                    //     setIsCreatingVideo(false);
                    //     ToastEffect("error", "No task ID received!");
                    //     return;
                    // }

                    // --------------------------------
                    // --- process payment
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        // --------------------------------
                        // --- record credit cost
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_RebuildVideo", paymentKind, createCreditCalculation);

                    } else {
                        // --------------------------------
                        // --- pay video
                        // --------------------------------
                        // await payVideo(email, createCreditCalculation, "VG_RebuildVideo");

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_RebuildVideo", "payment", createCreditCalculation);
                    }

                    // finally re-fetch data
                    setRefetchToolsData(oldVal => oldVal + 1);

                });

        } catch (error) {
            setIsCreatingVideo(false);
            consoleLog("handleGenerateVideo > Error", error);
            if (error?.detail) {
                ToastEffect("error", error?.detail);
            } else {
                ToastEffect("error", "Something went wrong! #handleGenerateVideo");
            }
        }
    };

    // handle generate custom media
    const handleGenerateCustomMedia = async () => {

        // set generating
        setIsGeneratingCustomMedia(true);

        // Check if either of these two exist
        if (!email && !task_id) { return; }

        // check if audio source is valid
        if (!containsUrl(audioSrc)) {
            ToastEffect("error", "Audio source is not valid");
            setIsGeneratingCustomMedia(false);
            return;
        }

        // required data to generate custom media
        const requiredData = {
            task_id: task_id,
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            audio_source: audioSrc,
            caption_data: JSON.stringify(captionSegments) || "",
            accuracy: enableAccuracy,
            aspect_ratio: videoAspectRatio,
            // aspect_ratio: aspectRatio,
        };

        // console log activity
        // consoleLog("Custom Media Data", requiredData);

        try {

            // --------------------------------
            // --- Check credit balance first
            // --------------------------------
            if (subscriptionData?.message !== "freeAccess") {

                const ceditBalance = await getCreditBalance(email);

                // --------------------------------
                // --- Extracting repeated variable calls
                // --------------------------------
                const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
                const paidCredits = ceditBalance?.data?.message?.paid || 0;

                // --------------------------------
                // --- Calculating the total credits and total price
                // --------------------------------
                const totalCredits = (subscriptionCredits + paidCredits);

                if (totalCredits < Number(config_caption)) {
                    setIsGeneratingCustomMedia(false);
                    await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                    return;
                }
            }

            // --------------------------------
            // --- process generating custom media
            // --------------------------------
            await createCustomMedia(requiredData)
                .then(async (response) => {

                    // console log response data
                    consoleLog("Response", response?.data);

                    // video task id
                    const taskId = task_id;

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        setIsGeneratingCustomMedia(false);
                        ToastEffect("error", "Maximum request limit reached.");
                        return;
                    }

                    // --------------------------------
                    // -- check if taskId did not exist and throw error message 
                    // --------------------------------
                    if (!taskId) {
                        setIsGeneratingCustomMedia(false);
                        ToastEffect("error", "No task ID received!");
                        return;
                    }

                    // --------------------------------
                    // --- process payment
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        // --------------------------------
                        // --- record credit cost
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_GenerateCustomMedia", paymentKind, Number(config_caption));

                    } else {
                        // --------------------------------
                        // --- pay video
                        // --------------------------------
                        await payVideo(email, Number(config_caption), "VG_GenerateCustomMedia");

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_GenerateCustomMedia", "payment", Number(config_caption));
                        adjustCreditsView("tokenBalance", "pay", Number(config_caption)); // adjust credit UI display
                    }
                });

        } catch (error) {
            setIsGeneratingCustomMedia(false);
            consoleLog("handleGenerateVideo > Error", error);
            if (error?.detail) {
                ToastEffect("error", error?.detail);
            } else {
                ToastEffect("error", "Something went wrong! #handleGenerateVideo");
            }
        }
    };

    return (
        <>
            {images?.length <= 0 && (
                <div className="absolute inset-0 flex items-center justify-center backdrop-blur-sm z-50">
                    <div className="float-end">
                        {imagesLoading ? (
                            <Spinner size="lg" label="Loading..." color="warning" />
                        ) : (
                            <Card
                                shadow="lg"
                                className={`${!videoData && "invisible"} w-auto dark:bg-gray-700`}
                            >
                                <CardHeader className="flex gap-3">
                                    <div className="text-xl font-bold">
                                        <p>No custom media detected!</p>
                                    </div>
                                </CardHeader>
                                <Divider />
                                <CardBody>
                                    <div className="px-1 py-2">
                                        {/* <div className="text-medium font-bold">
                                        <p>Custom Media Tip</p>
                                    </div> */}
                                        <ul className="list-disc pl-5 space-y-1 break-words w-[20rem] md:w-[20rem] lg:w-[30rem]">
                                            <li>Generate <b><i>custom media</i></b> will cost <b><i className="fa-solid fa-coins text-yellow-300" /> {config_caption} Credit(s)</b>.</li>
                                            <li><b className="capitalize"><i>{videoAspectRatio}</i></b> is the selected aspect ratio.</li>
                                            {enableAccuracy ? (
                                                <li><b><i>Slide Accuracy</i></b> is enabled. The <b>slide count</b> will be based on <b>accuracy</b> calculations.</li>
                                            ) : (
                                                <li>For no <b><i>Slide Accuracy</i></b>, the default slide count is 10.</li>
                                            )}
                                        </ul>
                                    </div>
                                </CardBody>
                                <Divider />
                                <CardFooter>
                                    <Tooltip
                                        color="primary"
                                        showArrow={true}
                                        content="Click to generate custom media"
                                    >
                                        <Button
                                            isLoading={isGeneratingCustomMedia}
                                            onClick={handleGenerateCustomMedia}
                                            variant="shadow"
                                            color="secondary"
                                            className={`${!videoData && "invisible"} text-medium`}
                                            startContent={!isGeneratingCustomMedia && (<i className="fa-solid fa-gear" />)}
                                        >
                                            Generate Custom Media
                                        </Button>
                                    </Tooltip>
                                </CardFooter>
                            </Card>
                        )}
                    </div>
                </div >
            )}

            {/* EditVideoMediaSource bottom nav */}
            <div className="flex items-center justify-between">

                <div className="flex items-center justify-end space-x-1 text-gray-800">

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content="Total media source"
                    >
                        <div
                            type="button"
                            className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-200 dark:hover:text-white dark:bg-gray-600"
                        >
                            <i className="fa-solid fa-arrow-up-9-1 mr-1 text-blue-400" />
                            <code>{(toolsDataImage?.length).toLocaleString()}</code>
                        </div>
                    </Tooltip>

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content="Total cost"
                    >
                        <div className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-200 dark:hover:text-white dark:bg-gray-600">
                            <span className="mr-1 text-warning dark:text-yellow-200">
                                <i className="fa-solid fa-coins" />
                            </span>
                            <code>{(toolsDataImage.length * config_image).toFixed(2)}</code>
                        </div>
                    </Tooltip>

                    <Tooltip
                        color="primary"
                        placement="top"
                        showArrow={true}
                        content={
                            <div className="px-1 py-2">
                                <div className="text-small font-bold">Custom Media Tip</div>
                                <ul className="list-disc pl-5 space-y-1 break-words w-[30rem]">
                                    {accuracyOwnAPI ?
                                        <li>Using own API key/auth cost 0 Credit(s).</li> :
                                        <li>
                                            Using this feature will cost you <b><i className="fa-solid fa-coins text-warning dark:text-yellow-300" /> {config_image} Credit(s)</b> per image.
                                            However, you can upload your own custom media, such as images and videos, at no credit cost.
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    >

                        <span className="cursor-help text-warning hover:text-warning/80 dark:text-yellow-300 text-xl dark:hover:text-yellow-400">
                            <i className="fa-solid fa-circle-question" />
                        </span>

                    </Tooltip>

                </div>

                <div className="flex justify-between items-center gap-1">

                    {/* <Button
                        onClick={handlePreviewVideo}
                        size="md"
                    >
                        <i className="fa-solid fa-clapperboard" /> Preview
                    </Button> */}

                    {/* <Tooltip showArrow={true} content="Preview Video">
                        <Button
                            onClick={handlePreviewVideo}
                            color="secondary"
                            className="font-semibold"
                            endContent={<i className="fa-solid fa-clapperboard text-lg" />}
                        />
                    </Tooltip> */}

                    <Tooltip
                        color="primary"
                        showArrow={true}
                        content="Build Video"
                    >
                        <Button
                            isLoading={filteredVideoStatus?.status === "processing" ? true : false}
                            onClick={() => containsUrl(videoSource) ? alertBeforeAction(handleCreateVideo, "function2", "create-custom-video", {}) : handleCreateVideo()}
                            color="primary"
                            className="font-semibold"
                            startContent={filteredVideoStatus?.status !== "processing" && (<i className="fa-solid fa-cog text-lg" />)}
                        >
                            Build Video
                        </Button>
                    </Tooltip>

                </div>

                {/* <div className="flex-shrink-0" /> */}
            </div>

            {/* <ul className="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex dark:divide-gray-600 dark:text-gray-400">
                <li className="w-full">
                    <div className="inline-block w-full p-4 rounded-lg bg-gray-50 focus:outline-none dark:bg-gray-700 dark:border-transparent text-gray-600 dark:text-gray-300 border-gray-100 dark:border-gray-700">
                        Media Source (Videos & Images)
                    </div>
                </li>
            </ul> */}

            <div className="mt-2">

                <div className="lg:flex lg:gap-1">

                    {/* media source */}
                    <div ref={divRef} className="relative grow w-full lg:max-w-[70%] lg:min-w-[40%] mb-2">
                        <div className="max-h-[80vh] overflow-auto space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                            <VG_CustomMediaGallery
                                imagesLoading={imagesLoading}
                                width={width}
                                toolsDataImage={toolsDataImage}
                                setImageData={setImageData}
                                onOpenMV={onOpenMV}
                                isGeneratingImage={isGeneratingImage}
                                handleClickUpload={handleClickUpload}
                                sourceMdiaStatus={sourceMdiaStatus}
                                handleUploadSourceFile={handleUploadSourceFile}
                                inputRef={inputRef}
                                showImageRedMark={showImageRedMark}
                                handleEditImageData={handleEditImageData}
                                handleGenerateImage={handleGenerateImage}
                                alertBeforeAction={alertBeforeAction}
                            />

                        </div>
                    </div>

                    {/* Preview video */}
                    <div className="grow">

                        <div className="rounded-lg border border-gray-200 bg-white py-1 px-3 mb-1 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                            <div className="flex justify-between items-center">

                                <div>
                                    <p>Partial Preview</p>
                                </div>

                                <div className="flex gap-1">

                                    <Tooltip
                                        placement="bottom"
                                        color="primary"
                                        showArrow={true}
                                        content="Play Video"
                                    >
                                        <Button
                                            isIconOnly
                                            size="sm"
                                            color="primary"
                                            aria-label="Play Button"
                                            onClick={handlePlayVideo}
                                            endContent={<i className="fa-solid fa-film text-lg" />}
                                        />
                                    </Tooltip>

                                    <Tooltip
                                        placement="bottom"
                                        color="primary"
                                        showArrow={true}
                                        content={playPreview ? "Pause Preview" : "Play Preview"}
                                    >
                                        <Button
                                            isIconOnly
                                            size="sm"
                                            color="danger"
                                            aria-label="Play Button"
                                            onClick={handlePlayPreview}
                                            // endContent={<i className="fa-solid fa-circle-play text-xl" />}
                                            endContent={<i className={`fa-solid fa-${playPreview ? "pause" : "play"} text-lg`} />}
                                        />
                                    </Tooltip>

                                </div>

                            </div>

                        </div>

                        <div className="relative space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                            {/* button */}
                            {/* <div className="absolute flex items-center justify-center z-20 top-0 right-0 mt-1 mr-1">
                                <Tooltip
                                    placement="bottom"
                                    color="primary"
                                    showArrow={true}
                                    content="Preview Video"
                                >
                                    <Button
                                        size="xl"
                                        color="danger"
                                        aria-label="Play Button"
                                        onClick={handlePlayPreview}
                                        // endContent={<i className="fa-solid fa-circle-play text-xl" />}
                                        endContent={<i className={`fa-solid fa-${playPreview ? "pause" : "play"} text-xl`} />}
                                    />
                                </Tooltip>
                            </div> */}

                            {isLoading && (
                                <div className="flex items-center justify-center w-[30rem] h-[15rem]">
                                    <i className="fa-solid fa-image fa-bounce fa-2xl text-gray-400" />
                                </div>
                            )}

                            {hasError && (
                                <div className="flex items-center justify-center w-[30rem] h-[15rem]">
                                    <img src={NotAvailableImage} alt="error" />
                                </div>
                            )}

                            {isVideo(images[currentImageIndex]) ? (
                                <video
                                    ref={videoRef}
                                    onLoadStart={() => setIsLoading(true)}
                                    onLoadedData={() => {
                                        setIsLoading(false);
                                        if (playPreview) {
                                            videoRef.current.play().catch(consoleLog); // Ensure to catch any errors from the promise
                                        }
                                    }}
                                    onError={() => {
                                        setIsLoading(false);
                                        setHasError(true);
                                    }}
                                    className="max-w-full max-h-full h-auto border-0 rounded-lg"
                                    src={images[currentImageIndex].image}
                                    alt="Slideshow"
                                    style={{ display: isLoading || hasError ? 'none' : 'block' }}
                                    loop={playPreview}
                                />
                            ) : (
                                <img
                                    onLoadStart={() => setIsLoading(true)}
                                    onLoad={() => setIsLoading(false)}
                                    onError={() => setIsLoading(false)}
                                    className="max-w-full max-h-full h-auto border-0 rounded-lg"
                                    src={images[currentImageIndex]?.image || (aspectRatio === "landscape" ? noImageLandScape : noImagePortrait)}
                                    alt="Slideshow"
                                    style={{ display: isLoading || hasError ? 'none' : 'block' }}
                                />
                            )}
                            <audio ref={audioRef} src={audioSrc} loop />
                        </div>
                    </div>

                </div>

                {/* progress status for build video */}
                {filteredVideoStatus?.status === "processing" && (
                    <div className="absolute flex px-10 inset-0 items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
                        <Progress
                            size="md"
                            radius="md"
                            classNames={{
                                base: "max-w-full",
                                track: "drop-shadow-md border border-default",
                                indicator: filteredVideoStatus?.process_type === "sub_process" ? "bg-gradient-to-r from-purple-500 to-pink-500" : "bg-gradient-to-r from-pink-500 to-yellow-500",
                                // indicator: "bg-gradient-to-r from-blue-500 to-purple-500",
                                label: "tracking-wider font-medium text-default-600",
                                value: "text-foreground/60",
                            }}
                            label={filteredVideoStatus?.message || "Processing"}
                            value={filteredVideoStatus?.current || 0}
                            maxValue={filteredVideoStatus?.total || 100}
                            showValueLabel={true}
                        />
                    </div>
                )}

                {/* progress status for custom media */}
                {filteredCustomMediaStatus?.status === "processing" && (
                    <div className="absolute flex px-10 inset-0 items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
                        <Progress
                            size="md"
                            radius="md"
                            classNames={{
                                base: "max-w-full",
                                track: "drop-shadow-md border border-default",
                                indicator: filteredCustomMediaStatus?.process_type === "sub_process" ? "bg-gradient-to-r from-purple-500 to-pink-500" : "bg-gradient-to-r from-pink-500 to-yellow-500",
                                // indicator: "bg-gradient-to-r from-blue-500 to-purple-500",
                                label: "tracking-wider font-medium text-default-600",
                                value: "text-foreground/60",
                            }}
                            // color={filteredVideoStatus?.process_type === "sub_process" ? "secondary" : "warning"}
                            label={filteredCustomMediaStatus?.message || "Processing"}
                            value={filteredCustomMediaStatus?.current || 0}
                            maxValue={filteredCustomMediaStatus?.total || 100}
                            showValueLabel={true}
                        />
                    </div>
                )}

                {/* image prompt editor */}
                <ImagePromptEditor
                    toolsDataImage={toolsDataImage}
                    imageData={imageData}
                    setRefetchData={setRefetchData}
                    mediasourceRef={mediasourceRef}
                    isOpen={isOpenIPE}
                    onClose={onCloseIPE}
                    setToolsDataImage={setToolsDataImage}
                />

                {/* media view modal component */}
                <MediaView
                    handleDownloadMediaFile={handleDownloadMediaFile}
                    isOpen={isOpenMV}
                    onClose={onCloseMV}
                    isDownloading={isDownloading}
                    modalData={imageData}
                    videoData={videoData}
                />

                {/* video trimmer modal component */}
                {showTrimmerModal && ( // didnot remove this to insure to load the modal if "sourceMediaFile" already exist
                    <VideoTrimmer
                        sourceMediaFile={sourceMediaFile}
                        imageData={imageData}
                        setIsTrimmingVideo={setIsTrimmingVideo}
                        isTrimmingVideo={isTrimmingVideo}
                        setIsGeneratingImage={setIsGeneratingImage}
                        isGeneratingImage={isGeneratingImage}
                        setIsUploadingMedia={setIsUploadingMedia}
                        isUploadingMedia={isUploadingMedia}
                        setRefetchData={setRefetchData}
                        isOpen={isOpenVT}
                        onClose={onCloseVT}
                        videoAspecRatio={videoAspecRatio}
                    />
                )}

                {/* video partial preview before re-building */}
                <PreviewVideo
                    isOpen={isOpenPV}
                    onClose={onClosePV}
                    images={images}
                    audioSrc={audioSrc}
                    modalData={videoData}
                />

            </div>
        </>
    );
};

export default VG_CustomMediaSource;