import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco, atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export const renderMessage = (message) => {
  const regex = /```(?:(\w+)\n)?([\s\S]*?)```/g;

  return (
    <>
      {message.split(regex).map((part, index) => {
        if (index % 3 === 1) {
          return null;
        } else if (index % 3 === 2) {
          const language = message.split(regex)[index - 1] || "plaintext";
          const codeSnippet = part.replace(/^\n|\n$/g, "");

          return (
            <div key={index} className="bg-white dark:bg-[#282c34] rounded-md">
              <div className="flex items-center relative text-gray-200 bg-gray-500 dark:bg-gray-800 px-4 py-2 text-base justify-between rounded-t-md">
                {language}
              </div>
              <div className="p-1 overflow-y-auto">
                <SyntaxHighlighter
                  language={language}
                  style={localStorage.theme === "dark" ? atomOneDark : docco}
                  wrapLongLines={true}
                  wrapLines={true}
                >
                  {codeSnippet}
                </SyntaxHighlighter>
              </div>
            </div>
          );
        } else {
          return (<div key={index}>{part}</div>);
        }
      })}
    </>
  );
};