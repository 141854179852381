// libraries
import React from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, Tooltip } from "@nextui-org/react";

// custom components
import { addMarkerToData, removeMarkedData, updateArrayData } from "../../../../../functions/general";
import UserContext from "../../../../../components/UserContext";
import consoleLog from "../../../../../functions/consoleLog";

const ImagePromptEditor = (props) => {

    // props
    const {
        toolsDataImage,
        imageData,
        isOpen,
        onClose,
        setToolsDataImage,
    } = props;

    // context method
    const {

        // debounce updateSourceMediaContentData function
        debounceUpdateSourceMediaContentData,

    } = React.useContext(UserContext);

    // custom variables
    const imagePromptValue = imageData?.content;
    const imageID = imageData?._id;
    const imageNumber = imageData?.number;
    const imageDureation = imageData?.duration;

    const [imagePrompt, setImagePrompt] = React.useState("");
    const [isProcessing, setIsProcessing] = React.useState({});
    const [dataKind, setDataKind] = React.useState("");

    // se existing content to setImagePrompt
    React.useEffect(() => {
        setImagePrompt(imagePromptValue);
    }, [imagePromptValue]);

    // console log activity
    // consoleLog("imageData", imageData);
    // consoleLog("imagePrompt", imageData?.content);

    const handleAutoSaveNewData = async (e, kind) => {

        // variables
        const newValue = e.target.value; // extracted new value

        // call marker for loading effect
        await addMarkerToData(setIsProcessing, imageID);

        // set values
        setImagePrompt(newValue);
        setDataKind(kind);

        if (newValue.length > 0) {
            await saveNewData(newValue, kind, "autoSave");
        }
    };

    // manual save new data
    const handleManualSaveNewData = async () => {

        // check if value exist before executing update
        if (imagePrompt.length > 0 && dataKind.length > 0) {

            // console log call function to save the new data
            await saveNewData(imagePrompt, dataKind, "manualSave");
        }
    };

    // function that will handle to save the new data
    const saveNewData = async (newValue, kind, action) => {

        // conditionally call debounceUpdateContentData to execute update
        if (action === "autoSave") {
            await debounceUpdateSourceMediaContentData(kind, imageID, kind, newValue); // asynchronous for auto save
        } else {
            debounceUpdateSourceMediaContentData(kind, imageID, kind, newValue); // normal for manual save
        }

        // update new data to the array
        const newUpdatedData = await updateArrayData(toolsDataImage, imageID, kind, newValue);
        setToolsDataImage(newUpdatedData); // set the new updated data

        // remove data as marked if process is done
        await removeMarkedData(setIsProcessing, imageID);
    };

    return (
        <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <code>{imageNumber}. Slide Duration {Number(imageDureation).toFixed(2)}</code>
                        </ModalHeader>
                        <ModalBody>

                            <Textarea
                                onChange={(e) => handleAutoSaveNewData(e, "content")}
                                value={imagePrompt}
                                minRows={15}
                                maxRows={15}
                                size="lg"
                                variant="faded"
                                label="Image Prompts"
                                description="Enter a brief image prompt for generating an image.    "
                                className="w-full text-lg"
                                placeholder="Write your image prompt here..."
                            />

                        </ModalBody>

                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-end -mt-8">

                            {isProcessing[imageID] && (

                                <Tooltip
                                    color="primary"
                                    showArrow={true}
                                    content="Saving content data"
                                >
                                    <div className="inline-flex items-center py-1 w-auto font-medium dark:text-gray-200 text-gray-700">
                                        <i className="fa-solid fa-circle-notch fa-spin text-xl" />
                                    </div>
                                </Tooltip>

                            )}

                            <Tooltip
                                color="primary"
                                showArrow={true}
                                content="Manual save"
                            >
                                <Button
                                    onClick={handleManualSaveNewData}
                                    color="primary"
                                    className="rounded-xl lg:md:sm:w-auto py-0.5 px-4 text-base"
                                >
                                    <i className={`fa-solid fa-floppy-disk text-lg mr-1 ${isProcessing[imageID] && "fa-bounce"}`} /> Save
                                </Button>
                            </Tooltip>

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal >
    );
};

export default ImagePromptEditor;