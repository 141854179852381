// libraries
import React from "react";

// custom imports
import { formatNumberValue } from "../../../../../../functions/general";

const VT_TableCell3 = (props) => {

    // props
    const {
        item,
    } = props;

    return (
        <div className="flex flex-col">
            <p className="font-semibold capitalize">
                <code className="text-base">
                    <i className="fa-solid fa-coins text-yellow-300 mr-1" />
                    {formatNumberValue(item?.amount)}
                </code>
            </p>
        </div>
    );
};

export default VT_TableCell3;