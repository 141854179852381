// libraries
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import lottie from "lottie-web";
import { useGoogleLogin } from "@react-oauth/google";

// google captcha imports
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// Images && Logo
import ShineRanker from "../../constant/images/ShineRanker.png";

// custom components
import { loginUser, processAutologin, processPaymentSuccessful, processPurchaseSuccessful, verifyCaptcha, verifyStripeAutoLogin, verifyToken } from "../../API/authAPI";
import ResponseMessage from "../../components/ResponseMessage";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import { checkLoginResponse } from "../../functions/signinFunctions";
import LogoAndTitle from "../../components/LogoAndTitle";
import { alertBeforeAction } from "../shinefy/Tools/Components/Transcriber/AlertBeforeAction";
import WaitingPage from "../shinefy/WaitingPage";
import { authenticateViaGoogle } from "../../API/server";


export default function SignIn() {

    // catch current URL
    const url = new URL(window.location.href);
    const baseUrl = url.origin;

    // react components
    const location = useLocation();
    const navigate = useNavigate();
    const passwordInputRef = useRef(null);
    let animationContainer = useRef();

    // extract path name
    const { pathname, search } = location;

    // Use URLSearchParams with the `search` string from location
    const queryParams = new URLSearchParams(search);

    // extract the "p" and "id" for custom media
    const redirectURL = queryParams.get('sso-auth') || null;
    const paymentStatus = queryParams.get('status') || null;
    const paymentAction = queryParams.get('action') || null;
    const purchaseID = queryParams.get('id') || null;
    const subsID = queryParams.get('subs') || null;

    // google captcha
    const { executeRecaptcha } = useGoogleReCaptcha();

    // google auth config
    const login = useGoogleLogin({
        onSuccess: tokenResponse => onSuccessGoogleAuth(tokenResponse),
        onError: error => onErrorGoogleAuth(error)
    });

    // react components
    const [rememberMe, setRememberMe] = useState(
        localStorage.getItem("isRemember") === "true"
    );

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [responseMessage, setResponseMessage] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isLoggingin, setIsLoggingin] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [publicResult, setPublicResult] = useState(null);
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    // console log activity 
    // consoleLog("redirectURL", redirectURL);
    // consoleLog("paymentStatus", paymentStatus);

    // capture data from the link
    useEffect(() => {

        const captureDataFromLink = async () => {
            // Parse the query string
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            // Convert all query params into a key-value object
            const data = {};
            urlParams.forEach((value, key) => {
                // Handling for nested objects like thrivecart[customer][email]
                const keyParts = key.match(/[^[\]]+(?=])/g) || [key]; // Matches inside brackets or the whole key if no brackets
                let current = data;

                keyParts.forEach((part, index) => {
                    if (index === keyParts.length - 1) {
                        // Last part of the key, assign the value
                        current[part] = value;
                    } else {
                        // Ensure nested object structure is initialized
                        if (!current[part]) current[part] = {};
                        current = current[part];
                    }
                });
            });

            await handleAutoLogin(data);
            // consoleLog("queryData", data);
        };
        captureDataFromLink();
    }, []);

    // capture auto login data from stripe
    // useEffect(() => {
    //     const processStripeAutoLogin = async () => {
    //         if (paymentStatus === "public" && subsID) {

    //             // required data
    //             const requiredData = {
    //                 subsID: subsID
    //             };

    //             // set the data for the process request
    //             await verifyStripeAutoLogin(requiredData)
    //                 .then((response) => {

    //                     // extracted data from response
    //                     const access = response?.data?.access_token;
    //                     const refresh = response?.data?.refresh_token;
    //                     const responseResult = response?.data?.result;

    //                     // set response result for "paymentStatus === "public""
    //                     setPublicResult(responseResult);

    //                     // check if "responseResult" is not equal to "0"
    //                     if (responseResult) {

    //                         // console log activity
    //                         consoleLog("response", response?.data);

    //                         // set refresh and access tokens
    //                         localStorage.setItem('access', access);
    //                         localStorage.setItem('refresh', refresh);

    //                         // finaly navigate to menu
    //                         window.location.href = "/menu";
    //                     }
    //                 });
    //         }
    //     };
    //     processStripeAutoLogin();
    // }, []);

    // stripe auto login function
    const processStripeAutoLogin = async () => {

        // required data
        const requiredData = {
            subsID: subsID
        };

        // set the data for the process request
        await verifyStripeAutoLogin(requiredData)
            .then((response) => {

                // extracted data from response
                const access = response?.data?.access_token;
                const refresh = response?.data?.refresh_token;
                const responseResult = response?.data?.result;

                // set response result for "paymentStatus === "public""
                setPublicResult(responseResult);

                // check if "responseResult" is not equal to "0"
                if (responseResult) {

                    // console log activity
                    consoleLog("response", response?.data);

                    // set refresh and access tokens
                    localStorage.setItem('access', access);
                    localStorage.setItem('refresh', refresh);

                    // finaly navigate to menu
                    window.location.href = "/my_plan";
                }
            });

    };

    // check user if already logged in and access, refresh tokens are valid
    useEffect(() => {
        const verifyIfTokensAreValid = async () => {

            // condition for auto login
            if (paymentStatus === "public" && subsID) {

                // call the function that will process the auto login
                await processStripeAutoLogin();
            }

            // access and refresh from local storage
            const accessToken = localStorage.getItem('access');
            const refreshToken = localStorage.getItem('refresh');

            // required data
            const requiredData = {
                redirect: redirectURL,
                access: accessToken,
                refresh: refreshToken,
            };

            // Verify token to check if the user is authorized
            const response = await verifyToken(requiredData);

            // console log activity
            // consoleLog("response", response?.data);

            // variables
            const responseMessage = response?.data?.message;
            const responseAccessToken = response?.data?.access;
            const responseUserInfo = response?.data?.UserInfo;
            const responseSSOAuthStatus = response?.data?.SSOAuth?.status;
            const responseSSOAuthRedirectLink = response?.data?.SSOAuth?.redirectLink;
            const userEmail = response?.data?.UserInfo?.email;

            // set data for verification
            setResponseData(response?.data?.UserInfo);

            // condition for login and others
            if (responseMessage === "Unauthorized") { return; }
            else if (responseMessage === "refreshed") { // if access and refresh token is refreshed

                localStorage.setItem('access', responseAccessToken);
                localStorage.removeItem('tokenBalance');
                window.location.reload();

            } else if (responseMessage === "refreshExpired") { // if refresh token is expired

                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
                return;

            } else if (paymentStatus === "success") { // if "paymentStatus" is successful process and update subscription data

                // required data
                const requiredData = {
                    email: userEmail,
                    action: paymentAction || ""
                };

                // call the "success" response processor
                await processPaymentSuccessful(requiredData)
                    .then((response) => {
                        window.location.href = "/my_plan";
                    });

            } else if (paymentStatus === "purchase" && purchaseID) {

                // required data
                const requiredData = {
                    email: userEmail,
                    purchaseID: purchaseID || ""
                };

                // call the "success" response processor
                await processPurchaseSuccessful(requiredData)
                    .then((response) => {
                        window.location.href = "/my_plan";

                        // console log activity
                        consoleLog("response", response?.data);
                    });

            } else if (responseUserInfo) { // if "responseUserInfo" data exist. process the SSO authentication

                if (responseSSOAuthStatus === "success") {
                    window.location.href = `${responseSSOAuthRedirectLink}?access=${accessToken}&email=${userEmail}&refresh=${refreshToken}`;
                } else {
                    window.location.href = "/menu";
                }

            }

            consoleLog("pages > Auth > Signin.js > verifyIfTokensAreValid > response", response?.data);
        };
        verifyIfTokensAreValid();
    }, []);

    // load lottie animation
    useEffect(() => {
        const heroAnimation = lottie.loadAnimation({
            container: animationContainer.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shinefy-flying-robot.json",
        });

        return () => {
            heroAnimation.destroy();
        };
    }, [animationContainer]);

    const closeResponseMessage = () => {
        setResponseMessage([]);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    // process email and password
    useEffect(() => {
        const savedUsername = localStorage.getItem("email");
        const savedPassword = localStorage.getItem("password");

        if (savedUsername && savedPassword) {
            setEmail(savedUsername);
            setPassword(savedPassword);
            setRememberMe(true);
        }
    }, []);

    // handle on change value function
    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
        }
    };

    // handle login process
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSubmitting(true);

        // remember my username and password
        if (rememberMe) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
            localStorage.setItem('isRemember', true);
        } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('isRemember');
        }

        // Clear previous response messages
        setResponseMessage([]);

        if (!email || !password) {
            setResponseMessage([{ kind: "error", message: "Email and Password is required." }]);
            setIsSubmitting(false);
            return;
        }

        if (password.length < 8) {
            setResponseMessage([{ kind: "error", message: "Min. of 8 characters required." }]);
            setIsSubmitting(false);
            return;
        }

        if (executeRecaptcha) {
            const token = await executeRecaptcha("signin");
            const captchaResponse = await verifyCaptcha(token);
            if (!captchaResponse?.data?.success === true) {
                setResponseMessage([{ kind: "error", message: captchaResponse?.data?.error }]);
                setIsSubmitting(false);
                return;
            }
        }

        // fix user name to the required format
        const fixedUsername = email.toLowerCase();

        // required data
        const requiredData = {
            redirect: redirectURL,
            email: fixedUsername,
            password: password,
        };

        // log in the user with the required data
        await loginUser(requiredData)
            .then(async (response) => {
                setIsSubmitting(false);

                // variables
                const responseMessage = response?.data?.message;
                const accessToken = response?.data?.access_token;
                const refreshToken = response?.data?.refresh_token;
                const responseSSOAuthRedirectLink = response?.data?.SSOAuth?.redirectLink;
                const responseSSOAuthStatus = response?.data?.SSOAuth?.status;
                const userEmail = response?.data?.email;

                // conditional for login response status
                if (responseMessage) {

                    const checkStatus = checkLoginResponse(responseMessage);
                    setResponseMessage([{ kind: "error", message: checkStatus }]);
                    return;

                } else {

                    localStorage.setItem('access', accessToken);
                    localStorage.setItem('refresh', refreshToken);

                }

                // check if login is an SSOAuth or normal
                if (responseSSOAuthStatus === "success") {

                    window.location.href = `${responseSSOAuthRedirectLink}?access=${accessToken}&email=${userEmail}&refresh=${refreshToken}`;

                } else {

                    navigate('/my_plan');

                }

                consoleLog("pages > Auth > Signin.js > handleSubmit > response", response?.data);

            }).catch((err) => {

                setIsSubmitting(false);
                ToastEffect("error", "Something went wrong! #loginUser");
                consoleLog("pages > Auth > Signin.js > handleSubmit > error", err);

            });

    };

    // validate auto login
    const validateAutoLogin = async (data) => {
        const email = data?.email;
        const orderID = data?.orderID;
        const url = data?.url;
        const hash = data?.hash;

        // process autologin
        await processAutologin(email, orderID, url, hash)
            .then((response) => {

                // hold if not valid
                if (response?.data?.message === "NoData") {
                    setEmail(email);
                    passwordInputRef.current.focus();
                } else {
                    localStorage.setItem('access', response?.data?.access_token);
                    localStorage.setItem('refresh', response?.data?.refresh_token);
                    navigate('/my_plan');
                }
            });
    };

    // handle autologin
    const handleAutoLogin = async (queryData) => {
        const email = queryData?.customer?.email;
        const orderID = queryData?.order_id;
        const url = baseUrl;
        const hash = queryData?.thrivecart_hash;

        const requiredData = {
            email: email,
            orderID: orderID,
            url: url,
            hash: hash,
        };

        if (email && orderID) {
            await validateAutoLogin(requiredData);
        }
    };


    // handle login via discord
    const handleLoginDiscord = () => {
        setIsConnecting(true);
        window.location.href = `${process.env.REACT_APP_SHINEFY_SERVER}/auth/discord?kind=auth`;
    };

    // handle login via shineranker
    const handleLoginShineRanker = () => {
        setIsLoggingin(true);
        window.location.href = process.env.REACT_APP_SHINERANKER_SSO_AUTH;
    };

    // on success authenticate via google
    const onSuccessGoogleAuth = async (googleResponseData) => {

        // trigger authenticating
        setIsAuthenticating(true);

        // call function that will send google response to the server for processing
        await authenticateViaGoogle({ googleResponseData: googleResponseData })
            .then((response) => {

                if (response?.data?.message?.type === "google_auth") {
                    // set access and refresh to local storage
                    localStorage.setItem('access', response?.data?.message?.access_token);
                    localStorage.setItem('refresh', response?.data?.message?.refresh_token);
                    navigate('/my_plan');
                }


                // console log activity
                consoleLog("authenticateViaGoogle", response?.data);

            });
    };

    // on error authenticate via google
    const onErrorGoogleAuth = (error) => {
        consoleLog(error);
    };

    return (
        <div className="min-h-screen text-gray-900 dark:text-gray-200 flex justify-center bg-gradient-to-tr from-blue-800 to-purple-950">

            {isAuthenticating && (
                <div className="absolute inset-0 flex items-center text-lg justify-center z-50 backdrop-blur-sm">
                    {/* <Spinner size="lg" label="Authenticating..." color="warning" /> */}
                    <i className="fa-solid fa-circle-notch fa-spin text-4xl mr-2" /> Loading...
                </div>
            )}

            <div className="max-w-screen-xl m-0 sm:m-20 bg-gray-800/90 shadow sm:rounded-lg flex justify-center flex-1">
                <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                    {/* <div>
                        <img
                            src="https://storage.googleapis.com/devitary-image-host.appspot.com/15846435184459982716-LogoMakr_7POjrN.png"
                            className="w-32 mx-auto" />
                    </div> */}
                    <div className="mt-12 flex flex-col items-center">
                        <h1 className="text-2xl xl:text-3xl font-extrabold text-gray-100">
                            Sign in to Shinefy
                        </h1>
                        <div className="w-full flex-1 mt-8">

                            <div className="flex flex-col items-center">

                                {/* <button
                                    onClick={handleLoginShineRanker}
                                    disabled={isLoggingin ? true : false}
                                    className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-gray-800/90 border border-gray-500 text-gray-300 hover:bg-gray-700 flex items-center justify-start transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
                                >
                                    <div className="bg-purple-700 p-1 ml-4 rounded-full">
                                        {isLoggingin ? (
                                            <i className="fa-solid fa-spinner fa-spin-pulse text-3xl w-9 h-9" />
                                        ) : (
                                            <img decoding="async" data-nimg="intrinsic" src={ShineRanker} className="w-8 h-8" alt="Image" />
                                        )}
                                    </div>
                                    <span className="ml-4">
                                        Login via Shine Ranker
                                    </span>
                                </button> */}

                                <div className="flex justify-between items-center space-x-2">
                                    <button
                                        onClick={() => login()}
                                        disabled={isConnecting ? true : false}
                                        className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-gray-800/90 border border-gray-500 text-gray-300 hover:bg-gray-700 flex items-center justify-start transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline mt-3 pr-[1.6rem]"
                                    >
                                        <div className="bg-gray-600 p-1 ml-4 rounded-full">
                                            {isConnecting ? (
                                                <i className="fa-solid fa-spinner fa-spin-pulse text-3xl w-9 h-9" />
                                            ) : (
                                                <i className="fa-brands fa-google text-2xl w-8 h-8 text-gray-100" />
                                            )}
                                        </div>
                                        <span className="ml-4">
                                            Google
                                        </span>
                                    </button>

                                    <button
                                        onClick={handleLoginDiscord}
                                        disabled={isConnecting ? true : false}
                                        className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-gray-800/90 border border-gray-500 text-gray-300 hover:bg-gray-700 flex items-center justify-start transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline mt-3 pr-[1.6rem]"
                                    >
                                        <div className="bg-[#7289da] p-1 ml-4 rounded-full">
                                            {isConnecting ? (
                                                <i className="fa-solid fa-spinner fa-spin-pulse text-3xl w-9 h-9" />
                                            ) : (
                                                <i className="fa-brands fa-discord text-2xl w-8 h-8 text-gray-100" />
                                            )}
                                        </div>
                                        <span className="ml-4">
                                            Discord
                                        </span>
                                    </button>
                                </div>

                                {/* google login */}
                                {/* <GoogleLogin
                                    onSuccess={onSuccessGoogleAuth}
                                    onError={onErrorGoogleAuth}
                                /> */}

                            </div>

                            <div className="my-12 border-b text-center">
                                <div
                                    className="leading-none px-2 inline-block text-sm text-gray-300 tracking-wide font-medium bg-gray-800/90 transform translate-y-1/2">
                                    Or sign in with e-mail
                                </div>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="mx-auto max-w-xs">

                                    <input
                                        value={email}
                                        onChange={handleChange}
                                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 dark:bg-gray-700 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                    />

                                    <div className="relative mt-5">
                                        <input
                                            ref={passwordInputRef}
                                            value={password}
                                            onChange={handleChange}
                                            className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 dark:bg-gray-700 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                                            type={passwordVisible ? "text" : "password"} // Toggle password visibility
                                            name="password"
                                            placeholder="Password"
                                        />
                                        <span
                                            className="absolute inset-y-0 right-3 top-1/2 transform text-gray-500 -translate-y-1/2 flex items-center cursor-pointer"
                                            onClick={togglePasswordVisibility}
                                            type="button"
                                        >
                                            {passwordVisible ? (
                                                <i className="fa-solid fa-eye-slash" />
                                            ) : (
                                                <i className="fa-solid fa-eye" />
                                            )}
                                        </span>
                                    </div>

                                    <div className="flex items-center justify-between mt-5">
                                        {/* <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input
                                                    checked={rememberMe}
                                                    onChange={(event) => setRememberMe(event.target.checked)}
                                                    id="remember" aria-describedby="remember" type="checkbox"
                                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-700 focus:ring-3 focus:ring-blue-300"
                                                />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="remember" className="text-gray-500">Remember me</label>
                                            </div>
                                        </div> */}
                                        <a href="/forgot_password" className="text-sm font-medium text-blue-500 hover:underline">Forgot password?</a>
                                    </div>

                                    {/* <button
                                        className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-between focus:shadow-outline focus:outline-none"
                                    >
                                        {!isSubmitting && (
                                            <i className="fa-solid fa-spinner fa-spin-pulse ml-4 fa-lg" />
                                        )}

                                        <div className="flex-grow flex items-center justify-center">
                                            <i className="fa-solid fa-right-to-bracket" />
                                            <span className="ml-3">
                                                Sign in
                                            </span>
                                        </div>
                                    </button> */}

                                    <button
                                        disabled={isSubmitting ? true : false}
                                        type="submit"
                                        className="relative flex w-full justify-center items-center mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out focus:shadow-outline focus:outline-none"
                                    >
                                        {isSubmitting && (
                                            <i className="fa-solid fa-spinner fa-spin-pulse absolute left-4 fa-lg" />
                                        )}
                                        <div className="flex-grow flex items-center justify-center">
                                            <i className="fa-solid fa-right-to-bracket" />
                                            <span className="ml-3">
                                                Sign in
                                            </span>
                                        </div>
                                    </button>


                                    <p className="mt-6 text-base text-gray-400 text-center">
                                        Don’t have an account yet? <a href="/register" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Sign up</a>
                                    </p>

                                    <div className="mt-3 mb-2">
                                        {responseMessage.length > 0 && (
                                            <ResponseMessage
                                                responseMessage={responseMessage}
                                                closeResponseMessage={closeResponseMessage}
                                            />
                                        )}
                                    </div>

                                    {/* <p className="mt-6 text-xs text-gray-600 text-center">
                                        I agree to abide by templatana's
                                        <a href="#" className="border-b border-gray-500 border-dotted">
                                            Terms of Service
                                        </a>
                                        and its
                                        <a href="#" className="border-b border-gray-500 border-dotted">
                                            Privacy Policy
                                        </a>
                                    </p> */}

                                </div>
                                <GoogleReCaptcha action="signin" />
                            </form>

                        </div>
                    </div>
                </div>

                {/* <div className="flex-1 bg-indigo-500/50 text-center hidden lg:flex justify-center border-r-0 rounded-r-lg">
                    <div
                        ref={animationContainer}
                        className="m-12 xl:m-16 w-[60%] h-auto bg-contain bg-center bg-no-repeat"
                    >
                    </div>
                </div> */}

                <div className="flex-1 bg-indigo-500/50 text-center hidden lg:flex justify-center border-r-0 rounded-r-lg bg-cover bg-center"
                    style={{ backgroundImage: 'url(/assets/images/cover.png)' }}>
                    <div
                        ref={animationContainer}
                        className="m-12 xl:m-16 w-[60%] h-auto bg-contain bg-center bg-no-repeat"
                    >
                    </div>
                </div>

            </div>

            {/* <div
                className="REMOVE-THIS-ELEMENT-IF-YOU-ARE-USING-THIS-PAGE hidden treact-popup fixed inset-0 flex items-center justify-center"
            // style="background-color: rgba(0,0,0,0.3);"
            >
                <div className="max-w-lg p-8 sm:pb-4 bg-white rounded shadow-lg text-center sm:text-left">

                    <h3 className="text-xl sm:text-2xl font-semibold mb-6 flex flex-col sm:flex-row items-center">
                        <div className="bg-green-200 p-2 rounded-full flex items-center mb-4 sm:mb-0 sm:mr-2">
                            <svg className="text-green-800 inline-block w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                                <path
                                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z">
                                </path>
                            </svg>
                        </div>
                        Free TailwindCSS Component Kit!
                    </h3>
                    <p>I recently released Treact, a <span className="font-bold">free</span> TailwindCSS Component Kit built with React.
                    </p>
                    <p className="mt-2">It has 52 different UI components, 7 landing pages, and 8 inner pages prebuilt. And they are
                        customizable!</p>
                    <div className="mt-8 pt-8 sm:pt-4 border-t -mx-8 px-8 flex flex-col sm:flex-row justify-end leading-relaxed">
                        <button
                            className="close-treact-popup px-8 py-3 sm:py-2 rounded border border-gray-400 hover:bg-gray-200 transition duration-300">Close</button>
                        <a className="font-bold mt-4 sm:mt-0 sm:ml-4 px-8 py-3 sm:py-2 rounded bg-purple-700 text-gray-100 hover:bg-purple-900 transition duration-300 text-center"
                            href="https://treact.owaiskhan.me" target="_blank">See Treact</a>
                    </div>
                </div>
            </div> */}

            {paymentStatus === "public" && subsID && publicResult === 1 && (
                <WaitingPage />
            )}

            {(paymentStatus || redirectURL) && responseData && (
                <WaitingPage />
            )}

        </div>
    );
}