// table labels
const INITIAL_VISIBLE_COLUMNS = ["process", "file_type", "cost", "status", "actions"];

// column names
const columns = [
    { name: "Process Name", uid: "process", sortable: true },
    { name: "Type", uid: "file_type", sortable: true },
    { name: "Process Cost", uid: "cost", sortable: true },
    { name: "Process Status", uid: "status", sortable: true },
    { name: "Actions", uid: "actions" },
];

// process tatus
const statusOptions = [
    { name: "Completed", uid: "completed" },
    { name: "Failed", uid: "failed" },
    { name: "Error", uid: "error" },
    { name: "Processing", uid: "processing" },
    { name: "Reported", uid: "reported" },
];

// uploaded types
const media_type = ["wav", "flac", "aac", "ogg", "m4a", "wma", "mp4", "avi", "mov", "mkv", "wmv", "flv"];
const file_type = ["pdf", "txt", "word", "csv"];
const audio_type = ["wav", "flac", "aac", "ogg", "m4a", "wma"];
const video_type = ["mp4", "avi", "mov", "mkv", "wmv", "flv"];

// progress styles
const progressstyle = {
    processsing: "bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-purple-100 dark:bg-gray-700 dark:border-purple-500 dark:text-purple-400",
    complete: "bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500",
    fail: "bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-red-100 dark:border-red-400 dark:bg-gray-700 dark:text-red-400",
    processsing_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800",
    complete_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-white focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800",
    fail_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800",
    status: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400",
};

export { INITIAL_VISIBLE_COLUMNS, columns, statusOptions, media_type, file_type, audio_type, video_type, progressstyle };