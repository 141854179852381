// react dependency libraries
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Progress, useDisclosure } from "@nextui-org/react";
import { useDropzone } from "react-dropzone";
import { Tooltip } from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import debounce from 'lodash.debounce';

// import custom css
import "react-tooltip/dist/react-tooltip.css";

// custom hooks and components
import { ToastEffect } from "../../../../functions/ToastEffect";
import { toolPrices, transcribePrices } from "../../../../Configs/toolPrices";
import { deleteGeneratedVideo, handleCheckLink, handleUploadMediaFile, updateContentData, videoTranscriber } from "../API/toolsAPI";
import { saveConfig } from "../API/configDBAPI";
import { cantProcessLink } from "../Components/SweetAlerts/transcriberSwal";
import { getCreditBalance, payVideo, recordCredits } from "../../../../API/account";
import { adjustCreditsView } from "../../../../functions/itemWithExpiry";
import { languageList } from "../../../../Configs/languageList";
import { alertBeforeAction } from "../Components/Transcriber/AlertBeforeAction";
import { cachedToolsConfiguration, getCachedToolsConfiguration, handlePurchaseCredits } from "../../../../functions/general";
import consoleLog from "../../../../functions/consoleLog";
import UserContext from "../../../../components/UserContext";
import TranscriberModalView from "../Components/Transcriber/TranscriberModalView";
import ToolTipDisplay from "../Components/ToolTip/ToolTipDisplay";
import TranscriberOption from "../Components/Transcriber/TranscriberOption";
import percentageCalculator from "../functions/calculations";
import ModalV2 from "../Components/ModalV2";
import RightSideTable from "./components/RightSideTable";
import LeftSideMenu from "./components/LeftSideMenu";
import { file_type, media_type } from "./components/data";
import ModalView from "./components/ModalView";

// VideoTranscriber Component
const VideoTranscriber = () => {

    // react context
    const {

        // others
        userData,
        appConfigData,
        configData,
        subscriptionData,
        userConfig,
        toolsDataStatus,
        refetchData,
        setRefetchToolsConfigData,

        // For transcriber data params
        transcriberData,
        setTranscriberData,
        itemsPerPage,
        totalSumTranscriberData,
        setTranscriberParam,
        transcriberPageCount,
        setRefetchTranscriberData,

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // react location
    const location = useLocation();

    // useRef and useNavigate
    // const taskArrayRef = useRef([]);
    const modalTranscriptionView = useRef(null);
    const navigate = useNavigate();

    // important extracted values
    const email = userData?.UserInfo?.email;
    const isOldUser = userData?.UserInfo?.is_oldSubs;

    // appConfigData extracted values
    const config_defaultPrice = appConfigData[0]?.default;
    const config_animation = appConfigData[0]?.animation;
    const config_caption = appConfigData[0]?.caption;
    const config_enhancer = appConfigData[0]?.enhancer;
    const config_language = appConfigData[0]?.language;

    // page path name
    const { pathname } = location;

    // console.log(useContext(UserContext));

    const _sourceLink = localStorage.getItem("sourceLink");

    // next ui components
    const { isOpen: isOpenTMV, onOpen: onOpenTMV, onClose: onCloseTMV } = useDisclosure(); // TMV - (Transcriber Modal View)

    // use states with values from database
    const [transcriberEnhancer, setTranscriberEnhancer] = useState(
        getCachedToolsConfiguration("enhancer", configData)?.config === "true"
    );
    const [transcriberLanguage, setTranscriberLanguage] = useState(
        getCachedToolsConfiguration("language", configData)?.config === "true"
    );
    const [transcriberLanguageID, setTranscriberLanguageID] = useState(
        getCachedToolsConfiguration("languageID", configData)?.normal_config
        || languageList[0]?.language_id // default en
    );
    const [transcriberLanguageName, setTranscriberLanguageName] = useState(
        getCachedToolsConfiguration("languageName", configData)?.normal_config
        || ""
    );

    // common use states
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [transcribing, isTranscribing] = useState(false);
    const [sourceLink, setSourceLink] = useState(_sourceLink);
    const [creditCalculation, setCreditCalculation] = useState(0);
    const [creditVideoCalculation, setCreditVideoCalculation] = useState(0);
    const [checkingLink, isCheckingLink] = useState(false);
    const [sourceType, setSourceType] = useState("");
    const [selectedURL, setSelectedURL] = useState("");
    const [URLSize, setURLSize] = useState(0);
    const [showViewModal, setShowViewModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [modalIdx, setModalIdx] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingIDX, setDeletingIDX] = useState(null);
    const [errorLink, isErrorLink] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [transcriberOption, setTranscriberOption] = useState(userConfig?.userConfig);
    const [videoOption, setVideoOption] = useState(userConfig?.userConfig);
    const [elevanLabsAPIKey, setElevanLabsAPIKey] = useState(userConfig?.userConfig?.elevenlabsAPIKey);
    const [showModalPlayVideo, setShowModalPlayVideo] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [toDeleteVideoID, setToDeleteVideoID] = useState(0);
    const [toDeleteVideoIdx, setToDeleteVideoIdx] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");

    // console log activity
    // consoleLog("transcriberData", transcriberData);
    // console.log("transcriberConfig", userConfig?.userConfig?.elevenlabsAPIKey);

    // handle open (Modal Delete)
    const openModal = () => {
        setIsModalOpen(true);
    };

    // handle clode (Modal Delete)
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Debounced function for anti spam
    const saveToDatabase = useCallback(
        debounce(async (data) => {

            try {

                // call api to update content
                await updateContentData(data)
                    .then(() => {
                        updateTranscriberData(data?.data_id, data?.kind, data?.value,);
                    });

                // console log activity
                console.log("Saving to database:", data);

            } catch (err) {
                ToastEffect("error", "Error updating title!");
                consoleLog("Error! #updateContentData", err?.message);
            }

        }, 500), // 500ms delay
        []
    );

    // update data from array setTranscribedData
    const updateTranscriberData = (dataID, dataKind, dataValue) => {

        // variables
        let kind = dataKind;

        // conditional function
        if (dataKind === "content") {
            kind = "content_url";
        }

        // Find the index of the item with the matching _id
        const index = transcriberData.findIndex(item => item._id === dataID);

        // Check if the item is found and the dataKind is a valid key
        if (index !== -1 && (kind === "title" || kind === "content_url")) {
            // Update the specified field with the new value
            transcriberData[index][kind] = dataValue;
        } else {
            consoleLog("Error!", "Item not found or invalid dataKind");
        }
    };

    // handle edit title
    const handleEditTitle = (e, data) => {

        // extracted value and kind
        const inputValue = e.target.value;

        // set value in useState to be usable
        setTitle(inputValue);

        // required data
        const requiredData = {
            value: inputValue,
            data_id: data?._id,
            user_auth: process.env.REACT_APP_AUTH,
            kind: "title"
        };

        // save to database code here
        saveToDatabase(requiredData);

        // console log activity
        // consoleLog("contextTitle", inputValue);
        // consoleLog("modalData", data);
    };

    // handle edit context
    const handleEditContext = (e, data) => {

        // extracted value and kind
        const inputValue = e.target.value;

        // set value in useState to be usable
        setContent(inputValue);

        // required data
        const requiredData = {
            value: inputValue,
            data_id: data?._id,
            user_auth: process.env.REACT_APP_AUTH,
            kind: "content"
        };

        // save to database code here
        saveToDatabase(requiredData);

        // console log activity
        // consoleLog("contextData", inputValue);
        // consoleLog("modalData", data);
    };

    // handle delete on click (Modal Delete)
    const handleDeleteOnclick = (dataID, idx) => {
        // consoleLog("dataID", dataID);
        openModal();
        setToDeleteVideoID(dataID);
        setToDeleteVideoIdx(idx);
    };

    // calculate generate video total cost
    useEffect(() => {
        let value = config_defaultPrice;
        if (videoOption?.animation) value += config_animation;
        if (videoOption?.caption) value += config_caption;
        setCreditVideoCalculation(value);
    }, [
        videoOption
    ]);

    //  Drop zone for upload function
    const {
        getRootProps,
        getInputProps,
        // isFocused,
        // isDragAccept,
        // isDragReject,
        acceptedFiles,
        // open
    } = useDropzone({
        accept: {
            // Video formats
            'video/mp4': ['.mp4', '.MP4'],
            'video/x-msvideo': ['.avi'],
            'video/quicktime': ['.mov'],
            'video/x-matroska': ['.mkv'],
            'video/x-ms-wmv': ['.wmv'],
            'video/x-flv': ['.flv'],

            // Audio formats
            'audio/wav': ['.wav'],
            'audio/flac': ['.flac'],
            'audio/aac': ['.aac'],
            'audio/ogg': ['.ogg'],
            'audio/mp4': ['.m4a'],
            'audio/x-ms-wma': ['.wma'],

            // Document formats
            'application/pdf': ['.pdf'],
            'text/plain': ['.txt'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'text/csv': ['.csv'],
        },
        noClick: true,
        noKeyboard: true,
        multiple: false,
    });

    // is mobile function
    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    // transcriber price cost calculation
    useEffect(() => {
        let value = transcribePrices();

        if (acceptedFiles && acceptedFiles.length > 0) {
            const mbSize = acceptedFiles[0].size / (1024 * 1024); // Fixed this line
            const finalVal = transcribePrices("file") * (mbSize / 10);
            value += finalVal;
            // consoleLog("value", mbSize, finalVal);
        }
        if (sourceLink && URLSize) {
            const mbSize = URLSize / (1024 * 1024); // Fixed this line
            const finalVal = transcribePrices("file") * (mbSize / 10);
            value += finalVal;
            // consoleLog("value", mbSize, finalVal);
        }
        if (transcriberOption?.enhancer) { value += config_enhancer; }
        if (transcriberOption?.language) { value += config_language; }
        if (videoOption?.animation) value += config_animation;
        if (videoOption?.caption) value += config_caption;

        setCreditCalculation(value);
        setUploadProgress(0); // reset upload progress value
    }, [acceptedFiles, URLSize, selectedURL, transcriberOption]);

    // handle source link
    const handleSourceLink = async (e) => {
        setSourceLink(e.target.value);
        localStorage.setItem("sourceLink", e.target.value);
        await checkLink(e.target.value);
    };

    // handle accepted files
    const files = acceptedFiles.map((file) => {
        const fileSizeInMB = (file.size) / (1024 * 1024);
        const formattedSize = fileSizeInMB.toFixed(2).toLocaleString();
        return (
            <span key={file.path}>
                {file.path} - {formattedSize} MB
            </span>
        );
    });

    // transcriber source link
    useEffect(() => {
        if (acceptedFiles.length > 0) {
            //  clean source link
            localStorage.removeItem("sourceLink");
            setSourceLink("");
        }
    }, [acceptedFiles]);

    // URL extractor method
    const getSelectedURLFromObject = (obj) => {
        // Normalizing keys to uppercase
        const normalizedObj = Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                // Removing extra text and spaces, keeping only resolution part
                const normalizedKey = key.split('\n')[0].trim().toUpperCase();
                return [normalizedKey, value];
            })
        );

        // if response is failed
        if (normalizedObj?.STATUS === "failed") {
            isCheckingLink(false);
            isErrorLink(true);
            ToastEffect("error", normalizedObj?.MESSAGE);
            return;
        }

        if (normalizedObj["1080P"] && normalizedObj["1080P"]?.size !== "Unknown size" && getSizeInBytes(normalizedObj["1080P"]?.size) < 25000000) { // facebook, youtube
            return {
                media: normalizedObj["1080P"]?.url,
                size: normalizedObj["1080P"]?.size || "10.50 MB"
            };
        } else if (normalizedObj["720P"] && normalizedObj["720P"]?.size !== "Unknown size" && getSizeInBytes(normalizedObj["720P"]?.size) < 25000000) { // facebook, youtube
            return {
                media: normalizedObj["720P"]?.url,
                size: normalizedObj["720P"]?.size || "10.50 MB"
            };
        } else if (normalizedObj["360P"] && normalizedObj["360P"]?.size !== "Unknown size" && getSizeInBytes(normalizedObj["360P"]?.size) < 25000000) { // facebook, youtube
            return {
                media: normalizedObj["360P"]?.url,
                size: normalizedObj["360P"]?.size || "10.50 MB"
            };
        } else if (normalizedObj["VIDEO"]) { // twitter 1
            return {
                media: normalizedObj["VIDEO"]?.url,
                size: normalizedObj["VIDEO"]?.size || "10.50 MB"
            };
        } else if (normalizedObj?.DATA?.tweetResult?.result?.legacy?.entities?.media[0]?.video_info?.variants[1]?.url) { // twitter 2
            return {
                media: selectTheCorrectMedia(normalizedObj?.DATA?.tweetResult?.result?.legacy?.entities?.media[0]?.video_info?.variants),
                size: "10.50 MB"
            };
        } else if (normalizedObj?.DATA?.shortcode_media?.video_url) { // instagram
            return {
                media: normalizedObj?.DATA?.shortcode_media?.video_url,
                size: "10.50 MB"
            };
        } else if (normalizedObj?.PLAYABLE_URL) { // facebook
            return {
                media: normalizedObj?.PLAYABLE_URL,
                size: "10.50 MB"
            };
        } else if (normalizedObj?.FORMATS[6]?.url) { // tiktok, youtube
            return {
                media: normalizedObj?.FORMATS[6]?.url,
                size: normalizedObj?.FORMATS[6]?.filesize
            };
        }
    };

    // sort and select the correct media for twitter 2
    const selectTheCorrectMedia = (variants) => {
        // Filter out only the objects with content_type 'video/mp4'
        const mp4Variants = variants.filter(variant => variant.content_type === "video/mp4");

        // Sort the filtered array by bitrate in descending order
        mp4Variants.sort((a, b) => b.bitrate - a.bitrate);

        // The first element is now the highest bitrate with the correct content_type
        const highestBitrateVariant = mp4Variants[0]?.url;

        // return the result
        return highestBitrateVariant;
    };

    // media size generator incase size does not exist
    function generateRandomSevenDigitNumber() {
        // First digit is randomly selected from 2, 3, 4, or 5
        let firstDigit = Math.floor(Math.random() * 4) + 2; // Generates 2, 3, 4, or 5

        // Generate the remaining six digits
        let remainingDigits = "";
        for (let i = 0; i < 6; i++) {
            remainingDigits += Math.floor(Math.random() * 10); // Generates a digit from 0 to 9
        }

        // Combine first digit and remaining digits
        return parseInt(firstDigit.toString() + remainingDigits, 10);
    }

    // convert string to size (Ex. 1.0 MB to 1000000)
    function getSizeInBytes(input) {
        // Check if input is not a string
        if (typeof input !== 'string') {
            // Return input directly if it's a number
            if (typeof input === 'number') {
                if (input < 2097152) {
                    return generateRandomSevenDigitNumber();
                } else {
                    return input;
                }
            }
            return null; // Return null or handle non-string, non-number inputs as needed
        }

        const units = {
            'B': 1,
            'KB': 1024,
            'MB': 1024 * 1024,
            'GB': 1024 * 1024 * 1024,
            // Add more if needed...
        };

        // Extract number and unit from the string
        const regex = /([\d.]+)\s?(B|KB|MB|GB)/i;
        const match = input.match(regex);

        if (!match) return null;

        const value = parseFloat(match[1]);
        const unit = match[2].toUpperCase();

        if (!units[unit]) return null;

        const sizeCalculation = Math.floor(value * units[unit]);

        if (sizeCalculation < 2097152) {
            return generateRandomSevenDigitNumber();
        } else {
            return sizeCalculation;
        }
    }

    // specific source link checker.
    const extractSpecificURL = async (url) => {
        try {
            // Parse the URL
            const parsedUrl = new URL(url);

            if (parsedUrl.hostname === "www.instagram.com") {
                // Reconstruct the Instagram URL
                const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname}`;
                return baseUrl;
            } else if (parsedUrl.hostname === "www.youtube.com" || parsedUrl.hostname === "youtu.be") {
                // Reconstruct the YouTube URL
                const videoId = parsedUrl.searchParams.get("v");
                const baseUrl = videoId ? `${parsedUrl.protocol}//${parsedUrl.hostname}/watch?v=${videoId}` : url;
                return baseUrl;
            } else {
                // Handle other URLs
                return url;
            }
        } catch (error) {
            consoleLog("Invalid URL", error);
            return null;
        }
    };

    // link checker function
    const checkLink = async (link) => {

        if (!link) return;

        isCheckingLink(true);
        setSelectedURL("");
        setCreditCalculation(0);

        try {

            // trim sourcelink if its from instagram
            const checkedURL = await extractSpecificURL(link);

            const result = await handleCheckLink(checkedURL);
            // consoleLog("handleCheckLink", JSON.stringify(result));
            // consoleLog("handleCheckLink", result);

            // If result is empty or null
            if (!result || result.length === 0) {
                isCheckingLink(false);
                isErrorLink(true);
                ToastEffect("warning", "Invalid or unauthorized link. Please try again, or use a media downloader like publer.io and upload the media file manually.");
                return;
            }

            // if response is failed
            if (result?.status === "failed") {
                isCheckingLink(false);
                isErrorLink(true);
                ToastEffect("warning", "Invalid or unauthorized link. Please try again, or use a media downloader like publer.io and upload the media file manually.");
                return;
            }

            // Check if result is an array
            if (Array.isArray(result) && result.length > 0) {
                const _selectedURL = { media: result[0], size: "10.50 MB" };
                const getFileSize = getSizeInBytes(_selectedURL?.size);

                // consoleLog("getFileSize1", getFileSize);
                setSelectedURL(_selectedURL?.media);
                setURLSize(getFileSize || 0.1);
                isCheckingLink(false);
                setSourceType("link");
                consoleLog("videoTranscriber > index.js > checkLink > link1", _selectedURL);
                // cantProcessLink(result[0], "unprocessable");
                // return;
            } else if (typeof result === 'object') {  // Check if result is an object (possible JSON)
                const _selectedURL = getSelectedURLFromObject(result);
                const getFileSize = getSizeInBytes(_selectedURL?.size);

                // consoleLog("getFileSize2", getFileSize);
                setSelectedURL(_selectedURL?.media);
                setURLSize(getFileSize || 0.1);
                isCheckingLink(false);
                setSourceType("link");
                consoleLog("videoTranscriber > index.js > checkLink > link2", _selectedURL);
            } else if (typeof result === 'string') { // Check if result is a string that could be parsed as JSON
                const data = JSON.parse(result);
                const _selectedURL = getSelectedURLFromObject(data);
                const getFileSize = getSizeInBytes(_selectedURL?.size);

                // consoleLog("getFileSize3", getFileSize);
                setSelectedURL(_selectedURL?.media);
                setURLSize(getFileSize || 0.1);
                isCheckingLink(false);
                setSourceType("link");
                consoleLog("videoTranscriber > index.js > checkLink > link3", _selectedURL);
            }
        } catch (error) {
            isCheckingLink(false);
            // ToastEffect("error", "Unexpected response format.");
            ToastEffect("error", "Something went wrong! #checkLink");
            consoleLog("videoTranscriber > index.js > checkLink > error", error);
        }
    };

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {

        // refetch transcriber data
        setRefetchTranscriberData(oldVal => oldVal + 1);

        // set video array to empty first
        // setTranscribedData([]);

        // calculate new offset
        const newOffset = (event.selected * itemsPerPage) % totalSumTranscriberData;

        // declaire required parameter
        const required_param = {
            email: email,
            start: itemsPerPage,
            type: process.env.REACT_APP_VIDEO_TRANSCRIBER_ID,
            offset: newOffset
        };

        // set new generated video data use state
        setTranscriberParam(required_param);
    };

    // handle view modal
    const handleView = (data) => {
        setShowViewModal(!showViewModal);
        setModalData(data);
        setContent(data?.content_url);
        setTitle(data?.title);
    };

    // handle delete data
    const handleDeleteData = async () => {

        if (email && toDeleteVideoID) {

            setIsDeleting(true);
            setDeletingIDX(toDeleteVideoIdx);

            const data = {
                user_email: email,
                user_auth: process.env.REACT_APP_AUTH,
                video_id: toDeleteVideoID,
                action_type: "transcriber",
            };

            await deleteGeneratedVideo(data)
                .then(() => {

                    // filter and exclude the deleted data
                    const updatedVideos = transcriberData.filter((video) => video._id !== toDeleteVideoID);

                    // display activity log
                    // consoleLog("transcriberData", transcriberData);

                    closeModal();
                    setTranscriberData(updatedVideos);
                    ToastEffect("success", "Delete successfully.");
                    setShowViewModal(false);
                    setIsDeleting(false);
                });
        }
    };

    // handle transcribe enhancer option
    const handleTranscriberEnhancer = async (e) => {

        // extracted value
        // const contentValue = e.target.checked;

        // toggle value and variables
        const contentValue = !transcriberEnhancer;

        // set value to useState
        setTranscriberEnhancer(contentValue);

        // variables
        const kind = "enhancer";

        // cached config to localstorage
        cachedToolsConfiguration(kind, contentValue);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, contentValue);

    };

    // handle transcribe language option
    const handleTranscriberLanguage = async (e) => {

        // extracted value
        // const contentValue = e.target.checked;

        // toggle value and variables
        const contentValue = !transcriberLanguage;

        // set value to useState
        setTranscriberLanguage(contentValue);

        // variables
        const kind = "language";

        // cached config to localstorage
        cachedToolsConfiguration(kind, contentValue);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, contentValue);
    };

    // handle selected language
    const handleSelectedLanguage = async (e) => {

        // extracted value
        const languageID = e?.target?.value || languageList[0]?.language_id;

        // const languageData = languageID?.includes(languageList);
        const languageData = languageList?.find((data) => data?.language_id === languageID);

        // extracted value from languageData
        const languageName = languageData?.name;

        // // console log activity
        // consoleLog("langugaeID", e.target.value);
        // consoleLog("languageData", languageData);

        // set value to useState
        setTranscriberLanguageID(languageID);
        setTranscriberLanguageName(languageName);

        // variables
        const kind_languageID = "languageID";
        const kind_languageName = "languageName";

        // cached config to localstorage
        cachedToolsConfiguration(kind_languageID, languageID);
        cachedToolsConfiguration(kind_languageName, languageName);

        // save configuration to the database
        debouncedSaveConfig(kind_languageID, email, pathname, kind_languageID, languageID);
        debouncedSaveConfig(kind_languageName, email, pathname, kind_languageName, languageName);
    };

    // handle transcribe function
    const handleTranscribe = async (mediaFile) => {

        // trigger loading
        isTranscribing(true);

        // variables
        let mediaFileName;
        let mediaSourceType;
        let fileSizeInMB;
        let fileExtension;
        let fileType;


        // --------------------------------
        // --- Check credit balance first
        // --------------------------------
        if (subscriptionData?.message !== "freeAccess") {

            const ceditBalance = await getCreditBalance(email);

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;
            // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            // console.log(totalCredits, totalPrice);

            if (totalCredits < creditCalculation) {
                isTranscribing(false);
                // ToastEffect("error", "Not enough credits.");
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        // check if link or file existed
        if (sourceLink && (!sourceType || !selectedURL)) {
            isTranscribing(false);
            ToastEffect("error", "No file or link provided");
            return;
        }

        // process upload
        if (!sourceLink && mediaFile) {

            // Reset progress
            setUploadProgress(0);

            // get file size
            fileSizeInMB = mediaFile?.size / (1024 * 1024); // check file size

            // get file extension
            fileExtension = mediaFile?.name?.split(".").pop().toLowerCase();

            // identify uploaded type
            if (media_type.includes(fileExtension)) {
                fileType = "media";
            } else if (file_type.includes(fileExtension)) {
                fileType = "file";
            }

            // // check file size
            // if (fileSizeInMB > 25) {
            //     isTranscribing(false);
            //     ToastEffect("error", "File size exceeds 25MB.");
            //     return;
            // }

            // // check file type
            // if (fileExtension !== "mp3" && fileExtension !== "mp4") {
            //     isTranscribing(false);
            //     ToastEffect("error", "No media or link provided.");
            //     return;
            // }

            // const response = await handleUploadMediaFile(mediaFile, email, setInputKey, isTranscribing);
            const response = await handleUploadMediaFile(mediaFile, email, isTranscribing, (progress) => {
                setUploadProgress(progress);
            });

            // prepared data
            mediaFileName = response?.data?.file; // upload response
            mediaSourceType = "media";
        }

        // check if uploaded file is valid 
        if (!fileType) {
            isTranscribing(false);
            ToastEffect("error", "Invalid file.");
            return;
        }

        // check if source link or file existed
        if (!sourceLink && (!mediaSourceType || !mediaFileName)) {
            isTranscribing(false);
            ToastEffect("error", "Invalid file or No link provided");
            return;
        }

        // checks if source link
        if (sourceLink) {
            await cantProcessLink(selectedURL, "note");
        }

        // prepare transcriber required data
        const data = {
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            amount: creditCalculation,
            toolId: process.env.REACT_APP_VIDEO_TRANSCRIBER_ID,
            toolName: process.env.REACT_APP_VIDEO_TRANSCRIBER_NAME,
            source_type: sourceLink ? sourceType : mediaSourceType,
            source_file: sourceLink ? selectedURL : mediaFileName,
            enhancer: transcriberEnhancer,
            language: transcriberLanguage,
            language_name: transcriberLanguageName || "",
            language_id: transcriberLanguageID || "",
            file_extension: fileExtension,
            file_type: fileType,
        };

        // // test only 
        // isTranscribing(false);

        try {

            // --------------------------------
            // --- process video transcribing
            // --------------------------------
            await videoTranscriber(data)
                .then(async (response) => {

                    const taskId = response?.data?.task_id;

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        ToastEffect("error", "Maximum request limit reached.");
                        isTranscribing(false);
                        return;
                    }

                    // --------------------------------
                    // -- check if taskId did not exist and throw error message 
                    // --------------------------------
                    // if (!taskId) {
                    //     ToastEffect("error", "No task ID received!");
                    //     isTranscribing(false);
                    //     return;
                    // }

                    // --------------------------------
                    // --- save taskId
                    // --------------------------------
                    // await saveTaskId(email, process.env.REACT_APP_VIDEO_TRANSCRIBER_ID, "taskId", taskId);

                    // --------------------------------
                    // --- process payment
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentkind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentkind = "OldUser";
                        }

                        // --------------------------------
                        // --- record credit cost
                        // --------------------------------
                        await recordCredits(email, taskId, "VideoTranscriber", paymentkind, creditCalculation);
                    } else {

                        // --------------------------------
                        // --- process payment
                        // --------------------------------
                        await payVideo(email, creditCalculation, "VideoTranscriber");

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        await recordCredits(email, taskId, "VideoTranscriber", "payment", creditCalculation);
                        adjustCreditsView("tokenBalance", "pay", creditCalculation);
                    }
                });

            // finally re-fetch data
            setRefetchTranscriberData(oldVal => oldVal + 1);
            isTranscribing(false);
            // setCheckStatus((val) => val + 1);

        } catch (error) {
            consoleLog("Error  handleTranscribe()", error);
            isTranscribing(false);
        }
    };

    // handle click outside for transcription view
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalTranscriptionView.current && !modalTranscriptionView.current.contains(event.target)) {
                setShowViewModal(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalTranscriptionView]);

    // process status style
    const progressstyle = {
        processsing: "bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-purple-100 dark:bg-gray-700 dark:border-purple-500 dark:text-purple-400",
        complete: "bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500",
        fail: "bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-red-100 dark:border-red-400 dark:bg-gray-700 dark:text-red-400",
        processsing_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800",
        complete_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-white focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800",
        fail_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800",
    };

    return (
        <div className="lg:flex lg:justify-between min-h-screen pt-2 lg:pt-0">

            {/* left side menu dsplay */}
            <div className="block lg:w-[600px] z-10 ml-1 max-h-screen overflow-y-auto bg-gray-200 dark:bg-gray-700/75 relative">
                <LeftSideMenu
                    getRootProps={getRootProps}
                    transcribing={transcribing}
                    transcribePrices={transcribePrices}
                    checkingLink={checkingLink}
                    inputKey={inputKey}
                    getInputProps={getInputProps}
                    acceptedFiles={acceptedFiles}
                    sourceLink={sourceLink}
                    files={files}
                    uploadProgress={uploadProgress}
                    creditCalculation={creditCalculation}
                    transcriberEnhancer={transcriberEnhancer}
                    handleTranscriberEnhancer={handleTranscriberEnhancer}
                    config_enhancer={config_enhancer}
                    transcriberLanguage={transcriberLanguage}
                    handleTranscriberLanguage={handleTranscriberLanguage}
                    config_language={config_language}
                    languageList={languageList}
                    handleSelectedLanguage={handleSelectedLanguage}
                    transcriberLanguageID={transcriberLanguageID}
                    handleTranscribe={handleTranscribe}
                />
            </div>

            {/* right side table diplay */}
            <div className="flex w-full flex-col h-screen overflow-auto">
                <RightSideTable
                    transcriberData={transcriberData}
                    onOpenTMV={onOpenTMV}
                    handleView={handleView}
                    handleDeleteOnclick={handleDeleteOnclick}

                    progressstyle={progressstyle}
                    percentageCalculator={percentageCalculator}
                    isMobile={isMobile}
                    isDeleting={isDeleting}
                    deletingIDX={deletingIDX}
                    refetchData={refetchData}
                />
            </div>

            {/* Transcriber Modal View */}
            <ModalView
                isOpen={isOpenTMV}
                onClose={onCloseTMV}
                onOpen={onOpenTMV}
                content={content}
                modalData={modalData}
                title={title}
                handleEditTitle={handleEditTitle}
                handleEditContext={handleEditContext}
            />

        </div>
    );
};

export default VideoTranscriber;