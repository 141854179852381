// libraries
import React from "react";

const VT_TableCell2 = (props) => {

    // props
    const {
        item,
    } = props;

    return (
        <div className="flex flex-col">
            <p className="font-semibold text-base">
                {item?.file_extension || "VT"}
            </p>
        </div>
    );
};

export default VT_TableCell2;