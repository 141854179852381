import React, { useState, useRef, useContext, useEffect } from "react";

// API
import { changePass } from "../../API/account";

// functions=
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import ResponseMessage from "../ResponseMessage";
import { verifyCaptchaV2 } from "../../API/authAPI";
import UserContext from "../UserContext";
import { catchURL } from "../../functions/general";

const Password = (props) => {

    const { logout } = props;
    const { userData } = useContext(UserContext);

    // catch URL
    // const url = new URL(window.location.href);
    // const queryParams = new URLSearchParams(url.search);
    // const cardValue = queryParams.get('card');

    // use state
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [changing, setChanging] = useState(false);
    const [responseMessage, setResponseMessage] = useState([]);
    const [urlCardValue, setURLCardValue] = useState("");

    // handle disable card animation
    const handleDisableCardAnimation = () => {
        setURLCardValue("");
    };

    // set URL card value
    useEffect(() => {
        const handleURLCardValue = () => {
            setURLCardValue(catchURL());
        };
        handleURLCardValue();
    }, []);

    // handle close reponse message
    const closeResponseMessage = () => {
        setResponseMessage([]);
    };

    // handle show, hide password
    const handleShowPass = () => {
        setShowPass(!showPass);
    };

    // handle change password
    const handleChangePass = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        closeResponseMessage();

        if (newPass.length < 8) {
            setResponseMessage([{ kind: "error", message: "Min. of 8 characters required." }]);
            setChanging(false);
            return;
        }

        if (newPass !== confirmPass) {
            setResponseMessage([{ kind: "error", message: "Passwords do not match." }]);
            setChanging(false);
            return;
        }

        setChanging(true);

        await changePass(userData?.UserInfo?.email, newPass, confirmPass)
            .then((response) => {
                setResponseMessage([{ kind: "success", message: response?.data?.message }]);
                setChanging(false);
                logout();
                // console.log("response", response);
            }).catch((error) => {
                ToastEffect("error", "Something went wrong! #handleChangePass");
                consoleLog("components > Profile > Password.js > handleChangePass", error);
            });
    };
    return (
        <div
            onClick={handleDisableCardAnimation}
            className={`${urlCardValue === "changepass" ? "border-blue-500 border-x-4 border-y-4" : "border-gray-200 dark:border-gray-700 sm:p-6"} p-4 mb-4 bg-white border rounded-lg shadow-sm 2xl:col-span-2 dark:bg-gray-800`}
        >
            <h3 className="mb-4 text-xl font-semibold dark:text-white">
                Change Password
                <small className="text-gray-500 ml-1 text-sm">
                    (After successfully changing the password, you will be logged out automatically.)
                </small>
            </h3>
            {!!responseMessage.length && (
                <ResponseMessage
                    responseMessage={responseMessage}
                    closeResponseMessage={closeResponseMessage}
                />
            )}
            <form onSubmit={handleChangePass}>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3 relative">
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New password</label>

                        <input
                            onChange={(e) => setNewPass(e.target.value)}
                            type={showPass ? "text" : "password"}
                            name="password"
                            id="password"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="••••••••"
                            minLength={8}
                            required
                        />
                        <div onClick={handleShowPass} className="absolute right-3 top-[71%] transform -translate-y-1/2 dark:text-gray-400 cursor-pointer">
                            <i className={`fa-solid ${showPass ? "fa-eye-slash" : "fa-eye"}`} />
                        </div>

                        {/* <div
                            data-popover=""
                            id="popover-password"
                            role="tooltip"
                            className="absolute inline-block opacity-[100%]  z-10 text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"
                            // style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(823px, -1792px);"
                            data-popper-placement="top"
                            data-popper-reference-hidden=""
                        >
                            <div className="p-3 space-y-2">
                                <h3 className="font-semibold text-gray-900 dark:text-white">Must have at least 6 characters</h3>
                                <div className="grid grid-cols-4 gap-2">
                                    <div className="h-1 bg-orange-300 dark:bg-orange-400"></div>
                                    <div className="h-1 bg-orange-300 dark:bg-orange-400"></div>
                                    <div className="h-1 bg-gray-200 dark:bg-gray-600"></div>
                                    <div className="h-1 bg-gray-200 dark:bg-gray-600"></div>
                                </div>
                                <p>It’s better to have:</p>
                                <ul>
                                    <li className="flex items-center mb-1">
                                        <svg className="w-4 h-4 mr-2 text-green-400 dark:text-green-500" aria-hidden="true" fill="currentColor" viewBox="10 bottom-2 10 bottom-2 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        Upper &amp; lower case letters
                                    </li>
                                    <li className="flex items-center mb-1">
                                        <svg className="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        A symbol (#$&amp;)
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        A longer password (min. 12 chars.)
                                    </li>
                                </ul>
                            </div>
                            <div
                                data-popper-arrow=""
                                className="absolute left-0 translate-x-32"
                            // style="position: absolute; left: 0px; transform: translate(139px, 0px);"
                            ></div>
                        </div> */}

                    </div>

                    {urlCardValue === "changepass" && (
                        <div className="absolute right-[2rem] -mt-[3.7rem]">
                            <span className="flex h-8 w-8">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75" />
                            </span>
                        </div>
                    )}

                    <div className="col-span-6 sm:col-span-3 relative">
                        <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                        <input
                            onChange={(e) => setConfirmPass(e.target.value)}
                            type={showPass ? "text" : "password"}
                            name="confirm-password"
                            id="confirm-password"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="••••••••"
                            minLength={8}
                            required
                        />
                        <div onClick={handleShowPass} className="absolute right-3 top-[71%] transform -translate-y-1/2 dark:text-gray-400 cursor-pointer">
                            <i className={`fa-solid ${showPass ? "fa-eye-slash" : "fa-eye"}`} />
                        </div>
                    </div>

                    <div className="col-span-6 sm:col-full relative">
                        <button
                            disabled={changing ? true : false}
                            className="flex w-52 justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            type="submit"
                        >
                            {changing && (
                                <i className="absolute fa-solid fa-spinner fa-spin-pulse mr-2 left-4" />
                            )}
                            Change Password
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Password;