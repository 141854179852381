// libraries
import React from "react";

// custom components
import formatNumber from "../../../../../functions/calculations";
import { handleUploadMediaSource } from "../../API/toolsAPI";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import consoleLog from "../../../../../functions/consoleLog";



class TrimmerEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMuted: false,
            timings: [],
            playing: false,
            currently_grabbed: { "index": 0, "type": "none" },
            difference: 0.2,
            deletingGrabber: false,
            imageUrl: ""
        };
        this.playVideo = React.createRef();
        this.progressBar = React.createRef();
        this.playBackBar = React.createRef();
    }

    reader = new FileReader();

    componentDidMount = () => {

        // consoleLog("videoAspecRatio", this.props.videoAspecRatio);

        var self = this;

        // Define the timeUpdateEventListener
        this.timeUpdateEventListener = () => {
            var curr_idx = this.state.currently_grabbed.index;
            var seek = (this.playVideo.current.currentTime - this.state.timings[curr_idx].start) / this.playVideo.current.duration * 100;
            this.progressBar.current.style.width = `${seek}%`;
            if ((this.playVideo.current.currentTime >= this.state.timings[this.state.timings.length - 1].end)) {
                this.playVideo.current.pause();
                this.setState({ playing: false });
            }
            else if (this.playVideo.current.currentTime >= this.state.timings[curr_idx].end) {
                if ((curr_idx + 1) < this.state.timings.length) {
                    this.setState({ currently_grabbed: { "index": curr_idx + 1, "type": "start" } }, () => {
                        this.progressBar.current.style.width = '0%';
                        this.progressBar.current.style.left = `${this.state.timings[curr_idx + 1].start / this.playVideo.current.duration * 100}%`;
                        this.playVideo.current.currentTime = this.state.timings[curr_idx + 1].start;
                    });
                }
            }
        };

        // Attach the timeUpdateEventListener to the video element
        this.playVideo.current.addEventListener('timeupdate', this.timeUpdateEventListener);

        window.addEventListener("keyup", function (event) {
            if (event.key === " ") {
                self.play_pause();
            }
        });
        var time = this.state.timings;
        this.playVideo.current.onloadedmetadata = () => {
            time.push({ 'start': 0, 'end': this.playVideo.current.duration });
            this.setState({ timings: time }, () => {
                this.addActiveSegments();
            });
        };
    };

    componentWillUnmount = () => {
        // Stop the video playback
        if (this.playVideo.current) {
            this.playVideo.current.pause();
            // Remove the timeupdate event listener
            this.playVideo.current.removeEventListener('timeupdate', this.timeUpdateEventListener);
        }
    };

    reset = () => {
        this.playVideo.current.pause();
        this.setState({
            isMuted: false,
            timings: [{ 'start': 0, 'end': this.playVideo.current.duration }],
            playing: false,
            currently_grabbed: { "index": 0, "type": "none" },
            difference: 0.2,
            deletingGrabber: false,
            imageUrl: ""
        }, () => {
            this.playVideo.current.currentTime = this.state.timings[0].start;
            this.progressBar.current.style.left = `${this.state.timings[0].start / this.playVideo.current.duration * 100}%`;
            this.progressBar.current.style.width = "0%";
            this.addActiveSegments();
        });
    };

    captureSnapshot = () => {
        var video = this.playVideo.current;
        const canvas = document.createElement("canvas");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height);

        const dataURL = canvas.toDataURL();
        this.setState({ imageUrl: dataURL });
    };

    skipPrevious = () => {
        if (this.state.playing) {
            this.playVideo.current.pause();
        }
        var prev_idx = (this.state.currently_grabbed.index !== 0) ? (this.state.currently_grabbed.index - 1) : (this.state.timings.length - 1);
        this.setState({ currently_grabbed: { "index": prev_idx, "type": "start" }, playing: false }, () => {
            this.progressBar.current.style.left = `${this.state.timings[prev_idx].start / this.playVideo.current.duration * 100}%`;
            this.progressBar.current.style.width = '0%';
            this.playVideo.current.currentTime = this.state.timings[prev_idx].start;
        });
    };

    play_pause = () => {
        var self = this;
        if (this.state.playing) {
            this?.playVideo?.current?.pause();
        }
        else {
            if ((self?.playVideo?.current?.currentTime >= self.state.timings[self.state.timings.length - 1].end)) {
                self.playVideo.current.pause();
                self.setState({ playing: false, currently_grabbed: { "index": 0, "type": "start" } }, () => {
                    self.playVideo.current.currentTime = self.state.timings[0].start;
                    self.progressBar.current.style.left = `${self.state.timings[0].start / self.playVideo.current.duration * 100}%`;
                    self.progressBar.current.style.width = "0%";
                });
            }
            this?.playVideo?.current?.play();
        }
        this.setState({ playing: !this.state.playing });
    };


    skipNext = () => {
        if (this.state.playing) {
            this.playVideo.current.pause();
        }
        var next_idx = (this.state.currently_grabbed.index !== (this.state.timings.length - 1)) ? (this.state.currently_grabbed.index + 1) : 0;
        this.setState({ currently_grabbed: { "index": next_idx, "type": "start" }, playing: false }, () => {
            this.progressBar.current.style.left = `${this.state.timings[next_idx].start / this.playVideo.current.duration * 100}%`;
            this.progressBar.current.style.width = '0%';
            this.playVideo.current.currentTime = this.state.timings[next_idx].start;
        });
    };

    updateProgress = (event) => {
        var playbackRect = this.playBackBar.current.getBoundingClientRect();
        var seekTime = ((event.clientX - playbackRect.left) / playbackRect.width) * this.playVideo.current.duration;
        this.playVideo.current.pause();

        var index = -1;
        var counter = 0;
        for (let times of this.state.timings) {
            if (seekTime >= times.start && seekTime <= times.end) {
                index = counter;
            }
            counter += 1;
        }
        if (index === -1) {
            return;
        }
        this.setState({ playing: false, currently_grabbed: { "index": index, "type": "start" } }, () => {
            this.progressBar.current.style.width = '0%';
            this.progressBar.current.style.left = `${this.state.timings[index].start / this.playVideo.current.duration * 100}%`;
            this.playVideo.current.currentTime = seekTime;
        });
    };

    startGrabberMove = (event) => {

        // console.log("Grabber move started");

        this.playVideo.current.pause();
        var playbackRect = this.playBackBar.current.getBoundingClientRect();
        var seekRatio = (event.clientX - playbackRect.left) / playbackRect.width;
        const index = this.state.currently_grabbed.index;
        const type = this.state.currently_grabbed.type;
        window.addEventListener("mouseup", () => { window.removeEventListener('mousemove', this.startGrabberMove); this.addActiveSegments(); });
        var time = this.state.timings;
        var seek = this.playVideo.current.duration * seekRatio;

        // Boundary checks for the grabbers
        if (type === "start") {
            seek = Math.max(seek, 0); // Start grabber should not be less than 0
            if (seek < time[index].end - this.state.difference) {
                time[index]["start"] = seek;
                this.playVideo.current.currentTime = seek;
                // console.log("Start grabber moved", seek);
            }
        } else if (type === "end") {
            seek = Math.min(seek, this.playVideo.current.duration); // End grabber should not exceed video duration
            if (seek > time[index].start + this.state.difference) {
                time[index]["end"] = seek;
                this.playVideo.current.currentTime = seek;
                // console.log("End grabber moved", seek);
            }
        }

        this.setState({ timings: time, playing: false }, () => {
            this.updateProgressBar(index, type, seek);
            this.calculateSegmentDurations(); // Calculate durations after updating timings
        });

    };

    calculateSegmentDurations = () => {
        const segmentDurations = this.state.timings.map(segment => {
            return (segment.end - segment.start).toFixed(2); // Assuming times are in seconds
        });

        // Update your component's state or do whatever you need with segmentDurations
        // console.log(segmentDurations); // For demonstration, logging the durations
    };


    updateProgressBar = (index, type, seek) => {
        if (type === "start") {
            this.progressBar.current.style.left = `${seek / this.playVideo.current.duration * 100}%`;
        } else if (type === "end") {
            // Add logic to adjust the progress bar for the end grabber if needed
        }
        this.progressBar.current.style.width = "0%";
    };


    renderGrabbers = () => {
        return this.state.timings.map((x, index) => (
            <div key={"grabber_" + index}>
                <div className="grabber start" style={{ left: `${x.start / this.playVideo.current.duration * 100}%` }} onMouseDown={(event) => {
                    if (this.state.deletingGrabber) {
                        this.deleteGrabber(index);
                    }
                    else {
                        this.setState({ currently_grabbed: { "index": index, "type": "start" } }, () => {
                            window.addEventListener('mousemove', this.startGrabberMove);
                        });
                    }
                }}>
                    <i className="fa-solid fa-grip-lines-vertical" />
                </div>
                <div className="grabber end" style={{ left: `${x.end / this.playVideo.current.duration * 100}%` }} onMouseDown={(event) => {
                    if (this.state.deletingGrabber) {
                        this.deleteGrabber(index);
                    }
                    else {
                        this.setState({ currently_grabbed: { "index": index, "type": "end" } }, () => {
                            window.addEventListener('mousemove', this.startGrabberMove);
                        });
                    }
                }}>
                    <i className="fa-solid fa-grip-lines-vertical" />
                </div>
            </div>
        ));
    };



    addActiveSegments = () => {
        if (this.playVideo.current) {
            var colors = "";
            var counter = 0;
            colors += `, rgb(240, 240, 240) 0%, rgb(240, 240, 240) ${this.state.timings[0].start / this.playVideo.current.duration * 100}%`;
            for (let times of this.state.timings) {
                if (counter > 0) {
                    colors += `, rgb(240, 240, 240) ${this.state.timings[counter - 1].end / this.playVideo.current.duration * 100}%, rgb(240, 240, 240) ${times.start / this.playVideo.current.duration * 100}%`;
                }
                colors += `, #ccc ${times.start / this.playVideo.current.duration * 100}%, #ccc ${times.end / this.playVideo.current.duration * 100}%`;
                counter += 1;
            }
            colors += `, rgb(240, 240, 240) ${this.state.timings[counter - 1].end / this.playVideo.current.duration * 100}%, rgb(240, 240, 240) 100%`;
            this.playBackBar.current.style.background = `linear-gradient(to right${colors})`;
        }
    };

    saveVideo = async () => {

        this.props.setIsTrimmingVideo(true);
        this.props.setIsUploadingMedia(true);

        // start the partial loading process effect
        this.props.setIsGeneratingImage(prevVal => [...prevVal, this.props.imageData?.number]);

        var metadata = {
            "trim_times": this.state.timings,
            "mute": this.state.isMuted,
            "duration": this.playVideo.current.duration
        };

        // execute upload media
        const response = await handleUploadMediaSource(
            this.props.sourceMediaFile, // source media file
            "custom_video", // source type
            this.props.imageData, // image data
            metadata?.trim_times[0]?.start, // start time
            metadata?.trim_times[0]?.end, // start time
            metadata?.duration, // full duration of the video
            metadata?.mute, // check if mute or not
            this.props.imageData?._id, // the image unique id
        );

        // console log activity
        // const taskId = response?.data?.task_id;
        // consoleLog("response", response);
        // consoleLog("imageData", this.props.imageData);

        // --------------------------------
        // --- check if max request reach
        // --------------------------------
        if (response?.data?.detail == "request_limit") {
            ToastEffect("error", "Maximum request limit reached.");
            this.props.setIsTrimmingVideo(false);
            this.props.setIsUploadingMedia(false);
            this.props.setIsGeneratingImage(prevVal => prevVal.filter(index => index !== this.props.imageData?.number));
            return;
        }

        // end the partial loading process effect
        this.props.setIsGeneratingImage(prevVal => prevVal.filter(index => index !== this.props.imageData?.number));

        // refetch source media data
        this.props.setRefetchData(oldVal => oldVal + 1);
    };

    render = () => {
        return (
            // <div className="wrapper">videoAspecRatio
            <div className={`${this.props.videoAspecRatio === "Landscape" ? "w-full h-full" : "wrapper"}`}>

                <video
                    className="video rounded-lg"
                    autoload="metadata"
                    muted={this.state.isMuted}
                    ref={this.playVideo}
                    onClick={this.play_pause.bind(this)}
                >
                    <source src={this.props.videoUrl} type="video/mp4" />
                </video>

                {/* <Progress
                    size="md"
                    radius="md"
                    classNames={{
                        base: "max-w-[35rem]",
                        track: "drop-shadow-md border border-default",
                        indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
                        label: "tracking-wider font-medium text-default-600",
                        value: "text-foreground/60",
                    }}
                    label="Processing"
                    value={scaleValueTo100(
                        50, // current value
                        100 // max value
                    )}
                    showValueLabel={true}
                /> */}

                <div className="playback">
                    {this.renderGrabbers()}
                    <div className="seekable" ref={this.playBackBar} onClick={this.updateProgress}></div>
                    <div className="progress" ref={this.progressBar}></div>
                </div>

                <div className="controls">
                    <div className="player-controls">

                        <button
                            className="settings-control"
                            title="Reset Video"
                            onClick={this.reset}
                        >
                            <i className="fa-solid fa-rotate" />
                        </button>

                        <button
                            className="settings-control"
                            title="Volume"
                            onClick={() => this.setState({ isMuted: !this.state.isMuted })}
                        >
                            {this.state.isMuted ? <i className="fa-solid fa-volume-xmark" /> : <i className="fa-solid fa-volume-high" />}
                        </button>

                        <button
                            style={{ display: 'none' }}
                            className="settings-control"
                            title="Capture Thumbnail"
                            onClick={this.captureSnapshot}
                        >
                            <i className="fa-solid fa-camera" />
                        </button>

                        <button
                            className="play-control"
                            title="Play/Pause"
                            onClick={this.play_pause.bind(this)}
                        >
                            {this.state.playing ? <i className="fa-solid fa-pause" /> : <i className="fa-solid fa-play" />}
                        </button>
                    </div>
                    <div className="player-controls">

                        {/* <button
                            className="seek-start"
                            title="Basa git"
                            onClick={this.skipPrevious}
                        >
                            <i className="fa-solid fa-backward-step" />
                        </button>

                        <button
                            className="play-control"
                            title="Play/Pause"
                            onClick={this.play_pause.bind(this)}
                        >
                            {this.state.playing ? <i className="fa-solid fa-pause" /> : <i className="fa-solid fa-play" />}
                        </button>

                        <button
                            className="seek-end"
                            title="Sona git"
                            onClick={this.skipNext}
                        >
                            <i className="fa-solid fa-forward-step" />
                        </button> */}

                        <div className="bg-[#2f3b44] border-0 text-[#a5b0b5] p-2 rounded font-bold uppercase tracking-wide">
                            {this.state.timings.map((segment, index) => (
                                <div key={index}>
                                    <i className="fa-regular fa-clock" /> {formatNumber(segment.end - segment.start)}
                                </div>
                            ))}
                        </div>

                    </div>
                    <div>

                        <button
                            style={{ display: 'none' }}
                            title="Add grabber"
                            className="trim-control margined"
                            onClick={this.addGrabber}
                        >
                            Add <i className="fa-solid fa-grip-lines-vertical" />
                        </button>

                        <button
                            style={{ display: 'none' }}
                            title="Delete grabber"
                            className="trim-control margined"
                            onClick={this.preDeleteGrabber}
                        >
                            Delete <i className="fa-solid fa-grip-lines-vertical" />
                        </button>

                        <button
                            disabled={this.props.isGeneratingImage.includes(this.props.imageData?.number) ? true : false}
                            title="Save"
                            className={`${this.props.isGeneratingImage.includes(this.props.imageData?.number) && "cursor-not-allowed"} text-gray-200 bg-blue-600 p-2 rounded-md hover:bg-blue-500 font-semibold`}
                            onClick={this.saveVideo}
                        >
                            {/* <i className={`${this.props.isTrimmingVideo && "fa-spin"} fa-solid fa-arrows-rotate mr-1`} /> */}
                            <i className={`${this.props.isGeneratingImage.includes(this.props.imageData?.number) && "fa-spin"} fa-solid fa-arrows-rotate mr-1`} />
                            Continue
                        </button>

                    </div>
                </div>

            </div>
        );
    };
}

export default TrimmerEditor

