// libraries
import React from "react";

const ProductCredits = (props) => {

    // props
    const {
        handleSelection,
        productData
    } = props;

    return (
        <>
            <input
                onChange={(e) => handleSelection(e, productData)}
                type="radio"
                id={productData?.productID}
                name="hosting"
                value={productData?.productID}
                className="hidden peer"
                required
            />
            <label htmlFor={productData?.productID} className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                <div className="block">
                    <div className="w-full text-lg font-semibold">
                        {productData?.productName}
                        <small>
                            , expires in {productData?.productExpiration} days.
                        </small>
                    </div>
                    <div className="w-full">
                        <i className={productData?.productIcon} /> {productData?.productDesc}
                    </div>
                </div>
                {/* <i className="fa-solid fa-arrow-right text-xl ml-3" /> */}
            </label>
        </>
    );
};

export default ProductCredits;