import axios from "axios";

// Functions
import { ToastEffect } from "../functions/ToastEffect";
import consoleLog from "../functions/consoleLog";

// fetch product details from the server
export const getProductDetails = async (product_ids) => {
    try {
        const requests = product_ids.map((product_id) =>
            axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/product/get-product-details`, {
                product_id: product_id
            })
        );

        const responses = await Promise.all(requests);

        const productDetails = {};

        responses.forEach((response, index) => {
            productDetails[`product_${index + 1}`] = response.data.productDetails[0];
        });

        return { productDetails };
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getProductDetails");
        consoleLog("API > yourAPIfile.js > getProductDetails", error);
    }
};

// fetch product data from the server
export const fetchProductData = async () => {

    try {

        // call server API endpoint and send required data
        const response = axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/product/get-product-list`);
        return response; // return response

    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchProductData");
        consoleLog("API > yourAPIfile.js > fetchProductData", error);
    }
};

// execute subscription process
export const stripeSubscriptionProcess = (data) => {

    try {

        // call server API endpoint and send required data
        const response = axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/stripe-subscription`, data);
        return response; // return response

    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchProductData");
        consoleLog("API > yourAPIfile.js > fetchProductData", error);
    }

};