import { useEffect, useRef } from "react";
import lottie from "lottie-web";
import NotAvailableImage from "../shinefy/Tools/Images/NotAvailable.png";

const WaitingPage = () => {

    let loadingToolsAnimationContainer = useRef();

    useEffect(() => {

        const loadingToolsAnimation = lottie.loadAnimation({
            container: loadingToolsAnimationContainer.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shine-ai-robot-head.json",
        });

        return () => {
            loadingToolsAnimation.destroy();
            // loadingToolsAnimation.setSpeed(1.5);
        };
    }, [loadingToolsAnimationContainer]);

    return (
        <div className="w-full h-full fixed top-0 left-0 bg-gray-300 opacity-70 z-50">
            <div className="flex justify-center items-center mt-[50vh]">
                {/* <div className="fas fa-circle-notch fa-spin fa-5x text-violet-600" /> */}
                <div className="w-auto h-60" ref={loadingToolsAnimationContainer} />
            </div>
        </div>
    );
};

export default WaitingPage;