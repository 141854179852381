// libraries
import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";

// custom components
import ProductCredits from "../PaidCredits/ProductCredits";
import ProductSelection from "../PaidCredits/ProductSelection";
import UserContext from "../UserContext";
import consoleLog from "../../functions/consoleLog";
import { unitAmountConversions } from "../../pages/shinefy/Tools/functions/general";
import BuyCredits from "../PaidCredits/BuyCredits";

export const CreditsView = (props) => {

    // props
    const {
        isOpen,
        onClose,
        selectedValue,
        setSelectedValue,
        subscriptionEmail,
    } = props;

    // context method
    const {

        // Others
        productData,

    } = useContext(UserContext);

    // react components
    const [selectedProduct, setSelectedProduct] = useState("0 Shinefy Credits");
    const [selectedProductPrice, setSelectedProductPrice] = useState("USD $0");
    const [selectedPriceID, setSelectedPriceID] = useState("");

    // console log activity
    // consoleLog("subscriptionData", subscriptionData);

    // set values from the selected data
    const handleSelection = (e, productData) => {
        setSelectedValue(e.target.value);
        setSelectedProduct(productData?.productDesc);
        setSelectedProductPrice(productData?.productName);
        setSelectedPriceID(productData?.productPriceID);
    };

    return (
        <Modal
            size="2xl"
            isOpen={isOpen}
            onClose={onClose}
            className="bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Add More Credits</ModalHeader>
                        <ModalBody>

                            {/* <div className="grid w-auto"> */}
                            {/* <div className="relative order-1 col-span-1 sm:order-2"> */}
                            {/* <div className="p-4 flex flex-col gap-3 bg-white z-20 relative dark:bg-gray-800"> */}

                            <ProductSelection
                                selectedData={{
                                    selectedProductName: selectedProduct,
                                    selectedProductPrice: selectedProductPrice
                                }}
                            />

                            {/* <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">
                                Some descriptions here
                            </h3> */}

                            <ul className="grid w-full gap-2 md:grid-cols-2">

                                {productData?.map((data, idx) => {

                                    if (data?.productObject === "product" && data?.productKind === "credit") {

                                        // extracted values from data
                                        const productID = data?.productID;
                                        const productPriceID = data?.productPrice?.id;
                                        const productPeriod = data?.productData?.metadata?.period_days;
                                        const productDescription = data?.productData?.name;
                                        const productPrice = data?.productPrice?.unit_amount;
                                        const productCurrency = data?.productPrice?.currency;
                                        const productName = (productCurrency).toUpperCase() + " $" + unitAmountConversions(productPrice);

                                        return (
                                            <li key={idx}>
                                                <ProductCredits
                                                    handleSelection={handleSelection}
                                                    productData={{
                                                        productID: productID,
                                                        productPriceID: productPriceID,
                                                        productExpiration: productPeriod,
                                                        productName: productName,
                                                        productDesc: productDescription,
                                                        productIcon: "fa-solid fa-coins text-yellow-300"
                                                    }}
                                                />
                                            </li>
                                        );
                                    }

                                })}

                            </ul>

                            {/* Buy Credits button */}
                            <BuyCredits
                                selectedValue={selectedValue}
                                selectedPriceID={selectedPriceID}
                                subscriptionEmail={subscriptionEmail}
                            />

                            {/* <BuyCreditsButton
                                selectedValue={selectedValue}
                            /> */}

                            {/* <div className="gap-2 flex flex-row justify-start text-sm items-start sm:pb-1">
                                <button className="flex flex-row items-center space-x-1 underline">
                                    Manage my subscription
                                </button>
                            </div>
                            <a
                                target="_blank" href="https://help.openai.com/en/collections/3943089-billing">
                                <div className="gap-2 flex flex-row justify-start text-sm items-start sm:pb-1">
                                    <div className="flex flex-row items-center space-x-1 underline">
                                        I need help with a billing issue
                                    </div>
                                </div>
                            </a> */}

                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-between -mt-2">
                            {/* <Button
                                onPress={onClose}
                                color="danger"
                                variant="flat"
                                className="w-auto h-auto text-base"
                            >
                                Close
                            </Button>
                            <Button
                                // onClick={handlesubmitReport}
                                color="primary"
                                className="h-full w-full lg:md:sm:w-auto py-3.5 px-7 text-base"
                            >
                                Submit Report
                            </Button>
                            <Button
                                // onClick={() => handleDeleteVideo(toBeDeletedData)}
                                color="danger"
                                variant="flat"
                                className="text-base"
                            >
                                <i className="fa-solid fa-trash-can" /> Remove
                            </Button> */}

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};
