// libraries
import React from "react";

// custom components
import { formatNumberValue } from "../../../../../functions/general";

const VG_TableCell2 = (props) => {

    // props
    const {
        data,
    } = props;

    return (
        <div className="flex flex-col">
            <p className="font-semibold capitalize">
                <code className="text-base">
                    <i className="fa-solid fa-images text-green-300 mr-1" />{formatNumberValue(data?.image_count)}
                </code>
            </p>
            {/* <p className="font-semibold text-tiny capitalize text-default-400">{formatNumberValue(data?.image_count)}</p> */}
            <p className="font-semibold text-xs text-blue-400">Slide count</p>
        </div>
    );
};

export default VG_TableCell2;