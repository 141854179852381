// libraries
import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Input, Select, SelectItem, Textarea, Tooltip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../functions/consoleLog";
import { cachedToolsConfiguration, getCachedToolsConfiguration } from "../../../functions/general";
import AdminContext from "../../../components/Admin/AdminContext";
import { searchGuildChannel } from "../../../API/server";

const Welcomer = () => {

    // context method
    const {

        // admin config
        debouncedSaveConfig,
        adminConfigs,

    } = useContext(AdminContext);

    // select option value
    const actionOptions = [
        { key: "welcomer_only", label: "Welcomer only" },
        { key: "welcomer_and_dm", label: "Welcomer and DM." },
        { key: "dm_only", label: "Send a DM only." },
    ];

    // .env configs
    const defaultChannelID = process.env.REACT_APP_DISCORD_CHANNELID;

    // react custom useState
    const [selectedChannelID, setSelectedChannelID] = useState(
        getCachedToolsConfiguration("channelID", adminConfigs)?.config || ""
    );
    const [selectedAction, setSelectedAction] = useState(
        getCachedToolsConfiguration("welcomerAction", adminConfigs)?.config || ""
    );
    const [welcomeMessage, setWelcomeMessage] = useState(
        getCachedToolsConfiguration("welcomeMessage", adminConfigs)?.normal_config || ""
    );
    const [DMMessage, setDMMessage] = useState(
        getCachedToolsConfiguration("DMMessage", adminConfigs)?.normal_config || ""
    );

    // react useState
    const [guildNames, setGuildNames] = useState([]);
    const [isTypingSearch, setIsTypingSearch] = useState(false);

    // console log activity
    // consoleLog("guildNames", guildNames);
    // consoleLog("selectedChannelID", selectedChannelID);

    // handle search guild member
    useEffect(() => {
        const executeFunction = async () => {

            // prepare required data
            const requiredData = {
                searchKey: selectedChannelID
            };

            // check if is typing search
            if (isTypingSearch) {
                // search guild member
                await searchGuildChannel(requiredData)
                    .then((response) => {

                        // set fetched data
                        setGuildNames(response?.data?.message);

                        // console log activity
                        consoleLog("searchGuildChannel", response?.data?.message);
                    });
            }
        };
        executeFunction();
    }, [selectedChannelID]);

    // handle selected discord channel
    const handleInputKeyword = (channelID) => {

        // set channel id
        setSelectedChannelID(channelID);

        // trigger is typing for search
        setIsTypingSearch(true);

        // console log activity
        // consoleLog("selectedChannelID", channelID);

    };

    // handle selected action
    const handleSelectedAction = (e) => {

        // extracted data
        const action = e.target.value;

        // set the selected action
        setSelectedAction(action ? action : "welcomer_only");

        // kind
        const kind = "welcomerAction";

        // cached config to localstorage
        cachedToolsConfiguration(kind, action);

        // Call the debounced function
        debouncedSaveConfig(kind, "admin", "admin", kind, action);

        // console log activity
        // consoleLog("selectedAction", action);
    };

    // handle welcome message
    const handleWelcomeMessage = (e) => {

        // extracted value
        const message = e.target.value;

        // set the welcome message
        setWelcomeMessage(message);

        // kind
        const kind = "welcomeMessage";

        // cached config to localstorage
        cachedToolsConfiguration(kind, message);

        // Call the debounced function
        debouncedSaveConfig(kind, "admin", "admin", kind, message);
    };

    // handle DM message
    const handleDMMessage = (e) => {

        // extracted value
        const message = e.target.value;

        // set DM message
        setDMMessage(message);

        // kind
        const kind = "DMMessage";

        // cached config to localstorage
        cachedToolsConfiguration(kind, message);

        // Call the debounced function
        debouncedSaveConfig(kind, "admin", "admin", kind, message);

    };

    // handle selected guild channel
    const handleSelectedGuildChannel = (data) => {

        // kind
        const kind = "channelID";
        const channelID = data?.id;

        // set selected channel ID
        setSelectedChannelID(channelID);

        // cached config to localstorage
        cachedToolsConfiguration(kind, channelID);

        // Call the debounced function
        debouncedSaveConfig(kind, "admin", "admin", kind, channelID);

        // console log activity 
        // consoleLog("selectedGuildChannelData", data);

    };

    // function higlight {mention} text
    const formatMessage = (message) => {
        const mentionPlaceholder = '{mention}';
        return message.split(mentionPlaceholder).join(
            `<span class="bg-blue-500 text-white font-bold px-1 rounded">${mentionPlaceholder}</span>`
        );

    };

    return (
        <div className="overflow-y-auto overflow-x-hidden flex justify-center items-center w-full md:inset-0 h-modal md:h-full">
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">

                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-900/70 sm:p-5">

                    <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Welcomer
                        </h3>
                        {/* <Button
                            color="primary"
                            variant="light"
                            size="sm"
                            isIconOnly
                            startContent={
                                <span className="text-2xl">
                                    <i className="fa-solid fa-ellipsis" />
                                </span>
                            }
                        /> */}
                    </div>

                    <div className="grid gap-4 sm:grid-cols-2">
                        {/* <div>
                                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                <input type="text" name="name" id="name" value="iPad Air Gen 5th Wi-Fi" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ex. Apple iMac 27&ldquo;" />
                            </div>
                            <div>
                                <label for="brand" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Brand</label>
                                <input type="text" name="brand" id="brand" value="Google" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ex. Apple" />
                            </div> */}
                        <div>
                            <Tooltip
                                content={
                                    <div className="px-1 py-2 w-[12rem]">
                                        <div className="text-small font-bold">Guild Members</div>
                                        {guildNames?.slice(0, 5)?.map((data, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="flex justify-start items-center text-tiny mt-2 p-2 hover:bg-gray-700 rounded-lg cursor-pointer"
                                                    onClick={() => handleSelectedGuildChannel(data)}
                                                >
                                                    <span className="ml-1">{data?.name}</span>
                                                </div>
                                            );
                                        })}

                                    </div>
                                }
                                placement="bottom"
                                isOpen={guildNames?.length && selectedChannelID !== "" ? true : false}
                                color="default"
                            >
                                <Input
                                    type="text"
                                    labelPlacement="outside"
                                    label="Set Channel ID"
                                    color="primary"
                                    variant="bordered"
                                    value={selectedChannelID}
                                    onValueChange={handleInputKeyword}
                                />
                            </Tooltip>
                        </div>
                        <div>
                            <Select
                                labelPlacement="outside"
                                label="Set Action"
                                variant="bordered"
                                color="warning"
                                selectedKeys={[selectedAction]}
                                onChange={handleSelectedAction}
                            >
                                {actionOptions.map((action) => (
                                    <SelectItem key={action.key}>
                                        {action.label}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="sm:col-span-2 space-y-5">
                            {/* <div> */}
                            <Textarea
                                labelPlacement="outside"
                                label="Set welcomer message"
                                variant="bordered"
                                placeholder="Enter your welcomer message"
                                disableAnimation
                                disableAutosize
                                classNames={{
                                    base: "w-full",
                                    input: "resize-y min-h-[10rem]",
                                }}
                                onChange={handleWelcomeMessage}
                                value={welcomeMessage}
                            />
                            {/* <p className="mt-4 text-base" dangerouslySetInnerHTML={{ __html: formatMessage(welcomeMessage) }} />
                            </div> */}

                            <Textarea
                                labelPlacement="outside"
                                label="set DM message"
                                variant="bordered"
                                placeholder="Enter your DM message"
                                disableAnimation
                                disableAutosize
                                classNames={{
                                    base: "w-full",
                                    input: "resize-y min-h-[10rem]",
                                }}
                                onChange={handleDMMessage}
                                value={DMMessage}
                            />
                        </div>
                    </div>
                    {/* <div className="flex items-center space-x-4">
                        <button type="submit" className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            Update product
                        </button>
                        <button type="button" className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                            <svg className="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                            Delete
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Welcomer;