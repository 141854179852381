export const DefaultVisualizer = () => {
  return (
    <div className="flex flex-row items-center space-x-px ml-4">
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-4 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-12 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-6 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-5 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-4 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-3 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-1 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-1 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
      <div className="h-4 w-1 bg-gray-500 rounded-lg"></div>
    </div>
  );
};