import React from 'react';

const LogoAndTitle = ({ logo, title }) => {
  // Message style
  const messageStyle = {
    success: "bg-green-200 border-l-4 border-green-500 text-green-700 pt-6 pb-4 px-4 rounded relative mb-0.5",
    error: "bg-red-200 border-l-4 border-red-500 text-red-700 pt-6 pb-4 px-4 rounded relative mb-0.5"
  };
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <a href="/">
        <img
          className="mx-auto h-48 w-auto"
          src={logo}
          alt="Your Company"
        />
      </a>
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
        {title}
      </h2>
    </div>
  );
};

export default LogoAndTitle;