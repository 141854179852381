import React from "react";
import { Tooltip } from "react-tooltip";

const RegisterInput = (props) => {

    const {
        errorMessages,
        label,
        data,
        handleChange,
        name,
        placeholder,
        type,
        icon,
        togglePasswordVisibility,
        showPassword,
        kind
    } = props;

    return (
        <div
            data-tooltip-id="tooltip-transcriber"
            data-tooltip-html={errorMessages ? label : null}
            data-tooltip-place="top-start"
            className={`${errorMessages && "border-red-500"} flex items-center border-2 py-2 px-3 rounded-2xl mb-4 text-gray-800 dark:text-gray-50`}
        >
            {errorMessages && (
                <Tooltip id="tooltip-transcriber" />
            )}

            <i className={`${icon} mr-1`} />
            <input
                className="pl-2 w-[89%] outline-none border-none focus:border-none focus:outline-none focus:ring-0 bg-white dark:bg-gray-800"
                type={type}
                name={name}
                placeholder={placeholder}
                value={data}
                onChange={handleChange}
            />
            {kind === "password" && (
                <span
                    className="absolute flex items-center ml-[18.5rem] mt-1 text-gray-500 cursor-pointer"
                    onClick={togglePasswordVisibility}
                    type="button"
                >
                    {showPassword ? (
                        <i className="fa-solid fa-eye-slash absolute" />
                    ) : (
                        <i className="fa-solid fa-eye absolute" />
                    )}
                </span>
            )}

        </div>
    );
};

export default RegisterInput;