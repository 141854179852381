import React, { useState, useEffect, useRef } from "react";

// google captcha imports
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// components and helpers
import { resetPass, verifyCaptcha, verifyResetKey } from "../../API/authAPI";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import ResponseMessage from "../../components/ResponseMessage";
import LogoAndTitle from "../../components/LogoAndTitle";
import lottie from "lottie-web";

const ForgotPassword = () => {

    // use ref
    let resetPassRobot = useRef();

    // google captcha
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [responseMessage, setResponseMessage] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetKey, setResetKey] = useState("");
    const [email, setEmail] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const resetPass = lottie.loadAnimation({
            container: resetPassRobot.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shinefy-floating-robot.json",
        });

        return () => {
            resetPass.destroy();
        };
    }, [resetPassRobot]);

    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    const closeResponseMessage = () => {
        setResponseMessage([]);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible((prev) => !prev);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');
        const key = params.get('key');
        setEmail(email);
        setResetKey(key);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSubmitting(true);

        // Clear previous response messages
        setResponseMessage([]);

        // Check if email and resetkey exist
        if (!email || !resetKey) {
            setResponseMessage([{ kind: "error", message: "Email and Resetkey is required." }]);
            setIsSubmitting(false);
            return;
        }

        // Check if email and resetkey exist
        if (!newPassword || !confirmNewPassword) {
            setResponseMessage([{ kind: "error", message: "Empty fields." }]);
            setIsSubmitting(false);
            return;
        }

        if (newPassword.length < 8) {
            setResponseMessage([{ kind: "error", message: "Min. of 8 characters required." }]);
            setIsSubmitting(false);
            return;
        }

        // check if newPassword and confirmNewPassword are equals
        if (newPassword !== confirmNewPassword) {
            setResponseMessage([{ kind: "error", message: "Passwords do not match." }]);
            setIsSubmitting(false);
            return;
        }

        // verify if resetkey is valid
        const response = await verifyResetKey(email, resetKey);
        // console.log(response);
        if (response.data.message === "Invalid Key.") {
            setResponseMessage([{ kind: "error", message: "This Is Not Possible!" }]);
            setIsSubmitting(false);
            return;
        } else if (response.data.message === "Token already used.") {
            setResponseMessage([{ kind: "error", message: "This Is Not Possible!" }]);
            setIsSubmitting(false);
            return;
        }

        if (executeRecaptcha) {
            const token = await executeRecaptcha("resetPassword");
            const captchaResponse = await verifyCaptcha(token);
            if (!captchaResponse?.data?.success === true) {
                setResponseMessage([{ kind: "error", message: captchaResponse?.data?.error }]);
                setIsSubmitting(false);
                return;
            }
        }

        await resetPass(email, newPassword, confirmNewPassword)
            .then((response) => {
                setIsSubmitting(false);
                setResponseMessage([{ kind: "success", message: "Your password has been changed successfully. You can now log in with your new password." }]);
                consoleLog("pages > Auth > Resetpassword.js > handleSubmit > response", response);
            }).catch((err) => {
                setIsSubmitting(false);
                ToastEffect("error", "Something went wrong!");
                consoleLog("pages > Auth > Resetpassword.js > handleSubmit > error", err);
            });
    };

    return (
        <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12 bg-gradient-to-tr from-blue-800 to-purple-950">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div
                    style={{ backgroundImage: 'url(/assets/images/cover.png)' }}
                    className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-90 sm:rounded-3xl"
                />
                <div className="relative bottom-32 w-auto h-[20rem]" ref={resetPassRobot} />
                <div className="relative px-4 py-10 bottom-40 bg-white dark:bg-gray-800 shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                        <div className="dark:text-gray-300">
                            <h1 className="text-2xl font-semibold">Create new shinefy password</h1>
                        </div>
                        <div className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">

                                <form onSubmit={handleSubmit}>
                                    <div className="relative">
                                        <input
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            type={passwordVisible ? "text" : "password"}
                                            value={newPassword}
                                            minLength={8}
                                            autoComplete="off"
                                            id="password"
                                            name="password"
                                            className="peer placeholder-transparent h-10 w-full border-x-0 border-t-0 border-b-2 border-gray-300 text-gray-900 dark:text-gray-300 dark:bg-gray-800 outline-none focus:outline-none focus:ring-0 focus:borer-rose-600"
                                            placeholder="Password"
                                            required
                                        />
                                        <div
                                            className="absolute inset-y-0 right-3 bottom-2 text-gray-600 flex items-center cursor-pointer"
                                            onClick={togglePasswordVisibility}
                                            type="button">
                                            {passwordVisible ? (
                                                <i className="fa-solid fa-eye-slash" />
                                            ) : (
                                                <i className="fa-solid fa-eye" />
                                            )}
                                        </div>
                                        <label htmlFor="password" className="absolute font-semibold left-0 -top-3.5 text-gray-600 dark:text-gray-300 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 dark:peer-focus:text-gray-400 peer-focus:text-gray-600 peer-focus:text-sm">
                                            New Password
                                        </label>
                                    </div>
                                    <div className="relative mt-5">
                                        <input
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            type={confirmPasswordVisible ? "text" : "password"}
                                            value={confirmNewPassword}
                                            minLength={8}
                                            autoComplete="off"
                                            id="re-password"
                                            name="re-password"
                                            className="peer placeholder-transparent h-10 w-full border-x-0 border-t-0 border-b-2 border-gray-300 text-gray-900 dark:text-gray-300 dark:bg-gray-800 outline-none focus:outline-none focus:ring-0 focus:borer-rose-600"
                                            placeholder="confirm password"
                                            required
                                        />
                                        <span
                                            className="absolute inset-y-0 right-3 bottom-2 text-gray-600 flex items-center cursor-pointer"
                                            onClick={toggleConfirmPasswordVisibility}
                                            type="button">
                                            {confirmPasswordVisible ? (
                                                <i className="fa-solid fa-eye-slash" />
                                            ) : (
                                                <i className="fa-solid fa-eye" />
                                            )}
                                        </span>
                                        <label htmlFor="re-password" className="absolute font-semibold left-0 -top-3.5 text-gray-600 dark:text-gray-300 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 dark:peer-focus:text-gray-400 peer-focus:text-gray-600 peer-focus:text-sm">
                                            Confirm Password
                                        </label>
                                    </div>

                                    {/* <div className="relative">
                                        <button className="bg-blue-500 text-white rounded-md px-2 py-1">Submit</button>
                                    </div> */}

                                    <div className="mt-3">
                                        <button
                                            disabled={isSubmitting ? true : false}
                                            type="submit"
                                            className="relative flex w-full justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            {isSubmitting && (
                                                <i className="fa-solid fa-spinner fa-spin-pulse absolute left-4" />
                                            )}
                                            Change Password
                                        </button>
                                        <GoogleReCaptcha action="resetPassword" />
                                    </div>
                                </form>

                                <div className={`absolute ${isMobile ? "w-[21rem]" : "w-[25rem]"}  right-[8%]`}>
                                    {responseMessage.length > 0 && (
                                        <ResponseMessage
                                            responseMessage={responseMessage}
                                            closeResponseMessage={closeResponseMessage}
                                        />
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;
