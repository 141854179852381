import consoleLog from "../../../../functions/consoleLog";
import { saveAs } from 'file-saver';

export default async function handleDownload(videoURL, filename, kind) {
  try {
    const response = await fetch(videoURL);
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    saveAs(objectURL, `${filename}.${kind}`); // Set the desired download filename
  } catch (error) {
    consoleLog('An error occurred:', error);
  }
};

// export default async function handleDownload(videoURL, filename, kind) {
//   try {
//     const response = await fetch(videoURL);
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     const blob = await response.blob();
//     saveAs(blob, `${filename}.${kind}`); // Set the desired download filename
//   } catch (error) {
//     consoleLog('An error occurred:', error); // Changed consoleLog to console.log
//   }
// }