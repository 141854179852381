// Get Item with expiry
export const getItemWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};

// Set Item with expiry
export const setItemWithExpiry = (key, value, ttl) => {

    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

// deduct token view
export const adjustCreditsView = (key, kind, value) => {
    const itemStr = JSON.parse(localStorage.getItem(key));
    if (!itemStr) {
        return null;
    }

    if (kind === "refund") {
        setItemWithExpiry('tokenBalance', Number(itemStr?.value) + value, 3600000);
    } else if (kind === "new_balance") {
        setItemWithExpiry('tokenBalance', Number(itemStr?.value) + value, 3600000);
    } else {
        setItemWithExpiry('tokenBalance', Number(itemStr?.value) - value, 3600000);
    }

};