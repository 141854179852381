// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { Select, SelectItem, Tooltip } from "@nextui-org/react";
import ApexCharts from "apexcharts";

// custom components
import { arrayOfMonths, compareValues, getCurrentMonthAndYear, getYearsFrom2023ToCurrent, sortTheUsageData, sumPayments } from "../../../functions/adminFunctions";
import AdminContext from "../../../components/Admin/AdminContext";
import consoleLog from "../../../functions/consoleLog";

const CreditsUsageGraph = () => {

    // context method
    const {

        // others
        selectedMonth,
        setSelectedMonth,
        selectedYear,
        setSelectedYear,

        // tools log data
        toolsLogSpentData,

        // fetching spent logs data
        isFetchingSpentData,

    } = useContext(AdminContext);

    // react useRef
    const credistUsageChartRef = useRef(null);

    // react useState
    const [sortedUsageData, setSortedUsageData] = useState([]);
    const [sortedTotalSpent, setSortedTotalSpent] = useState(0);
    const [calculatedValue, setCalculatedValue] = useState([]);

    // // call sort function
    // const sortUsageData = sortTheUsageData(toolsLogSpentData, "November");

    // console log activity
    // consoleLog("getCurrentMonthAndYear", getCurrentMonthAndYear());
    // consoleLog("getYearsFrom2023ToCurrent", getYearsFrom2023ToCurrent());
    // consoleLog("sortedUsageData", sortedUsageData);
    // consoleLog("toolsLogSpentData", toolsLogSpentData);

    // sort usage data
    useEffect(() => {
        const calculations = async () => {

            // sort by month and year
            const sortUsageData = sortTheUsageData(
                toolsLogSpentData, // array data
                selectedMonth, // selected month
                selectedYear, // selected year
                "payment", // data kind
            );
            setSortedUsageData(sortUsageData?.totalsArray);
            setSortedTotalSpent(sortUsageData?.totalValue);
        };

        calculations();
    }, [toolsLogSpentData, selectedMonth, selectedYear]);

    // get current total usage this month
    useEffect(() => {

        const calculations = async () => {

            const usageThisMonth = sortTheUsageData(
                toolsLogSpentData, // array data
                getCurrentMonthAndYear()?.month, // selected month
                getCurrentMonthAndYear()?.year, // selected year
                "payment", // data kind
            );

            // set value
            const totalUsageThisMonth = Number(usageThisMonth?.totalValue);
            const dynamicValue = Number(sortedTotalSpent);

            // calculate the difference value
            const calculatedData = await compareValues(totalUsageThisMonth, dynamicValue);

            // set calculated value
            setCalculatedValue(calculatedData);

            // console log activity
            // consoleLog("calculatedData", calculatedData);
            // consoleLog("constantValue", totalUsageThisMonth);
            // consoleLog("dynamicValue", dynamicValue);
        };

        calculations();

    }, [sortedTotalSpent]);

    useEffect(() => {
        const options = {

            chart: {
                height: '80%',
                maxWidth: '100%',
                type: 'area',
                fontFamily: 'Inter, sans-serif',
                dropShadow: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.55,
                    opacityTo: 0,
                    shade: '#1C64F2',
                    gradientToColors: ['#1C64F2'],
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 6,
            },
            grid: {
                show: false,
                strokeDashArray: 4,
                padding: {
                    left: 2,
                    right: 2,
                    top: 0,
                },
            },
            series: [
                {
                    name: 'Total Usage',
                    data: sortedUsageData?.map((data) => (data?.total)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })),
                    color: '#1A56DB',
                },
            ],
            xaxis: {
                categories: sortedUsageData?.map((data) => data?.date),
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },

        };

        const chart = new ApexCharts(credistUsageChartRef.current, options);

        // Initialize chart if ApexCharts is available and the ref is attached to the DOM
        if (credistUsageChartRef.current) {
            chart.render();
        }

        // Clean up chart on component unmount
        return () => {
            if (credistUsageChartRef.current) {
                chart.destroy();
            }
        };
    }, [sortedUsageData, selectedMonth, selectedYear]);

    // const toggleDropdown = () => {
    //     setIsDropdownOpen(!isDropdownOpen);
    // };

    return (

        <div className="relative p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">

            {/* Loading indicator */}
            {isFetchingSpentData && (
                <div className="absolute text-5xl inset-0 flex items-center justify-center backdrop-blur-lg z-20">
                    {/* <i className="fa-solid fa-chart-column fa-bounce" /> */}
                    <i className="fa-solid fa-cog fa-spin" />
                </div>
            )}

            {/* Top display */}
            <div className="flex justify-between">

                <div>
                    <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
                        <i className="fa-solid fa-coins text-yellow-300" /> {(sortedTotalSpent)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                    <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Total burned <code>(<i className="fa-solid fa-coins text-yellow-300" /> {sumPayments(toolsLogSpentData?.message)})</code>
                    </p>
                </div>

                <Tooltip
                    color="primary"
                    offset={-7}
                    showArrow={true}
                    content={
                        <div>
                            <i className="fa-solid fa-coins text-yellow-300 mr-1" />
                            <code>{(calculatedValue?.difference)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</code>
                        </div>
                    }
                    className="cursor-help"
                >
                    <div className={`${calculatedValue?.trend === "down" ? "text-danger" : "text-success"} flex items-center px-2.5 py-0.5 text-lg font-bold text-center`}>
                        <span>{calculatedValue?.trend === "down" ? "-" : "+"}</span>
                        {calculatedValue?.percentage}
                        <i className={`fa-solid fa-arrow-${calculatedValue?.trend}-long ms-1 fa-sm`} />
                    </div>
                </Tooltip>

            </div>

            {/* Graph displays here */}
            <div className="text-gray-800 font-semibold" ref={credistUsageChartRef} id="area-chart" />

            {/*  Buttom display */}
            <div className="relative grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
                <div className="flex justify-start gap-1 items-center pt-5">

                    <Select
                        label="Select Month"
                        className="w-[40%] lg:w-[20%]"
                        color="primary"
                        variant="bordered"
                        size="sm"
                        selectedKeys={[selectedMonth]}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                        {arrayOfMonths()?.map((data) => (
                            <SelectItem key={data?.month}>
                                {data?.month}
                            </SelectItem>
                        ))}
                    </Select>

                    <Select
                        label="Select Year"
                        className="w-[40%] lg:w-[20%]"
                        color="primary"
                        variant="bordered"
                        size="sm"
                        selectedKeys={[(selectedYear).toString()]}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        {getYearsFrom2023ToCurrent()?.map((data) => (
                            <SelectItem key={(data?.year).toString()}>
                                {(data?.year).toString()}
                            </SelectItem>
                        ))}
                    </Select>

                    {/* <span className="cursor-pointer uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
                        User Usage <i className="fa-solid fa-arrow-up-right-from-square ms-1.5 rtl:rotate-180" />
                    </span> */}

                </div>
            </div>
        </div>
    );
};

export default CreditsUsageGraph;