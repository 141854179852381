// libraries
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, Spinner } from "@nextui-org/react";

// custom components
import consoleLog from "../../functions/consoleLog";
import AdminContext from "../../components/Admin/AdminContext";
import { getStatusColor } from "../../functions/adminFunctions";
import { convertToCustomerLocalTime, countDataFromArray } from "../../functions/general";
import NotifList from "../../components/Notification/NotifList";
import NotifView from "../../components/Notification/NotifView";
import NotificationList from "./Notifications/NotificationList";
import NotificationInteract from "./Notifications/NotificationInteract";

const NotificationView = () => {

    // context method
    const {

        // admin notifications
        adminNotifications,
        setAdminNotifications,
        isFetchingNotifications,
        setRefetchNotifications,

    } = useContext(AdminContext);

    // react components
    const [selectedNotificationID, setSelectedNotificationID] = useState(null);
    const [notificationData, setNotificationData] = useState([]);


    // console log activity
    // consoleLog("adminNotifications", adminNotifications);

    // filter and set data base from the selectedNotificationID
    useEffect(() => {
        const filterData = () => {

            // sorted data
            const filteredData = adminNotifications?.find((data) => data?.linked_id === selectedNotificationID);
            setNotificationData(filteredData); // set the filtered data

            // console log activity
            // consoleLog("filteredData", filteredData);
        };
        filterData();
    }, [selectedNotificationID, adminNotifications]);

    // call fnction that counts the data conditionally
    // const dataCount = countDataFromArray(
    //     adminNotifications,
    //     "isRead",
    //     false
    // );

    return (

        <div className="my-5 sm:mt-4 lg:flex lg:gap-4">

            {/* notification list */}
            <NotificationList
                adminNotifications={adminNotifications}
                setSelectedNotificationID={setSelectedNotificationID}
                selectedNotificationID={selectedNotificationID}
                setRefetchNotifications={setRefetchNotifications}
            />

            {/* notification interact */}
            <NotificationInteract
                notificationData={notificationData}
                setRefetchNotifications={setRefetchNotifications}
            />

        </div>

    );
};

export default NotificationView;