// libraries
import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { io } from "socket.io-client";

// custom components
import AdminContext from "./AdminContext";
import TopNav from "../../pages/Admin/TopNav";
import LeftSideNav from "../../pages/Admin/LeftSideNav";
import FooterButtom from "../../pages/Admin/FooterButtom";
import consoleLog from "../../functions/consoleLog";
import { verifyToken } from "../../API/authAPI";
import NotFound from "../../pages/shinefy/NotFound";
import LoadingPage from "../../pages/shinefy/LoadingPage";
import { fetchAccounts, fetchActiveSubscribers, fetchAdminConfigs, fetchAdminNotifications, fetchBillingActivities, fetchBotchats, fetchLoginRecords, fetchServerActivities, fetchToolsLogSpentData } from "../../API/server";
import { getCurrentMonthAndYear } from "../../functions/adminFunctions";
import { debounce } from "../../functions/general";
import { saveConfig } from "../../pages/shinefy/Tools/API/configDBAPI";

const AdminLayout = (props) => {

    // props
    const {
        logout
    } = props;

    // react useState
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthAndYear()?.month || "");
    const [selectedYear, setSelectedYear] = useState(getCurrentMonthAndYear()?.year || "");
    const [toggleShowSideNav, setToggleShowSideNav] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingSpentData, setIsFetchingSpentData] = useState(false);
    const [isFetchingLoginRecords, setIsFetchingLoginRecords] = useState(false);
    const [isFetchingActivities, setIsFetchingActivities] = useState(false);
    const [isFetchingAccountRecords, setIsFetchingAccountRecords] = useState(false);
    const [isFetchingSubscribers, setIsFetchingSubscribers] = useState(false);
    const [isFetchingBillingActivities, setIsFetchingBillingActivities] = useState(false);
    const [isFetchingNotifications, setIsFetchingNotifications] = useState(false);
    const [isFetchingBotChats, setIsFetchingBotChats] = useState(false);
    const [isFetchingAdminconfigs, setIsFetchingAdminconfigs] = useState(false);
    const [toolsLogSpentData, setToolsLogSpentData] = useState([]);
    const [loginRecords, setLoginRecords] = useState([]);
    const [serverActivities, setServerActivities] = useState([]);
    const [accountRecords, setAccountRecords] = useState([]);
    const [activeSubscribers, setActiveSubscribers] = useState([]);
    const [allBillingActivities, setAllBillingActivities] = useState([]);
    const [adminNotifications, setAdminNotifications] = useState([]);
    const [botChatsData, setBotChatsData] = useState([]);
    const [adminConfigs, setAdminConfigs] = useState([]);
    const [socket, setSocket] = useState(null);
    const [refetchAuth, setRefetchAuth] = useState(0);
    const [refetchServerActivities, setRefetchServerActivities] = useState(0);
    const [refetchAccountRecords, setRefetchAccountRecords] = useState(0);
    const [refetchSubscribers, setRefetchSubscribers] = useState(0);
    const [refetchBillingActivities, setRefetchBillingActivities] = useState(0);
    const [refetchSpendLogs, setRefetchSpendLogs] = useState(0);
    const [refetchLoginRecords, setRefetchLoginRecords] = useState(0);
    const [refetchNotifications, setRefetchNotifications] = useState(0);
    const [refetchBotChats, setRefetchBotChats] = useState(0);
    const [refetchAdminConfigs, setRefetchAdminConfigs] = useState(0);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [botDMStatus, setBotDMStatus] = useState([]);

    const [currentTheme, setCurrentTheme] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            return storedTheme;
        } else {
            return window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';
        }
    });

    // console log activity
    // consoleLog("adminNotifications", adminNotifications);

    // handle switch theme dark | light
    const handleTheme = () => {
        if (localStorage.theme === "dark") {
            localStorage.theme = "light";
            document.documentElement.classList.remove("dark");
        } else {
            localStorage.theme = "dark";
            document.documentElement.classList.add("dark");
        }
        setCurrentTheme(localStorage.theme);
    };

    //Web Socket
    useEffect(() => {
        const webSocket = () => {
            setSocket(io.connect(process.env.REACT_APP_SHINEFY_SERVER));
        };
        webSocket();
    }, []);

    // emit new user function
    async function newOnlineUser(email, type) {
        await socket?.emit("new-user", email, type);
    }

    // fetch user data
    useEffect(() => {

        const fetchUserData = async () => {

            // set loading mark
            setIsLoading(true);

            // access and refresh from local storage
            const accessToken = localStorage.getItem('access');
            const refreshToken = localStorage.getItem('refresh');

            // required data
            const requiredData = {
                access: accessToken,
                refresh: refreshToken,
            };

            // Verify token to check if the user is authorized
            const tokenResponse = await verifyToken(requiredData);

            // verifyToken extracted values
            const tokenResponseMessage = tokenResponse?.data?.message;
            const tokenResponseEmail = tokenResponse?.data?.UserInfo?.email;
            const tokenResponseData = tokenResponse?.data;
            const tokenResponseRole = tokenResponse?.data?.UserInfo?.role;
            const tokenResponseAccess = tokenResponse?.data?.access;

            // conditional function for "tokenResponse"
            if (tokenResponseMessage === "Unauthorized") {
                return; // do nothing
            } else if (tokenResponseMessage === "refreshed") {
                localStorage.setItem('access', tokenResponseAccess); // save new items to the local storage
                localStorage.removeItem('tokenBalance'); // remove token balance
            } else if (tokenResponseMessage === "refreshExpired") {
                // remove items to the local storage
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
                window.location.href = "/"; // send the user back to the main page which is the login page
            }

            // emit the new user to the server
            await newOnlineUser(tokenResponseEmail, tokenResponseRole);

            // Set data to useState
            setUserData(tokenResponseData);

            // disable loading mark for userdata
            setIsLoading(false);

            // --------------------------------------------------------------------------

            // console log activity
            // consoleLog("toolsLogSpentData", toolsLogSpentData);
            // consoleLog("tokenResponseData", tokenResponseData);
        };
        fetchUserData();

    }, [refetchAuth, socket]);

    // fetch spend logs
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching spent data logs
            setIsFetchingSpentData(true);

            // call function fetchToolsLogSpentData
            const toolsLogSpentData = await fetchToolsLogSpentData();
            setToolsLogSpentData(toolsLogSpentData?.data); // set data result

            // disable loading for fetching spent data logs
            setIsFetchingSpentData(false);
        };
        fetchData();
    }, [refetchSpendLogs]);

    // fetch login records
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching login records
            setIsFetchingLoginRecords(true);

            // call function fetchLoginRecords
            const loginRecords = await fetchLoginRecords();
            setLoginRecords(loginRecords?.data);

            // disable loading for fetching login records
            setIsFetchingLoginRecords(false);
        };
        fetchData();
    }, [refetchLoginRecords]);

    // fetch server activity
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching server activities
            setIsFetchingActivities(true);

            // call function fetchServerActivities
            const serverActivitiesResponse = await fetchServerActivities();
            setServerActivities(serverActivitiesResponse?.data); // set data result

            // disable loading for fetching server activities
            setIsFetchingActivities(false);
        };
        fetchData();
    }, [refetchServerActivities]);

    // fetch account records
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching account records
            setIsFetchingAccountRecords(true);

            // call function fetchAccounts
            const accountRecords = await fetchAccounts();
            setAccountRecords(accountRecords?.data); // set data result

            // disable loading for fetching account records
            setIsFetchingAccountRecords(false);
        };
        fetchData();
    }, [refetchAccountRecords]);

    // fetch all active subscribers
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching all subscribers
            setIsFetchingSubscribers(true);

            // call function fetchActiveSubscribers
            const subscribers = await fetchActiveSubscribers();
            setActiveSubscribers(subscribers?.data); // set data result

            // disable loading for fetching all subscribers
            setIsFetchingSubscribers(false);
        };
        fetchData();
    }, [refetchSubscribers]);

    // fetch billing activities
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching billing activities
            setIsFetchingBillingActivities(true);

            // call function fetchBillingActivities
            const billingActivities = await fetchBillingActivities();
            setAllBillingActivities(billingActivities?.data); // set data result

            // disable loading for fetching billing activities
            setIsFetchingBillingActivities(false);
        };
        fetchData();
    }, [refetchBillingActivities]);

    // fetch admin notifications
    useEffect(() => {
        const fetchData = async () => {
            // set loading for fetching notifications
            setIsFetchingNotifications(true);

            // call function fetchAdminNotifications
            const notifications = await fetchAdminNotifications();
            setAdminNotifications(notifications?.data?.message);

            // disable loading for fetching notifications
            setIsFetchingNotifications(false);
        };
        fetchData();
    }, [refetchNotifications]);

    // fetch chat sessions
    useEffect(() => {
        const fetchData = async () => {
            // set loading mark for fetching
            setIsFetchingBotChats(true);

            // call function fetchBotChats
            const botChats = await fetchBotchats();
            setBotChatsData(botChats?.data?.message);

            // console log activity
            // consoleLog("botChats", botChats);

            // disable loading marked for fetching
            setIsFetchingBotChats(false);
        };
        fetchData();
    }, [refetchBotChats]);

    // fetch admin configs
    useEffect(() => {
        const fetchData = async () => {
            // set loading mark for fetching
            setIsFetchingAdminconfigs(true);

            // call function fetchBotChats
            const adminConfigs = await fetchAdminConfigs();
            setAdminConfigs(adminConfigs?.data?.message);

            // console log activity
            consoleLog("adminConfigs", adminConfigs?.data);

            // disable loading marked for fetching
            setIsFetchingAdminconfigs(false);
        };
        fetchData();
    }, [refetchAdminConfigs]);

    // emited data from socket
    useEffect(() => {

        // live online users
        const updateSocket = () => {

            // for online users
            socket?.on("onlineUsers", data => {
                if (data) {
                    setOnlineUsers(data.onlineUsers);
                    consoleLog("onlineUsers", data);
                }
            });

            // for server activities
            socket?.on("serverActivity", data => {
                if (data) {
                    setRefetchServerActivities(value => value + 1);
                    setRefetchSpendLogs(value => value + 1);
                    consoleLog("serverActivity", data);
                }
            });

            // for incoming notifications
            socket?.on("incomingNotification", data => {
                if (data) {
                    setRefetchNotifications((value) => value + 1);
                    consoleLog("incomingNotification", data);
                }
            });

            // for login records
            socket?.on("userLoginRecords", data => {
                if (data) {
                    setRefetchLoginRecords(value => value + 1);
                    consoleLog("userLoginRecords", data);
                }
            });

            // for billing activities
            socket?.on("billingActivities", data => {
                if (data) {
                    setRefetchBillingActivities(value => value + 1);
                    consoleLog("billingActivities", data);
                }
            });

            // for Discord DM bot status
            socket?.on("DMStatus", data => {
                if (data) {
                    setBotDMStatus(data?.data);
                    consoleLog("DMStatus", data);
                }
            });

            // for incoming discord message
            socket?.on("incomingDiscordMessage", data => {
                if (data) {
                    setRefetchBotChats(value => value + 1);
                    consoleLog("incomingDiscordMessage", data);
                }
            });

        };

        updateSocket();
    }, [socket]);

    // check if is mobile size
    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    // debounce saveConfig function. this is to prevent spamming and abuse of buttons. delays 1000 = 1sec
    const debouncedSaveConfig = useCallback(debounce(async (email, pathname, kind, value) => {

        // console log activity
        consoleLog(kind, value);

        // save configuration to the database
        saveConfig(email, pathname, kind, value);

    }, 1000), []);

    return (
        <>
            {isLoading ? (<LoadingPage />) : userData?.UserInfo?.role === 9999 ? (
                <>
                    {/** Top nav */}
                    <TopNav
                        setToggleShowSideNav={setToggleShowSideNav}
                        toggleShowSideNav={toggleShowSideNav}
                        userData={userData}
                        logout={logout}
                        adminNotifications={adminNotifications}
                    />
                    <div className="flex pt-16 overflow-hidden bg-gray-50 dark:bg-gray-900 min-h-screen backgroundCustom">

                        {/* LeftSide Nav */}
                        <LeftSideNav
                            toggleShowSideNav={toggleShowSideNav}
                            handleTheme={handleTheme}
                            currentTheme={currentTheme}
                        />

                        <AdminContext.Provider
                            value={{

                                // others
                                userData,
                                isMobile,
                                onlineUsers,
                                selectedMonth,
                                setSelectedMonth,
                                selectedYear,
                                setSelectedYear,


                                // tools log data
                                toolsLogSpentData,

                                // fetching spent logs data
                                isFetchingSpentData,

                                // server activities
                                serverActivities,
                                isFetchingActivities,
                                setRefetchServerActivities,

                                // login records
                                loginRecords,
                                isFetchingLoginRecords,
                                setRefetchLoginRecords,

                                // account records
                                accountRecords,
                                isFetchingAccountRecords,
                                setRefetchAccountRecords,

                                // subscribers record
                                activeSubscribers,
                                isFetchingSubscribers,
                                setRefetchSubscribers,

                                // billing activities
                                allBillingActivities,
                                isFetchingBillingActivities,
                                setRefetchBillingActivities,

                                // admin notifications
                                adminNotifications,
                                setAdminNotifications,
                                isFetchingNotifications,
                                setRefetchNotifications,

                                // admin discord 
                                botDMStatus,
                                botChatsData,
                                isFetchingBotChats,
                                setRefetchBotChats,

                                // admin config
                                debouncedSaveConfig,
                                adminConfigs,
                                isFetchingAdminconfigs,
                                setRefetchAdminConfigs,

                            }}
                        >
                            <div className="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" /> {/* id="sidebarBackdrop" */}
                            <div className="relative w-full h-full overflow-y-auto lg:ml-64 bg-gray-50 dark:bg-gray-900"> {/* id="main-content" */}
                                <main>
                                    <div className="px-4 pt-6">
                                        <Outlet />

                                        {/* Footer Buttom */}
                                        {/* <FooterButtom /> */}
                                    </div>
                                </main>
                            </div>

                        </AdminContext.Provider>
                    </div>
                </>
            ) : (<NotFound />)}
        </>
    );
};

export default AdminLayout;