export const toolPrices = (data) => {
  switch (data) {
    case "accuracy": return 0.2;
    case "caption": return 0.36;
    case "animation": return 0.36;
    case "avatar": return 3.5;
    case "voice": return 0.7;
    case "language": return 0.5;
    case "enhancer": return 0.3;
    case "image": return 0.2;
    case "audio": return 0.1;
    case "motion": return 1.0;
    case "default_image": return 0.33;
    case "default_voice": return 0.34;
    default: return 0.33;
  }
};

export const transcribePrices = (data) => {
  switch (data) {
    case "file": return 0.35;
    default: return 0;
  }
};