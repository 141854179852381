// libraries
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button } from "@nextui-org/react";
import React from "react";

const VT_TableCell5 = (props) => {

    // props
    const {
        item,
        onOpenTMV,
        handleView,
        handleDeleteOnclick,
    } = props;

    // custom icon class
    const iconClasses = "text-2xl text-default-500 pointer-events-none flex-shrink-0";

    // handle open modal view
    const handleOpenModalView = (item) => {

        // trigger open modal
        onOpenTMV();

        // set item
        handleView(item);

    };

    return (
        <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
                <DropdownTrigger>
                    <Button isIconOnly size="sm" variant="light">
                        <i className="fa-solid fa-ellipsis-vertical" />
                    </Button>
                </DropdownTrigger>
                <DropdownMenu
                    // closeOnSelect={false} //  when the menu item is selected close | stayed open
                    variant="faded"
                    aria-label="Dropdown menu with description"
                >

                    <DropdownSection title="Actions" showDivider>

                        <DropdownItem
                            key="preview"
                            // shortcut="⌘N"
                            description="View transcription text"
                            startContent={<i className={`fa-regular fa-file-lines ${iconClasses}`} />}
                            className={item?.content_url ? "show" : "hidden"} // This option is available only for content that has URL
                            onClick={() => handleOpenModalView(item)}
                        >
                            View transcription
                        </DropdownItem>

                        {/* <DropdownItem
                            key="download"
                            // shortcut="⌘C"
                            description="Download media file"
                            startContent={<i className={`fa-solid fa-download ${isProcessing[dataID] && "fa-bounce"} ${iconClasses}`} />}
                        // className={containsUrl(data?.content_url) ? "show" : "hidden"} // This option is available only for content that has URL
                        // onClick={handleDownloadMediaFromMenu}
                        >
                            Download Media
                        </DropdownItem>

                        <DropdownItem
                            key="srtFile"
                            // shortcut="⌘C"
                            description="Download .srt file"
                            startContent={<i className={`fa-solid fa-download ${isProcessing[`${dataID}_srt_file`] && "fa-bounce"} ${iconClasses}`} />}
                        // className={captionStatus ? "show" : "hidden"} // This option is available only for content that has URL
                        // onClick={handleDownloadCaptionfile}
                        >
                            Download .SRT
                        </DropdownItem>

                        <DropdownItem
                            key="retry"
                            // shortcut="⌘⇧E"
                            description="Retry failed process"
                            startContent={<i className={`${isProcessing[dataID] ? "fa-solid fa-arrows-rotate fa-spin" : "fa-solid fa-repeat"} ${iconClasses}`} />}
                        // className={data?.status === "failed" && data?.content_url !== "accuracy_video" ? "show" : "hidden"} // This option is available only for failed process and not "accuracy_video"
                        // onClick={handleRetryProcessFromMenu}
                        >
                            Retry Process
                        </DropdownItem> */}

                        {/* <DropdownItem
                            key="chat"
                            // shortcut="⌘⇧E"
                            description="Can chat with the file"
                            startContent={<i className={`fa-regular fa-message ${iconClasses}`} />}
                            // onClick={() => handleCustomMedia(data)}
                            className={
                                (item?.status === "failed" || item?.status === "error") ? "hidden" // hidden if process fialed or error
                                    : item?.file_type ? "show" // show if file type exist
                                        : "hidden" // default hidden
                            }
                        >
                            Chat with file
                        </DropdownItem>

                        <DropdownItem
                            key="download"
                            // shortcut="⌘N"
                            description="Download the file"
                            startContent={<i className={`fa-solid fa-download ${iconClasses}`} />}
                            // onClick={() => handlePlayVideo(data, idx)}
                            className={
                                (item?.status === "failed" || item?.status === "error") ? "hidden" // hidden if process fialed or error
                                    : item?.file_type ? "show" // show if file type exist
                                        : "hidden" // default hidden
                            }
                        >
                            Download file
                        </DropdownItem> */}

                        {/* <DropdownItem
                            key="report"
                            // shortcut="⌘⇧E"
                            description="Report the problem"
                            startContent={<i className={`fa-solid fa-flag ${iconClasses}`} />}
                            className={data?.status === "failed" || data?.status === "error" ? "show" : "hidden"}
                            onClick={() => handleReportVideo(data)}
                        >
                            Report Problem
                        </DropdownItem> */}

                    </DropdownSection>

                    <DropdownSection title="Danger zone">
                        <DropdownItem
                            key="delete"
                            className="text-danger"
                            color="danger"
                            // shortcut="⌘⇧D"
                            description="Permanently delete data"
                            startContent={<i className={`fa-solid fa-trash ${iconClasses}`} />}
                        // onClick={() => handleDeleteOnclick(item?._id)}
                        >
                            Remove Data
                        </DropdownItem>
                    </DropdownSection>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default VT_TableCell5;