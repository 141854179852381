// libraries
import React, { useContext } from "react";
import moment from "moment";

// custom components
import { getStatus, getStyle } from "../functions/subscriptionStatus";
import UserContext from "./UserContext";
import { getPlatFormKind } from "../functions/general";
import ResponseMessage from "./ResponseMessage";
import { alertBeforeAction } from "../pages/shinefy/Tools/Components/Transcriber/AlertBeforeAction";
import consoleLog from "../functions/consoleLog";

const MyPlanStatus = (props) => {

    // props
    const {
        responseMessage,
        closeResponseMessage,
        isCanceling,
        isContinuing,
        handleCancelPlan,
        handleContinuePlan,
    } = props;

    // context method
    const {

        // others
        subscriptionData,

    } = useContext(UserContext);

    // extracted values from subscriptionData
    const fromPlatform = subscriptionData?.status?.fromPlatform;
    const isNewStripe = subscriptionData?.status?.isNewStripe;
    const subsMessage = subscriptionData?.message;

    // console log activity
    // consoleLog("subsMessage", subsMessage);

    return (
        <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 flex flex-col">

            <div className="flex items-center justify-between mb-16">
                <h5 className="text-xl font-medium leading-none text-gray-900 dark:text-white">Plan Status</h5>

                {/* <div className="text-sm font-medium px-1 rounded-md bg-green-500 text-gray-100">
                    <code>Cancel Plan</code>
                </div> */}

                <div className={`text-sm font-medium px-1 rounded-md ${getStyle(subscriptionData?.message)} font-semibold`}>
                    <code>{getStatus(subscriptionData?.message)}</code>
                </div>
            </div>
            <div>

                {/* <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Frequency </label>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Next Payment</label> */}

                <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-medium leading-none text-gray-900 dark:text-white">Currency</label>
                    <div className="text-xs font-medium px-1 text-gray-400 capitalize">{(subscriptionData?.status?.currency).toUpperCase() || "currency"}</div>
                </div>
                <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-medium leading-none text-gray-900 dark:text-white">Frequency</label>
                    <div className="text-xs font-medium px-1 text-gray-400 capitalize">{subscriptionData?.status?.frequency || "frequency"}</div>
                </div>
                <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-medium leading-none text-gray-900 dark:text-white">Platform</label>
                    <div className="text-xs font-medium px-1 text-gray-400">{getPlatFormKind(subscriptionData?.status?.fromPlatform)}</div>
                </div>
                <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-medium leading-none text-gray-900 dark:text-white">Processor</label>
                    <div className="text-xs font-medium px-1 text-gray-400 capitalize">{subscriptionData?.status?.processor || "processor"}</div>
                </div>
                <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-medium leading-none text-gray-900 dark:text-white">Next Payment</label>
                    <div className="text-xs font-medium px-1 text-gray-400">{moment(subscriptionData?.status?.valid_till).format("MMM D, YYYY")}</div>
                </div>
                {/* <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required /> */}
            </div>

            {!!responseMessage.length && (
                <div className="lg:mt-7">
                    <ResponseMessage
                        responseMessage={responseMessage}
                        closeResponseMessage={closeResponseMessage}
                    />
                </div>
            )}


            {fromPlatform === "stripe_platform" && isNewStripe && (
                <div className="flex flex-col items-center mt-auto w-full">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 text-center">
                        Cancel subscription anytime.
                    </label>

                    {subsMessage === "subscribed" || subsMessage === "trial" ? (
                        <button
                            disabled={isCanceling ? true : false}
                            onClick={() => alertBeforeAction(handleCancelPlan, "handleFunction2", "cancel-subscription", {})}
                            className={`${!isCanceling ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-500 cursor-not-allowed"} w-full focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-white focus:ring-blue-900 relative flex items-center justify-center`}
                        >
                            {isCanceling && (
                                <i className="fa-solid fa-spinner fa-spin-pulse absolute left-4" />
                            )}
                            Cancel Subscription
                        </button>
                    ) : (
                        <button
                            disabled
                            className="bg-gray-500 cursor-not-allowed w-full focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-white focus:ring-blue-900 relative flex items-center justify-center"
                        >
                            Cancel Subscription
                        </button>
                    )}

                    {/* <button
                        disabled={isContinuing ? true : false}
                        onClick={() => alertBeforeAction(handleContinuePlan, "handleFunction2", "continue-subscription", {})}
                        className={`${!isContinuing ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-500 cursor-not-allowed"} w-full focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-white focus:ring-blue-900 relative flex items-center justify-center`}
                    >
                        {isContinuing && (
                            <i className="fa-solid fa-spinner fa-spin-pulse absolute left-4" />
                        )}
                        Continue Subscription
                    </button> */}


                </div>
            )}
        </div>
    );
};

export default MyPlanStatus;