// libraries
import React, { useState } from "react";

// custom components
import consoleLog from "../../functions/consoleLog";
import { purchaseSelectedCreditProduct } from "../../API/subsAPI";

const BuyCredits = (props) => {

    // props
    const {
        selectedValue,
        selectedPriceID,
        subscriptionEmail,
    } = props;

    // react props
    const [isLoading, setIsLoading] = useState(false);

    // handle click to process buy credits
    const handleClick = async () => {

        // set setIsLoading to true
        setIsLoading(true);

        // required data
        const requiredData = {
            email: subscriptionEmail,
            productID: selectedValue,
            priceID: selectedPriceID,
        };

        // call the function to send the request data to the server
        await purchaseSelectedCreditProduct(requiredData)
            .then((response) => {

                // response extracted value
                const redirectURL = response?.data?.message?.url;

                // check if "redirectURL" existed
                if (redirectURL)

                    // send to payment URL
                    window.location.href = redirectURL;

                // console log activity
                consoleLog("response", response?.data);
            });

        // console log activity
        // consoleLog("selectedValue", selectedValue);
    };

    return (
        <button
            disabled={selectedValue ? false : true}
            onClick={handleClick}
            className={`"opacity-50 relative btn-primary border-none rounded-md font-semibold ${selectedValue ? "bg-blue-300 py-3 hover:bg-blue-400" : "bg-gray-400 py-3"}`}
            as="button"
        >
            <div className="flex w-full gap-2 items-center justify-center">
                <span className="inline-block text-gray-700 text-xl font-semibold">
                    <i className={`fa-solid fa-circle-notch fa-spin ${!isLoading && "invisible"}`} /> Purchase Credits
                </span>
            </div>

        </button>
    );
};

export default BuyCredits;
