import { useState, useEffect } from 'react';
import axios from 'axios';
import consoleLog from '../../../../functions/consoleLog';

// Custom hook to check API status
const useApiStatus = () => {
    const [isToolOnline, setIsToolOnline] = useState(null); // null, 'online', or 'maintenance'

    useEffect(() => {
        const checkAPIStatus = async () => {
            try {
                await axios.get(process.env.REACT_APP_API_END_POINT);
                setIsToolOnline("online");
            } catch (error) {
                setIsToolOnline("maintenance"); // If there is any error (including network or server issues), set isOnline to false
                consoleLog("error", error);
            }
        };

        checkAPIStatus();
    }, []);

    return isToolOnline;
};

export default useApiStatus;