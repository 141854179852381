// libraries
import React from "react";
import { Select, SelectItem, Tooltip } from "@nextui-org/react";

// custom components
import AO_Switch from "../UI/AO_Switch";
import advanceOptionJsonData from "../index.json";
import consoleLog from "../../../../../../functions/consoleLog";

const AO_tab1 = (props) => {

    const {
        isCheckedAccuracy,
        handleToggleAccuracy,
        config_image,
        isCheckedCaption,
        handleToggleCaption,
        config_caption,
        isCheckedEffect,
        handleToggleEffects,
        config_animation,
        videoAspectRatio,
        handleVideoAspectRatio,
        imageTheme,
        handleImageTheme,
        handleEditCustomImageTheme,
        handleCustomizeCaption,
        promptKind,
        videoTheme,
        handleVideoTheme,
        handleEditCustomVideoTheme,
        videoCaptionOption,
        handleCaptionOption,
        videoSlideOption,
        handleSlideOption,
        disabledOptions,
    } = props;

    // console log activity
    // consoleLog("disabledOptions", disabledOptions);

    return (
        <>
            <div>
                <AO_Switch
                    label="Slide Accuracy"
                    isChecked={isCheckedAccuracy}
                    handleToggle={handleToggleAccuracy}
                    content={
                        <div className="px-1 py-2">
                            <div className="text-small font-bold">Video Generator Tip</div>
                            <ul className="list-disc pl-5 space-y-1 break-words w-[25rem]">
                                <li>
                                    Using this feature will cost you <b><i className="fa-solid fa-coins text-yellow-300" /> {config_image} Credit(s)</b> per image.
                                    Accurately switches images when the speaker changes words, and adds more accurate images based on the accuracy calculation.
                                </li>
                            </ul>
                        </div>
                    }
                    version={2}
                    isDisabled={disabledOptions?.AO_sub_accuracy}
                />

                {/* midjourney key (Disabled) */}
                {/* {isCheckedAccuracy && accuracyOwnAPI && (
                    <div>
                        <h4 className="fontKarla text-white">
                            Midjourney Auth Token
                        </h4>
                        <div className="flex items-center">
                            <div className="relative w-full">
                                <input
                                    // onChange={handleMidjourneyAPIKey}
                                    onChange={(e) => setMidjourneyAPIKey(e.target.value)}
                                    value={midjourneyAPIKey || ""}
                                    type={showShowMidjourneyAPIKEy ? "text" : "password"}
                                    className="apiKeyTextInput pr-8 border-0 rounded-md"
                                    placeholder="Midjourney Auth Token"
                                    autoComplete="off"
                                />
                                <div
                                    // onClick={handleMidjourneyLabsAPI}
                                    className="cursor-pointer"
                                >
                                    <i className={`${showShowMidjourneyAPIKEy ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"} absolute right-2 top-[59%] transform -translate-y-1/2`} />
                                </div>
                            </div>
                            <div
                                // onClick={handleCheckMidjourneyAPI}
                                title="Check api"
                                className={`relative ml-2 border rounded-md mt-1.5 px-2 py-[0.05rem] cursor-pointer w-10 flex items-center justify-center ${isMidjourneyAPIKeyOkay
                                    ? "bg-green-600 hover:bg-green-500"
                                    : "bg-gray-600 hover:bg-gray-500"
                                    }`}
                            >
                                {isMidjourneyAPIKeyChecking ? (
                                    <i className="fa-solid fa-spinner fa-spin-pulse text-lg text-gray-100" />
                                ) : isMidjourneyAPIKeyOkay ? (
                                    <i className="fa-solid fa-check-double text-lg text-gray-100" />
                                ) : (
                                    <i className="fa-solid fa-check text-lg text-gray-100" />
                                )}
                            </div>
                        </div>
                    </div>
                )} */}

            </div>

            <AO_Switch
                label="Slide Animation"
                isChecked={isCheckedEffect}
                handleToggle={handleToggleEffects}
                content={
                    <div className="px-1 py-2">
                        <div className="text-small font-bold">Video Generator Tip</div>
                        <ul className="list-disc pl-5 space-y-1 break-words w-auto">
                            <li>Add animation will cost <b><i className="fa-solid fa-coins text-yellow-300" /> {config_animation} Credit(s)</b>.</li>
                            <li>Add a slide animation to the video</li>
                        </ul>
                    </div>
                }
                version={2}
                isDisabled={disabledOptions?.AO_sub_animation}
            />

            <AO_Switch
                label="Video Caption"
                isChecked={isCheckedCaption}
                handleToggle={handleToggleCaption}
                content={
                    <div className="px-1 py-2">
                        <div className="text-small font-bold">Video Generator Tip</div>
                        <ul className="list-disc pl-5 space-y-1 break-words w-auto">
                            <li>Add caption will cost <b><i className="fa-solid fa-coins text-yellow-300" /> {config_caption} Credit(s)</b>.</li>
                            <li>Add a caption to the video.</li>
                        </ul>
                    </div>
                }
                version={3}
                isDisabled={disabledOptions?.AO_sub_caption}
            />

            <div className="sectionDivider">
                <hr />
            </div>

            {/* Caption option */}
            {isCheckedCaption && (
                <div className="mb-1">
                    <div className="flex items-center">
                        <Select
                            isDisabled={disabledOptions?.AO_sub_captionOption}
                            label="Caption Options"
                            // placeholder="Select video aspect ratio"
                            color="primary"
                            variant="flat"
                            className="font-semibold"
                            selectedKeys={[videoCaptionOption]}
                            onChange={handleCaptionOption}
                        >
                            {advanceOptionJsonData?.captionOption?.map((data) => (
                                <SelectItem
                                    className="font-semibold"
                                    key={data.value}
                                    value={data.value}
                                >
                                    {data.option}
                                </SelectItem>
                            ))}
                        </Select>

                        {(videoCaptionOption === "caption_customize") && (

                            <Tooltip
                                color="primary"
                                placement="top"
                                showArrow={true}
                                content="Customize Caption"
                            >
                                <div
                                    onClick={handleCustomizeCaption}
                                    title="Custom Theme"
                                    className="relative ml-2 mt-1.5 px-4 cursor-pointer w-10 flex items-center justify-center"
                                >
                                    <span className="text-3xl text-gray-600 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400">
                                        <i className="fa-solid fa-sliders" />
                                    </span>
                                </div>
                            </Tooltip>

                        )}

                    </div>
                </div>
            )}

            {/* slide animations */}
            {isCheckedEffect && (
                <div className="mb-1">
                    <Select
                        isDisabled={disabledOptions?.AO_sub_slideOption}
                        label="Slide Options"
                        color="primary"
                        variant="flat"
                        className="font-semibold"
                        selectedKeys={[videoSlideOption]}
                        onChange={handleSlideOption}
                    >
                        {advanceOptionJsonData?.slideOption?.map((data) => (
                            <SelectItem
                                className="font-semibold"
                                key={data.value}
                                value={data.value}
                            >
                                {data.option}
                            </SelectItem>
                        ))}
                    </Select>
                </div>
            )}

            {/* Avideo mode (landscape or portrait) */}
            <div className="mb-1">
                <Select
                    isDisabled={disabledOptions?.AO_sub_aspectRatio}
                    label="Aspect Ratio"
                    variant="flat"
                    className="font-semibold"
                    selectedKeys={[videoAspectRatio]}
                    onChange={handleVideoAspectRatio}
                >
                    {advanceOptionJsonData?.aspectRatio?.map((data) => (
                        <SelectItem
                            className="font-semibold"
                            key={data.value}
                            value={data.value}
                        >
                            {data.option}
                        </SelectItem>
                    ))}
                </Select>
            </div>

            {/* image theme */}
            <div className="mb-1">
                <div className="flex items-center">

                    <Select
                        isDisabled={disabledOptions?.AO_sub_imgTheme}
                        label="Image Theme"
                        variant="flat"
                        className="font-semibold"
                        selectedKeys={[imageTheme]}
                        onChange={handleImageTheme}
                    >
                        {advanceOptionJsonData?.ImageTheme?.map((data) => (
                            <SelectItem
                                className="font-semibold"
                                key={data.value}
                                value={data.value}
                            >
                                {data.option}
                            </SelectItem>
                        ))}
                    </Select>

                    {imageTheme === "Custom Theme" && (

                        <Tooltip
                            color="primary"
                            placement="top"
                            showArrow={true}
                            content="Edit Video Image"
                        >
                            <div
                                onClick={handleEditCustomImageTheme}
                                title="Custom Theme"
                                className="relative ml-2 mt-1.5 px-4 cursor-pointer w-10 flex items-center justify-center"
                            >
                                <span className="text-3xl text-gray-600 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400">
                                    <i className="fa-solid fa-pen-to-square" />
                                </span>
                            </div>
                        </Tooltip>

                    )}

                </div>
            </div>

            {/* video theme */}
            {promptKind === "idea" && (

                <div className="mb-1">
                    <div className="flex items-center">

                        <Select
                            isDisabled={disabledOptions?.AO_sub_vidTheme}
                            label="Video Theme"
                            variant="flat"
                            color="warning"
                            className="font-semibold"
                            selectedKeys={[videoTheme]}
                            onChange={handleVideoTheme}
                        >
                            {advanceOptionJsonData?.videoTheme?.map((data) => (
                                <SelectItem
                                    className="font-semibold"
                                    key={data.value}
                                    value={data.value}
                                >
                                    {data.option}
                                </SelectItem>
                            ))}
                        </Select>

                        {videoTheme === "custom_theme" && (

                            <Tooltip
                                color="primary"
                                placement="top"
                                showArrow={true}
                                content="Edit Video Theme"
                            >
                                <div
                                    onClick={handleEditCustomVideoTheme}
                                    title="Custom Theme"
                                    className="relative ml-2 mt-1.5 px-4 cursor-pointer w-10 flex items-center justify-center"
                                >
                                    <span className="text-3xl text-gray-600 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400">
                                        <i className="fa-solid fa-pen-to-square" />
                                    </span>
                                </div>
                            </Tooltip>

                        )}

                    </div>
                </div>

            )}

            {/* <div className="sectionDivider pb-[0px]">
                <hr />
            </div> */}

        </>
    );
};

export default AO_tab1;