// libraries
import React, { forwardRef, useState } from "react";
import moment from "moment/moment";
import { Avatar } from "@nextui-org/react";

// custom components
import AudioVisualizer from "./AudioVisualizer";
import AvatarImage from "../AvatarImage";

const ChatUser = forwardRef((props, ref) => {

    // props
    const {
        userData,
        isMobile,
        data
    } = props;

    // variables
    const userName = userData?.userData?.UserInfo?.first_name === "Shinefy" ? "User" : userData?.userData?.UserInfo?.first_name;

    const [isLoading, setIsLoading] = useState(true); // New state for loading

    const handleImageLoaded = () => {
        setIsLoading(false);
    };

    const handleImageError = () => {
        setIsLoading(false);
    };

    const imageURL = `${process.env.REACT_APP_SHINEFY_SERVER}/file/image/${data?.image}`;

    return (
        <div ref={ref} className="w-full text-token-text-primary font-semibold font-mono" data-testid="conversation-turn-2" style={{ '--avatar-color': '#19c37d' }}>
            <div className="px-4 py-2 justify-center text-base md:gap-6 m-auto">
                <div className="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] group">

                    {/* avatar */}
                    <div className="flex-shrink-0 flex flex-col relative items-end">
                        <div>
                            <div className="pt-0.5">
                                {/* <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                                    <div className="relative flex">
                                        <AvatarImage userData={userData} classData="rounded-full" />
                                    </div>
                                </div> */}

                                <Avatar
                                    // size="sm"
                                    isBordered
                                    src={userData?.userAvatar}
                                    className="w-6 h-6 text-tiny"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="relative flex w-full flex-col text-base font-normal font-sans">
                        <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            <span className="font-semibold text-lg text-sky-300">{userName}</span>
                            <span className="font-semibold text-gray-400">
                                <small>{moment(data.created).fromNow()}</small>
                            </span>
                        </div>
                        <div className="flex-col gap-1 md:gap-3">

                            <div className="flex flex-grow flex-col max-w-full">
                                <div data-message-author-role="assistant" data-message-id="a545d514-401e-4dbd-9c86-ec8184645edd" className="min-h-[20px] flex flex-col items-start gap-3 whitespace-pre-wrap break-words overflow-x-auto">
                                    <div className="markdown prose w-full break-words dark:prose-invert dark">

                                        {data?.image && (
                                            <>
                                                {isLoading ? (
                                                    <div className="flex justify-center items-center w-full h-[18rem] rounded-lg border-0 bg-gray-500">
                                                        <i className="fa-solid fa-image text-6xl fa-bounce" />
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={data?._id ? imageURL : data?.image}
                                                        alt="shinefy"
                                                        className="cursor-pointer rounded-xl w-full h-[18rem] bg-gray-100 dark:bg-gray-600 object-cover"
                                                    // className="rounded-xl h-full w-1/2 bg-gray-100 dark:bg-gray-600 object-cover"
                                                    />
                                                )}
                                                {/* image status checker*/}
                                                <img
                                                    src={data?._id ? imageURL : data?.image}
                                                    className="hidden"
                                                    onLoad={handleImageLoaded}
                                                    onError={handleImageError}
                                                />
                                            </>
                                        )}

                                        <p className="whitespace-pre-wrap">{data?.message}</p>

                                        {data?.audio && (
                                            <>
                                                <div className="boder border-b-[1.5px] border-r-gray-100 my-2" />
                                                <AudioVisualizer audioSrc={data?.audio.startsWith("blob:") ? data?.audio : data?.audio} />
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className="mt-1 flex justify-start gap-3 empty:hidden">
                                {/* <div className="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 h-7 visible">
                                    <button className="p-1 rounded-md text-token-text-tertiary hover:text-token-text-primary md:invisible md:group-hover:visible md:group-[.final-completion]:visible">
                                        <div className="flex items-center gap-1.5 text-xs">
                                            <i className="fa-solid fa-pen text-xl" />
                                        </div>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
});

export default ChatUser;