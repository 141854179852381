// OpenAI TTS models
export const TTSModels = [
    {
        model: "Alloy",
        voice: "https://cdn.openai.com/API/docs/audio/alloy.wav",
        platform: "openai",
    },
    {
        model: "Echo",
        voice: "https://cdn.openai.com/API/docs/audio/echo.wav",
        platform: "openai",
    },
    {
        model: "Fable",
        voice: "https://cdn.openai.com/API/docs/audio/fable.wav",
        platform: "openai",
    },
    {
        model: "Onyx",
        voice: "https://cdn.openai.com/API/docs/audio/onyx.wav",
        platform: "openai",
    },
    {
        model: "Nova",
        voice: "https://cdn.openai.com/API/docs/audio/nova.wav",
        platform: "openai",
    },
    {
        model: "Shimmer",
        voice: "https://cdn.openai.com/API/docs/audio/shimmer.wav",
        platform: "openai",
    },
];

// video search orientation
export const videoOrientation = [
    {
        value: "portrait",
    },
    {
        value: "landscape",
    }
];

// aspect ratio
export const aspectRatio = [
    {
        ratio: "Portrait",
        size: "1024x1792",
    },
    {
        ratio: "Landscape",
        size: "1792x1024",
    },
];

// caption position
export const captionPosition = [
    { label: "Middle Center", value: "midle_center", description: "Caption position in the midle center" },
    { label: "Middle Left", value: "midle_left", description: "Caption position in the middle left" },
    { label: "Middle Right", value: "midle_right", description: "Caption position in the middle right" },
    { label: "Middle Top", value: "midle_top", description: "Caption position in the midle top" },
    { label: "Middle Bottom", value: "midle_bottom", description: "Caption position in the middle bottom" },
    { label: "Top Left", value: "top_left", description: "Caption position in the top left" },
    { label: "Top Right", value: "top_right", description: "Caption position in the top right" },
    { label: "Bottom Left", value: "bottom_left", description: "Caption position in the bottom left" },
    { label: "Bottom Right", value: "bottom_right", description: "Caption position in the bottom right" },
];

// Google fonts
export const googleFontsData = [

    {
        customName: "font-bangers",
        fontName: "Bangers",
        sampleText: "The quick brown fox jumps over the lazy dog.",
    },
    {
        customName: "font-caveat_brush",
        fontName: "Caveat Brush",
        sampleText: "How much wood would a woodchuck chuck if a woodchuck could chuck wood?",
    },
    {
        customName: "font-concert_one",
        fontName: "Concert One",
        sampleText: "To be or not to be, that is the question.",
    },
    {
        customName: "font-freeman",
        fontName: "Freeman",
        sampleText: "All that glitters is not gold.",
    },
    {
        customName: "font-jaro",
        fontName: "Jaro",
        sampleText: "Actions speak louder than words.",
    },
    {
        customName: "font-jersey_15",
        fontName: "Jersey 15",
        sampleText: "Beauty is in the eye of the beholder.",
    },
    {
        customName: "font-montserrat",
        fontName: "Montserrat",
        sampleText: "Where there is smoke, there is fire.",
    },
    {
        customName: "font-nanum_pen_script",
        fontName: "Nanum Pen Script",
        sampleText: "The pen is mightier than the sword.",
    },
    {
        customName: "font-noto_sans",
        fontName: "Noto Sans",
        sampleText: "You can't judge a book by its cover.",
    },
    {
        customName: "font-poetsen_one",
        fontName: "Poetsen One",
        sampleText: "When in Rome, do as the Romans do.",
    },
    {
        customName: "font-rubik_moonrocks",
        fontName: "Rubik Moonrocks",
        sampleText: "A picture is worth a thousand words.",
    },
    {
        customName: "font-sedan_sc",
        fontName: "Sedan SC",
        sampleText: "The grass is always greener on the other side.",
    },
    {
        customName: "font-josefin_sans",
        fontName: "Josefin Sans",
        sampleText: "The best way to predict the future is to invent it.",
    },
    {
        customName: "font-bebas_neue",
        fontName: "Bebas Neue",
        sampleText: "Don't count your chickens before they hatch.",
    },
    {
        customName: "font-anton",
        fontName: "Anton",
        sampleText: "The only constant in life is change.",
    },
    {
        customName: "font-lilita_one",
        fontName: "Lilita One",
        sampleText: "Every cloud has a silver lining.",
    },
];