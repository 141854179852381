// libraries
import React, { useContext, useState } from "react";

// custom components
import NotifList from "./NotifList";
import NotifView from "./NotifView";
import UserContext from "../UserContext";
import consoleLog from "../../functions/consoleLog";

const NotifPage = () => {

    // context method
    const {

        // Others
        userAvatar,

        // For notification data params
        notifData,
        setNotifData,
        setTotalSumNotifData,
        setNotifParam,
        notifParam,
        refetchNotifData,
        setRefetchNotifData,

    } = useContext(UserContext);

    // react components
    const [viewNotifContent, setViewNotifContent] = useState(null);

    // console log activity
    // consoleLog("notifData", notifData);

    return (
        <div className="flex flex-col px-6 pt-6">
            <div className="flex flex-col my-4 xl:flex-row xl:gap-4">

                {/* Notification List Left Side */}
                <div className="xl:flex-1">
                    <NotifList
                        notifData={notifData}
                        setNotifData={setNotifData}
                        setViewNotifContent={setViewNotifContent}
                        setTotalSumNotifData={setTotalSumNotifData}
                    />
                </div>

                {/* Notification View Right Side */}
                <div className="xl:flex-1">
                    {viewNotifContent && (
                        <NotifView
                            viewNotifContent={viewNotifContent}
                        />
                    )}
                </div>

            </div>
        </div>
    );
};

export default NotifPage;