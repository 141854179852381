// libraries
import React from "react";

// custom components
import VG_TableMenu from "./VG_TableMenu";

const VG_TableCell5 = (props) => {

    // props
    const {
        idx,
        data,
        modalData,
        handlePlayVideo,
        handleDownloadMediaFile,
        handleRetryProcess,
        handleReportVideo,
        handleCustomMedia,
        handleDeleteFunction,
    } = props;

    return (
        <div className="relative flex justify-end items-center gap-2">
            {(data?.status === "completed" || data?.status === "failed" || data?.status === "error") && modalData && !data?.report_id && (
                <VG_TableMenu
                    idx={idx}
                    data={data}
                    handlePlayVideo={handlePlayVideo}
                    handleDownloadMediaFile={handleDownloadMediaFile}
                    handleRetryProcess={handleRetryProcess}
                    handleReportVideo={handleReportVideo}
                    handleCustomMedia={handleCustomMedia}
                    handleDeleteFunction={handleDeleteFunction}
                />
            )}
        </div>
    );
};

export default VG_TableCell5;