// libraries
import React from "react";
import { Modal, ModalBody, ModalContent, ModalHeader, ModalFooter, Button } from "@nextui-org/react";

// custom components

const ModalAlert = ({
    title,
    content,
    isOpen,
    onClose,
    handleDeleteVideo,
    isDeletingData,
    toBeDeletedData,
}) => {
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onClose={onClose}
            className="bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
                        <ModalBody>
                            <p className="whitespace-pre-wrap">
                                {content}
                            </p>
                        </ModalBody>
                        <ModalFooter
                        // className="flex flex-col lg:flex-row items-center justify-between -mt-2"
                        >
                            {/* <Button
                                onPress={onClose}
                                color="danger"
                                variant="flat"
                                className="w-auto h-auto text-base"
                            >
                                Close
                            </Button> */}
                            {/* <Button
                                // onClick={handlesubmitReport}
                                color="primary"
                                className="h-full w-full lg:md:sm:w-auto py-3.5 px-7 text-base"
                            >
                                Submit Report
                            </Button> */}
                            <Button
                                color="danger"
                                variant="flat"
                                className="text-base"
                                onClick={() => handleDeleteVideo(toBeDeletedData)}
                            >
                                <i className={`fa-solid fa-trash-can ${isDeletingData[toBeDeletedData?._id] && "fa-bounce"}`} /> Remove
                            </Button>

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default ModalAlert;
