// libraries
import React, { useContext } from "react";
import { Chip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../functions/consoleLog";
import AdminContext from "../../../components/Admin/AdminContext";
import { convertToCustomerLocalTime } from "../../../functions/general";

const UserActivities = () => {

    // context method
    const {

        // server activities
        serverActivities,

    } = useContext(AdminContext);

    // console log activities
    // consoleLog("serverActivities", serverActivities);

    return (
        <>
            <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">

                {/* Top label */}
                {/* <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Server Activities</h3>
                    <span className="cursor-pointer inline-flex items-center p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100 dark:text-blue-500 dark:hover:bg-gray-700">
                        View all
                    </span>
                </div> */}

                {/* Activities */}
                <ol className="relative border-l border-gray-200 dark:border-gray-700">

                    {serverActivities?.message?.map((data, idx) => {
                        return (
                            <li key={idx} className="mb-5 ml-4">
                                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-800 dark:bg-gray-700" />
                                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                    {convertToCustomerLocalTime(data?.date_started)?.moment}
                                </time>
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    {data?.queue_name}
                                </h3>
                                <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                                    <code>Executed by {data?.args[0]?.user_email} and paid <i className="fa-solid fa-coins fa-sm text-yellow-300" /> {Number(data?.args[0]?.amount)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} credits.</code>
                                </p>

                                <Chip
                                    startContent={<i className="fa-solid fa-circle-check" />}
                                    variant="faded"
                                    color={data?.status === "RE-QUEUED" ? "warning" : data?.status === "SUCCESS" ? "success" : data?.status === "STARTED" ? "secondary" : "danger"}
                                >
                                    <p className="capitalize">{(data?.status).toLowerCase()}</p>
                                </Chip>

                                {/* <span className="cursor-pointer inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">
                                    View User <i className="fa-solid fa-arrow-right-long ml-2" />
                                </span> */}

                                {/* <span className="cursor-pointer inline-flex items-center text-xs font-medium hover:underline text-blue-700 sm:text-sm dark:text-blue-500">
                                    View User <i className="fa-solid fa-arrow-right-long ml-2" />
                                </span> */}
                            </li>
                        );
                    })}

                </ol>
            </div>
        </>
    );
};

export default UserActivities;