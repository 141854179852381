// libraries
import React, { useContext, useEffect, useRef } from "react";
import { Tooltip } from "@nextui-org/react";

// custom components
import ScriptInput from "../../functions/ScriptInput";
import { saveConfig } from "../../API/configDBAPI";
import { cachedToolsConfiguration } from "../../../../../functions/general";
import UserContext from "../../../../../components/UserContext";
import PromptEditor from "../../functions/PromptEditor";

const GeneralPurposeForm = (props) => {

    // props
    const {
        inputValue,
        handleOnChange,
        isError,
        isMobile,
        email,
        pathname,
        textScriptValue,
        handleTextScriptOnChange,
        setPromptKind,
        promptKind,
        isOpenSE,
        onCloseSE,
        onOpenSE,
    } = props;

    // context method
    const {

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // use refs and navigates
    const modalPromptEditor = useRef(null);

    // hadnle selected script type. Idea | Script
    const handlePromptType = (data) => {

        // variables
        const kind = "scriptPrompt";

        // set value to useState
        setPromptKind(data);

        // cached config to localstorage
        cachedToolsConfiguration(kind, data);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, data);
    };

    // handle toggel prompt modal editor
    const handlePromptEditor = () => {
        onOpenSE();
    };

    // handle click outside modal effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalPromptEditor.current && !modalPromptEditor.current.contains(event.target)) {
                onOpenSE();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalPromptEditor]);

    return (
        <>

            <div className="space-y-4 mb-1">
                <div className="flex items-center justify-between"> {/* Use justify-between to space out child elements */}
                    <div className="flex items-center space-x-2"> {/* Group the left-aligned items */}
                        <div className="flex bg-gray-300/[.7] dark:bg-gray-500 hover:bg-gray-400/50 dark:hover:hover:bg-gray-400 rounded-lg transition p-1">
                            <nav className="flex space-x-1" aria-label="Tabs" role="tablist">

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Short idea"
                                >
                                    <button
                                        onClick={() => handlePromptType("idea")}
                                        type="button"
                                        className={`${promptKind === "idea" ? "bg-white dark:bg-gray-600/80" : "bg-gray-300/[.3] text-gray-600"} w-20 shadow-sm text-gray-700 dark:text-white hover:text-gray-700 font-medium rounded-md py-2 px-3`}
                                        id="ctc-component-5-tab-1-item"
                                        data-hs-tab="#ctc-component-5-tab-1"
                                        aria-controls="ctc-component-5-tab-1"
                                        role="tab"
                                    >
                                        Idea
                                    </button>
                                </Tooltip>

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Custom script"
                                >
                                    <button
                                        onClick={() => handlePromptType("script")}
                                        type="button"
                                        className={`${promptKind === "script" ? "bg-white dark:bg-gray-600/80" : "bg-gray-300/[.3] text-gray-600"} w-20 shadow-sm text-gray-700 dark:text-white hover:text-gray-700 font-medium rounded-md py-2 px-3`}
                                        id="ctc-component-5-tab-2-item"
                                        data-hs-tab="#ctc-component-5-tab-2"
                                        aria-controls="ctc-component-5-tab-2"
                                        role="tab"
                                    >
                                        Script
                                    </button>
                                </Tooltip>

                            </nav>
                        </div>
                        <div className="text-gray-800">

                            <Tooltip
                                color="primary"
                                placement="top"
                                showArrow={true}
                                content={
                                    <div className="px-1 py-2">
                                        <div className="text-small font-bold">Video Generator Tip</div>
                                        <ul className="list-disc pl-5 space-y-1 break-words w-[30rem]">
                                            <li><b>Idea</b> allows you to provide a brief concept that identifies the video, and the AI will generate a script based on this 'idea'. Example: 'what if the telephone was never invented'.</li>
                                            <li><b>Script</b> lets you input your own custom script without AI assistance.</li>
                                        </ul>
                                    </div>
                                }>
                                <span className="text-warning dark:text-yellow-300 text-2xl dark:hover:text-yellow-400">
                                    <i className="fa-solid fa-circle-question" />
                                </span>
                            </Tooltip>

                            {/* <ToolTipDisplay
                                label={`
                                <p class="break-words whitespace-pre-line -mt-5 ${isMobile ? "w-48" : "w-72"}">
                                    ▬ <b>Idea</b> allows you to provide a brief concept that identifies the video, and the AI will generate a script based on this 'idea'. ex: 'what if the telephone was never invented'.
                                    
                                    ▬ <b>Script</b> lets you input your own custom script without AI assistance.
                                </p>
                            `}
                                id="general-purpose-form-idea-and-script"
                                icon="fa-regular fa-circle-question text-2xl"
                                zIndex="z-10"
                            /> */}

                        </div>
                    </div>

                    <Tooltip
                        color="primary"
                        placement="top"
                        showArrow={true}
                        content="Modal Prompt Editor"
                    >
                        <div
                            onClick={handlePromptEditor}
                            className="text-gray-600 hover:text-gray-500 dark:text-gray-100 dark:hover:text-gray-300 cursor-pointer"
                        >
                            <i className="fa-solid fa-arrow-up-right-from-square text-2xl" />
                        </div>
                    </Tooltip>

                </div>
            </div>

            {/* <p className="fontKarla text-white text-base font-medium">
                Enter your idea to generate a video.
            </p> */}

            {/* side nav prompt editor old */}
            <ScriptInput
                promptKind={promptKind}
                contentValueIdea={inputValue}
                contentValueScript={textScriptValue}
                handleFunctionIdea={handleOnChange}
                handleFunctionScript={handleTextScriptOnChange}
                isFieldError={isError}
            />

            {/* Modal prompt editor */}
            <PromptEditor
                isOpen={isOpenSE}
                onClose={onCloseSE}
                isMobile={isMobile}
                promptKind={promptKind}
                contentValueIdea={inputValue}
                contentValueScript={textScriptValue}
                handleFunctionIdea={handleOnChange}
                handleFunctionScript={handleTextScriptOnChange}
                modalPromptEditor={modalPromptEditor}
            // setShowPromptEditor={setShowPromptEditor}
            />
        </>
    );
};

export default GeneralPurposeForm;
