// libraries
import { CircularProgress, Tooltip } from "@nextui-org/react";
import React, { useContext } from "react";

// custom imports
import { convertToCustomerLocalTime } from "../../../../../../functions/general";
import consoleLog from "../../../../../../functions/consoleLog";
import { audio_type, video_type } from "../data";
import UserContext from "../../../../../../components/UserContext";

const VT_TableCell1 = (props) => {

    // props
    const {
        item,
        onOpenTMV,
        handleView,
    } = props;

    // react context
    const {

        // others
        toolsDataStatus,

    } = useContext(UserContext);

    // capture the tools process progress
    const foundObject = toolsDataStatus.find((status) => status?.tools_status?.task_id === item?.task_id);

    // console log activity
    // consoleLog("toolsDataStatus", toolsDataStatus);
    // consoleLog("foundObject", foundObject);
    // consoleLog("itemTaskID", item?.task_id);

    // handle open modal view
    const handleOpenModalView = (item) => {

        // trigger open modal
        onOpenTMV();

        // set item
        handleView(item);

    };

    return (
        <div className="flex flex-row items-center">

            <span onClick={() => item?.status === "completed" ? handleOpenModalView(item) : null}>
                {foundObject?.tools_status?.task_id === item?.task_id && foundObject?.tools_status?.status === "processing" ? (
                    <CircularProgress
                        aria-label="Processing..."
                        size="lg"
                        value={foundObject?.tools_status?.current || 0}
                        maxValue={foundObject?.tools_status?.total || 100}
                        classNames={{ value: "text-xs font-semibold", }}
                        color={foundObject?.tools_status?.process_type === "sub_process" ? "secondary" : "warning"}
                        // strokeWidth={3}
                        showValueLabel={true}
                    />
                ) : item?.status === "failed" || item?.status === "error" ? (
                    <i className="fa-solid fa-file-circle-xmark text-4xl cursor-pointer text-red-400 mr-3" />
                ) : item?.file_type === "media" && audio_type?.includes(item?.file_extension) ? (
                    <i className="fa-solid fa-file-audio text-4xl cursor-pointer text-primary-500 mr-3" />
                ) : item?.file_type === "media" && video_type?.includes(item?.file_extension) ? (
                    <i className="fa-solid fa-file-video text-4xl cursor-pointer text-primary-500 mr-3" />
                ) : item?.file_type === "file" ? (
                    <i className="fa-regular fa-file-lines text-4xl cursor-pointer text-primary-500 mr-3" />
                ) : (
                    <i className="fa-solid fa-file-circle-question text-4xl cursor-pointer text-warning-500 mr-3" />
                )}
            </span>

            <div className="flex flex-col">
                <p className="font-semibold text-base truncate">
                    {item?.title ? item?.title : "AI Media and File Transcriber"}
                    {/* {item?.title ? item?.title : item?.task_id} */}
                </p>
                <p className="font-semibold text-sm capitalize text-default-400">
                    <Tooltip
                        color="primary"
                        // className="bg-gray-300 dark:bg-gray-700"
                        key="right-start"
                        placement="right-start"
                        showArrow={true}
                        content={convertToCustomerLocalTime(item?.created).dateTime}
                    >
                        <time>
                            {/* {moment(data?.created).fromNow()} */}
                            {convertToCustomerLocalTime(item?.created)?.moment}
                        </time>
                    </Tooltip>
                </p>
            </div>

        </div>
    );
};

export default VT_TableCell1;