// libraries
import React from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, Tooltip } from "@nextui-org/react";

// custom components
import advanceOptionJsonData from "../AdvancedOptions/index.json";

const CustomThemeEditor = (props) => {

    // props
    const {
        isOpen,
        onClose,
        handleThemePrompt,
        handleSaveThemePrompt,
        themePrompt,
        savingThemePrompt,
        modalKind,
    } = props;

    return (
        <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            {modalKind === "videoThemeEditor" ? "Custom Video Theme" : "Custom Image Theme"}
                        </ModalHeader>
                        <ModalBody>

                            <Textarea
                                onChange={handleThemePrompt}
                                value={themePrompt || ""}
                                minRows={20}
                                maxRows={20}
                                size="lg"
                                variant="faded"
                                label={modalKind === "videoThemeEditor" ? "Video Theme" : "Image Theme"}
                                // description="Enter a brief image prompt for generating an image."
                                className="w-full text-lg"
                                placeholder={modalKind === "videoThemeEditor" ? advanceOptionJsonData?.exampleVideoPrompt : advanceOptionJsonData?.exampleImagePrompt}
                            />

                        </ModalBody>

                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-end -mt-4">

                            {/* Start */}
                            <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">
                                <div className="flex font-semibold font-sans space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-800">
                                    <div className="flex items-center justify-end space-x-1 text-gray-800">

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content={
                                                <div className="px-1 py-2">
                                                    <div className="text-small font-bold">Video Generator Tip</div>
                                                    <ul className="list-disc pl-5 space-y-1 break-words w-[25rem]">
                                                        {modalKind === "videoThemeEditor" ?
                                                            <li>This is where you can input your custom video theme prompt, which will dictate the feel and theme of the video based on your provided custom prompt.</li> :
                                                            <li>This is where you can input your custom image theme prompt, which will dictate the feel and theme of the image based on your provided custom prompt.</li>}
                                                    </ul>
                                                </div>
                                            } >
                                            <span className="text-2xl text-warning hover:text-warning/90 cursor-help dark:text-yellow-300 dark:hover:text-yellow-400">
                                                <i className="fa-solid fa-circle-question" />
                                            </span>
                                        </Tooltip>

                                        {/* <ToolTipDisplay
                                            label={`
                                            <p class="break-words whitespace-pre-line -mt-5 ${isMobile ? "w-48" : "w-72"}">
                                                This is where you can input your custom video theme prompt, which will dictate the feel and theme of the video based on your provided custom prompt.
                                            </p>
                                            `}
                                            id="customize-audio-info"
                                            icon="fa-solid fa-circle-question text-gray-400 text-2xl hover:text-gray-500"
                                        /> */}
                                    </div>

                                    <div className="flex space-x-1">

                                        {/* <div
                                            data-tooltip-id="customize-audio-prompt-updating"
                                            data-tooltip-content="Saving content data"
                                            className="inline-flex items-center py-1 w-auto font-medium dark:text-gray-200 text-gray-700"
                                        >
                                            <Tooltip id="customize-audio-prompt-updating" />
                                            <i className="fa-solid fa-circle-notch fa-spin text-xl" />
                                        </div> */}

                                        <button
                                            // disabled={isManualSaving ? true : false}
                                            onClick={handleSaveThemePrompt}
                                            data-tooltip-id="save-audio-prompt"
                                            data-tooltip-content="Save Custom Prompt"
                                            type="submit"
                                            className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                        >
                                            {savingThemePrompt ? (
                                                <i className="fa-solid fa-circle-notch fa-spin text-lg mr-1" />
                                            ) : (
                                                <i className="fa-solid fa-floppy-disk text-lg mr-1" />
                                            )}

                                            Save prompt
                                            {/* <Tooltip id="save-audio-prompt" /> */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* End */}

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal >
    );
};

export default CustomThemeEditor;