import React, { useState, useEffect } from 'react';

// components
import { DefaultVisualizer } from './DefaultVisualizer';
import { getAudio } from '../../API/chatAPI';
import consoleLog from '../../functions/consoleLog';

const AudioVisualizer = ({ audioSrc }) => {
  const [audioData, setAudioData] = useState(new Uint8Array(0));
  const [isPlaying, setIsPlaying] = useState(false);
  const [fetchedAudio, setFetchedAudio] = useState(null);

  // console.log(`fetchedAudio: ${new Audio(fetchedAudio)}`);
  // console.log(`audioSrc: ${new Audio(audioSrc)}`);

  useEffect(() => {

    if (audioSrc.startsWith("blob:")) {
      return;
    }

    const fetchAudio = async () => {
      const audio = await getAudio(audioSrc);
      setFetchedAudio(audio);
      // console.log(`audio1: ${audio}`);
    };

    fetchAudio();
  }, [audioSrc]);

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const audio = new Audio(fetchedAudio ? fetchedAudio : audioSrc);
    // console.log(`audio2: ${audio}`);
    const source = audioContext.createMediaElementSource(audio);
    source.connect(analyser);
    analyser.connect(audioContext.destination);

    // Configure the analyser and the audio data array
    analyser.fftSize = 64;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Function to update the audio data and re-render the component
    const updateAudioData = () => {
      analyser.getByteFrequencyData(dataArray);
      setAudioData([...dataArray]);
      requestAnimationFrame(updateAudioData);
    };

    // Function to handle the end of audio playback
    const handleAudioEnded = () => {
      setIsPlaying(false);
    };

    audio.addEventListener('ended', handleAudioEnded);  // Add ended event listener

    if (isPlaying) {
      audio.play().then(updateAudioData)
        .catch((error) => {
          consoleLog("components > ChatAI > AudioVisualizer.js > error", `Audio play failed: ${error}`);
        });
    } else {
      audio.pause();
    }

    // Return a cleanup function to stop the audio playback and disconnect the audio nodes
    return () => {
      audio.removeEventListener('ended', handleAudioEnded);  // Remove ended event listener
      audio.pause();
      source.disconnect();
      analyser.disconnect();
    };
  }, [audioSrc, isPlaying]);

  const handlePlayPause = () => {
    setIsPlaying(prevIsPlaying => !prevIsPlaying);
  };

  const scalingFactor = 0.2;

  const bars = audioData.map((value, index) => {
    const height = value * scalingFactor + 20;
    return (
      <div key={index} className="h-auto w-1 bg-gray-500 rounded-lg" style={{ height }} />
    );
  });

  return (
    <div className="relative ml-0 text-sm bg-white py-2 px-4 shadow rounded-xl w-[16.5rem] float-right">
      <div className="flex h-16 flex-row items-center">
        <button
          className="flex items-center justify-center bg-indigo-600 hover:bg-indigo-800 rounded-full h-8 w-10"
          onClick={handlePlayPause}
        >
          <i className={`fa-solid ${isPlaying ? 'fa-circle-pause' : 'fa-circle-play'} text-white text-lg`} />
        </button>
        <div className="flex flex-row items-center space-x-px ml-2">
          {isPlaying ? (
            <div className="flex flex-row items-center space-x-px ml-4">{bars}</div>
          ) : (
            <DefaultVisualizer />
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioVisualizer;
