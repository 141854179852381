// libraries
import React from "react";

// table labels
const INITIAL_VISIBLE_COLUMNS = ["title", "image_count", "amount", "status", "actions"];

// table columns
const columns = [
    { name: "Media", uid: "title", sortable: true },
    { name: "Slide Count", uid: "image_count", sortable: true },
    { name: "Process Cost", uid: "amount", sortable: true },
    { name: "Process Status", uid: "status", sortable: true },
    { name: "Action", uid: "actions", sortable: false },
];

// table status options
const statusOptions = [
    { name: "Completed", uid: "completed" },
    { name: "Failed", uid: "failed" },
    { name: "Error", uid: "error" },
    { name: "Processing", uid: "processing" },
    { name: "Reported", uid: "reported" },
];

// URL values
const URLValues = {
    customMedia: "custom-media",
};

// progress styles
const progressstyle = {
    processsing: "bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-purple-100 dark:bg-gray-700 dark:border-purple-500 dark:text-purple-400",
    complete: "bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500",
    fail: "bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-red-100 dark:border-red-400 dark:bg-gray-700 dark:text-red-400",
    processsing_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800",
    complete_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-white focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800",
    fail_v2: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800",
    status: "relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400",
};

// caption position
export const captionPosition = [
    { label: "Middle Center", value: "align:center position:50% line:50%", description: "Caption position in the midle center" },
    { label: "Middle Left", value: "align:start position:10% line:50%", description: "Caption position in the middle left" },
    { label: "Middle Right", value: "align:end position:99% line:50%", description: "Caption position in the middle right" },
    { label: "Middle Top", value: "align:center position:50% line:10%", description: "Caption position in the midle top" },
    { label: "Middle Bottom", value: "align:center position:50% line:80%", description: "Caption position in the middle bottom" }, // align:center position:50% line:90%
    { label: "Top Left", value: "align:start position:15% line:10%", description: "Caption position in the top left" },
    { label: "Top Right", value: "align:end position:97% line:10%", description: "Caption position in the top right" },
    { label: "Bottom Left", value: "align:start position:10% line:80%", description: "Caption position in the bottom left" },
    { label: "Bottom Right", value: "align:end position:97% line:80%", description: "Caption position in the bottom right" },
];

// caption fonts
export const captionFonts = [
    { label: "Noto Sans", value: "Noto Sans", description: "Caption font noto-sans" },
    { label: "Bangers", value: "Bangers", description: "Caption font bangers" },
    { label: "Poetsen One", value: "Poetsen One", description: "Caption font poetsen-one" },
    { label: "Jersey 15", value: "Jersey 15", description: "Caption font jersey-25-charted" },
    { label: "Anton", value: "Anton", description: "Caption font sedan-sc" },
    { label: "Lilita One", value: "Lilita One", description: "Caption font sedan-sc" },
    { label: "Sedan SC", value: "Sedan SC", description: "Caption font sedan-sc" },
    { label: "Bebas Neue", value: "Bebas Neue", description: "Caption font montserrat" },
    { label: "Nabla", value: "Nabla", description: "Caption font bungee-nabla" },
    { label: "Concert One", value: "Concert One", description: "Caption font concert-one" },
    { label: "Freeman", value: "Freeman", description: "Caption font freeman" },
    { label: "Jaro", value: "Jaro", description: "Caption font jaro" },
    { label: "Caveat Brush", value: "Caveat Brush", description: "Caption font caveat-brush" },
    { label: "Josefin Sans", value: "Josefin Sans", description: "Caption font nanum-pen-script" },
    { label: "Rubik Moonrocks", value: "Rubik Moonrocks", description: "Caption font rubik-moonrocks" },
    // { label: "Honk", value: "subtitle-honk", description: "Caption font honk" },
    // { label: "Bungee Spice", value: "subtitle-bungee-spice", description: "Caption font bungee-spice" },
    // { label: "Foldit", value: "subtitle-foldit", description: "Caption font bungee-spice" },
];

// example data
const exampleData = {
    "text": "Welcome to a world transformed by artificial intelligence, where machines enhance productivity by analyzing years of data in mere hours. Discover the breakthroughs AI has made in medicine, enabling quicker, more precise diagnoses. Witness its impact on transportation, as AI makes vehicles smarter and roads safer. Dive into AI-driven entertainment, tailored to your preferences, and experience the future of shopping with personalized, algorithm-suggested products. See how AI tackles climate change by predicting environmental shifts from vast datasets. The power of AI is limitless, reshaping our reality with incredible advancements.",
    "task": "transcribe",
    "language": "english",
    "duration": 45.95000076293945,
    "words": [
        {
            "word": "Welcome",
            "start": 0.0,
            "end": 0.5
        },
        {
            "word": "to",
            "start": 0.5,
            "end": 0.800000011920929
        },
        {
            "word": "a",
            "start": 0.800000011920929,
            "end": 1.0
        },
        {
            "word": "world",
            "start": 1.0,
            "end": 1.2999999523162842
        },
        {
            "word": "transformed",
            "start": 1.2999999523162842,
            "end": 1.899999976158142
        },
        {
            "word": "by",
            "start": 1.899999976158142,
            "end": 2.240000009536743
        },
        {
            "word": "artificial",
            "start": 2.240000009536743,
            "end": 2.759999990463257
        },
        {
            "word": "intelligence",
            "start": 2.759999990463257,
            "end": 3.4000000953674316
        },
        {
            "word": "where",
            "start": 4.179999828338623,
            "end": 4.179999828338623
        },
        {
            "word": "machines",
            "start": 4.179999828338623,
            "end": 4.559999942779541
        },
        {
            "word": "enhance",
            "start": 4.559999942779541,
            "end": 5.0
        },
        {
            "word": "productivity",
            "start": 5.0,
            "end": 5.599999904632568
        },
        {
            "word": "by",
            "start": 5.599999904632568,
            "end": 6.059999942779541
        },
        {
            "word": "analyzing",
            "start": 6.059999942779541,
            "end": 6.5
        },
        {
            "word": "years",
            "start": 6.5,
            "end": 6.920000076293945
        },
        {
            "word": "of",
            "start": 6.920000076293945,
            "end": 7.460000038146973
        },
        {
            "word": "data",
            "start": 7.460000038146973,
            "end": 7.460000038146973
        },
        {
            "word": "in",
            "start": 7.460000038146973,
            "end": 7.980000019073486
        },
        {
            "word": "mere",
            "start": 7.980000019073486,
            "end": 8.239999771118164
        },
        {
            "word": "hours",
            "start": 8.239999771118164,
            "end": 8.699999809265137
        },
        {
            "word": "Discover",
            "start": 10.039999961853027,
            "end": 10.039999961853027
        },
        {
            "word": "the",
            "start": 10.039999961853027,
            "end": 10.279999732971191
        },
        {
            "word": "breakthroughs",
            "start": 10.279999732971191,
            "end": 10.979999542236328
        },
        {
            "word": "AI",
            "start": 10.979999542236328,
            "end": 11.199999809265137
        },
        {
            "word": "has",
            "start": 11.199999809265137,
            "end": 11.5
        },
        {
            "word": "made",
            "start": 11.5,
            "end": 11.65999984741211
        },
        {
            "word": "in",
            "start": 11.65999984741211,
            "end": 12.279999732971191
        },
        {
            "word": "medicine",
            "start": 12.279999732971191,
            "end": 12.279999732971191
        },
        {
            "word": "enabling",
            "start": 13.0600004196167,
            "end": 13.079999923706055
        },
        {
            "word": "quicker",
            "start": 13.079999923706055,
            "end": 13.5600004196167
        },
        {
            "word": "more",
            "start": 14.140000343322754,
            "end": 14.300000190734863
        },
        {
            "word": "precise",
            "start": 14.300000190734863,
            "end": 14.600000381469727
        },
        {
            "word": "diagnoses",
            "start": 14.600000381469727,
            "end": 15.359999656677246
        },
        {
            "word": "Witness",
            "start": 16.68000030517578,
            "end": 16.68000030517578
        },
        {
            "word": "its",
            "start": 16.68000030517578,
            "end": 17.040000915527344
        },
        {
            "word": "impact",
            "start": 17.040000915527344,
            "end": 17.420000076293945
        },
        {
            "word": "on",
            "start": 17.420000076293945,
            "end": 17.739999771118164
        },
        {
            "word": "transportation",
            "start": 17.739999771118164,
            "end": 18.34000015258789
        },
        {
            "word": "as",
            "start": 18.780000686645508,
            "end": 19.1200008392334
        },
        {
            "word": "AI",
            "start": 19.1200008392334,
            "end": 19.440000534057617
        },
        {
            "word": "makes",
            "start": 19.440000534057617,
            "end": 19.700000762939453
        },
        {
            "word": "vehicles",
            "start": 19.700000762939453,
            "end": 20.200000762939453
        },
        {
            "word": "smarter",
            "start": 20.200000762939453,
            "end": 20.579999923706055
        },
        {
            "word": "and",
            "start": 20.579999923706055,
            "end": 21.020000457763672
        },
        {
            "word": "roads",
            "start": 21.020000457763672,
            "end": 21.239999771118164
        },
        {
            "word": "safer",
            "start": 21.239999771118164,
            "end": 21.600000381469727
        },
        {
            "word": "Dive",
            "start": 22.959999084472656,
            "end": 22.959999084472656
        },
        {
            "word": "into",
            "start": 22.959999084472656,
            "end": 23.260000228881836
        },
        {
            "word": "AI",
            "start": 23.260000228881836,
            "end": 23.719999313354492
        },
        {
            "word": "driven",
            "start": 23.719999313354492,
            "end": 24.0
        },
        {
            "word": "entertainment",
            "start": 24.0,
            "end": 24.559999465942383
        },
        {
            "word": "tailored",
            "start": 25.200000762939453,
            "end": 25.280000686645508
        },
        {
            "word": "to",
            "start": 25.280000686645508,
            "end": 25.520000457763672
        },
        {
            "word": "your",
            "start": 25.520000457763672,
            "end": 25.739999771118164
        },
        {
            "word": "preferences",
            "start": 25.739999771118164,
            "end": 26.1200008392334
        },
        {
            "word": "and",
            "start": 26.1200008392334,
            "end": 26.81999969482422
        },
        {
            "word": "experience",
            "start": 26.81999969482422,
            "end": 27.399999618530273
        },
        {
            "word": "the",
            "start": 27.399999618530273,
            "end": 27.6200008392334
        },
        {
            "word": "future",
            "start": 27.6200008392334,
            "end": 27.920000076293945
        },
        {
            "word": "of",
            "start": 27.920000076293945,
            "end": 28.459999084472656
        },
        {
            "word": "shopping",
            "start": 28.459999084472656,
            "end": 28.459999084472656
        },
        {
            "word": "with",
            "start": 28.459999084472656,
            "end": 28.84000015258789
        },
        {
            "word": "personalized",
            "start": 28.84000015258789,
            "end": 29.559999465942383
        },
        {
            "word": "algorithm",
            "start": 30.540000915527344,
            "end": 30.540000915527344
        },
        {
            "word": "suggested",
            "start": 30.540000915527344,
            "end": 31.219999313354492
        },
        {
            "word": "products",
            "start": 31.219999313354492,
            "end": 31.579999923706055
        },
        {
            "word": "See",
            "start": 33.02000045776367,
            "end": 33.040000915527344
        },
        {
            "word": "how",
            "start": 33.040000915527344,
            "end": 33.279998779296875
        },
        {
            "word": "AI",
            "start": 33.279998779296875,
            "end": 33.619998931884766
        },
        {
            "word": "tackles",
            "start": 33.619998931884766,
            "end": 34.040000915527344
        },
        {
            "word": "climate",
            "start": 34.040000915527344,
            "end": 34.41999816894531
        },
        {
            "word": "change",
            "start": 34.41999816894531,
            "end": 34.79999923706055
        },
        {
            "word": "by",
            "start": 34.79999923706055,
            "end": 35.08000183105469
        },
        {
            "word": "predicting",
            "start": 35.08000183105469,
            "end": 35.400001525878906
        },
        {
            "word": "environmental",
            "start": 35.400001525878906,
            "end": 36.119998931884766
        },
        {
            "word": "shifts",
            "start": 36.119998931884766,
            "end": 36.41999816894531
        },
        {
            "word": "from",
            "start": 36.41999816894531,
            "end": 36.720001220703125
        },
        {
            "word": "vast",
            "start": 36.720001220703125,
            "end": 37.060001373291016
        },
        {
            "word": "datasets",
            "start": 37.060001373291016,
            "end": 37.65999984741211
        },
        {
            "word": "The",
            "start": 38.779998779296875,
            "end": 39.040000915527344
        },
        {
            "word": "power",
            "start": 39.040000915527344,
            "end": 39.18000030517578
        },
        {
            "word": "of",
            "start": 39.18000030517578,
            "end": 39.560001373291016
        },
        {
            "word": "AI",
            "start": 39.560001373291016,
            "end": 39.79999923706055
        },
        {
            "word": "is",
            "start": 39.79999923706055,
            "end": 40.119998931884766
        },
        {
            "word": "limitless",
            "start": 40.119998931884766,
            "end": 40.540000915527344
        },
        {
            "word": "reshaping",
            "start": 41.15999984741211,
            "end": 41.400001525878906
        },
        {
            "word": "our",
            "start": 41.400001525878906,
            "end": 41.7400016784668
        },
        {
            "word": "reality",
            "start": 41.7400016784668,
            "end": 42.099998474121094
        },
        {
            "word": "with",
            "start": 42.099998474121094,
            "end": 42.459999084472656
        },
        {
            "word": "incredible",
            "start": 42.459999084472656,
            "end": 42.91999816894531
        },
        {
            "word": "advancements",
            "start": 42.91999816894531,
            "end": 43.560001373291016
        }
    ]
};

export { columns, statusOptions, progressstyle, URLValues, INITIAL_VISIBLE_COLUMNS, exampleData };
