// libraries
import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";

// custom image
import NotAvailableImage from "../../pages/shinefy/Tools/Images/NotAvailable.png";

// custom components
import consoleLog from "../../functions/consoleLog";

const PreviewVideo = (props) => {

    // props
    const {
        isOpen,
        onClose,
        images,
        audioSrc,
        modalData,
    } = props;

    // react components
    const audioRef = useRef(null);
    const videoRef = useRef(null);

    // react components
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [playPreview, setPlayPreview] = useState(false);

    // check media if video
    const isVideo = (media) => media.image.endsWith('.mp4');

    // Function to handle the transition to the next media item in the slideshow.
    const handleMediaChange = () => {
        if (!isLoading) {
            const nextIndex = (currentImageIndex + 1) % images.length;
            setCurrentImageIndex(nextIndex);
        }
    };

    // Effect to manage image transitions based on the duration specified for each image.
    useEffect(() => {
        if (audioLoaded && audioRef.current && images.length > 0) {
            const currentDuration = images[currentImageIndex].total_seconds * 1000;
            const intervalId = setTimeout(handleMediaChange, currentDuration);

            return () => clearTimeout(intervalId);
        }
    }, [audioLoaded, images, currentImageIndex, isLoading]);

    // Effect to control audio playback depending on loading state.
    useEffect(() => {
        if (audioLoaded && audioRef.current) {
            isLoading ? audioRef.current.pause() : audioRef.current.play();
        }
    }, [isLoading, audioLoaded]);

    // Effect to toggle audio playback when 'playPreview' state changes.
    useEffect(() => {
        if (audioRef.current) {
            if (playPreview) {
                audioRef.current.play().catch(consoleLog);
                setAudioLoaded(true);
            } else {
                audioRef.current.pause();
                setAudioLoaded(false);
            }
        }
    }, [playPreview]);

    // Effect to control playback for both audio and video based on 'playPreview' state.
    useEffect(() => {
        // Control audio
        const audioElement = audioRef.current;
        if (audioElement) {
            if (playPreview) {
                audioElement.play().catch(consoleLog);
                setAudioLoaded(true);
            } else {
                audioElement.pause();
                setAudioLoaded(false);
            }
        }

        // Control video
        const videoElement = videoRef.current;
        if (videoElement) {
            if (playPreview) {
                videoElement.play().catch(consoleLog);
            } else {
                videoElement.pause();
            }
        }
    }, [playPreview]);

    // Assuming this is already handling image loading
    useEffect(() => {
        const videoElement = videoRef.current;

        const handleVideoLoaded = () => {
            setIsLoading(false);
            setHasError(false);
        };

        const handleVideoError = () => {
            setIsLoading(false);
            setHasError(true);
        };

        if (videoElement) {
            videoElement.onloadeddata = handleVideoLoaded;
            videoElement.onerror = handleVideoError;
        }

        // Cleanup function
        return () => {
            if (videoElement) {
                videoElement.onloadeddata = null;
                videoElement.onerror = null;
            }
        };
    }, [videoRef]);

    // Function to toggle the 'playPreview' state indicating whether the preview is playing or paused.
    const handlePlayPreview = () => {
        setPlayPreview(prev => !prev);
    };

    return (
        <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">{modalData?.title ? modalData?.title : modalData?.task_id}</ModalHeader>
                        <ModalBody>

                            {/* video tag */}
                            <div className="flex justify-center items-center h-[30rem] mt-2">

                                {isLoading && (
                                    <div className="flex items-center justify-center w-[30rem] h-[15rem]">
                                        <i className="fa-solid fa-image fa-bounce fa-2xl text-gray-400" />
                                    </div>
                                )}

                                {hasError && (
                                    <div className="flex items-center justify-center w-[30rem] h-[15rem]">
                                        <img src={NotAvailableImage} alt="error" />
                                    </div>
                                )}

                                {isVideo(images[currentImageIndex]) ? (
                                    <video
                                        ref={videoRef}
                                        onLoadStart={() => setIsLoading(true)}
                                        onLoadedData={() => {
                                            setIsLoading(false);
                                            if (playPreview) {
                                                videoRef.current.play().catch(consoleLog); // Ensure to catch any errors from the promise
                                            }
                                        }}
                                        onError={() => {
                                            setIsLoading(false);
                                            setHasError(true);
                                        }}
                                        className="max-w-full max-h-full h-auto border-0 rounded-lg"
                                        src={images[currentImageIndex].image}
                                        alt="Slideshow"
                                        style={{ display: isLoading || hasError ? 'none' : 'block' }}
                                        loop={playPreview}
                                    />
                                ) : (
                                    <img
                                        onLoadStart={() => setIsLoading(true)}
                                        onLoad={() => setIsLoading(false)}
                                        onError={() => setIsLoading(false)}
                                        className="max-w-full max-h-full h-auto border-0 rounded-lg"
                                        src={images[currentImageIndex].image}
                                        alt="Slideshow"
                                        style={{ display: isLoading || hasError ? 'none' : 'block' }}
                                    />
                                )}
                                <audio ref={audioRef} src={audioSrc} loop />
                            </div>

                        </ModalBody>
                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-between -mt-2">
                            {/* <Button
                                onPress={onClose}
                                color="danger"
                                variant="flat"
                                className="w-auto h-auto text-base"
                            >
                                Close
                            </Button> */}
                            {/* <Button
                                // onClick={handlesubmitReport}
                                color="primary"
                                className="h-full w-full lg:md:sm:w-auto py-3.5 px-7 text-base"
                            >
                                Submit Report
                            </Button> */}
                            <Button
                                onClick={handlePlayPreview}
                                color="primary"
                            >
                                <i className={`fa-solid fa-${playPreview ? "pause" : "play"} text-xl`} />
                            </Button>

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default PreviewVideo;