// libraries
import React from "react";

// custom components
import { formatNumberValue } from "../../../../../functions/general";

const VG_TableCell3 = (props) => {

    // props
    const {
        data,
    } = props;

    // handle more cost details 
    const handleMoreDetails = async () => {

        // push the page to the URL
        window.history.pushState("Cost", "Details", `?p=cost-details&id=${data?.task_id}`);
    };

    return (
        <div className="flex flex-col">
            <p className="font-semibold capitalize">
                <code className="text-base">
                    <i className="fa-solid fa-coins text-yellow-300 mr-1" />{formatNumberValue(data?.amount)}
                </code>
            </p>
            {/* <p
                onClick={handleMoreDetails}
                className="font-semibold text-xs cursor-pointer text-blue-400"
            >
                More details
            </p> */}
            <p className="font-semibold text-xs text-blue-400">Process cost</p>
        </div>
    );
};

export default VG_TableCell3;