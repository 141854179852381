// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Progress, Select, SelectItem, Slider, Switch, Tab, Tabs, Tooltip, cn, useDisclosure } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

// custom css
import "../Helpers/subtitle.css";

// custom components
import { captionFonts, captionPosition, exampleData } from "../Helpers/data";
import consoleLog from "../../../../../functions/consoleLog";
import UserContext from "../../../../../components/UserContext";
import { cachedToolsConfiguration, capitalizeFirstLetter, getCachedToolsConfiguration, handlePurchaseCredits } from "../../../../../functions/general";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import { containsUrl } from "../../functions/general";
import { generateCaption, rebuildCaption, syncCaption } from "../../API/toolsAPI";
import MediaView from "../../../../../components/Modals/MediaView";
import { getCreditBalance, payVideo, recordCredits } from "../../../../../API/account";
import { adjustCreditsView } from "../../../../../functions/itemWithExpiry";
import { alertBeforeAction } from "../../Components/Transcriber/AlertBeforeAction";

const VG_CustomMediaCaption = (props) => {

    // props
    const {
        videoData,
        toolsDataAudio,
        setRefetchData,
        handleDownloadMediaFile,
        isDownloading,
        subscriptionData,
        isOldUser,
        backgroundMusic,
    } = props;

    // context method
    const {

        // Others
        userData,
        appConfigData,
        toolsDataStatus,
        refetchData,
        configData,

        // For image2video data params
        setRefetchToolsData,

        // debounce saveConfig function
        debouncedSaveConfig,

        // debounce updateCaptionData function
        debounceUpdateCaptionData,

    } = useContext(UserContext);

    // extracted data from videoData
    const contentURL = videoData?.raw_video || videoData?.content_url;
    const rawVideoSource = videoData?.raw_video;
    const captionData = videoData?.caption;
    const task_id = videoData?.task_id;
    const aspectRatio = videoData?.aspect_ratio;
    const email = videoData?.email;
    const pathname = "/general-purpose";
    const config_caption = appConfigData[0]?.caption;

    // conditional variables
    const default_param = (aspectRatio === "landscape" ? "segments" : "words");

    // react useRef
    const videoRef = useRef(null);
    const audioRef = useRef(null);

    // use navigate
    const navigate = useNavigate();

    // extracted data from toolsDataAudio
    const audioSrc = toolsDataAudio?.data;
    const audioStatus = toolsDataAudio?.status;
    const audioID = toolsDataAudio?._id;

    // background audio music
    const bg_music = `${process.env.REACT_APP_API_END_POINT}/music/${backgroundMusic}.mp3`;

    // config react useState
    const [positionValue, setPositionValue] = useState(
        getCachedToolsConfiguration("captionPosition", configData)?.config || ""
    );
    const [fontValue, setFontValue] = useState(
        capitalizeFirstLetter(getCachedToolsConfiguration("captionFont", configData)?.config) || "Bangers"
    );
    const [isBgColor, setIsBgColor] = useState(
        getCachedToolsConfiguration("captionIsBGColor", configData)?.config === "true"
    );
    const [bgColor, setBgColor] = useState(
        getCachedToolsConfiguration("captionBGColor", configData)?.config || "#18181B"
    );
    const [captionColor, setCaptionColor] = useState(
        getCachedToolsConfiguration("captionFontColor", configData)?.config || "#FFD700" // RGB -> .ass format BGR
    );
    const [fontSize, setFontSize] = useState(
        Number(getCachedToolsConfiguration("captionFontSize", configData)?.config) || 1.5
    );
    const [wordsPerCue, setWordsPerCue] = useState(
        Number(getCachedToolsConfiguration("captionWordsPerCue", configData)?.config) || 2
    );

    // nextUI components
    const { isOpen: isOpenMV, onOpen: onOpenMV, onClose: onCloseMV } = useDisclosure(); // MV - (Media View)

    // react components
    const [selectedCaptionType, setSelectedCaptionType] = useState(default_param);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredWords, setFilteredWords] = useState(captionData?.[`caption_${selectedCaptionType}`]);
    const [VTTUrl, setVTTUrl] = useState();
    const [videoSource, setVideoSource] = useState(contentURL);
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isCombining, setIsCombining] = useState(false);
    const [filteredSyncCaptionStatus, setFilteredSyncCaptionStatus] = useState({});
    const [filteredRebuildCaptionStatus, setFilteredRebuildCaptionStatus] = useState({});

    // conditional variables
    const param = (selectedCaptionType === "segments" ? "text" : "word");

    // console log activity
    // consoleLog("toolsDataAudio", toolsDataAudio);
    // consoleLog("captionData", captionData);

    // refetch data 
    useEffect(() => {
        const refetchAudioData = () => {
            setRefetchData(oldval => oldval + 1);
            setIsSyncing(false);
            setFilteredSyncCaptionStatus({});
        };
        refetchAudioData();
    }, [refetchData]);

    // filter get rebuild video status from toolsDataStatus via task_id from videoData
    useEffect(() => {
        const filterVideoData = () => {
            const custom_media_status = toolsDataStatus.find((status) => status?.tools_status?.type === "sync_caption" && status?.tools_status?.task_id === task_id); // filter custom media status process
            const rebuild_caption_status = toolsDataStatus.find((status) => status?.tools_status?.type === "rebuild_caption" && status?.tools_status?.task_id === task_id); // filter rebuild caption status process
            if (custom_media_status) { setFilteredSyncCaptionStatus(custom_media_status?.tools_status); } // set filtered data
            if (rebuild_caption_status) { setFilteredRebuildCaptionStatus(rebuild_caption_status?.tools_status); } // set filtered data
        };
        filterVideoData(); // call function
    }, [toolsDataStatus]);

    // check if process is doen and trigger functions
    useEffect(() => {
        let timer;
        if (filteredRebuildCaptionStatus?.status === "processing") {
            setIsCombining(true);
        } else if (filteredRebuildCaptionStatus?.status === "done" && refetchData) {
            setIsCombining(false); // disable loading effects
            timer = setTimeout(() => { // 5 secs delay
                setFilteredRebuildCaptionStatus({}); // clean the status data
                onOpenMV(); // open mediaview modal
            }, 5000); // 1000 milliseconds = 1 second
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [filteredRebuildCaptionStatus, refetchData]);

    // process and filter words base from the search term
    useEffect(() => {
        if (!searchTerm.trim()) {
            // If no search term, use the original data with indexes
            const originalDataWithIndex = captionData?.[`caption_${selectedCaptionType}`].map((word, index) => ({ word, index }));
            setFilteredWords(originalDataWithIndex);
        } else {
            // Filter the data and preserve the original index
            const filtered = captionData?.[`caption_${selectedCaptionType}`]
                .map((word, index) => ({ word, index })) // Map to include the original index
                .filter(({ word }) =>
                    word[param]?.toLowerCase().includes(searchTerm.toLowerCase())
                );
            setFilteredWords(filtered);
        }
    }, [searchTerm, captionData?.[`caption_${selectedCaptionType}`]]);


    const handleSearchWords = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // set search term
        setSearchTerm(e.target.value);
    };

    // Sort the captionFonts array by the 'name' property
    const sortedCaptionFonts = captionFonts.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });

    // Function to break long text into lines
    const breakLongText = (text, maxLength) => {
        let lines = [];
        while (text.length > maxLength) {
            let lastSpaceIndex = text.lastIndexOf(' ', maxLength);
            if (lastSpaceIndex === -1) {
                lastSpaceIndex = maxLength;
            }
            lines.push(text.substring(0, lastSpaceIndex));
            text = text.substring(lastSpaceIndex + 1);
        }
        lines.push(text);
        return lines.join('\n');
    };

    // Convert JSON data to VTT
    const createVTTContent = async (data, positionValue, wordsPerCue) => {
        let vttString = "WEBVTT\n\n"; // VTT header
        const words = data?.[`caption_${selectedCaptionType}`];


        if (param === "word") {
            for (let i = 0; i < words?.length; i += wordsPerCue) {
                let captionText = "";
                let startTime = words[i].start;
                let endTime = words[Math.min(i + wordsPerCue - 1, words.length - 1)].end;

                for (let j = 0; j < wordsPerCue && i + j < words.length; j++) {
                    captionText += words[i + j].word + " ";
                }

                captionText = captionText.trim(); // Remove trailing space
                const startVTTTime = new Date(startTime * 1000).toISOString().substr(11, 12);
                const endVTTTime = new Date(endTime * 1000).toISOString().substr(11, 12);

                vttString += `${startVTTTime} --> ${endVTTTime} ${positionValue}\n${captionText}\n\n`;
            }
        } else {
            words?.forEach((word) => {
                const startTime = new Date(word.start * 1000).toISOString().substr(11, 12);
                const endTime = new Date(word.end * 1000).toISOString().substr(11, 12);

                // variable
                let captionText;

                // check there is a position
                if (positionValue) {
                    // Break long text into multiple lines
                    captionText = breakLongText(word[param], 30);
                } else {
                    captionText = word[param]; // just return the value
                }

                // Construct the VTT cue with positioning
                // vttString += `${startTime} --> ${endTime} ${positionValue ? positionValue : "size:80%"} \n${captionText}\n\n`;
                vttString += `${startTime} --> ${endTime} ${positionValue} \n${captionText}\n\n`;
            });
        }

        return vttString;
    };

    // const createVTTContent = async (data, positionValue) => {
    //     let vttString = "WEBVTT\n\n"; // VTT header
    //     // let param = captionType === "segments" ? "text" : "word"; // Determine param based on caption type

    //     data?.[`caption_${selectedCaptionType}`]?.forEach((word) => {
    //         const startTime = new Date(word.start * 1000).toISOString().substr(11, 12);
    //         const endTime = new Date(word.end * 1000).toISOString().substr(11, 12);

    //         // variable
    //         let captionText;

    //         // check there is a position
    //         if (positionValue) {
    //             // Break long text into multiple lines
    //             captionText = breakLongText(word[param], 30);
    //         } else {
    //             captionText = word[param]; // just return the value
    //         }

    //         // Construct the VTT cue with positioning
    //         // vttString += `${startTime} --> ${endTime} ${positionValue ? positionValue : "size:80%"} \n${captionText}\n\n`;
    //         vttString += `${startTime} --> ${endTime} ${positionValue} \n${captionText}\n\n`;
    //     });

    //     return vttString;
    // };

    // sync audio and video
    useEffect(() => {
        const asyncAudioTrack = () => {
            const video = videoRef.current;
            const audio = audioRef.current;

            if (video && audio) {
                const syncAudioWithVideo = () => {
                    const timeDifference = Math.abs(video.currentTime - audio.currentTime);
                    if (timeDifference > 0.3) { // Adjust threshold as needed
                        audio.currentTime = video.currentTime;
                    }
                };

                const playAudioWithVideo = () => {
                    audio.play();
                };

                const pauseAudioWithVideo = () => {
                    audio.pause();
                };

                video.addEventListener('timeupdate', syncAudioWithVideo);
                video.addEventListener('play', playAudioWithVideo);
                video.addEventListener('pause', pauseAudioWithVideo);
                video.addEventListener('seeked', syncAudioWithVideo);

                return () => {
                    video.removeEventListener('timeupdate', syncAudioWithVideo);
                    video.removeEventListener('play', playAudioWithVideo);
                    video.removeEventListener('pause', pauseAudioWithVideo);
                    video.removeEventListener('seeked', syncAudioWithVideo);
                };
            }
        };
        asyncAudioTrack();
    }, []);

    // updates caption color
    const updateCaptionStyles = async (captionColor) => {
        const styleId = 'caption-style';
        let styleElement = document.getElementById(styleId);

        if (!styleElement) {
            styleElement = document.createElement('style');
            styleElement.id = styleId;
            document.head.appendChild(styleElement);
        }

        // some css configs
        // background-color: transparent !important;
        // font-weight: bold;
        // animation: blurInOut 1s ease-in-out both;

        styleElement.textContent = `
            video::cue {
                font-family: '${fontValue}', cursive;
                font-size: ${fontSize}em;
                color: ${captionColor} !important;
                background-color: ${isBgColor ? "rgba(0, 0, 0, 0.9)" : "transparent"} !important;
                ${!isBgColor && (`
                    text-shadow: 
                        4px 1px 2px ${bgColor}, 
                        -2px 2px 4px ${bgColor};
                `)}
            }
        `;
    };

    // call this function if changes happens
    useEffect(() => {
        const runCaptionFunctions = async () => {
            const vttContent = await createVTTContent(captionData, positionValue, wordsPerCue);
            const blob = new Blob([vttContent], { type: 'text/vtt' });
            const vttUrl = URL.createObjectURL(blob);
            setVTTUrl(vttUrl);

            await updateCaptionStyles(captionColor);

            return () => {
                URL.revokeObjectURL(vttUrl);
                const styleElement = document.getElementById('caption-style');
                if (styleElement) {
                    document.head.removeChild(styleElement);
                }
            };
        };
        runCaptionFunctions();
    }, [captionColor, positionValue, fontValue, fontSize, isBgColor, bgColor, captionData, selectedCaptionType, updateTrigger, wordsPerCue]);

    // handle selected caption type
    const handleCaptionType = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // selected data
        const selectedType = e;

        // setData
        setSelectedCaptionType(selectedType);

        // console log activity
        // consoleLog("selectedType", selectedType);
    };

    // handle selected position
    const handleSelectedPosition = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // extracted data and variables
        const positionValue = e.target.value;
        const kind = "captionPosition";

        // set selected position
        setPositionValue(positionValue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, positionValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, positionValue);
    };

    // handle selected font
    const handleSelectedFont = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // extracted data and variables
        const selectedFont = e.target.value;
        const kind = "captionFont";

        // set selected font
        setFontValue(selectedFont);

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedFont);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, selectedFont);
    };

    // handle is BG Color
    const handleIsBGColor = () => {

        // check if videoData exist
        if (!videoData) { return; }

        // variables
        const kind = "captionIsBGColor";

        // set is BG Color
        setIsBgColor(!isBgColor);

        // cached config to localstorage
        cachedToolsConfiguration(kind, !isBgColor);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, !isBgColor);
    };

    // handle Selecte BG Color
    const handleSelectedBGColor = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // extracted data and variables
        const selectedBGColor = e.target.value;
        const kind = "captionBGColor";

        // set selected bg color
        setBgColor(selectedBGColor);

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedBGColor);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, selectedBGColor);
    };

    // handle Selecte BG Color
    const handleSelectedCaptionColor = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // extracted data and variables
        const selectedFontColor = e.target.value;
        const kind = "captionFontColor";

        // set selected bg color
        setCaptionColor(selectedFontColor);

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedFontColor);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, selectedFontColor);
    };

    // handle font size
    const handleFontSize = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // extracted data and variables
        const selectedFontSize = e;
        const kind = "captionFontSize";

        // set font size
        setFontSize(selectedFontSize);

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedFontSize);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, selectedFontSize);
    };

    // handle font size
    const handleWordsPerCue = (e) => {

        // check if videoData exist
        if (!videoData) { return; }

        // extracted data and variables
        const selectedWordsPerCue = e;
        const kind = "captionWordsPerCue";

        // set font size
        setWordsPerCue(selectedWordsPerCue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedWordsPerCue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, selectedWordsPerCue);
    };

    // handle caption data change
    const handleCaptionDataChange = async (e, kind, type, idx) => {

        // extracted value
        const newValue = e.target.value;

        // execute function to save via deboucne new value
        await debounceUpdateCaptionData(kind, task_id, kind, type, idx, newValue)
            .then((response) => {
                setRefetchToolsData(oldVal => oldVal + 1);
            });
    };

    // handle rebuild caption
    const handleReBuildCaption = async () => {

        // check if videoData exist
        if (!videoData) { return; }

        // set combining
        setIsCombining(true);

        // check if raw video exist
        if (!rawVideoSource) {
            ToastEffect("error", "Please rebuild a video (Custom Media) first.");
            setIsCombining(false);
            return;
        }

        // check if audio source exist
        if (!audioSrc) {
            ToastEffect("error", "Please generate an audio (Custom Audio) first.");
            setIsCombining(false);
            return;
        }

        // required caption data
        const requiredCaptionData = captionData?.[`caption_${selectedCaptionType}`];

        // check if caption data exist
        if (!requiredCaptionData) { return; }

        // required data
        const requiredData = {
            task_id: task_id,
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            caption_data: JSON.stringify(requiredCaptionData) || "",
            caption_position: positionValue,
            caption_font: fontValue,
            caption_isBG: Number(isBgColor),
            caption_BGColor: bgColor,
            caption_FontColor: captionColor,
            caption_FontSize: fontSize,
            video_source: rawVideoSource,
            audio_source: audioSrc,
            caption_kind: param,
            aspect_ratio: aspectRatio,
            words_per_cue: Number(wordsPerCue),
            bg_music: backgroundMusic,
        };

        // call function to process media
        await rebuildCaption(requiredData)
            .then((response) => {
                // console log activity
                consoleLog("rebuildCaption", response?.data);
            }).catch((error) => {
                consoleLog("error", error);
                setIsCombining(false);
            });

    };

    // handle sync caption to the audio
    const handleSyncCaption = async (kind) => {

        // set Syncing
        setIsSyncing(true);

        // Check if either of these two exist
        if (!email && !task_id) { return; }

        // check if audio source is valid
        if (!containsUrl(audioSrc)) {
            ToastEffect("error", "Audio source is not valid");
            setIsSyncing(false);
            return;
        }

        // --------------------------------
        // --- Check credit balance first
        // --------------------------------
        if (subscriptionData?.message !== "freeAccess") {

            const ceditBalance = await getCreditBalance(email);

            // --------------------------------
            // --- Extracting repeated variable calls
            // --------------------------------
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;

            // --------------------------------
            // --- Calculating the total credits and total price
            // --------------------------------
            const totalCredits = (subscriptionCredits + paidCredits);

            if (totalCredits < Number(config_caption)) {
                setIsSyncing(false);
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        // required data
        const requiredData = {
            task_id: task_id,
            media_id: audioID,
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            audio_source: audioSrc,
        };

        await syncCaption(requiredData)
            .then(async (response) => {

                // console log activity
                consoleLog("syncCaption", response?.data);

                // if kind is "generate" execute payment
                if (kind === "generate") {

                    // video task id
                    const taskId = task_id;

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        setIsSyncing(false);
                        ToastEffect("error", "Maximum request limit reached.");
                        return;
                    }

                    // --------------------------------
                    // -- check if taskId did not exist and throw error message 
                    // --------------------------------
                    if (!taskId) {
                        setIsSyncing(false);
                        ToastEffect("error", "No task ID received!");
                        return;
                    }

                    // --------------------------------
                    // --- process payment
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        // --------------------------------
                        // --- record credit cost
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_GenerateCaption", paymentKind, Number(config_caption));

                    } else {
                        // --------------------------------
                        // --- pay video
                        // --------------------------------
                        await payVideo(email, Number(config_caption), "VG_GenerateCaption");

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        await recordCredits(email, taskId, "VG_GenerateCaption", "payment", Number(config_caption));
                        adjustCreditsView("tokenBalance", "pay", Number(config_caption)); // adjust credit UI display
                    }

                }

            }).catch((error) => {
                consoleLog("error", error);
                setIsSyncing(false);
            });

    };

    return (
        <>
            {/* <div className="mx-auto max-w-screen-xl px-4 2xl:px-0"> */}
            {/* <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">Track the delivery of order #957684673</h2> */}

            <div className="lg:flex lg:gap-1">

                {/* <div className={`${!captionData && "relative"} grow w-full lg:max-w-[40%] lg:min-w-[30%] mb-2`}> */}
                <div className={`${!captionData && "relative"} grow lg:w-[45rem] mb-2`}>

                    {!captionData && (
                        <div className="absolute inset-0 flex items-center justify-center backdrop-blur-sm z-50">

                            <div className="float-end">
                                <Card
                                    shadow="lg"
                                    className={`${!videoData && "invisible"} w-auto dark:bg-gray-700`}
                                >
                                    <CardHeader className="flex gap-3">
                                        <div className="text-xl font-bold">
                                            <p>No caption detected!</p>
                                        </div>
                                    </CardHeader>
                                    <Divider />
                                    <CardBody>
                                        <div className="px-1 py-2">
                                            <ul className="list-disc pl-5 space-y-1 break-words w-[15rem] lg:w-[20rem]">
                                                <li>Generate <b><i>caption</i></b> will cost <b><i className="fa-solid fa-coins text-yellow-300" /> {config_caption} Credit(s)</b>.</li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                    <Divider />
                                    <CardFooter>
                                        <Tooltip
                                            color="primary"
                                            showArrow={true}
                                            content="Click to generate caption"
                                        >
                                            <Button
                                                isLoading={isSyncing}
                                                onClick={() => handleSyncCaption("generate")}
                                                variant="shadow"
                                                color="secondary"
                                                className={`${!videoData && "invisible"} text-medium`}
                                                startContent={!isSyncing && (<i className="fa-solid fa-gear" />)}
                                            >
                                                Generate Caption
                                            </Button>
                                        </Tooltip>
                                    </CardFooter>
                                </Card>
                            </div>

                        </div>
                    )}

                    <div className="rounded-lg border border-gray-200 bg-white py-1 px-3 mb-1 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                        <div className="flex justify-between items-center">
                            {/* <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Caption</h3> */}

                            {/* check if audio data is newly generated */}
                            {audioStatus === "new" ? (
                                <Tooltip
                                    color="warning"
                                    showArrow={true}
                                    content="Update the caption"
                                >
                                    <Button
                                        onClick={() => handleSyncCaption("sync")}
                                        isLoading={isSyncing ? true : false}
                                        size="sm"
                                        className="text-md font-semibold"
                                        color="primary"
                                        startContent={!isSyncing && (<i className="fa-solid fa-arrows-rotate" />)}
                                    >
                                        Update Caption
                                    </Button>
                                </Tooltip>
                            ) : (
                                <p className="text-xl font-semibold">Caption</p>
                            )}

                            <Tabs
                                aria-label="Options"
                                color="primary"
                                variant="bordered"
                                selectedKey={selectedCaptionType}
                                onSelectionChange={handleCaptionType}
                            >

                                <Tab
                                    key="words"
                                    title={
                                        <Tooltip
                                            color="primary"
                                            showArrow={true}
                                            content="Caption By Words"
                                        >
                                            <div className="flex items-center space-x-1">
                                                <i className="fa-solid fa-w" />
                                                {/* <i className="fa-solid fa-arrow-down-1-9" />
                                                <span>Words</span> */}
                                            </div>
                                        </Tooltip>
                                    }
                                />

                                <Tab
                                    key="segments"
                                    title={
                                        <Tooltip
                                            color="primary"
                                            showArrow={true}
                                            content="Caption By Segments"
                                        >
                                            <div className="flex items-center space-x-1">
                                                <i className="fa-solid fa-s" />
                                                {/* <i className="fa-solid fa-arrow-up-1-9" />
                                                <span>Segments</span> */}
                                            </div>
                                        </Tooltip>
                                    }
                                />
                            </Tabs>
                        </div>

                        <div className="w-auto mx-auto mt-1">
                            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <span className="text-gray-500 dark:text-gray-400">
                                        <i className="fa-solid fa-magnifying-glass" />
                                    </span>
                                </div>
                                <input
                                    onChange={handleSearchWords}
                                    type="search"
                                    id="default-search"
                                    className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Search for caption words."
                                    required
                                />
                                {/* <button
                                    type="submit"
                                    className="text-white absolute end-2.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    Search
                                </button> */}
                            </div>
                        </div>

                    </div>

                    <div className="h-[55vh] overflow-auto space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
                        <ol className="relative ms-3 border-s border-gray-200 dark:border-gray-700">

                            {filteredWords?.map((data, idx) => {

                                // console log activity
                                // consoleLog("data", data);

                                return (
                                    <li key={`${data?.word?.[`${param}`]}-${idx}`} className="mb-5 ms-6">
                                        <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-700 dark:ring-gray-800">
                                            <span className="text-gray-500 dark:text-gray-400 text-tiny font-semibold">
                                                {/* <i className="fa-regular fa-closed-captioning" /> */}
                                                {data?.index}
                                            </span>
                                        </span>
                                        <input
                                            onChange={(e) => handleCaptionDataChange(e, "caption_data", selectedCaptionType, data?.index)}
                                            type="text"
                                            defaultValue={data?.word?.[`${param}`]}
                                            className="inline-flex items-center justify-center w-full px-2 py-1 text-lg font-medium text-center hover:text-gray-900 dark:hover:text-white bg-white dark:bg-gray-700 border rounded-lg cursor-pointer text-gray-500 border-gray-200 dark:border-gray-700 dark:peer-checked:border-blue-500 peer-checked:border-blue-700 dark:hover:border-gray-600 dark:peer-checked:text-blue-500 peer-checked:bg-blue-50 peer-checked:text-blue-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-600 dark:peer-checked:bg-blue-900"
                                        />
                                        <div className="flex justify-center items-center gap-1 mt-1">
                                            <span className="relative w-full">
                                                <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3 pointer-events-none">
                                                    <span className="text-gray-500 dark:text-gray-400 text-xs">
                                                        {/* <i className="fa-solid fa-s" /> */}
                                                        <i className="fa-regular fa-clock" />
                                                    </span>
                                                </div>
                                                <input
                                                    onChange={(e) => handleCaptionDataChange(e, "caption_start", selectedCaptionType, data?.index)}
                                                    type="number"
                                                    defaultValue={Number(data?.word?.start)?.toFixed(3)}
                                                    className="inline-flex items-center justify-center w-full px-2 py-1 text-sm font-medium text-center hover:text-gray-900 dark:hover:text-white bg-white dark:bg-gray-800 border rounded-lg cursor-pointer text-gray-500 border-gray-200 dark:border-gray-700 dark:peer-checked:border-blue-500 peer-checked:border-blue-700 dark:hover:border-gray-600 dark:peer-checked:text-blue-500 peer-checked:bg-blue-50 peer-checked:text-blue-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-600 dark:peer-checked:bg-blue-900"
                                                />
                                            </span>:
                                            <span className="relative w-full">
                                                <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3 pointer-events-none">
                                                    <span className="text-gray-500 dark:text-gray-400 text-xs">
                                                        {/* <i className="fa-solid fa-e" /> */}
                                                        <i className="fa-regular fa-clock" />
                                                    </span>
                                                </div>
                                                <input
                                                    onChange={(e) => handleCaptionDataChange(e, "caption_end", selectedCaptionType, data?.index)}
                                                    type="number"
                                                    defaultValue={Number(data?.word?.end)?.toFixed(3)}
                                                    className="inline-flex items-center justify-center w-full px-2 py-1 text-sm font-medium text-center hover:text-gray-900 dark:hover:text-white bg-white dark:bg-gray-800 border rounded-lg cursor-pointer text-gray-500 border-gray-200 dark:border-gray-700 dark:peer-checked:border-blue-500 peer-checked:border-blue-700 dark:hover:border-gray-600 dark:peer-checked:text-blue-500 peer-checked:bg-blue-50 peer-checked:text-blue-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-600 dark:peer-checked:bg-blue-900"
                                                />
                                            </span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ol>

                        {/* <div className="gap-4 sm:flex sm:items-center">
                            <button
                                type="button"
                                className="w-full rounded-lg  border border-gray-200 bg-white px-0 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                            >
                                Rebuild Caption
                            </button>

                            <a
                                href="#"
                                className="mt-4 flex w-full items-center justify-center rounded-lg bg-primary-700  px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:mt-0"
                            >
                                Order details
                            </a>
                        </div> */}

                    </div>

                    <div className="rounded-lg border border-gray-200 bg-white py-1 px-2 my-1 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                        <div className="w-auto mx-auto mt-1">
                            <div className="flex justify-between items-center gap-1">

                                <Select
                                    size="sm"
                                    variant="bordered"
                                    // color="primary"
                                    label="Position"
                                    className="w-full"
                                    selectedKeys={[positionValue]}
                                    onChange={handleSelectedPosition}
                                >
                                    {captionPosition.map((data, idx) => (
                                        <SelectItem
                                            key={data.value}
                                            value={data.value}
                                        >
                                            {data.label}
                                        </SelectItem>
                                    ))}
                                </Select>

                                <Select
                                    size="sm"
                                    variant="bordered"
                                    // color="primary"
                                    label="Font"
                                    className="w-full mr-1"
                                    selectedKeys={[fontValue]}
                                    onChange={handleSelectedFont}
                                >
                                    {sortedCaptionFonts.map((data) => (
                                        <SelectItem
                                            key={data.value}
                                            value={data.value}
                                        >
                                            {data.label}
                                        </SelectItem>
                                    ))}
                                </Select>

                            </div>
                        </div>

                        <div className="w-auto mx-auto mt-1">
                            <div className="flex justify-start items-center gap-1">

                                {/* <div className={`flex justify-between items-center gap-1 w-full border-2 p-2 rounded-lg ${isBgColor ? "border-blue-400 dark:border-blue-500" : "border-gray-300 dark:border-gray-600"}`}>
                                    <span>Background</span>
                                    <Switch
                                        defaultSelected
                                        isSelected={isBgColor}
                                        onValueChange={handleIsBGColor}
                                        classNames={{
                                            base: cn(
                                                // "inline-flex flex-row-reverse w-full bg-gray-900 hover:bg-gray-800 items-center",
                                                // "justify-between cursor-pointer rounded-lg gap-2 p-2.5 border-2 border-transparent",
                                                // "data-[selected=true]:border-primary",
                                            ),
                                            wrapper: "p-0 h-4 overflow-visible",
                                            thumb: cn("w-6 h-6 border-2 shadow-lg",
                                                "group-data-[hover=true]:border-primary",
                                                //selected
                                                "group-data-[selected=true]:ml-6",
                                                // pressed
                                                "group-data-[pressed=true]:w-7",
                                                "group-data-[selected]:group-data-[pressed]:ml-4",
                                            ),
                                        }}
                                    />
                                </div> */}

                                <Tooltip
                                    color="primary"
                                    showArrow={true}
                                    content="Enable Background"
                                >
                                    <div className={`flex justify-between items-center gap-1 w-auto border-2 p-3 rounded-lg ${isBgColor ? "border-blue-400 dark:border-blue-500" : "border-gray-300 dark:border-gray-600"}`}>
                                        <Switch
                                            defaultSelected
                                            isSelected={isBgColor}
                                            onValueChange={handleIsBGColor}
                                            classNames={{
                                                base: cn(
                                                    // "inline-flex flex-row-reverse w-full bg-gray-900 hover:bg-gray-800 items-center",
                                                    // "justify-between cursor-pointer rounded-lg gap-2 p-2.5 border-2 border-transparent",
                                                    // "data-[selected=true]:border-primary",
                                                ),
                                                wrapper: "p-0 h-4 overflow-visible",
                                                thumb: cn("w-6 h-6 border-2 shadow-lg",
                                                    "group-data-[hover=true]:border-primary",
                                                    //selected
                                                    "group-data-[selected=true]:ml-6",
                                                    // pressed
                                                    "group-data-[pressed=true]:w-7",
                                                    "group-data-[selected]:group-data-[pressed]:ml-4",
                                                ),
                                            }}
                                        />
                                    </div>
                                </Tooltip>

                                <Tooltip
                                    color="primary"
                                    showArrow={true}
                                    content="Shadow Color"
                                >
                                    <input
                                        onChange={handleSelectedBGColor}
                                        value={bgColor}
                                        type="color"
                                        className="block p-1 h-10 w-20 bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                        id="hs-color-input"
                                        title="Choose your bg color"
                                    />
                                </Tooltip>

                                {/* <label for="hs-color-input" className="block text-sm font-medium mb-2">Color picker</label> */}
                                <Tooltip
                                    color="primary"
                                    showArrow={true}
                                    content="Font Color"
                                >
                                    <input
                                        onChange={handleSelectedCaptionColor}
                                        value={captionColor}
                                        type="color"
                                        className="block p-1 h-10 w-20 bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                        id="hs-color-input"
                                        title="Choose your font color"
                                    />
                                </Tooltip>
                            </div>
                        </div>

                        <div className="w-auto mx-auto mt-1">
                            <Slider
                                isDisabled={selectedCaptionType === "segments" ? true : false}
                                size="md"
                                step={1}
                                color="secondary"
                                label="Words Per Cue"
                                showSteps={true}
                                maxValue={4}
                                minValue={1}
                                value={wordsPerCue}
                                className="w-full"
                                onChange={handleWordsPerCue}
                            />
                        </div>

                        <div className="w-auto mx-auto mt-1">
                            <Slider
                                size="md"
                                step={0.5}
                                color="secondary"
                                label="Font Size"
                                showSteps={true}
                                maxValue={5}
                                minValue={1}
                                value={fontSize}
                                className="w-full"
                                onChange={handleFontSize}
                            />
                        </div>

                        {/* <div className="w-auto mx-auto mt-1">
                            <label for="hs-color-input" className="block text-sm font-medium mb-2">Color picker</label>
                            <input
                                type="color"
                                className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                id="hs-color-input"
                                value="#FFD700"
                                title="Choose your color"
                            />
                        </div> */}

                    </div>

                    <div className="gap-4 sm:flex sm:items-center mt-1">
                        <Button
                            isLoading={isCombining ? true : false}
                            onClick={handleReBuildCaption}
                            color="primary"
                            className="w-full text-base font-semibold"
                        >
                            Rebuild & Combine Caption
                        </Button>
                    </div>

                </div>

                <div className="grow">

                    <div className="rounded-lg border border-gray-200 bg-white py-1 px-3 mb-1 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                        <div className="flex justify-between items-center">

                            <div>
                                <p>Video Preview</p>
                            </div>

                            <div className="flex gap-1">
                                <Tooltip
                                    color="primary"
                                    showArrow={true}
                                    content="View Video"
                                >
                                    <Button
                                        onClick={() => onOpenMV()}
                                        size="sm"
                                        color="primary"
                                        className="text-md"
                                        startContent={<i className="fa-solid fa-film" />}
                                    >
                                        View Video
                                    </Button>
                                </Tooltip>

                                {/* <Tooltip
                                    color="primary"
                                    showArrow={true}
                                    content="Download Video"
                                >
                                    <Button
                                        isIconOnly
                                        size="sm"
                                        color="secondary"
                                        className="text-md"
                                        startContent={<i className="fa-solid fa-download" />}
                                    />
                                </Tooltip> */}
                            </div>

                        </div>

                    </div>

                    <div className="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">

                        <div className="relative w-full">

                            <video
                                ref={videoRef}
                                className="w-full h-full border-0 rounded-lg"
                                controls
                            >
                                {/* video source */}
                                <source
                                    src={videoSource}
                                    type="video/mp4"
                                />

                                {/* caption track */}
                                <track
                                    label="Subtitles"
                                    kind="subtitles"
                                    srcLang="en"
                                    src={VTTUrl}
                                    default
                                />

                                {/* audio track */}
                                {/* <track
                                    label="Audio"
                                    kind="audio"
                                    srcLang="en"
                                    src={audioSrc}
                                /> */}

                                Your browser does not support the video tag.
                            </video>

                            {/* audio source */}
                            {containsUrl(audioSrc) && (
                                <audio ref={audioRef}>
                                    <source
                                        src={audioSrc}
                                        type="audio/mp3"
                                    />
                                    Your browser does not support the audio element.
                                </audio>
                            )}

                        </div>

                    </div>
                </div>

                {/* progress status for syncing caption */}
                {filteredSyncCaptionStatus?.status === "processing" && (
                    <div className="absolute flex px-10 inset-0 items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
                        <Progress
                            size="md"
                            radius="md"
                            classNames={{
                                base: "max-w-full",
                                track: "drop-shadow-md border border-default",
                                indicator: filteredSyncCaptionStatus?.process_type === "sub_process" ? "bg-gradient-to-r from-purple-500 to-pink-500" : "bg-gradient-to-r from-pink-500 to-yellow-500",
                                // indicator: "bg-gradient-to-r from-blue-500 to-purple-500",
                                label: "tracking-wider font-medium text-default-600",
                                value: "text-foreground/60",
                            }}
                            label={filteredSyncCaptionStatus?.message || "Processing"}
                            value={filteredSyncCaptionStatus?.current || 0}
                            maxValue={filteredSyncCaptionStatus?.total || 100}
                            showValueLabel={true}
                        />
                    </div>
                )}

                {/* progress status for build caption */}
                {filteredRebuildCaptionStatus?.status === "processing" && (
                    <div className="absolute flex px-10 inset-0 items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
                        <Progress
                            size="md"
                            radius="md"
                            classNames={{
                                base: "max-w-full",
                                track: "drop-shadow-md border border-default",
                                indicator: filteredRebuildCaptionStatus?.process_type === "sub_process" ? "bg-gradient-to-r from-purple-500 to-pink-500" : "bg-gradient-to-r from-pink-500 to-yellow-500",
                                // indicator: "bg-gradient-to-r from-blue-500 to-purple-500",
                                label: "tracking-wider font-medium text-default-600",
                                value: "text-foreground/60",
                            }}
                            label={filteredRebuildCaptionStatus?.message || "Processing"}
                            value={filteredRebuildCaptionStatus?.current || 0}
                            maxValue={filteredRebuildCaptionStatus?.total || 100}
                            showValueLabel={true}
                        />
                    </div>
                )}

            </div>

            {/* media view modal components*/}
            <MediaView
                handleDownloadMediaFile={handleDownloadMediaFile}
                isOpen={isOpenMV}
                onClose={onCloseMV}
                isDownloading={isDownloading}
                modalData={videoData}
            />
        </>
    );
};

export default VG_CustomMediaCaption;