import axios from "axios";
import { ToastEffect } from "../functions/ToastEffect";
import consoleLog from "../functions/consoleLog";

/** verify subscription */
export const verifySubscription = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/check-subscription`, {
            email: email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #verifySubscription");
        consoleLog("API > authAPI.js > verifySubscription", error);
    }
};

/** get subscription data */
export const getSubscription = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/get-subscription`, {
            email: email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getSubscription");
        consoleLog("API > authAPI.js > getSubscription", error);
    }
};

/** cancel stripe subscription */
export const cancelSubscription = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/cancel-subscription`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #cancelSubscriptionThriveCart");
        consoleLog("API > authAPI.js > cancelSubscriptionThriveCart", error);
    }
};

/** continue stripe subscription */
export const continueSubscription = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/continue-subscription`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #continueSubscription");
        consoleLog("API > authAPI.js > continueSubscription", error);
    }
};

/** update subscription record */
export const updateSubscription = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/update-subscription`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateSubscription");
        consoleLog("API > authAPI.js > updateSubscription", error);
    }
};

/** validate subscription */
export const validateSubscription = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/validate-subscription`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #validateSubscription");
        consoleLog("API > authAPI.js > validateSubscription", error);
    }
};

/** send request and required data to the server to process purchase */
export const purchaseSelectedCreditProduct = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/product/purchase-credits`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #purchaseSelectedCreditProduct");
        consoleLog("API > authAPI.js > purchaseSelectedCreditProduct", error);
    }
};