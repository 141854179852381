// libraries
import React from "react";
import { Textarea } from "@nextui-org/react";

// custom css
import "./index.css";

// custom components
import consoleLog from "../../../../functions/consoleLog";

const ScriptInput = (props) => {
    const {
        promptKind,
        contentValueIdea,
        contentValueScript,
        handleFunctionIdea,
        handleFunctionScript,
        isFieldError
    } = props;

    return (
        <>
            <div
                className="text-gray-700 dark:text-gray-200 font-semibold font-sans" // relative
            >
                <Textarea
                    label={`Characters ${promptKind === "idea" ? (contentValueIdea?.length).toLocaleString() || 0 : (contentValueScript.length).toLocaleString() || 0}`}
                    // variant="bordered"
                    placeholder={promptKind === "idea" ? "Turn Your Idea Into a Video" : "Turn Your Script Into a Video"}
                    disableAnimation
                    disableAutosize
                    classNames={{
                        base: "my-1.5",
                        input: "resize-y min-h-[180px]",
                    }}
                    onChange={promptKind === "idea" ? handleFunctionIdea : handleFunctionScript}
                    value={promptKind === "idea" ? contentValueIdea || "" : contentValueScript || ""}
                />

                {/* <div className="absolute bottom-0 inset-y-0 right-2 flex items-end py-2.5 text-gray-500">
                    <p className="px-2">{contentValue?.length || 0}</p>
                </div> */}

            </div>
            {/* {(userIdea?.length > 50) && (
                <div className="relative flex items-center text-yellow-300 z-10">
                    <p className="px-2">Hey there! we noticed you exceeded the recommended character count! Please note this could affect the image quality!</p>
                </div>
            )} */}
        </>
    );
};

export default ScriptInput;
