import React, { forwardRef } from "react";

const ChatLoading = forwardRef((props, ref) => {
    const {
        isMobile,
        shinefy_img,
    } = props;

    return (
        <div ref={ref} className="w-full" data-testid="conversation-turn-3" style={{ '--avatar-color': '#19c37d' }}>
            <div className="px-4 py-2 justify-center text-base md:gap-6 m-auto">
                <div className="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] group final-completion">

                    <div className="flex-shrink-0 flex flex-col relative items-end">
                        <div>
                            <div className="pt-0.5">
                                <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                                    <div className="relative p-1 rounded-sm h-9 w-9 text-white flex items-center justify-center bg-purple-400">
                                        <img className="rounded-full" decoding="async" data-nimg="intrinsic" src={shinefy_img} alt="Robot" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative flex w-full flex-col text-base font-normal font-sans">

                        <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            <span className="font-semibold text-lg text-lime-400">ShinefyAI</span>
                            {/* <span className="font-semibold text-gray-400">
                                <small>{moment(data.created).format('dddd h:mma')}</small>
                            </span> */}
                        </div>

                        <div className="flex-col gap-1 md:gap-3">

                            <div className="flex flex-grow flex-col max-w-full">
                                <div className="min-h-[20px] flex flex-col items-start gap-3 whitespace-pre-wrap break-words overflow-x-auto" data-message-author-role="assistant" data-message-id="a545d514-401e-4dbd-9c86-ec8184645edd">
                                    <div className="markdown prose w-full break-words dark:prose-invert dark">
                                        <div className="typing mt-1">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
});

export default ChatLoading;