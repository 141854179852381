// libraries
import React, { useState } from "react";
import { Avatar, Button, Chip, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Tooltip, User, cn } from "@nextui-org/react";
import moment from "moment";

// custom components
import consoleLog from "../../../../functions/consoleLog";
import { formatNumberCount, truncateString } from "../../../../functions/general";
import { updateBotChatSessionStatus } from "../../../../API/server";

const LeftSideMenu = (props) => {

    // props
    const {
        botChatsData,
        setSelectedBotChat,
        selectedBotChat,
        setRefetchBotChats,
    } = props;

    // class for custom icons
    const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";

    // react useState
    const [isProcessing, setIsProcessing] = useState([]);

    // console log activity
    // consoleLog("botChatsData", botChatsData);
    // consoleLog("isProcessing", isProcessing);

    // handle selected menu
    const handleSelectedOption = async (key, data) => {

        // set selected data
        setSelectedBotChat(data);

        // check if not "isNewChat" and action is "read" do nothing
        if (key === "read" && !data?.isNewChat) return;

        // prepare required data
        const requiredData = {
            action: key,
            channelID: data?.channelID
        };

        // trigger loading mark (inserting "channelID" to the array)
        setIsProcessing(prevState => [...prevState, data?.channelID]);

        // execute process
        await updateBotChatSessionStatus(requiredData)
            .then((response) => {
                // refetch bot chat data
                setRefetchBotChats((val) => val + 1);
            });

        // disable loading mark (remove the "channelID" from the array)
        setIsProcessing(prevState => prevState.filter(id => id !== data?.channelID));

        // console log activity
        // consoleLog("selectedValue", key);
        // consoleLog("selectedData", data);
    };

    return (
        <div className="flex flex-col py-8 pl-6 pr-4 w-64 bg-white dark:bg-gray-800 flex-shrink-0 rounded-md">

            <div className="flex flex-row items-center justify-center h-12 w-full">
                <div className="flex items-center justify-center rounded-2xl h-10 w-10">
                    <span className="text-2xl">
                        {/* <i className="fa-solid fa-message" /> */}
                        <img src="logo192.png" />
                    </span>
                </div>
                <div className="ml-2 font-bold text-2xl dark:text-gray-50">Shinefy</div>
            </div>

            <div className="flex flex-col mt-8">

                <div className="flex flex-row items-center justify-between text-xs font-bold dark:text-gray-50">

                    {botChatsData?.length ? (
                        <>
                            <span className="font-bold">Active Conversations</span>
                            {/* <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">4</span> */}
                            <Chip
                                radius="full"
                                // color="danger"
                                variant="faded"
                                size="sm"
                            >
                                {formatNumberCount(botChatsData?.length)}
                            </Chip>
                        </>
                    ) : (<></>)}
                </div>

                <div className="flex flex-col space-y-1 mt-4 -mx-2 h-[60vh] overflow-y-auto">

                    {botChatsData?.map((data, idx) => {

                        // chat bot avatar
                        let chatBotavatar = "user.jpg";

                        if (data?.avatar) {
                            // get avatar source
                            chatBotavatar = `${process.env.REACT_APP_DISCORD_ENDPOINT}/avatars/${data?.userID}/${data?.avatar}.png`;
                        }

                        // find if channelID included from the process markings
                        const isIncluded = isProcessing?.some((id) => id === data?.channelID) ?? false;

                        // console log activity
                        // consoleLog("data", data);
                        // consoleLog("isIncluded", isIncluded);

                        return (
                            <div
                                key={idx}
                                className={`${selectedBotChat?.channelID === data?.channelID && "bg-gray-200 dark:bg-gray-700"} flex flex-row items-center hover:bg-gray-200 dark:hover:bg-gray-700 rounded-xl p-2`}
                                onClick={() => handleSelectedOption("read", data)}
                            >
                                {/* <Avatar showFallback src={chatBotavatar} />

                                <div className="grid grid-rows-2 grid-flow-col ml-1">
                                    <div className="ml-2 text-sm font-semibold dark:text-gray-50 float-start">
                                        {data?.globalName || data?.userName}
                                    </div>
                                    <small className="text-gray-500 dark:text-gray-400 text-xs">
                                        Test Chat 123
                                    </small>
                                </div> */}

                                <Tooltip offset={-4} isDisabled={(data?.globalName)?.length > 13 ? false : true} color="primary" content={data?.globalName}>
                                    <User
                                        as="button"
                                        name={truncateString(data?.globalName || data?.userName, 13)}
                                        description={truncateString(data?.dmsData[(data?.dmsData?.length - 1)]?.messageContent, 18) || "new message"}
                                        className="transition-transform font-semibold dark:text-gray-50 truncate"
                                        avatarProps={{
                                            src: chatBotavatar,
                                        }}
                                    />
                                </Tooltip>

                                {data?.isNewChat ? (
                                    <span className="flex items-center justify-center ml-auto leading-none text-xs font-bold">
                                        <Chip
                                            radius="full"
                                            variant="bordered"
                                            color="success"
                                            size="sm"
                                        >
                                            new
                                        </Chip>
                                    </span>
                                ) : (
                                    <span className="flex items-center justify-center ml-auto leading-none text-xs font-bold">
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    isLoading={isIncluded ? true : false}
                                                    isIconOnly
                                                    size="sm"
                                                    color="primary"
                                                    variant="light"
                                                    aria-label="Menu"
                                                    radius="sm"
                                                    startContent={
                                                        !isIncluded && (
                                                            <span className="text-xl">
                                                                <i className="fa-solid fa-ellipsis" />
                                                            </span>
                                                        )
                                                    }
                                                />
                                            </DropdownTrigger>
                                            <DropdownMenu
                                                variant="faded"
                                                aria-label="Dropdown menu with description"
                                                onAction={(key) => handleSelectedOption(key, data)}
                                            >
                                                <DropdownSection title="Actions" showDivider>
                                                    <DropdownItem
                                                        key="mark_unread"
                                                        // shortcut="⌘⇧E"
                                                        description="Mark as unread message"
                                                        startContent={
                                                            <span className={iconClasses}>
                                                                <i className="fa-solid fa-envelope" />
                                                            </span>
                                                        }
                                                    >
                                                        Mark as unread
                                                    </DropdownItem>
                                                </DropdownSection>
                                                <DropdownSection title="Danger zone">
                                                    <DropdownItem
                                                        key="delete"
                                                        className="text-danger"
                                                        color="danger"
                                                        // shortcut="⌘⇧D"
                                                        description="Delete this message"
                                                        startContent={
                                                            // <DeleteDocumentIcon className={cn(iconClasses, "text-danger")} />
                                                            <span className={cn(iconClasses, "text-danger")}>
                                                                <i className="fa-solid fa-trash-can" />
                                                            </span>
                                                        }
                                                    >
                                                        Delete message
                                                    </DropdownItem>
                                                </DropdownSection>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </span>
                                )}
                            </div>
                        );
                    })}

                </div>

                {/* <div className="flex flex-row items-center justify-between text-xs mt-6">
                    <span className="font-bold dark:text-gray-50">Archivied</span>
                    <Chip radius="full" color="default" size="sm" className="text-tiny font-bold">1</Chip>
                </div>

                <div className="flex flex-col space-y-1 mt-4 -mx-2">
                    <button className="flex flex-row items-center hover:bg-gray-200 dark:hover:bg-gray-700 rounded-xl p-2">
                        <Avatar showFallback src='https://images.unsplash.com/broken' />
                        <div className="ml-2 text-sm font-semibold dark:text-gray-50">Henry Boyd</div>
                    </button>
                </div> */}

            </div>
        </div>
    );
};

export default LeftSideMenu;