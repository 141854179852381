// libraries
import React from "react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import TrimmerEditor from "../Trimmer/TrimmerEditor";

const VideoTrimmer = (props) => {

    // props
    const {
        sourceMediaFile,
        imageData,
        setIsTrimmingVideo,
        isTrimmingVideo,
        setIsGeneratingImage,
        isGeneratingImage,
        setIsUploadingMedia,
        isUploadingMedia,
        setRefetchData,
        isOpen,
        onClose,
        videoAspecRatio,
    } = props;

    // create object URL
    let fileUrl = window?.URL?.createObjectURL(sourceMediaFile);

    // console log activity
    // consoleLog("videoAspecRatio", videoAspecRatio);

    // const handleCloseTrimmer = () => {
    //     setSourceMediaFile([]);
    //     setShowTrimmerModal(!showTrimmerModal);
    // };

    return (
        <Modal
            size="3xl"
            isOpen={isOpen}
            onClose={onClose}
            className={`dark:bg-gray-900 ${videoAspecRatio !== "Landscape" && "h-[54rem]"}`}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <code>{imageData?.number}. Slide Duration {Number(imageData?.duration).toFixed(2)}</code>
                        </ModalHeader>
                        <ModalBody>


                            <TrimmerEditor
                                videoUrl={fileUrl}
                                imageData={imageData}
                                sourceMediaFile={sourceMediaFile}
                                setIsTrimmingVideo={setIsTrimmingVideo}
                                isTrimmingVideo={isTrimmingVideo}
                                setIsGeneratingImage={setIsGeneratingImage}
                                isGeneratingImage={isGeneratingImage}
                                setIsUploadingMedia={setIsUploadingMedia}
                                isUploadingMedia={isUploadingMedia}
                                setRefetchData={setRefetchData}
                                videoAspecRatio={videoAspecRatio}
                            />


                        </ModalBody>
                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-between -mt-2">
                            {/* <Button
                                onPress={onClose}
                                color="danger"
                                variant="flat"
                                className="w-auto h-auto text-base"
                            >
                                Close
                            </Button> */}
                            {/* <Button
                                // onClick={handlesubmitReport}
                                color="primary"
                                className="h-full w-full lg:md:sm:w-auto py-3.5 px-7 text-base"
                            >
                                Submit Report
                            </Button> */}
                            {/* <Button onClick={() => handleDownloadMediaFile(mediaSrc, title, checkMediaKind(kind)?.extension, dataID)} color="primary">
                                <i className={`fa-solid fa-download ${isDownloading[dataID] && "fa-bounce"}`} /> Download
                            </Button> */}

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default VideoTrimmer;