import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const SingleOption = (props) => {

    const {
        optionName,
        optionIcon,
        optionLocation,
        optionType
    } = props;

    const navigate = useNavigate();

    const handleNavigateLocation = () => {
        if (optionType === "link") {
            window.open(optionLocation, '_blank');
        } else {
            navigate(optionLocation);
        }
    };

    return (
        <li>
            <span
                onClick={handleNavigateLocation}
                className="flex cursor-pointer items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
            >
                <span className="text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">
                    <i className={`${optionIcon} fa-lg`} />
                </span>
                <span className="ml-3" sidebar-toggle-item="">{optionName}</span>
            </span>
        </li>
    );
};

export const MultipleOption = (props) => {

    const {
        optionName,
        optionIcon,
        optiondropDown
    } = props;

    const navigate = useNavigate();
    const [showDropDown, setShowDropDown] = useState(false);

    const handleNavigateLocation = (location, type) => {
        if (type === "link") {
            window.open(location, '_blank');
        } else {
            navigate(location);
        }
    };

    const handleDropDown = () => {
        setShowDropDown(!showDropDown);
    };

    return (
        <li>
            <button
                onClick={handleDropDown}
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
                aria-controls="dropdown-layouts"
                data-collapse-toggle="dropdown-layouts"
            >
                <span className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">
                    <i className={`${optionIcon} fa-lg`} />
                </span>
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    {optionName}
                </span>
                <i className={`${showDropDown ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} fa-md`} />
            </button>

            <ul
                id="dropdown-layouts"
                className={`${!showDropDown && "hidden"} py-2 space-y-2`}
            >
                {optiondropDown?.map((data, idx) => {
                    return (
                        <li key={idx}>
                            <span
                                onClick={() => handleNavigateLocation(data?.location, data?.type)}
                                className="flex cursor-pointer items-center p-2 text-base text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
                            >
                                <i className={`${data?.icon} fa-md mr-2`} /> {data?.label}
                            </span>
                        </li>
                    );
                })}
            </ul >

        </li >
    );
};