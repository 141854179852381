// libraries
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, Tooltip, } from "@nextui-org/react";
import React, { useState } from "react";

// custom imports
import { estimateAudioLength } from "../../functions/general";
import consoleLog from "../../../../../functions/consoleLog";

const ModalView = (props) => {

    // props
    const {
        isOpen,
        onClose,
        onOpen,
        content,
        modalData,
        title,
        handleEditTitle,
        handleEditContext,
    } = props;

    // react components
    const [modalSize, setModalSize] = useState("5xl");

    // handle modal size switching
    const handleModalSize = () => {

        // set modal size
        if (modalSize === "full") {
            setModalSize("4xl");
        } else {
            setModalSize("full");
        }

        // trigger reopen modal
        onOpen();
    };

    return (
        <Modal
            size={modalSize}
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            Transcriber Editor
                        </ModalHeader>

                        <ModalBody>

                            {/* context title */}
                            <Input
                                onChange={(e) => handleEditTitle(e, modalData)}
                                value={title}
                                isClearable
                                size="md"
                                type="text"
                                label="Context title"
                                className="w-full text-lg"
                            />

                            {/* context area */}
                            <Textarea
                                onChange={(e) => handleEditContext(e, modalData)}
                                value={content}
                                minRows={20}
                                maxRows={20}
                                size="lg"
                                variant="faded"
                                label="Transcribed data view"
                                // description="Displays transcribed content."
                                className="w-full text-lg"
                                placeholder="Write your context here..."
                            />

                        </ModalBody>

                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-end -mt-4">

                            {/* Start */}
                            <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">

                                <div className="flex font-semibold font-sans space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600 bg-gray-100 rounded-lg dark:bg-gray-800">



                                    <div className="flex items-center justify-end space-x-1 text-gray-800">

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Characters"
                                        >
                                            <div
                                                type="button"
                                                className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                            >
                                                <i className="fa-solid fa-font mr-1" />
                                                <code>{(content?.length || 0)?.toLocaleString()}</code>
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Words"
                                        >
                                            <div
                                                type="button"
                                                className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                            >
                                                <i className="fa-solid fa-arrow-up-z-a mr-1" />
                                                <code>{(estimateAudioLength(content)?.count || 0)?.toLocaleString()}</code>
                                            </div>
                                        </Tooltip>

                                        {/* {promptKind !== "idea" && (
                                            <>
                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Estimated video length"
                                        >
                                            <div className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800">
                                                <i className="fa-regular fa-clock mr-1" />
                                                <code>{estimateAudioLength(content)?.length}</code>
                                            </div>
                                        </Tooltip>

                                        <div className="mt-1">

                                            <Tooltip
                                                color="primary"
                                                placement="top"
                                                showArrow={true}
                                                content={
                                                    <div className="px-1 py-2">
                                                        <div className="text-small font-bold">Transcriber Tip</div>
                                                        <ul className="list-disc pl-5 space-y-1 break-words w-[30rem]">
                                                            <li><b>Note:</b> The estimated video length may not be accurate. Sometimes, it might be slightly greater or less than the actual length.</li>
                                                        </ul>
                                                    </div>
                                                } >
                                                <span className="text-warning dark:text-yellow-300 text-xl dark:hover:text-yellow-400">
                                                    <i className="fa-solid fa-circle-question" />
                                                </span>
                                            </Tooltip>

                                        </div>
                                        </>
                                        )} */}

                                    </div>


                                    <div className="flex space-x-1">

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Save edited context"
                                        >
                                            <Button
                                                color="primary"
                                                variant="shadow"
                                                size="sm"
                                                className="text-md font-semibold"
                                                startContent={
                                                    <span className="text-lg">
                                                        <i className="fa-solid fa-floppy-disk mr-1" />
                                                        Save
                                                    </span>
                                                }
                                            // onClick={handleSaveContent}
                                            />
                                        </Tooltip>

                                        {/* <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Change modal size"
                                        >
                                            <Button
                                                color="primary"
                                                variant="shadow"
                                                size="sm"
                                                className="text-md font-semibold"
                                                startContent={
                                                    <span className="text-lg">
                                                        {modalSize === "full" ? (
                                                            <>
                                                                <i className="fa-solid fa-down-left-and-up-right-to-center mr-1" />
                                                                Windowed
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="fa-solid fa-up-right-and-down-left-from-center mr-1" />
                                                                Full-screen
                                                            </>
                                                        )}
                                                    </span>
                                                }
                                                // onClick={onClose}
                                                onClick={() => {
                                                    onClose();
                                                    handleModalSize();
                                                }}
                                            />
                                        </Tooltip> */}

                                    </div>

                                </div>
                            </div>

                            {/* End */}
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal >
    );
};

export default ModalView;