// libraries
import React from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, } from "@nextui-org/react";

// custom imports
import { columns, statusOptions, INITIAL_VISIBLE_COLUMNS } from "./data";
import consoleLog from "../../../../../functions/consoleLog";
import VT_TableCell1 from "./RightSideTable/VT_TableCell1";
import VT_TableCell2 from "./RightSideTable/VT_TableCell2";
import VT_TableCell3 from "./RightSideTable/VT_TableCell3";
import VT_TableCell4 from "./RightSideTable/VT_TableCell4";
import VT_TableCell5 from "./RightSideTable/VT_TableCell5";
import VT_TableTopContent from "./RightSideTable/VT_TableTopContent";
import VT_TableBottomContent from "./RightSideTable/VT_TableBottomContent";

const RightSideTable = (props) => {

    // props
    const {
        transcriberData,
        onOpenTMV,
        handleView,
        handleDeleteOnclick,
    } = props;

    // react components
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "age",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    // console log activity
    // consoleLog("transcriberData", transcriberData);

    // columns
    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    // capitalize function
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // filtering function
    const filteredItems = React.useMemo(() => {
        let filteredUsers = [...transcriberData];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((item) =>
                item.title?.toLowerCase()?.includes(filterValue?.toLowerCase()) || // filter by title
                item.task_id?.toLowerCase()?.includes(filterValue?.toLowerCase()), // filter by task_id
            );
        }
        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(statusFilter).includes(user.status),
            );
        }

        return filteredUsers;
    }, [transcriberData, filterValue, statusFilter]);

    // sorting function
    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    // sorting function
    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    // render cell
    const renderCell = React.useCallback((
        item,
        columnKey,
        idx,
    ) => {

        // cell value
        const cellValue = item[columnKey];

        // table columns
        switch (columnKey) {
            case "process":
                return (
                    <VT_TableCell1
                        item={item}
                        onOpenTMV={onOpenTMV}
                        handleView={handleView}
                    />
                );
            case "file_type":
                return (
                    <VT_TableCell2 item={item} />
                );
            case "cost":
                return (
                    <VT_TableCell3 item={item} />
                );
            case "status":
                return (
                    <VT_TableCell4 item={item} />
                );
            case "actions":
                return (
                    <VT_TableCell5
                        item={item}
                        onOpenTMV={onOpenTMV}
                        handleView={handleView}
                        handleDeleteOnclick={handleDeleteOnclick}
                    />
                );
            default:
                return cellValue;
        }
    }, []);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("");
        setPage(1);
    }, []);

    // top content render
    const topContent = React.useMemo(() => {
        return (
            <VT_TableTopContent
                filterValue={filterValue}
                onClear={onClear}
                onSearchChange={onSearchChange}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                statusOptions={statusOptions}
                capitalize={capitalize}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                columns={columns}
                transcriberData={transcriberData}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPage={rowsPerPage}
            />
        );
    }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        transcriberData.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <VT_TableBottomContent
                page={page}
                pages={pages}
                setPage={setPage}
                onPreviousPage={onPreviousPage}
                onNextPage={onNextPage}
            />
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <Table
            aria-label="Example table with custom cells, pagination and sorting"
            isHeaderSticky
            bottomContent={bottomContent}
            bottomContentPlacement="outside"
            // classNames={{
            //     wrapper: "max-h-[382px]",
            // }}
            selectedKeys={selectedKeys}
            selectionMode="multiple"
            sortDescriptor={sortDescriptor}
            topContent={topContent}
            topContentPlacement="outside"
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}
            selectionBehavior="replace"
            className="p-5 h-screen"
        >

            <TableHeader>
                {headerColumns?.map((column) => {
                    return (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    );
                })}
            </TableHeader>

            <TableBody
                // isLoading={isLoading ? true : false}
                loadingContent={<Spinner color="secondary" size="lg" label="Loading..." />}
                emptyContent={"No data found"}
            >
                {sortedItems?.map((item, idx) => {
                    return (
                        <TableRow key={item?._id}>
                            {(columnKey) =>
                                <TableCell>
                                    {renderCell(
                                        item,
                                        columnKey,
                                        idx,
                                    )}
                                </TableCell>}
                        </TableRow>
                    );
                })}

            </TableBody>

        </Table>
    );
};

export default RightSideTable;
