// libraries
import React, { useContext, useState } from "react";
import { Tabs, Tab, Chip, Card, CardBody } from "@nextui-org/react";

// custom components
import consoleLog from "../../functions/consoleLog";
import SendDMs from "./DiscordPage/SendDMs";
import BotChatBox from "./DiscordPage/BotChatBox";
import { cachedToolsConfiguration, formatNumberCount, getCachedToolsConfiguration } from "../../functions/general";
import AdminContext from "../../components/Admin/AdminContext";
import Welcomer from "./DiscordPage/Welcomer";


const DiscordPage = () => {

    // context method
    const {

        // admin discord
        botChatsData,

    } = useContext(AdminContext);

    // customized react useState
    const [selectedTab, setSelectedTab] = useState(
        getCachedToolsConfiguration("selectedDiscordPageTab", [])?.config || "chat_bot"
    );

    // react useState
    const [] = useState();

    // handle selected tab
    const handleSelectedTab = (selectedTab) => {

        // set selected value
        setSelectedTab(selectedTab);

        // cached config to localstorage
        cachedToolsConfiguration("selectedDiscordPageTab", selectedTab);

        // cosnole log activity
        // consoleLog("selectedTab", selectedTab);
    };

    return (
        <div className="flex w-full flex-col">
            <Tabs
                aria-label="Options"
                color="primary"
                variant="underlined"
                classNames={{
                    tabList: "gap-6 w-full relative rounded-none p-0 border-b border-divider",
                    cursor: "w-full bg-[#22d3ee]",
                    tab: "max-w-fit px-0 h-12",
                    tabContent: "group-data-[selected=true]:text-[#06b6d4]"
                }}
                // className="h-screen"
                selectedKey={selectedTab}
                onSelectionChange={handleSelectedTab}
            >
                {/* bot chat tab */}
                <Tab
                    key="chat_bot"
                    title={
                        <div className="flex items-center space-x-2">
                            <span className="text-lg">
                                <i className="fa-solid fa-comments" />
                            </span>
                            <span>Chat Bot</span>
                            {botChatsData?.length ? (
                                <Chip size="sm" variant="faded">{formatNumberCount(botChatsData?.length)}</Chip>
                            ) : (<></>)}
                        </div>
                    }
                >
                    <Card>
                        <CardBody className="bg-white antialiased dark:bg-gray-900">
                            <BotChatBox />
                        </CardBody>
                    </Card>
                </Tab>

                {/* DM broad  cast tab */}
                <Tab
                    key="send_dm"
                    title={
                        <div className="flex items-center space-x-2">
                            <span className="text-lg">
                                <i className="fa-solid fa-paper-plane" />
                            </span>
                            <span>Send DM</span>
                            {/* <Chip size="sm" variant="faded">9</Chip> */}
                        </div>
                    }
                >
                    <Card>
                        <CardBody className="bg-white antialiased dark:bg-gray-800">
                            <SendDMs />
                        </CardBody>
                    </Card>
                </Tab>

                {/* welcomer tab */}
                <Tab
                    key="welcomer"
                    title={
                        <div className="flex items-center space-x-2">
                            <span className="text-lg">
                                <i className="fa-solid fa-user-plus" />
                            </span>
                            <span>Welcomer</span>
                            {/* <Chip size="sm" variant="faded">1</Chip> */}
                        </div>
                    }
                >
                    <Card>
                        <CardBody className="bg-white antialiased dark:bg-gray-800">
                            <Welcomer />
                        </CardBody>
                    </Card>
                </Tab>

            </Tabs>
        </div>
    );
};

export default DiscordPage;