import React from "react";

const ProductSelection = (props) => {

    const {
        selectedData
    } = props;

    return (
        <>
            <div className="flex justify-between items-center text-lg font-sans font-semibold bg-gray-700 p-3 rounded-md dark:text-gray-300">
                Note that these credits will expire within the specified period, depending on the product you purchase or choose.
            </div>
            < div className="text-xl font-semibold justify-between items-center flex dark:text-gray-300" >
                <span className="mr-4">{selectedData?.selectedProductName}</span>
                <span className="font-semibold text-gray-500">{selectedData?.selectedProductPrice}</span>
            </div >
        </>
    );
};

export default ProductSelection;