// libraries
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Tooltip, Listbox, ListboxItem, Spinner, Input, Progress } from "@nextui-org/react";

// custom components
import advanceOptionJsonData from "../index.json";
import AO_Switch from "../UI/AO_Switch";
import { uploadOwnMediaFile } from "../../../API/toolsAPI";
import consoleLog from "../../../../../../functions/consoleLog";
import { ToastEffect } from "../../../../../../functions/ToastEffect";
import UserContext from "../../../../../../components/UserContext";
import { cachedToolsConfiguration, getCachedToolsConfiguration } from "../../../../../../functions/general";
import { deleteBGMData, renameBGMData } from "../../../../../../API/server";

const AO_tab5 = (props) => {

    // props
    const {
        onSelectBackgroundMusic,
        backgroundMusic,
        togglePlay,
        isPlaying,
        handleToggleCustomBGM,
        isCheckedCustomBGM,
        selectedKeys,
        setSelectedKeys,
        selectedValue,
        filteredOwnBGMData,
    } = props;

    // context method
    const {

        // Others
        userData,
        configData,

        // own BGM
        ownBGM,
        isFetchingOwnBGM,
        setRefetchOwnBGM,

    } = useContext(UserContext);

    // extracted values
    const email = userData?.UserInfo?.email;

    // react useState
    const [isOpenRename, setIsOpenRename] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [audio, setAudio] = useState(null);
    const [isPlayingOwnBGM, setIsPlayingOwnBGM] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // console log activity
    // consoleLog("ownBGM", ownBGM);
    // consoleLog("filteredOwnBGMData", filteredOwnBGMData);

    // handle file change upload media
    const handleFileChange = async (event) => {

        // selected file data
        const file = event.target.files[0];

        // extract file size
        const sizeInMB = file?.size / (1024 * 1024);

        // extract file extension
        const fileExtension = file?.name?.split(".").pop().toLowerCase();

        // check if file is not a video media
        if (fileExtension !== "mp3") {
            ToastEffect("error", "Media file is invalid.");
            return;
        }

        // check media file size
        if (sizeInMB > 10) {
            ToastEffect("error", "File size exceeds 10MB.");
            return;
        }

        // check limit up to 10 BGM only
        if (ownBGM?.data?.length >= 10) {
            ToastEffect("error", "You can only upload a maximum of 10 BGM tracks.");
            return;
        }

        if (file) {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('email', email);
            formData.append('refrence_id', "image2video");
            formData.append('kind', "own_BGM");
            formData.append('linked_to', "image2video");

            try {

                // call function upload
                const uploadResponse = await uploadOwnMediaFile(formData, (progress) => {
                    setUploadProgress(progress); // set progress value

                    // console log activity
                    consoleLog("progress", progress);
                });

                // extracted data from "uploadResponse"
                const uploadedLink = uploadResponse?.data?.file_link;

                // refetch own BGM data
                setRefetchOwnBGM((val) => val + 1);

                // set the new modefied data
                setUploadProgress(null); // clear progress

                // console log activity
                // consoleLog("formData", formData);

            } catch (error) {
                consoleLog("Error! #handleFileChange:", error);
            }
        }
    };

    // handle selected keys
    const handleSelectedKeys = (selected) => {

        // set Selected value
        setSelectedKeys(selected);

        // filter selected own BGM key
        const selectedBGMValue = Array.from(selected).join(", ");

        // cached config to localstorage
        cachedToolsConfiguration("selectedOwnBGM", selectedBGMValue);

        // console log activity
        consoleLog("filteredOwnBGMData", filteredOwnBGMData);
    };

    // function toggle open rename editor
    const handleToggleRenameEditor = () => {

        // toggle rename editor
        setIsOpenRename(!isOpenRename);
    };

    // Effect to update the audio source when filteredOwnBGMData changes
    useEffect(() => {

        // check if filtered data doe not exist do nothing
        if (!filteredOwnBGMData) { return; }

        // set audio source
        if (filteredOwnBGMData?.media_link) {
            const newAudio = new Audio(filteredOwnBGMData.media_link);
            setAudio(newAudio);
        }
    }, [filteredOwnBGMData]);

    // Function to play or stop BGM
    const handlePlayBGM = () => {
        if (!audio) return;

        if (isPlayingOwnBGM) {
            audio.pause();
            audio.currentTime = 0; // Reset the audio to start
        } else {
            audio.play().catch(error => {
                console.error("Error playing audio:", error);
            });
        }

        setIsPlayingOwnBGM(!isPlayingOwnBGM);

        // Console log activity
        consoleLog("filteredOwnBGMData", filteredOwnBGMData);
    };

    // handle on change new BGM name
    const handleNewBGMName = async (e) => {

        // check if filtered data doe not exist do nothing
        if (!filteredOwnBGMData) { return; }

        // new BGM name
        const newBGMName = e.target.value;

        // required data
        const requiredData = {
            email: email,
            dataID: filteredOwnBGMData?._id,
            newName: newBGMName,
        };

        // execute rename
        await renameBGMData(requiredData)
            .then((response) => {

                // refetch own BGM data
                setRefetchOwnBGM((val) => val + 1);

                // console log activity
                consoleLog("renameBGMData", response?.data);
            });

        // console loga ctivity
        consoleLog("newBGMName", newBGMName);
        consoleLog("selectedKeys", selectedKeys);
    };

    // handle delete BGM data
    const handleDeleteBGM = async () => {

        // check if filtered data doe not exist do nothing
        if (!filteredOwnBGMData) { return; }

        // required data
        const requiredData = {
            dataID: filteredOwnBGMData?._id,
        };

        // set deleting to true
        setIsDeleting(true);

        // execute rename
        await deleteBGMData(requiredData)
            .then((response) => {

                // refetch own BGM data
                setRefetchOwnBGM((val) => val + 1);
                setIsDeleting(false); // disable deleting

                // console log activity
                consoleLog("deleteBGMData", response?.data);
            });

    };

    return (
        <>
            <div>

                {/* <h3 className="fontKarla text-white">
                    Background Music
                </h3> */}

                <AO_Switch
                    label={isCheckedCustomBGM ? "Own Background Music" : "Background Music"}
                    isChecked={isCheckedCustomBGM}
                    handleToggle={handleToggleCustomBGM}
                    content={
                        <div className="px-1 py-2">
                            <div className="text-small font-bold">Background Music</div>
                            <ul className="list-disc pl-5 space-y-1 break-words w-[20rem]">
                                <li>Enabling this will give you the option to upload your own background music.</li>
                                <li>Please read the <a href={process.env.REACT_APP_DISCLAIMER} target="_blank" className="underline font-semibold">Disclaimer</a> first before uploading audio.</li>
                            </ul>
                        </div>
                    }
                />

                {/* <div className="flex items-center">
                    <select
                        id="backgroundMusic"
                        onChange={onSelectBackgroundMusic}
                        className="mt-2 font-semibold bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={backgroundMusic || ""}
                    >
                        <option className="font-semibold" value="" index={0}>No Background Music</option>
                        {advanceOptionJsonData?.backGroundMusic?.map((data, idx) => (
                            <option
                                className="font-semibold"
                                key={idx}
                                value={data.musicName}
                                index={data.id}
                            >
                                {data.musicName}
                            </option>
                        ))}
                    </select>
                    <div
                        title="Music preview"
                        className="relative ml-2 border rounded-md mt-1.5 px-4 py-[0.3rem] cursor-pointer w-10 flex items-center justify-center"
                        onClick={togglePlay}
                    >
                        <i className={`fa-solid ${isPlaying ? "fa-pause" : "fa-play"} text-lg text-blue-600`} />
                    </div>
                </div> */}

                {isCheckedCustomBGM ? (
                    <>
                        <div className="relative">

                            {uploadProgress && (
                                <div className="absolute rounded-md inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                                    <Progress
                                        label="Uploading File"
                                        size="sm"
                                        value={uploadProgress}
                                        maxValue={100}
                                        color="warning"
                                        // formatOptions={{ style: "currency", currency: "ARS" }}
                                        showValueLabel={true}
                                        className="w-full px-1 text-sm"
                                    />
                                </div>
                            )}

                            <input
                                type="file"
                                accept="audio/mp3"
                                onChange={(event) => handleFileChange(event)}
                                className="block mt-2 w-full text-base font-semibold text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            />
                        </div>

                        <div className="flex flex-col mt-2 gap-2">

                            <div className="relative">


                                {isOpenRename && (
                                    <div className="absolute rounded-md inset-0 flex items-center justify-center z-50 backdrop-blur-sm">

                                        <Button
                                            isIconOnly
                                            size="sm"
                                            variant="light"
                                            radius="full"
                                            onClick={() => setIsOpenRename(false)}
                                            className="absolute top-2 right-2 text-xl z-20 text-gray-700 dark:text-gray-200 bg-gray-300 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-700"
                                        >
                                            <i className="fa-solid fa-times" />
                                        </Button>

                                        <Input
                                            type="text"
                                            label="New BGM Name"
                                            placeholder="New BGM name here"
                                            labelPlacement="outside"
                                            startContent={
                                                <span className="text-2xl text-default-400 pointer-events-none flex-shrink-0">
                                                    <i className="fa-solid fa-music" />
                                                </span>
                                            }
                                            className="max-w-[15rem]"
                                            onChange={handleNewBGMName}
                                        />
                                    </div>
                                )}


                                {/* <div className="w-full border-small px-1 py-2 rounded-small border-default-200 dark:border-default-100"> */}
                                <Listbox
                                    aria-label="own BGM"
                                    variant="flat"
                                    color="warning"
                                    disallowEmptySelection
                                    selectionMode="single"
                                    selectedKeys={selectedKeys}
                                    onSelectionChange={handleSelectedKeys}
                                    className="bg-gray-700 rounded-small overflow-y-auto h-[11rem]"
                                >
                                    {ownBGM?.data?.map((data, idx) => {
                                        return (<ListboxItem key={data?._id}>{data?.media_name}</ListboxItem>);
                                    })}
                                </Listbox>
                            </div>
                            {/* </div> */}
                            {ownBGM?.data?.length > 0 && (
                                <div className="flex justify-between items-center">
                                    {/* <p className="text-small text-default-500">Selected BGM: {selectedValue}</p> */}
                                    <div className="space-x-1">
                                        {/* play button */}
                                        <Tooltip showArrow={true} color="primary" content="Play">
                                            <Button
                                                onClick={handlePlayBGM}
                                                size="sm"
                                                color="primary"
                                                radius="sm"
                                                isIconOnly
                                                startContent={
                                                    <span className="text-lg">
                                                        <i className={isPlayingOwnBGM ? "fa-solid fa-pause" : "fa-solid fa-play"} />
                                                    </span>
                                                }
                                                className="cursor-pointer text-sm font-semibold"
                                            />
                                        </Tooltip>

                                        {/* rename button */}
                                        <Tooltip showArrow={true} color="primary" content="Rename">
                                            <Button
                                                onClick={handleToggleRenameEditor}
                                                size="sm"
                                                color="secondary"
                                                radius="sm"
                                                isIconOnly
                                                startContent={
                                                    <span className="text-lg">
                                                        <i className="fa-solid fa-pen-to-square" />
                                                    </span>
                                                }
                                                className="cursor-pointer text-sm font-semibold"
                                            />
                                        </Tooltip>

                                        {/* delete button */}
                                        <Tooltip showArrow={true} color="primary" content="Delete">
                                            <Button
                                                onClick={handleDeleteBGM}
                                                isDisabled={isDeleting ? true : false}
                                                size="sm"
                                                color="danger"
                                                radius="sm"
                                                isIconOnly
                                                startContent={
                                                    <span className="text-lg">
                                                        <i className={isDeleting ? "fa-solid fa-circle-notch fa-spin" : "fa-solid fa-trash-can"} />
                                                    </span>
                                                }
                                                className="cursor-pointer text-sm font-semibold"
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="flex items-center">

                        <select
                            id="voiceModel"
                            onChange={onSelectBackgroundMusic}
                            className="mt-2 font-semibold p-4 bg-gray-100 dark:bg-[#27272A] border border-gray-600 text-gray-700 dark:text-gray-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                            value={backgroundMusic || ""}
                        >
                            <option className="font-semibold" value="" index={0}>No Background Music</option>
                            {advanceOptionJsonData?.backGroundMusic?.map((data, idx) => (
                                <option
                                    className="font-semibold"
                                    key={idx}
                                    value={data.musicName}
                                    index={data.id}
                                >
                                    {data.musicName}
                                </option>
                            ))}
                        </select>

                        <Tooltip
                            color="primary"
                            placement="top"
                            showArrow={true}
                            content="Preview Voice"
                        >
                            <Button
                                isIconOnly
                                color="warning"
                                variant="faded"
                                aria-label="Take a photo"
                                onClick={togglePlay}
                                className="relative ml-1 border rounded-lg mt-1.5 px-7 py-[1.5rem] cursor-pointer w-10 flex items-center justify-center"
                            >
                                <i className={`fa-solid ${isPlaying ? "fa-pause" : "fa-play"} text-lg text-blue-600`} />
                            </Button>
                        </Tooltip>

                    </div>
                )}


            </div>

            {/* <div className="sectionDivider pb-[0px]">
                <hr />
            </div> */}

        </>
    );
};

export default AO_tab5;