// libraries
import moment from "moment";
import React, { useState } from "react";
import { Tooltip } from "@nextui-org/react";

// custom components
import { removeNotifData, updateNotifStatus } from "../../pages/shinefy/Tools/API/toolsAPI";
import { addMarkerToData, removeDataFromArray, removeMarkedData, updateArrayData } from "../../functions/general";
import consoleLog from "../../functions/consoleLog";
import { buttonLabels } from "../../functions/buttonLabels";

const NotifList = (props) => {

    // props
    const {
        notifData,
        setNotifData,
        setViewNotifContent,
        // setTotalSumNotifData,
    } = props;

    // react props
    const [isRemovingNotif, setIsRemovingNotif] = useState({});

    // console log activity
    // consoleLog("isRemovingNotif", isRemovingNotif);

    // function that will limit character display
    const limitDisplayCharacter = (text) => {

        // variable
        const maxChar = 150;

        // check for length
        if (text.length > maxChar) {
            // limit the display
            return text.substring(0, maxChar) + ". . .";
        } else {
            // return the full text
            return text;
        }
    };

    // handle view notification content
    const handleViewNotifContent = async (data) => {

        // set data for global use
        setViewNotifContent(data);

        // required data
        const requiredData = {
            notifID: data?._id,
            auth_client: process.env.REACT_APP_AUTH,
        };


        // only call the "updateNotifStatus" function if "isRead" is false
        if (!data?.isRead) {
            // call function that updates the notification status
            await updateNotifStatus(requiredData)
                .then(async (response) => {
                    // call the function that will modify and update the data from array
                    const newData = await updateArrayData(notifData, data?._id, "isRead", true);
                    setNotifData(newData); // set the new modefied data
                    // setTotalSumNotifData(oldVal => oldVal - 1); // deduct the active notification counts
                });
        }

        // console log activity
        // consoleLog("data", data);
    };

    // handle delete notification
    const handleDeleteNotification = async (data) => {

        // extracted dataID
        const dataID = data?._id;

        // required data
        const requiredData = {
            dataID: dataID,
            auth_client: process.env.REACT_APP_AUTH,
        };

        // insert new dataID to mark process as removing
        await addMarkerToData(setIsRemovingNotif, dataID);

        // update data from database and remove data base from dataID
        await removeNotifData(requiredData)
            .then(async (response) => {
                // call the remove function to remove the data from the array
                const newData = await removeDataFromArray(notifData, dataID);
                setNotifData(newData); // set the new modefied data

                // remove dataID to mark the process is done 
                await removeMarkedData(setIsRemovingNotif, dataID);

            });

        // console log activity
        // consoleLog("data", data);
        // consoleLog("dataID", dataID);
    };

    return (
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
            <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Notifications
                    <span className="ml-3 inline-flex justify-center items-center px-2 rounded-md text-gray-10 font-sans text-sm font-semibold bg-gray-400 dark:bg-gray-500">
                        {Number(notifData?.length).toLocaleString()}
                    </span>
                </h3>
                {/* <span className="inline-flex items-center p-2 text-sm font-medium rounded-lg text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
                    View all
                </span> */}
            </div>
            <div className="overflow-y-auto max-h-[80vh] px-2">
                <ol className="relative border-l border-gray-200 dark:border-gray-700">

                    {notifData?.map((data, idx) => {

                        return (
                            <li
                                key={idx}
                                className="mb-10 ml-4"
                            >
                                <div className={`${!data?.isRead ? "bg-gray-500 dark:bg-gray-400" : "bg-gray-300 dark:bg-gray-700"} absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-800`} />
                                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                    {moment(data?.createdAt).fromNow()}
                                </time>
                                <div className="flex items-center justify-between">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                        {data?.notif_title}
                                    </h3>
                                    <Tooltip
                                        color="primary"
                                        showArrow={true}
                                        content={buttonLabels("deleteNotif")}
                                    >
                                        <button
                                            disabled={isRemovingNotif[data?._id] ? true : false}
                                            onClick={() => handleDeleteNotification(data)}
                                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-primary-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                                        >
                                            {isRemovingNotif[data?._id] ? (
                                                <i className="fa-solid fa-spinner fa-spin-pulse" />
                                            ) : (
                                                <i className="fa-solid fa-trash-can" />
                                            )}

                                        </button>
                                    </Tooltip>
                                </div>
                                <p className="break-words whitespace-pre-line mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                                    {limitDisplayCharacter(data?.partial_content ? data?.partial_content : data?.main_content)}
                                </p>
                                <button
                                    onClick={() => handleViewNotifContent(data)}
                                    className="inline-flex items-center text-xs font-medium hover:underline text-primary-700 sm:text-sm dark:text-primary-500"
                                >
                                    View Notification <i className="fa-solid fa-arrow-right-long ml-2" />
                                </button>
                                {/* <button className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-primary-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">
                                Read more <i className="fa-solid fa-arrow-right-long ml-2" />
                            </button> */}
                            </li>
                        );
                    })}
                </ol>
            </div>
        </div>
    );
};

export default NotifList;