// libraries
import React, { useState } from "react";

// media imports
import shinefy_img from "../../constant/images/Shinefy.png";
import profile_image from "../../constant/images/profile_image.jpg";

// custom components
import Notifications from "./TopNav/Notifications";
import { countDataFromArray } from "../../functions/general";

const TopNav = (props) => {

    const {
        setToggleShowSideNav,
        toggleShowSideNav,
        userData,
        logout,
        adminNotifications,
    } = props;

    // extracted data from userData
    const email = userData?.UserInfo?.email;

    // react components
    const [showNotification, setShowNotification] = useState(false);
    const [showAccountMenu, setShowAccountMenu] = useState(false);


    // call fnction that counts the data conditionally
    const dataCount = countDataFromArray(
        adminNotifications,
        "isRead",
        false
    );

    // toggle show notification
    const handleShowNotification = () => {
        setShowNotification(!showNotification);
        setShowAccountMenu(false);
    };

    // toggle show menu
    const handleAccountMenu = () => {
        setShowAccountMenu(!showAccountMenu);
        setShowNotification(false);
    };

    // toggle show side nav on mobile mode
    const handleShowSideNav = () => {
        setToggleShowSideNav(!toggleShowSideNav);
    };

    return (
        <nav className="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            {/* <nav className="fixed z-30 w-full bg-white dark:bg-gray-800"> */}
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        <button
                            onClick={handleShowSideNav}
                            id="toggleSidebarMobile"
                            aria-expanded="true"
                            aria-controls="sidebar"
                            className="p-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <i className={`${toggleShowSideNav ? "fa-solid fa-x fa-xl " : "fa-solid fa-bars fa-xl"} px-1`} />
                        </button>
                        <span className="flex ml-2 md:mr-24">
                            <img decoding="async" data-nimg="intrinsic" src={shinefy_img} className="w-9 h-auto mr-2" alt="Image" />
                            <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                                Shinefy
                            </span>
                        </span>

                        {/* search form web view */}
                        {/* <form action="#" method="GET" className="hidden lg:block lg:pl-3.5">
                            <label htmlFor="topbar-search" className="sr-only">Search</label>
                            <div className="relative mt-1 lg:w-96">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <i className="fa-solid fa-magnifying-glass fa-lg text-gray-500 dark:text-gray-400" />
                                </div>
                                <input type="text" name="email" id="topbar-search"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Search" />
                            </div>
                        </form> */}

                    </div>

                    <div className="flex relative items-center space-x-2">

                        <div className="hidden mr-3 -mb-1 sm:block"><span></span></div>

                        {/* search form mobile view */}
                        {/* <button id="toggleSidebarMobileSearch" type="button"
                            className="p-2 text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="sr-only">Search</span>
                            <i className="fa-solid fa-magnifying-glass fa-lg text-gray-500 dark:text-gray-400" />
                        </button> */}

                        {/* notification bell */}
                        <button
                            onClick={handleShowNotification}
                            type="button"
                            data-dropdown-toggle="notification-dropdown"
                            className={`${showNotification && "bg-gray-700"} relative p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700`}
                        >
                            <span className="sr-only">View notifications</span>
                            <i className="fa-solid fa-bell fa-lg" />

                            {dataCount > 0 && (
                                <span className="flex absolute -top-1 -right-1 justify-center items-center h-5 px-1 w-auto min-w-5 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-700">
                                    {(dataCount)?.toLocaleString()}
                                </span>
                            )}
                        </button>

                        {/* notification drop down component */}
                        <div
                            className={`${!showNotification && "hidden"} z-30 w-[16.3rem] lg:w-[21.3rem] -left-[5.8rem] md:-left-[5rem] lg:-left-[7.9rem] my-4 overflow-hidden text-base list-none bg-white divide-y divide-gray-100 rounded shadow-lg dark:divide-gray-600 dark:bg-gray-700`}
                            id="notification-dropdown"
                            data-popper-placement="bottom"
                            style={{ position: 'absolute', top: '100%', transform: 'translateX(-33%)' }}
                        >
                            <Notifications
                                shinefy_img={shinefy_img}
                                adminNotifications={adminNotifications}
                            />
                        </div>

                        {/* Dark and light mode */}
                        {/* <button
                            id="theme-toggle"
                            data-tooltip-target="tooltip-toggle"
                            type="button"
                            className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
                        >
                            <i className="fa-solid fa-moon fa-lg" />
                            <i className="fa-solid fa-sun fa-lg" />
                        </button>
                        <div
                            id="tooltip-toggle"
                            role="tooltip"
                            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
                            data-popper-placement="bottom"
                            style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(1557.69px, 63.4615px)' }}
                        >
                            Toggle dark mode
                            <div
                                className="tooltip-arrow"
                                data-popper-arrow=""
                                style={{ position: 'absolute', left: '0px', transform: 'translate(68.0769px, 0px)' }}
                            >
                            </div>
                        </div> */}

                        {/* Profile menu */}
                        <div className="flex items-center ml-3">
                            <div>
                                <button
                                    onClick={handleAccountMenu}
                                    type="button"
                                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                    id="user-menu-button-2"
                                    aria-expanded="false"
                                    data-dropdown-toggle="dropdown-2"
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <img decoding="async" data-nimg="intrinsic" src={profile_image} className="w-8 h-8 rounded-full" alt="Image" />
                                </button>
                            </div>

                            <div
                                className={`${!showAccountMenu && "hidden"} z-20 my-4 -left-[2rem] md:-left-[5rem] lg:-left-[0.7rem] text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600`}
                                id="dropdown-2"
                                data-popper-placement="bottom"
                                style={{ position: 'absolute', top: '100%', transform: 'translateX(-33%)' }}
                            >
                                <div className="px-4 py-3" role="none">
                                    {/* <p className="text-sm text-gray-900 dark:text-white" role="none">
                                        Shinefy
                                    </p> */}
                                    <p className="text-sm font-medium text-gray-900 truncate w-[9.3rem] dark:text-gray-300" role="none">
                                        {email}
                                    </p>
                                </div>
                                <ul className="py-1 cursor-pointer" role="none">
                                    {/* <li>
                                        <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <i className="fa-solid fa-gauge fa-lg" />  Dashboard
                                        </span>
                                    </li> */}
                                    <li>
                                        <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <i className="fa-solid fa-gear fa-lg" /> Settings
                                        </span>
                                    </li>
                                    <li>
                                        <span onClick={logout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <i className="fa-solid fa-right-from-bracket fa-lg" />  Sign out
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default TopNav;