// libraries
import { Switch } from "@nextui-org/react";

// custom components
import React, { useContext, useEffect, useState } from "react";
import { updateUserConfig } from "../../API/account";
import UserContext from "../UserContext";
import { getConfig, saveConfig } from "../../pages/shinefy/Tools/API/configDBAPI";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import { cachedToolsConfiguration, getCachedToolsConfiguration } from "../../functions/general";

const AdvanceSettings = () => {

    const {

        // Others
        userData,
        configData,

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // variables
    const email = userData?.UserInfo?.email;
    const pathname = "/general-purpose";

    // use state
    const [ownElevenLabsAPIKey, setOwnElevenLabsAPIKey] = useState(
        // getCachedToolsConfiguration("ownElevenLabsAPI", configData)?.config === "true"
        true // disabled
    );

    // handle advance option for
    const handleElevenLabsOwnAPI = (e) => {

        // toggle value and variables
        const contentValue = e.target.checked; // Extracted value
        const kind = "ownElevenLabsAPI";

        // toggle button
        setOwnElevenLabsAPIKey(contentValue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, contentValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, contentValue);
    };

    return (
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
            <div className="flow-root">
                <h3 className="text-xl font-semibold dark:text-white">Advance Settings</h3>
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">You can set up Themesberg to get notifications</p> */}
                <div className="divide-y divide-gray-200 dark:divide-gray-700">

                    {/* <div className="flex items-center justify-between py-4">
                        <div className="flex flex-col flex-grow">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">Accuracy
                                <span className={`inline-flex items-center justify-center w-[0.65rem] h-[0.65rem] ml-2 bg-${advanceOption?.accuracy ? "green" : "red"}-500 rounded-full`} />
                                <span className="border border-gray-700 rounded-md p-0.5 ml-2 text-[0.7rem] bg-red-400">Disabled</span>
                            </div>
                            <div className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                                Use own <a href="https://www.thenextleg.io" target="_blank" className="text-blue-500 hover:underline">Midjourney Auth token</a>
                            </div>
                        </div>
                        <label htmlFor="accuracy" className="relative flex items-center cursor-pointer">
                            <input
                                disabled // disable accuracy own API
                                // onChange={(e) => handleAdvanceOption(e, "accuracy")}
                                // checked={advanceOption?.accuracy || false}
                                type="checkbox"
                                id="accuracy"
                                className="sr-only"
                            />
                            <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600" />
                        </label>
                    </div> */}

                    {/* <div className="flex items-center justify-between py-4">
                        <div className="flex flex-col flex-grow space-y-1.5">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">Avatar
                                <span className={`inline-flex items-center justify-center w-[0.65rem] h-[0.65rem] ml-2 bg-${isCheckedAvatar ? "green" : "red"}-500 rounded-full`} />
                                <span className="border border-gray-700 rounded-md p-0.5 ml-2 text-[0.7rem] bg-red-400">Disabled</span>
                            </div>
                            <div className="flex justify-between items-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                                <span>
                                    Enable Avatar
                                </span>
                                <label className="inline-flex relative items-center cursor-pointer">
                                    <input
                                        disabled // disable avatar
                                        // onChange={handleEnableDisableAvatar}
                                        // checked={isCheckedAvatar}
                                        type="checkbox"
                                        id="avatar"
                                        className="sr-only peer"
                                    />
                                    <div className="block bg-gray-200 dark:bg-gray-700 rounded-full w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-blue-600 transition-all duration-300 ease-in-out" />
                                    <div className="dot absolute bg-white w-4 h-4 rounded-full transition transform peer-checked:translate-x-4 left-0.5 top-0.5" />
                                </label>
                            </div>
                            <div className="flex justify-between items-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                                <span>
                                    Use own <a href="https://www.heygen.com" target="_blank" className="text-blue-500 hover:underline">HeyGen API Key</a>
                                    <span className="border text-gray-900 dark:text-white border-gray-700 rounded-md p-0.5 ml-2 text-[0.7rem] bg-red-400">Disabled</span>
                                </span>
                                <label className="inline-flex relative items-center cursor-pointer">
                                    <input
                                        disabled // disable avatar default API
                                        // onChange={(e) => handleAdvanceOption(e, "avatar")}
                                        // checked={advanceOption?.avatar || false}
                                        checked
                                        type="checkbox"
                                        id="avatar"
                                        className="sr-only peer"
                                    />
                                    <div className="block bg-gray-200 dark:bg-gray-700 rounded-full w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-blue-600 transition-all duration-300 ease-in-out" />
                                    <div className="dot absolute bg-white w-4 h-4 rounded-full transition transform peer-checked:translate-x-4 left-0.5 top-0.5" />
                                </label>
                            </div>
                        </div>
                        <label htmlFor="avatar" className="relative flex items-center cursor-pointer">
                            <input
                                disabled // disable avatar default API
                                // onChange={(e) => handleAdvanceOption(e, "avatar")}
                                // checked={advanceOption?.avatar || false}
                                type="checkbox"
                                id="avatar"
                                className="sr-only"
                            />
                            <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600" />
                        </label>
                    </div> */}

                    <div className="flex items-center justify-between py-4">
                        <div className="flex flex-col flex-grow">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">Voice
                                <span className={`inline-flex items-center justify-center w-[0.65rem] h-[0.65rem] ml-2 bg-${ownElevenLabsAPIKey ? "green" : "red"}-500 rounded-full`} />
                                <span className="border border-gray-700 rounded-md p-0.5 ml-2 text-[0.7rem] bg-red-400">Disabled</span>
                            </div>
                            <div className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                                Use own <a href="https://elevenlabs.io" target="_blank" className="text-blue-500 hover:underline">ElevenLabs API Key</a>
                            </div>
                        </div>
                        <Switch
                            isDisabled
                            // defaultSelected aria-label="Automatic updates"
                            onChange={handleElevenLabsOwnAPI}
                            isSelected={ownElevenLabsAPIKey}
                        />
                        {/* <label htmlFor="voice" className="relative flex items-center cursor-pointer">
                            <input
                                onChange={handleElevenLabsOwnAPI}
                                checked={ownElevenLabsAPIKey}
                                type="checkbox"
                                id="voice"
                                className="sr-only"
                            />
                            <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600" />
                        </label> */}
                    </div>

                </div>

                {/* <div className="mt-6">
                    <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save all</button>
                </div> */}

            </div>
        </div>
    );
};

export default AdvanceSettings;