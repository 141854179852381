export const SVGtxt2image = () => {
  return (
    <svg className="mr-3" width="22" height="23" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg ">
      <path d="M1 5.95557V3.95557C1 3.42513 1.21071 2.91643 1.58579 2.54135C1.96086 2.16628 2.46957 1.95557 3 1.95557H5M1 13.9556V15.9556C1 16.486 1.21071 16.9947 1.58579 17.3698C1.96086 17.7449 2.46957 17.9556 3 17.9556H5M13 1.95557H15C15.5304 1.95557 16.0391 2.16628 16.4142 2.54135C16.7893 2.91643 17 3.42513 17 3.95557V5.95557M13 17.9556H15C15.5304 17.9556 16.0391 17.7449 16.4142 17.3698C16.7893 16.9947 17 16.486 17 15.9556V13.9556M6 7.95557H6.01M12 7.95557H12.01M6.5 12.9556C6.82588 13.2882 7.21485 13.5524 7.64413 13.7328C8.07341 13.9132 8.53436 14.0061 9 14.0061C9.46564 14.0061 9.92659 13.9132 10.3559 13.7328C10.7852 13.5524 11.1741 13.2882 11.5 12.9556" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const SVGVideoEnhancer = () => {
  return (
    <svg className="mr-2" width="24" height="25" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3333 6.31271V3.88414C17.3333 3.24004 17.0875 2.62232 16.6499 2.16688C16.2123 1.71143 15.6188 1.45557 15 1.45557H3.33333C2.71449 1.45557 2.121 1.71143 1.68342 2.16688C1.24583 2.62232 1 3.24004 1 3.88414V11.1699C1 11.8139 1.24583 12.4317 1.68342 12.8871C2.121 13.3426 2.71449 13.5984 3.33333 13.5984H5.66667M8 18.4556H19.6667C20.2855 18.4556 20.879 18.1997 21.3166 17.7443C21.7542 17.2888 22 16.6711 22 16.027V8.74128C22 8.09718 21.7542 7.47947 21.3166 7.02402C20.879 6.56858 20.2855 6.31271 19.6667 6.31271H8C7.38116 6.31271 6.78767 6.56858 6.35008 7.02402C5.9125 7.47947 5.66667 8.09718 5.66667 8.74128V16.027C5.66667 16.6711 5.9125 17.2888 6.35008 17.7443C6.78767 18.1997 7.38116 18.4556 8 18.4556ZM16.1667 12.3841C16.1667 13.0282 15.9208 13.646 15.4832 14.1014C15.0457 14.5568 14.4522 14.8127 13.8333 14.8127C13.2145 14.8127 12.621 14.5568 12.1834 14.1014C11.7458 13.646 11.5 13.0282 11.5 12.3841C11.5 11.74 11.7458 11.1223 12.1834 10.6669C12.621 10.2114 13.2145 9.95557 13.8333 9.95557C14.4522 9.95557 15.0457 10.2114 15.4832 10.6669C15.9208 11.1223 16.1667 11.74 16.1667 12.3841Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};