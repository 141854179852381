// libraries
import React from "react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, cn } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import { containsUrl } from "../../functions/general";
import { addMarkerToData, downloadSRT, removeMarkedData } from "../../../../../functions/general";

const VG_TableMenu = (props) => {

    // props
    const {
        idx,
        data,
        handlePlayVideo,
        handleDownloadMediaFile,
        handleRetryProcess,
        handleReportVideo,
        handleCustomMedia,
        handleDeleteFunction,
    } = props;

    // react components
    const [isProcessing, setIsProcessing] = React.useState({});

    // console log activity
    // consoleLog("data", data);
    // consoleLog("isProcessing", isProcessing);

    // custom icon class
    const iconClasses = "text-2xl text-default-500 pointer-events-none flex-shrink-0";
    const videoSrc = data?.content_url;
    const title = data?.title ? data?.title : data?.task_id;
    const dataID = data?._id;
    const captionStatus = data?.caption?.caption_status;
    const captionData = data?.caption;
    const aspectRatio = data?.aspect_ratio;

    // handle download media from table menu
    const handleDownloadMediaFromMenu = async () => {

        // call marker function and insert data
        await addMarkerToData(setIsProcessing, dataID);

        // call the download function
        await handleDownloadMediaFile(videoSrc, title, "mp4", dataID);

        // call marker to remove marked data
        await removeMarkedData(setIsProcessing, dataID);
    };

    // handle retry process from menu
    const handleRetryProcessFromMenu = async () => {

        // call marker function and insert data
        await addMarkerToData(setIsProcessing, dataID);

        // call the retry function
        await handleRetryProcess(data);

        // call marker to remove marked data
        await removeMarkedData(setIsProcessing, dataID);
    };

    // handle download caption file
    const handleDownloadCaptionfile = async () => {

        // variable data
        let captionType;
        let isWords;

        // download SRT Marker
        const markedID = `${dataID}_srt_file`;

        // call marker function and insert data
        await addMarkerToData(setIsProcessing, markedID);

        // conditional
        if (aspectRatio === "portrait") {
            captionType = "words";
            isWords = true;
        } else {
            captionType = "segments";
            isWords = false;
        }

        // call the download caption file function
        await downloadSRT(captionData?.[`caption_${captionType}`], "caption.srt", isWords);

        // call marker to remove marked data
        await removeMarkedData(setIsProcessing, markedID);
    };

    return (
        <Dropdown>
            <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                    <i className="fa-solid fa-ellipsis-vertical" />
                </Button>
            </DropdownTrigger>
            <DropdownMenu
                closeOnSelect={false} //  when the menu item is selected close | stayed open
                variant="faded"
                aria-label="Dropdown menu with description"
            >

                <DropdownSection title="Actions" showDivider>

                    <DropdownItem
                        key="preview"
                        // shortcut="⌘N"
                        description="Play or view media"
                        startContent={<i className={`fa-solid fa-film ${iconClasses}`} />}
                        className={containsUrl(data?.content_url) ? "show" : "hidden"} // This option is available only for content that has URL
                        onClick={() => handlePlayVideo(data, idx)}
                    >
                        Preview Media
                    </DropdownItem>
                    <DropdownItem
                        key="download"
                        // shortcut="⌘C"
                        description="Download media file"
                        startContent={<i className={`fa-solid fa-download ${isProcessing[dataID] && "fa-bounce"} ${iconClasses}`} />}
                        className={containsUrl(data?.content_url) ? "show" : "hidden"} // This option is available only for content that has URL
                        onClick={handleDownloadMediaFromMenu}
                    >
                        Download Media
                    </DropdownItem>

                    <DropdownItem
                        key="srtFile"
                        // shortcut="⌘C"
                        description="Download .srt file"
                        startContent={<i className={`fa-solid fa-download ${isProcessing[`${dataID}_srt_file`] && "fa-bounce"} ${iconClasses}`} />}
                        className={captionStatus ? "show" : "hidden"} // This option is available only for content that has URL
                        onClick={handleDownloadCaptionfile}
                    >
                        Download .SRT
                    </DropdownItem>

                    <DropdownItem
                        key="retry"
                        // shortcut="⌘⇧E"
                        description="Retry failed process"
                        startContent={<i className={`${isProcessing[dataID] ? "fa-solid fa-arrows-rotate fa-spin" : "fa-solid fa-repeat"} ${iconClasses}`} />}
                        className={data?.status === "failed" && data?.content_url !== "accuracy_video" ? "show" : "hidden"} // This option is available only for failed process and not "accuracy_video"
                        onClick={handleRetryProcessFromMenu}
                    >
                        Retry Process
                    </DropdownItem>

                    <DropdownItem
                        key="customize"
                        // shortcut="⌘⇧E"
                        description="Allows you to customize"
                        startContent={<i className={`fa-solid fa-pen-to-square ${iconClasses}`} />}
                        className={data?.status === "failed" || data?.status === "error" ? "hidden" : data?.content_url === "accuracy_video" ? "hidden" : "show"} // This option is available only for completed and customize video
                        onClick={() => handleCustomMedia(data)}
                    >
                        Customize Video
                    </DropdownItem>

                    <DropdownItem
                        key="report"
                        // shortcut="⌘⇧E"
                        description="Report the problem"
                        startContent={<i className={`fa-solid fa-flag ${iconClasses}`} />}
                        className={data?.status === "failed" || data?.status === "error" ? "show" : "hidden"}
                        onClick={() => handleReportVideo(data)}
                    >
                        Report Problem
                    </DropdownItem>

                </DropdownSection>

                <DropdownSection title="Danger zone">
                    <DropdownItem
                        key="delete"
                        className="text-danger"
                        color="danger"
                        // shortcut="⌘⇧D"
                        description="Permanently delete data"
                        startContent={<i className={`fa-solid fa-trash ${iconClasses}`} />}
                        onClick={() => handleDeleteFunction(data)}
                    >
                        Remove Data
                    </DropdownItem>
                </DropdownSection>
            </DropdownMenu>
        </Dropdown>
    );
};

export default VG_TableMenu;