// libraries
import React, { useContext } from "react";

// custom imports
import { progressstyle } from "../data";
import UserContext from "../../../../../../components/UserContext";

const VT_TableCell4 = (props) => {

    // props
    const {
        item,
    } = props;

    // react context
    const {

        // others
        toolsDataStatus,

    } = useContext(UserContext);

    // filter data by task_id
    const filtered_generateVideoStatus = toolsDataStatus.find((status) => status?.tools_status?.task_id === item?.task_id);

    return (
        <div>

            {filtered_generateVideoStatus?.tools_status?.task_id === item?.task_id && filtered_generateVideoStatus?.tools_status?.status === "processing" ? (
                <span className={progressstyle.processsing_v2} >
                    <span className="relative capitalize text-center px-3 w-auto py-0.5 transition-all ease-in duration-75 bg-white dark:bg-gray-800 rounded-md">
                        {filtered_generateVideoStatus?.tools_status?.message}
                    </span>
                </span>
            ) : (
                <span className={item?.report_id ? progressstyle.status : item?.status === "completed" ? progressstyle.complete_v2 : item?.status === "processing" ? progressstyle.processsing_v2 : progressstyle.fail_v2} >
                    <span className="relative capitalize text-center px-3 w-[6rem] py-0.5 transition-all ease-in duration-75 bg-white dark:bg-gray-800 rounded-md">
                        {item?.report_id ? "Reported" : item?.status}
                    </span>
                </span>
            )}
        </div>
    );
};

export default VT_TableCell4;