// libraries
import React from "react";
import { Tabs, Tab, Button, Select, SelectItem } from "@nextui-org/react";

// custom imports
import Tab_DropZone from "./LeftSideMenu/Tab_DropZone";
import Tab_LinkGrabber from "./LeftSideMenu/Tab_LinkGrabber";
import AO_Switch from "../../Components/AdvancedOptions/UI/AO_Switch";
import { formatNumberValue } from "../../../../../functions/general";

const LeftSideMenu = (props) => {

    // props
    const {
        getRootProps,
        transcribing,
        transcribePrices,
        checkingLink,
        inputKey,
        getInputProps,
        acceptedFiles,
        sourceLink,
        files,
        uploadProgress,
        creditCalculation,
        transcriberEnhancer,
        handleTranscriberEnhancer,
        config_enhancer,
        transcriberLanguage,
        handleTranscriberLanguage,
        config_language,
        languageList,
        handleSelectedLanguage,
        transcriberLanguageID,
        handleTranscribe,
    } = props;

    return (
        // <div className="lg:flex containerDiv fontMontserrat false">
        <div className="lg:flex containerDiv">

            <div className="w-full max-w-full">

                {/* Title and names */}
                <div className="divTitle pb-[30px] text-gray-700 dark:text-gray-100">
                    <h1 className="fontMontserrat">Video Transcriber</h1>
                    <p className="fontKarla">Transcribe any media type, whether long form or short.</p>
                </div>

                {/* Media source tabs */}
                <div>

                    <div className="flex w-full flex-col">
                        <Tabs
                            aria-label="Options"
                            color="primary"
                            variant="bordered"
                            classNames={{
                                tabList: "border-divider"
                            }}
                        >

                            {/* Drop Zone tab */}
                            <Tab
                                key="drop_zone"
                                title={
                                    <div className="flex items-center space-x-2">
                                        <i className="fa-solid fa-cloud-arrow-up" />
                                        <span>Drop Zone</span>
                                    </div>
                                }
                            >
                                <Tab_DropZone
                                    getRootProps={getRootProps}
                                    transcribing={transcribing}
                                    transcribePrices={transcribePrices}
                                    checkingLink={checkingLink}
                                    inputKey={inputKey}
                                    getInputProps={getInputProps}
                                    acceptedFiles={acceptedFiles}
                                    sourceLink={sourceLink}
                                    files={files}
                                    uploadProgress={uploadProgress}
                                />
                            </Tab>

                            {/* Link Grabber tab */}
                            <Tab
                                key="link_grabber"
                                isDisabled
                                title={
                                    <div className="flex items-center space-x-2">
                                        <i className="fa-solid fa-link" />
                                        <span>Link Grabber</span>
                                    </div>
                                }
                            >
                                <Tab_LinkGrabber />
                            </Tab>
                        </Tabs>
                    </div>
                </div>

                {/* button */}
                <div>
                    <Button
                        className="bg-gradient-to-tr from-sky-400 to-blue-600 text-white shadow-lg w-full"
                        endContent={
                            <span className="w-full text-center">
                                {transcribing && (
                                    <i className="fa-solid fa-cog fa-spin absolute left-4 text-base" />
                                )}
                                <i className="fa-solid fa-microchip mr-2.5" />
                                Transcribe
                                <span className="float-right font-semibold">
                                    <i className="fa-solid fa-coins" /> {formatNumberValue(creditCalculation)}
                                </span>
                            </span>
                        }
                        variant="shadow"
                        onClick={() => handleTranscribe(acceptedFiles[0])}
                    />
                </div>

                {/* Advance option */}
                <div className="my-4">

                    {/* header */}
                    <div>
                        <p className="text-xl font-medium">Advance Options</p>
                        <p className="text-small text-default-400">AI transcriber advance options.</p>
                    </div>

                    {/* switch */}
                    <div className="mt-3">

                        {/* Enhancer switch */}
                        <AO_Switch
                            label="Enhancer"
                            isChecked={transcriberEnhancer}
                            handleToggle={handleTranscriberEnhancer}
                            content={
                                <div className="px-1 py-2">
                                    <div className="text-small font-bold">Video Generator Tip</div>
                                    <ul className="list-disc pl-5 space-y-1 break-words w-[20rem]">
                                        <li>It enhances transcription result.</li>
                                        <li>This option will cost you <b><i className="fa-solid fa-coins text-yellow-300" /> {config_enhancer} Credit(s)</b>.</li>
                                    </ul>
                                </div>
                            }
                            // isDisabled={disabledOptions?.AO_sub_accuracy}
                            version={1}
                        />

                        {/* Custom Langugae switch */}
                        <AO_Switch
                            label="Language"
                            isChecked={transcriberLanguage}
                            handleToggle={handleTranscriberLanguage}
                            content={
                                <div className="px-1 py-2">
                                    <div className="text-small font-bold">Video Generator Tip</div>
                                    <ul className="list-disc pl-5 space-y-1 break-words w-[20rem]">
                                        <li>Use a custom language.</li>
                                        <li>This option will cost you <b><i className="fa-solid fa-coins text-yellow-300" /> {config_language} Credit(s)</b>.</li>
                                    </ul>
                                </div>
                            }
                            // isDisabled={disabledOptions?.AO_sub_accuracy}
                            version={1}
                        />

                        {/* Custom language select */}
                        {transcriberLanguage && (
                            <Select
                                size="md"
                                label="Select an animal"
                                selectedKeys={[transcriberLanguageID]}
                                onChange={handleSelectedLanguage}
                            >
                                {languageList.map((data) => (
                                    <SelectItem key={data?.language_id}>
                                        {data?.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        )}

                    </div>

                </div>

            </div>

        </div>
    );
};

export default LeftSideMenu;