import { useEffect, useRef } from "react";
import lottie from "lottie-web";
import NotAvailableImage from "../shinefy/Tools/Images/NotAvailable.png";

const LoadingPage = () => {

    let loadingToolsAnimationContainer = useRef();

    useEffect(() => {

        const loadingToolsAnimation = lottie.loadAnimation({
            container: loadingToolsAnimationContainer.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shine-ai-robot-head.json",
        });

        return () => {
            loadingToolsAnimation.destroy();
            // loadingToolsAnimation.setSpeed(1.5);
        };
    }, [loadingToolsAnimationContainer]);

    return (
        <section className="h-screen bg-gradient-to-tr from-blue-800 to-purple-950 flex items-center justify-center">
            <div className="text-center flex flex-col items-center">
                <div className="w-auto h-60" ref={loadingToolsAnimationContainer} />
                <p className="mb-4 text-3xl tracking-widest font-light text-gray-200 md:text-4xl">www.shinefy.com</p>
                {/* <p className="mb-4 text-lg text-gray-400 font-medium">Sorry, this feature is not yet available</p> */}
                {/* <a href="/" className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Back to Homepage</a> */}
            </div>
        </section>
    );
};

export default LoadingPage;