// libraries
import { Progress } from "@nextui-org/react";
import React from "react";

const Tab_DropZone = (props) => {

    // props
    const {
        getRootProps,
        transcribing,
        transcribePrices,
        checkingLink,
        inputKey,
        getInputProps,
        acceptedFiles,
        sourceLink,
        files,
        uploadProgress,
    } = props;

    return (
        <div className="flex items-center justify-center w-full">
            <label {...getRootProps()} className="flex flex-col items-center justify-center w-full h-[15.05rem]  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">

                    {/* <i className="fa-solid fa-cloud-arrow-up mb-1 text-3xl text-gray-500 dark:text-gray-400" /> */}
                    {transcribing ? (
                        <i className="fa-solid fa-cloud-arrow-up mb-1 text-3xl text-gray-500 dark:text-gray-400 fa-bounce" />
                    ) : (
                        <i className="fa-solid fa-cloud-arrow-up mb-1 text-3xl text-gray-500 dark:text-gray-400" />
                    )}
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">{`MP4, MP3 ${transcribePrices("file")} credits per 10MB`}</p>
                </div>
                <input
                    disabled={checkingLink || transcribing ? true : false}
                    type="file"
                    className="hidden"
                    key={inputKey}
                    {...getInputProps()}
                />

                {/* <b>
                    <ul className='text-[12px] text-center dark:text-gray-300'>{!sourceLink && files}</ul>
                </b> */}

                {acceptedFiles[0] ? (
                    <div className="w-[90%]">
                        <Progress
                            classNames={{
                                base: "max-w-md",
                                // track: "drop-shadow-md border border-default",
                                // indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
                                label: "tracking-wider font-medium text-default-600",
                                value: "text-foreground/60",
                            }}
                            label={!sourceLink && files}
                            size="sm"
                            value={uploadProgress}
                            maxValue={100}
                            color="primary"
                            // formatOptions={{ style: "currency", currency: "ARS" }}
                            showValueLabel={true}
                            className="max-w-md"
                        />
                    </div>
                ) : (<></>)}

            </label>
        </div>
    );
};

export default Tab_DropZone;