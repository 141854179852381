import axios from "axios";
import { ToastEffect } from "../ToastEffect";
import consoleLog from "../consoleLog";

/** fetch custom voice model from eleven labs get method function */
export async function safeFetchCustomVoiceModel(elevenLabsAPIKey, setElvenLabsAPIValidity) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_ELEVENLAB_API}/voices`, {
            headers: {
                accept: "application/json",
                "xi-api-key": elevenLabsAPIKey,
            },
        }
        );
        return response;
    } catch (error) {
        // ToastEffect("error", "Something went wrong! #safeFetchCustomVoiceModel");
        consoleLog("api > toolsAPI.js > safeFetchCustomVoiceModel", error);
        setElvenLabsAPIValidity(error?.response?.data?.detail);
    }
}