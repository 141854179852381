import React from "react";

const ResponseMessage = ({ responseMessage, closeResponseMessage }) => {
  // Message style
  const messageStyle = {
    success: "bg-green-200 border-l-4 border-green-500 text-green-700 pt-6 pb-4 px-4 rounded relative mb-0.5 justify-start items-start text-start",
    error: "bg-red-200 border-l-4 border-red-500 text-red-700 pt-6 pb-4 px-4 rounded relative mb-0.5 justify-start items-start text-start"
  };
  return (
    <div className={responseMessage[0]?.kind === "success" ? messageStyle?.success : messageStyle?.error}>
      <p className="block sm:inline p-0 font-medium">{responseMessage[0]?.message}</p>
      <button
        onClick={closeResponseMessage}
        className="absolute top-0 right-0 mr-1.5 mt-0 p-1 hover:text-gray-500 text-xl">
        <i className="fa-solid fa-circle-xmark" />
      </button>
    </div>
  );
};

export default ResponseMessage;