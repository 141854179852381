// libraries
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, Tooltip, RadioGroup, Radio, cn, Select, SelectItem } from "@nextui-org/react";

// custom components
import advanceOptionJsonData from "../AdvancedOptions/index.json";
import { captionPosition, googleFontsData } from "../../Idea2Video/Utils/data";
import { cachedConfig } from "../../../../../functions/general";
import consoleLog from "../../../../../functions/consoleLog";

const CustomCaptionEditor = (props) => {

    // props
    const {
        isOpen,
        onClose,
        setSelectedFontColor,
        selectedFontColor,
        setSelectedCaptionPosition,
        selectedCaptionPosition,
        setSelectedFont,
        selectedFont,
    } = props;


    // filter and to get the font style
    const fontStyle = googleFontsData?.find((data) => data?.fontName === selectedFont);

    // console log activity
    // consoleLog("selectedFontColor", selectedFontColor);
    // consoleLog("selectedFontPosition", selectedFontPosition);

    // const handle selected font
    const handleSelectedFont = async (font) => {

        // set selected font
        setSelectedFont(font);

        // cached selected voice model
        await cachedConfig("captionFont", font);

        // console log activity
        // consoleLog("font", font);
    };

    // handle selected color
    const handleSelectedColor = async (e) => {

        // extracted value
        const color = e.target.value;

        // set selected color
        setSelectedFontColor(color);

        // cached selected color
        await cachedConfig("fontColor", color);

        // console log activity
        // consoleLog("color", color);
    };

    // handle selected position
    const handleSelectedPosition = async (e) => {

        // extracted value
        const position = e.target.value;

        // set selected position
        setSelectedCaptionPosition(position);

        // cached selected position
        await cachedConfig("fontPosition", position);

        // console log activity
        consoleLog("position", position);
    };

    return (
        <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            Caption Customization
                        </ModalHeader>
                        <ModalBody>

                            <div className="lg:grid lg:grid-cols-1 gap-2 w-full">

                                <div className="lg:grid lg:grid-cols-2 gap-2">

                                    {/* left side */}
                                    <div className="h-auto mb-3 lg:mb-0 space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">

                                        <p className="text-xl font-semibold text-gray-900 dark:text-white">Selected Font</p>

                                        <div className="flex items-center justify-between gap-4">
                                            <dt className="text-lg font-semibold text-gray-500 dark:text-gray-400">
                                                Font
                                            </dt>
                                            <dd style={{ color: selectedFontColor }} className={`${fontStyle?.customName} text-2xl font-medium text-gray-900 dark:text-white`}>
                                                {selectedFont}
                                            </dd>
                                        </div>

                                    </div>

                                    {/* right side */}
                                    <div className="h-auto mb-3 lg:mb-0 space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                                        <p className="text-xl font-semibold text-gray-900 dark:text-white">Caption Option</p>
                                        <div className="flex justify-between items-center gap-2">

                                            <Select
                                                size="sm"
                                                variant="bordered"
                                                label="Position"
                                                className="w-full"
                                                selectedKeys={[selectedCaptionPosition]}
                                                onChange={handleSelectedPosition}

                                            >
                                                {captionPosition.map((item) => (
                                                    <SelectItem key={item.value}>
                                                        {item.label}
                                                    </SelectItem>
                                                ))}
                                            </Select>

                                            <div className="flex flex-col items-center">
                                                <input
                                                    type="color"
                                                    value={selectedFontColor}
                                                    onChange={handleSelectedColor}
                                                    className="p-1 h-11 w-[5rem] bg-white border dark:bg-neutral-800 border-gray-200 cursor-pointer rounded-lg dark:border-neutral-700"
                                                />
                                                <span className="text-xs font-semibold mt-1">Font color</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* right side card */}
                                <div className="space-y-3 overflow-y-auto max-h-[30rem] lg:max-h-[40rem]">
                                    <RadioGroup
                                        // label="Plans"
                                        // description="Selected plan can be changed at any time."
                                        value={selectedFont}
                                        onValueChange={(font) => handleSelectedFont(font)}
                                    >

                                        {googleFontsData?.map((data, idx) => {
                                            return (
                                                <div key={idx}>
                                                    <Radio
                                                        value={data?.fontName}
                                                        size="lg"
                                                        classNames={{
                                                            base: cn(
                                                                "inline-flex m-0 bg-gray-100 hover:bg-gray-100/80 dark:bg-gray-900 dark:hover:bg-gray-900/80 items-center justify-between",
                                                                "flex-row cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
                                                                "data-[selected=true]:border-primary"
                                                            ),
                                                        }}
                                                    >
                                                        <p className="font-sans font-semibold">
                                                            {data?.fontName}
                                                        </p>
                                                        {/* <p style={{ color: selectedFontColor }} className={`${data?.customName} text-2xl truncate text-gray-400`}> */}
                                                        <p style={{ color: selectedFontColor }} className={`${data?.customName} text-2xl truncate w-[14.5rem] lg:w-full text-gray-400`}>
                                                            {data?.sampleText}
                                                        </p>
                                                    </Radio>
                                                </div>
                                            );
                                        })}

                                    </RadioGroup>
                                </div>

                            </div>

                        </ModalBody>

                        {/* <ModalFooter className="flex flex-col lg:flex-row items-center justify-end -mt-4">

                 
                            <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">
                                <div className="flex font-semibold font-sans space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-800">
                                    <div className="flex items-center justify-end space-x-1 text-gray-800">

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content={
                                                <div className="px-1 py-2">
                                                    <div className="text-small font-bold">Video Generator Tip</div>
                                                    <ul className="list-disc pl-5 space-y-1 break-words w-[25rem]">
                                                        <li>This is a test tip</li>
                                                    </ul>
                                                </div>
                                            } >
                                            <span className="text-2xl text-warning hover:text-warning/90 cursor-help dark:text-yellow-300 dark:hover:text-yellow-400">
                                                <i className="fa-solid fa-circle-question" />
                                            </span>
                                        </Tooltip>
                                    </div>

                                    <div className="flex space-x-1">

                                        <div
                                            data-tooltip-id="customize-audio-prompt-updating"
                                            data-tooltip-content="Saving content data"
                                            className="inline-flex items-center py-1 w-auto font-medium dark:text-gray-200 text-gray-700"
                                        >
                                            <Tooltip id="customize-audio-prompt-updating" />
                                            <i className="fa-solid fa-circle-notch fa-spin text-xl" />
                                        </div>

                                        <button
                                            // disabled={isManualSaving ? true : false}
                                            onClick={handleSaveThemePrompt}
                                            data-tooltip-id="save-audio-prompt"
                                            data-tooltip-content="Save Custom Prompt"
                                            type="submit"
                                            className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                        >
                                            {savingThemePrompt ? (
                                                <i className="fa-solid fa-circle-notch fa-spin text-lg mr-1" />
                                            ) : (
                                                <i className="fa-solid fa-floppy-disk text-lg mr-1" />
                                            )}

                                            Save prompt
                                            <Tooltip id="save-audio-prompt" />
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </ModalFooter> */}
                    </>
                )}
            </ModalContent>
        </Modal >
    );
};

export default CustomCaptionEditor;