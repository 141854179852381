// libraries
import React from "react";
import { Button, Input, Select, SelectItem, Tooltip } from "@nextui-org/react";

// custom components
import ToggleButton from "../../ToggleButton";
import AO_Switch from "../UI/AO_Switch";
import advanceOptionJsonData from "../index.json";
import consoleLog from "../../../../../../functions/consoleLog";

const AO_tab3 = (props) => {

    // components
    const {
        config_voice,
        handleToggleCustomVoice,
        isCheckedCustomVoice,
        voiceOwnAPI,
        setElevenLabsAPIKey,
        elevenLabsAPIKey,
        showShowElevenLabsAPIKEy,
        handleShowelevenLabsAPI,
        handleDefaultVoice,
        defaultVoiceName,
        onSelectedVoice,
        voiceId,
        voicePreview,
        voiceModelList,
        togglePlayVoice,
        isPlayingVoice,
        config_language,
        handleCustomLanguage,
        isCheckedCustomLanguage,
        onSelectedLanguage,
        languageID,
        languageName,
        languageList,
    } = props;

    // console log activity
    // consoleLog("voiceModelList", voiceModelList);

    return (
        <>
            <div>
                <div className="mb-2">
                    <AO_Switch
                        label="Custom Voice"
                        isChecked={isCheckedCustomVoice}
                        handleToggle={handleToggleCustomVoice}
                        content={
                            <div className="px-1 py-2">
                                <div className="text-small font-bold">Video Generator Tip</div>
                                <ul className="list-disc pl-5 space-y-1 break-words w-[30rem]">
                                    <li>Using this feature will cost you <b><i className="fa-solid fa-coins text-yellow-300" /> {config_voice} Credit(s)</b>.</li>
                                    <li>Alternatively, you can use your own API key at no cost by enabling the feature in <b>Settings &gt; Account</b> under <b>Advanced Settings</b> section.</li>
                                </ul>
                            </div>
                        }
                    />
                </div>
                <div>
                    {isCheckedCustomVoice && voiceOwnAPI && (

                        <div className="mb-[15px]">
                            <div className="flex items-center">

                                <Input
                                    label="ElevenLabs API Key"
                                    type={showShowElevenLabsAPIKEy ? "text" : "password"}
                                    className="font-semibold"
                                    onChange={(e) => setElevenLabsAPIKey(e.target.value)}
                                    value={elevenLabsAPIKey || ""}
                                    endContent={
                                        <button
                                            className="focus:outline-none"
                                            type="button"
                                            onClick={handleShowelevenLabsAPI}
                                        >
                                            {showShowElevenLabsAPIKEy ? (
                                                <span className="text-2xl text-default-400 pointer-events-none">
                                                    <i className="fa-solid fa-eye" />
                                                </span>
                                            ) : (
                                                <span className="text-2xl text-default-400 pointer-events-none">
                                                    <i className="fa-solid fa-eye-slash" />
                                                </span>
                                            )}
                                        </button>
                                    }
                                />

                                {/* <div
                                        onClick={handleCheckVoiceAPI}
                                        title="Check api"
                                        className={`relative ml-2 border rounded-md mt-1.5 px-2 py-[0.05rem] cursor-pointer w-10 flex items-center justify-center ${isVoiceAPIKeyOkay
                                            ? "bg-green-600 hover:bg-green-500"
                                            : "bg-gray-600 hover:bg-gray-500"
                                            }`}
                                    >
                                        {isVoiceAPIKeyChecking ? (
                                            <i className="fa-solid fa-spinner fa-spin-pulse text-lg text-gray-100" />
                                        ) : isVoiceAPIKeyOkay ? (
                                            <i className="fa-solid fa-check-double text-lg text-gray-100" />
                                        ) : (
                                            <i className="fa-solid fa-check text-lg text-gray-100" />
                                        )}
                                    </div> */}

                            </div>
                        </div>
                    )}

                    {/* <div className="sectionDivider pb-[0px]">
                        <hr />
                    </div> */}

                    {!isCheckedCustomVoice && (

                        <div className="mb-1">
                            <Select
                                label="Default Voice"
                                variant="flat"
                                className="font-semibold"
                                selectedKeys={[defaultVoiceName]}
                                onChange={handleDefaultVoice}
                            >
                                {advanceOptionJsonData?.openAIVoice?.map((data) => (
                                    <SelectItem
                                        className="font-semibold"
                                        key={data.value}
                                        value={data.value}
                                    >
                                        {data.option}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {isCheckedCustomVoice && (
                        <div>

                            <h3 className="dark:text-white font-semibold">
                                Voice Models <small className="dark:text-yellow-200 text-warning">(Max of 5,000 characters)</small>
                            </h3>

                            <div className="flex items-center">

                                <select
                                    id="voiceModel"
                                    onChange={onSelectedVoice}
                                    className="mt-2 font-semibold p-4 bg-gray-100 dark:bg-[#27272A] border border-gray-600 text-gray-700 dark:text-gray-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                    value={`${voiceId}|${voicePreview}` || ""}
                                >
                                    {voiceModelList?.length > 0 && voiceModelList.map((data, index) => (
                                        <option
                                            className="font-semibold"
                                            value={`${data.voice_id}|${data.preview_url}`}
                                            key={index + 1}
                                        >
                                            {data.name}{data?.labels?.accent && ` - ${data?.labels?.accent}`} {data?.labels?.gender && `(${data?.labels?.gender})`}
                                        </option>
                                    ))}
                                </select>

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Preview Voice"
                                >
                                    <Button
                                        isIconOnly
                                        color="warning"
                                        variant="faded"
                                        aria-label="Take a photo"
                                        onClick={togglePlayVoice}
                                        className="relative ml-1 border rounded-lg mt-1.5 px-7 py-[1.5rem] cursor-pointer w-10 flex items-center justify-center"
                                    >
                                        <i className={`fa-solid ${isPlayingVoice ? "fa-pause" : "fa-play"} text-lg text-blue-600`} />
                                    </Button>
                                </Tooltip>

                            </div>

                            <div className="sectionDivider">
                                <hr />
                            </div>

                            <AO_Switch
                                label="Language"
                                isChecked={isCheckedCustomLanguage}
                                handleToggle={handleCustomLanguage}
                                content={
                                    <div className="px-1 py-2">
                                        <div className="text-small font-bold">Video Generator Tip</div>
                                        <ul className="list-disc pl-5 space-y-1 break-words w-[25rem]">
                                            <li>Custom language will cost <b><i className="fa-solid fa-coins text-yellow-300" /> {config_language} Credit(s)</b>.</li>
                                            <li>This feature that allows you to select from different supported languages.</li>
                                        </ul>
                                    </div>
                                }
                            />

                            {/* Drop down language list */}
                            {isCheckedCustomLanguage && (
                                <>
                                    <div className="flex items-center">
                                        <select
                                            id="voiceModel"
                                            onChange={onSelectedLanguage}
                                            className="mt-2 font-semibold p-4 bg-gray-100 dark:bg-[#27272A] border border-gray-600 text-gray-700 dark:text-gray-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                            value={`${languageID}|${languageName}` || ""}
                                        >
                                            {languageList.map((data, index) => (
                                                <option
                                                    className="font-semibold"
                                                    value={`${data.language_id}|${data.name}`}
                                                    key={index + 1}
                                                >
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )}

                        </div>
                    )}

                    {/* <div className="sectionDivider">
                        <hr />
                    </div> */}

                </div>
            </div>
        </>
    );
};

export default AO_tab3;