// libraries
import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Link, Textarea, Tooltip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../functions/consoleLog";
import { addMessageToInteract, removeInteractMessage, resolveIssue } from "../../../API/server";
import { ToastEffect } from "../../../functions/ToastEffect";
import { containsUrl, convertToCustomerLocalTime } from "../../../functions/general";

const NotificationInteract = (props) => {

    // props
    const {
        notificationData,
        setRefetchNotifications,
    } = props;

    // variables
    const maxRows = 8;

    // extracted data from "notificationData"
    const toEmail = notificationData?.from_email;
    const linkedID = notificationData?.linked_id;

    // use ref
    const endOfMessagesRef = useRef(null);

    // react useState
    const [message, setMessage] = useState("");
    const [rows, setRows] = useState(1);
    const [isRemovingMessage, setIsRemovingMessage] = useState(false);
    const [removeID, setRemoveID] = useState([]);
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const [isResolving, setIsResolving] = useState(false);

    // console log activity
    // consoleLog("notificationData", notificationData);

    // handle send message
    const handleSendMessage = async () => {

        // check if email exist
        if (!toEmail) {
            ToastEffect("error", "Receiver email is not valid.");
            return;
        }

        // check if "linkedID" exist
        if (!linkedID) {
            ToastEffect("error", "linked id is invalid.");
            return;
        }

        // check if message exist
        if (message?.length <= 0) {
            ToastEffect("error", "Interact message is required.");
            return;
        }

        // Your send message logic here
        // consoleLog("Message sent:", message);
        setMessage(""); // Clear the message after sending
        setRows(1); // Reset rows to default

        // require data
        const requiredData = {
            from: "admin",
            to: toEmail,
            message: message,
            linkedID: linkedID,
            notifData: notificationData,
        };

        // trigger markings
        setIsSubmittingResponse(true);

        // send the message to the server 
        await addMessageToInteract(requiredData)
            .then((response) => {
                // refetch message interactions
                setRefetchNotifications(oldVal => oldVal + 1);
                // console log activity
                consoleLog("response", response?.data);
            });

        // disable markings when done
        setIsSubmittingResponse(false);

    };

    // handle on change
    const handleChange = (event) => {
        const { value } = event.target;
        setMessage(value);

        const lineBreaks = (value.match(/\n/g) || []).length + 1;
        setRows(Math.min(lineBreaks, maxRows));
    };

    // send message sub function
    const sensMessageSubFunction = () => {
        // Check if the message contains non-whitespace characters
        if (message.trim().length > 0) {
            handleSendMessage();
        }
    };

    // handle on key down
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default newline behavior
            // call the function
            sensMessageSubFunction();
        }
    };

    // handle remove message
    const handleRemoveMessage = async (messageID) => {

        // set "isRemovingNotif" to true
        setIsRemovingMessage(true);
        setRemoveID((prevRemoveID) => [...prevRemoveID, messageID]);

        await removeInteractMessage({ messageID })
            .then((response) => {

                // refetch message interactions
                setRefetchNotifications(oldVal => oldVal + 1);

                // console log activity
                consoleLog("response", response?.data);
            });

        // disable marking when done executing
        setIsRemovingMessage(false);

        // console log activity
        // consoleLog("messageID", messageID);
    };

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({
                // behavior: 'smooth'
            });
        }
    }, [notificationData]);

    // function handle resolve issue
    const handleResolveIssue = async () => {

        // check if "notificationData" exist
        if (!notificationData) { return; }

        // required data
        const requiredData = {
            reportedDataID: notificationData?.report?.reported_id,
            reportID: notificationData?.report?._id,
            notificationID: notificationData?._id,
            reporterEmail: notificationData?.from_email,
        };

        // set trigger loading
        setIsResolving(true);

        // execute function to send request to the server
        await resolveIssue(requiredData)
            .then((response) => {
                // refetch message interactions
                setRefetchNotifications(oldVal => oldVal + 1);
            });

        // disable loading
        setIsResolving(false);

        // console log activity
        consoleLog("notificationData", notificationData);

    };

    return (
        <div className="mt-6 grow sm:mt-8 lg:mt-0 max-h-[88vh] overflow-y-auto">
            <div className="bg-white border border-gray-700 dark:bg-gray-900 py-3 antialiased rounded-lg">

                <div className="max-w-2xl mx-auto px-4">


                    <div className="bg-white dark:bg-gray-900 sticky top-0 z-20">

                        <div className="flex justify-between items-center mb-6">

                            {notificationData ? (
                                <>
                                    <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
                                        {notificationData?.from_email}
                                    </h2>


                                    <Dropdown className="bg-gray-100 dark:bg-gray-800">
                                        <DropdownTrigger>
                                            <Button
                                                isIconOnly
                                                color="warning"
                                                variant="light"
                                                aria-label="Take a photo"
                                                isLoading={isResolving ? true : false}
                                                isDisabled={notificationData?.report?.isResolved ? true : false}
                                            >
                                                <Tooltip showArrow={true} color="primary" content="Actions">
                                                    <span className="text-2xl">
                                                        <i className="fa-solid fa-ellipsis" />
                                                    </span>
                                                </Tooltip>
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownMenu
                                            color="primary"
                                            aria-label="Static Actions"
                                        >
                                            <DropdownSection title="Actions" showDivider>
                                                <DropdownItem
                                                    key="resolve"
                                                    // shortcut="⌘⇧E"
                                                    description="Mark the issue as resolved."
                                                    startContent={<i className="fa-solid fa-bookmark text-xl" />}
                                                    onClick={notificationData?.report?.isResolved ? null : handleResolveIssue}
                                                >
                                                    Resolve
                                                </DropdownItem>
                                            </DropdownSection>
                                            {/* <DropdownSection title="Danger zone">
                                                <DropdownItem
                                                    key="refund"
                                                    className="text-warning"
                                                    color="warning"
                                                    // shortcut="⌘⇧D"
                                                    description="Refund user credits."
                                                    startContent={<i className="fa-solid fa-coins text-xl" />}
                                                >
                                                    Refund
                                                </DropdownItem>
                                            </DropdownSection> */}
                                        </DropdownMenu>
                                    </Dropdown>
                                </>
                            ) : (
                                <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
                                    Notification Content
                                </h2>
                            )}

                        </div>

                        {notificationData && (
                            <>
                                <div className="mb-1 border-2 p-3 rounded-lg border-gray-400 dark:border-gray-700">
                                    <p className="whitespace-pre-line">
                                        {notificationData?.main_content}
                                    </p>
                                </div>

                                {containsUrl(notificationData?.report?.media_file_url) && (
                                    <Link
                                        isExternal
                                        href={notificationData?.report?.media_file_url}
                                        showAnchorIcon
                                        target="_blank"
                                        className="mb-3 ml-1 hover:underline"
                                    >
                                        Attached media file.
                                    </Link>
                                )}
                            </>
                        )}

                    </div>

                    {notificationData ? (
                        <>

                            {notificationData?.interacts?.map((data, idx) => {

                                if (!data?.isRemoved) {
                                    return (

                                        <article ref={endOfMessagesRef} key={idx} className="p-3 text-base bg-white rounded-lg dark:bg-gray-900">

                                            <Divider className="mb-4" />

                                            <footer className="flex justify-between items-center mb-0">

                                                <div className="flex items-center">



                                                    <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                                                        <span className="mr-2 text-2xl text-gray-400 dark:text-gray-500 rounded-full">
                                                            <i className="fa-solid fa-circle-user" />
                                                        </span>
                                                        <p className="">
                                                            {data?.from === "admin" ? data?.from?.charAt(0)?.toUpperCase() + data?.from?.slice(1) : data?.from}
                                                        </p>
                                                    </div>

                                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                                        <time dateTime="2022-02-08" title="February 8th, 2022">
                                                            {convertToCustomerLocalTime(data?.createdAt)?.moment}
                                                        </time>
                                                    </p>

                                                </div>

                                                <Dropdown className="bg-gray-100 dark:bg-gray-800">
                                                    <DropdownTrigger>
                                                        <Button
                                                            isIconOnly
                                                            isLoading={isRemovingMessage && removeID?.includes(data?._id) ? true : false}
                                                            color="primary"
                                                            variant="light"
                                                            aria-label="Take a photo"
                                                        >
                                                            <i className="fa-solid fa-ellipsis" />
                                                        </Button>
                                                    </DropdownTrigger>
                                                    <DropdownMenu
                                                        color="primary"
                                                        aria-label="Static Actions"
                                                    >
                                                        <DropdownSection title="Actions" showDivider>
                                                            <DropdownItem
                                                                key="remove"
                                                                className="text-danger"
                                                                color="danger"
                                                                // shortcut="⌘⇧E"
                                                                description="Remove message"
                                                                onClick={() => handleRemoveMessage(data?._id)}
                                                                startContent={<i className="fa-solid fa-trash text-xl" />}
                                                            >
                                                                Remove
                                                            </DropdownItem>
                                                        </DropdownSection>
                                                        {/* <DropdownSection title="Danger zone">
                                                            <DropdownItem
                                                                key="delete"
                                                                className="text-danger"
                                                                color="danger"
                                                                // shortcut="⌘⇧D"
                                                                description="No function for now"
                                                                startContent={<i className="fa-solid fa-trash text-xl" />}
                                                            >
                                                                Remove
                                                            </DropdownItem>
                                                        </DropdownSection> */}
                                                    </DropdownMenu>
                                                </Dropdown>

                                            </footer>

                                            <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
                                                {data?.message}
                                            </p>

                                            {/* reply button */}
                                            {/* <div className="flex items-center mt-4 space-x-4">
                                                <button type="button"
                                                    className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                                                    <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                                                    </svg>
                                                    Reply
                                                </button>
                                            </div> */}

                                        </article>
                                    );
                                }
                            })}
                        </>
                    ) : (
                        <div className="flex justify-center mb-1 border-2 p-3 rounded-lg border-gray-400 dark:border-gray-700">
                            <p className="whitespace-pre-line">
                                View interaction messages here.
                            </p>
                        </div>
                    )}

                </div>

                {notificationData && (
                    <div className={`${notificationData?.interacts?.length <= 0 && "mt-5"} bg-white dark:bg-gray-900 sticky bottom-0 z-20 max-w-2xl mx-auto px-4`}>
                        <div className="relative mx-2">
                            <Textarea
                                disabled={isSubmittingResponse ? true : false}
                                size="lg"
                                label="Reply Message"
                                variant="bordered"
                                placeholder="Enter your message"
                                disableAnimation
                                disableAutosize
                                classNames={{
                                    base: "h-full",
                                    // input: "resize-y min-h-[40px]",
                                }}
                                value={message}
                                onValueChange={setMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                rows={rows}
                                style={{ minHeight: '40px' }}
                            />
                            <div className="absolute bottom-2 right-1">
                                <Tooltip showArrow={true} color="primary" content="Send message">
                                    {/* <span onClick={sensMessageSubFunction} className="cursor-pointer text-2xl mr-2 text-gray-500 dark:text-gray-500 hover:text-gray-400">
                                        <i className="fa-solid fa-paper-plane" />
                                    </span> */}

                                    <Button
                                        isIconOnly
                                        size="sm"
                                        variant="light"
                                        startContent={
                                            !isSubmittingResponse && (
                                                <span className="text-2xl text-gray-500 dark:text-gray-500 hover:text-gray-400">
                                                    <i className="fa-solid fa-paper-plane" />
                                                </span>
                                            )
                                        }
                                        onClick={sensMessageSubFunction}
                                        isLoading={isSubmittingResponse ? true : false}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default NotificationInteract;