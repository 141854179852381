// libraries
import { parseISO, format, eachWeekOfInterval, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import { enUS } from 'date-fns/locale';

// custom components
import consoleLog from './consoleLog';

// login source
export const getLoginSource = (source) => {
    switch (source) {
        case "discord": return { type: "icon", data: "fa-brands fa-discord mr-1 text-[#7289da]", source: "Discord" };
        case "shinefy": return { type: "shinefy", data: "shinefy", source: "Shinefy" };
        case "shineranker": return { type: "shineranker", data: "shineranker", source: "Shine Ranker" };
    }
};

// subscription status style
export const getSubscriptionStatus = (status) => {
    switch (status) {
        case "active": return { color: "text-green-400 border border-green-500", data: "Active" };
        case "expired": return { color: "text-red-400 border border-red-500", data: "Expired" };
        case "neutral": return { color: "text-blue-400 border border-blue-500", data: "Neutral" };
        case "unlimited": return { color: "text-yellow-300 border border-yellow-400", data: "Unlimited" };
    }
};

// array of months
export const arrayOfMonths = () => {
    return [
        { month: "January" },
        { month: "February" },
        { month: "March" },
        { month: "April" },
        { month: "May" },
        { month: "June" },
        { month: "July" },
        { month: "August" },
        { month: "September" },
        { month: "October" },
        { month: "November" },
        { month: "December" },
    ];

};

// get years starts from 2023
export const getYearsFrom2023ToCurrent = () => {
    const startYear = 2023;
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push({ year });
    }

    return years;
};

// sum all payment value
export const sumPayments = (data) => {
    const totalPayment = data?.reduce((acc, item) => {
        return acc + (item.payment || 0);
    }, 0);

    return totalPayment?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

// sort data into months
export const sortTheUsageData = (toolsLogSpentData, selectedMonth, selectedYear, kind) => {

    // making sure that the selectedYear is a number
    const year = Number(selectedYear);

    // Parse the provided month name to get the corresponding month index (0-11)
    const monthIndex = new Date(Date.parse(`${selectedMonth} 1, ${year}`)).getMonth();

    // console log activity
    // consoleLog("year", year);
    // consoleLog("monthIndex", monthIndex);

    // Generate start and end dates for the specified month and year
    const start = startOfMonth(new Date(year, monthIndex));
    const end = endOfMonth(new Date(year, monthIndex));

    // Generate an array of dates for each day in the specified month
    const daysInMonth = eachDayOfInterval({ start, end });

    // Format each date to 'MMM d, yyyy' (e.g., 'Nov 1, 2023')
    const formattedDays = daysInMonth.map(day => format(day, 'MMM d, yyyy', { locale: enUS }));

    // Initialize an object to hold the totals for each day
    const totalsByDay = formattedDays.reduce((acc, day) => {
        acc[day] = 0;
        return acc;
    }, {});

    // Variable to hold the total value of all the "kind"
    let totalValue = 0;

    // Iterate over your data and sum the values by the `createdAt` date
    toolsLogSpentData?.message?.forEach(item => {
        const createdAtDate = parseISO(item.createdAt);
        const formattedDate = format(createdAtDate, 'MMM d, yyyy', { locale: enUS });
        if (totalsByDay[formattedDate] !== undefined) {
            totalsByDay[formattedDate] += item?.[kind] || 0;
            totalValue += item?.[kind] || 0;
        }
    });

    // Convert the totalsByDay object to an array
    const totalsArray = Object.keys(totalsByDay).map(date => ({
        date,
        total: totalsByDay[date]
    }));

    // Include the total value in the return object
    return {
        totalsArray,
        totalValue: totalValue
    };

};

// sort data into weeks
export const sortDataIntoWeeks = (toolsLogSpentData, selectedMonth, selectedYear, kind) => {

    // Ensure the selectedYear is a number
    const year = Number(selectedYear);

    // Parse the provided month name to get the corresponding month index (0-11)
    const monthIndex = new Date(Date.parse(`${selectedMonth} 1, ${year}`)).getMonth();

    // Generate start and end dates for the specified month and year
    const start = startOfMonth(new Date(year, monthIndex));
    const end = endOfMonth(new Date(year, monthIndex));

    // Generate an array of start dates for each week in the specified month
    const weeksInMonth = eachWeekOfInterval({ start, end });

    // Format each week's start date to 'MMM d, yyyy' (e.g., 'Nov 1, 2023')
    const formattedWeeks = weeksInMonth.map(weekStart => {
        const weekEnd = endOfWeek(weekStart);
        return {
            start: weekStart,
            end: weekEnd,
            formatted: `${format(weekStart, 'MMM d', { locale: enUS })} - ${format(weekEnd, 'MMM d', { locale: enUS })}`
        };
    });

    // Initialize an object to hold the totals for each week
    const totalsByWeek = formattedWeeks.reduce((acc, week) => {
        acc[week.formatted] = 0;
        return acc;
    }, {});

    // Variable to hold the total value of all the "kind"
    let totalValue = 0;

    // Iterate over your data and sum the values by the `updatedAt` date
    toolsLogSpentData?.message?.forEach(item => {
        const updatedAtDate = parseISO(item.updatedAt);
        formattedWeeks.forEach(week => {
            if (updatedAtDate >= week.start && updatedAtDate <= week.end) {
                totalsByWeek[week.formatted] += item?.[kind] || 0;
                totalValue += item?.[kind] || 0;
            }
        });
    });

    // Convert the totalsByWeek object to an array
    const totalsArray = Object.keys(totalsByWeek).map(week => ({
        week,
        total: totalsByWeek[week]
    }));

    // Include the total value in the return object
    return {
        totalsArray,
        totalValue: totalValue
    };
};

// get current month and year
export const getCurrentMonthAndYear = () => {
    const today = new Date();
    const month = today.toLocaleString('default', { month: 'long' });
    const year = today.getFullYear();
    return { month: month, year: year };
};

// calculate the difference
export const compareValues = async (currentValue, previousValue) => {
    // Calculate the difference
    const difference = previousValue - currentValue;

    // Calculate the percentage difference
    const percentage = ((currentValue / previousValue) * 100).toFixed(0);

    // Determine the trend (up or down)
    const trend = currentValue < previousValue ? 'down' : 'up';

    // Return the result as an object
    return {
        difference: difference < 0 ? 0 : difference,
        percentage: `${percentage === "Infinity" ? 0 : percentage}%`,
        trend
    };
};

// get color for status type 
export const getStatusColor = (inputString) => {

    const keywords = {
        succeeded: "text-success",
        paid: "text-success",
        withdraw: "text-success",
        complete: "text-success",
        create: "text-primary",
        update: "text-secondary",
        fail: "text-danger",
        cancel: "text-danger",
        delete: "text-danger",
    };

    for (const [keyword, color] of Object.entries(keywords)) {
        if (inputString.includes(keyword)) {
            return { keyword, color };
        }
    }

    return { keyword: "default", color: "text-warning" };

};

// truncate text
export const truncateText = (text, maxLength = 28) => {
    if (text?.length > maxLength) {
        return text?.substring(0, maxLength) + '...';
    }
    return text;
};

// get admin config
export const getCachedToolsConfiguration = (configKey, configData) => {

    // fetch data from local storage
    const cachedConfiguration = localStorage.getItem(configKey);

    // if data exist
    if (cachedConfiguration) {

        // return data
        return {
            config: (cachedConfiguration)?.toLocaleString()?.toLowerCase(), // lower case letters | for config
            normal_config: cachedConfiguration, // normal letters || for normal_config
            content: cachedConfiguration // normal letters || for content
        };

    } else { // if it does not exist

        // variable
        let updatedConfigKey = configKey;

        // condition for other values
        if (configKey === "customImageThemePrompt") {
            updatedConfigKey = "imageTheme";
        } else if (configKey === "customVideoThemePrompt") {
            updatedConfigKey = "videoTheme";
        } else if (configKey === "userIdeaContent") {
            updatedConfigKey = "idea";
        } else if (configKey === "userScriptContent") {
            updatedConfigKey = "script";
        }

        // fetch data from database
        const accuracyConfig = configData?.find(item => item?.dataID === updatedConfigKey);

        // if data exist
        if (accuracyConfig) {

            // return data
            return {
                config: (accuracyConfig?.link)?.toLocaleString()?.toLowerCase(), // for config
                normal_config: accuracyConfig?.link, // for none lowered case letter
                content: accuracyConfig?.content // for content
            };

        } else { // if it does not exist

            // return null
            return null;
        }
    }
};