// libraries
import axios from "axios";

// custom components
import { ToastEffect } from "../functions/ToastEffect";
import consoleLog from "../functions/consoleLog";

/** save account info */
export const saveAccountInfo = async (email, kind, value) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/save_account_info`, {
            email: email,
            kind: kind,
            value: value
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #saveAccountInfo");
        consoleLog("API > account.js > saveAccountInfo", error);
    }
};

/** change password */
export const changePass = async (email, password, confirmPassword) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/change_pass`, {
            email: email,
            password: password,
            confirmPassword: confirmPassword
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #changePass");
        consoleLog("API > account.js > changePass", error);
    }
};

/** get dicord data from server */
export const getDiscordData = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/discord-info`, {
            email: email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getDiscord");
        consoleLog("API > account.js > getDiscord", error);
    }
};

/** deduct credits */
export const payVideo = async (email, value, kind) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/pay-video`, {
            email: email,
            kind: kind,
            value: value
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #payVideo");
        consoleLog("API > account.js > payVideo", error);
    }
};

/** check credit balance */
export const getCreditBalance = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/check-credit-balance`, {
            email: email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getCreditBalance");
        consoleLog("API > account.js > getCreditBalance", error);
    }
};

/** refund credits */
export const refundCredits = async (email, taskId) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/refund-credits`, {
            email: email,
            taskId: taskId
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #refundCredits");
        consoleLog("API > account.js > refundCredits", error);
    }
};

/** save credit transaction records */
export const recordCredits = async (email, task_id, kind, action, paymentPrice, image_cost, image_price) => {

    // const { GeneralPurpose } = paymentPrice[0];

    try {
        await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/record-credits`, {
            email: email,
            task_id: task_id,
            kind: kind,
            action: action,
            value: paymentPrice,
            image_cost: image_cost || 0,
            image_price: image_price || 0
        });
        return paymentPrice;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #recordCredits");
        consoleLog("API > account.js > recordCredits", error);
    }
};

/** update log credits payment */
export const updateLogCreditsPayment = async (data) => {
    try {
        await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/update-log-credits-payment`, data);
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateLogCreditsPayment");
        consoleLog("API > account.js > updateLogCreditsPayment", error);
    }
};

/** record refund credits */
export const recordRefundCredits = async (email, task_id, kind, action, value) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/record-credits`, {
            email: email,
            task_id: task_id,
            kind: kind,
            action: action,
            value: value
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #recordRefundCredits");
        consoleLog("API > account.js > recordRefundCredits", error);
    }
};

/** get payment price */
export const appConfig = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/app-config`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #appConfig");
        consoleLog("API > account.js > appConfig", error);
    }
};

/** update database for the config */
export const updateUserConfig = async (email, value, kind) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/update-user-config`, {
            email: email,
            value: value,
            kind: kind
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateUserConfig");
        consoleLog("API > authAPI.js > updateUserConfig", error);
    }
};

/** get user config */
export const getUserConfig = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/get-user-config`, {
            email
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getUserConfig");
        consoleLog("API > account.js > getUserConfig", error);
    }
};

/** update or insert tools config to database */
export const updateToolsConfig = async (email, value, kind, dataID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/update-tools-config`, {
            email: email,
            value: value,
            kind: kind,
            dataID: dataID
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateToolsConfig");
        consoleLog("API > authAPI.js > updateToolsConfig", error);
    }
};

/** get  tools config */
export const getToolsConfig = async (email, dataID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/get-tools-config`, {
            email,
            dataID
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getToolsConfig");
        consoleLog("API > account.js > getToolsConfig", error);
    }
};

/** UPdate tools data */
export async function updateToolsData(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/update_tools_data`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateToolsData");
        consoleLog("api > toolsAPI.js > updateToolsData", error);
        // throw error.response;
    }
}

/** get live credit balance */
export const getLiveCreditBalance = async (userEmail) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/live-credit-balance`, { email: userEmail });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getLiveCreditBalance");
        consoleLog("api > toolsAPI.js > getLiveCreditBalance", error);
        // throw error.response;
    }
};