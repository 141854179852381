import React, { useContext } from "react";
import { Avatar as NextUIAvatar } from "@nextui-org/react";

// image
import { checkName } from "../../functions/subscriptionStatus";
import AvatarImage from "../AvatarImage";
import UserContext from "../UserContext";

// components

const Avatar = () => {

    // context method
    const {

        // Others
        userData,
        userAvatar,

    } = useContext(UserContext);

    return (
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                {/* <AvatarImage classData="mb-4 rounded-lg w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0" /> */}
                <NextUIAvatar
                    // size="lg"
                    radius="sm"
                    isBordered
                    src={userAvatar}
                    className="w-20 h-20 text-large mb-4 sm:mb-0 xl:mb-4 2xl:mb-0"
                />
                <div>
                    <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white truncate w-56">
                        {checkName(userData?.UserInfo?.first_name, userData?.UserInfo?.last_name)}
                    </h3>
                    <div className="mb-4 text-sm text-gray-500 dark:text-gray-400 truncate w-56">
                        {userData?.UserInfo?.email || "www.shineranker.com"}
                    </div>
                    <div className="flex items-center space-x-4">
                        {/* <button type="button" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <i className="fa-solid fa-cloud-arrow-up w-4 h-4 mr-2 -ml-1" />
                            Upload Picture
                        </button> */}
                        {/* <button type="button" className="py-2 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            Delete
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Avatar;