import React from "react";
import consoleLog from "../../../functions/consoleLog";

const ViewMedia = (props) => {

    const {
        modalMediaViewRef,
        setShowViewMediaMOdal,
        viewMediaData
    } = props;

    consoleLog("viewMediaData", viewMediaData);

    return (
        <div className="absolute inset-0 z-50">
            <div data-state="open" className="fixed inset-0">
                <div className="overflow-x-auto overflow-y-auto grid-cols-[10px_1fr_10px] grid h-full w-full grid-rows-[minmax(10px,_1fr)_auto_minmax(10px,_1fr)] md:grid-rows-[minmax(20px,_1fr)_auto_minmax(20px,_1fr)]">
                    <div role="dialog" id="radix-:r35:" aria-describedby="radix-:r37:" aria-labelledby="radix-:r36:" data-state="open" tabIndex="-1" className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-lg text-left transition-all left-1/2 -translate-x-1/2 bg-white dark:bg-gray-900 focus-none !bg-transparent outline-none">
                        <div className="focus-none flex h-full flex-col items-center justify-start outline-none">
                            <div className="relative">
                                <div ref={modalMediaViewRef} className="flex grow justify-center bg-gray-200 dark:bg-gray-800 rounded-md flex-col items-start overflow-hidden shadow-md">

                                    <div className="flex w-full flex-row items-center justify-between border-b px-4 py-3 dark:border-gray-700">
                                        <span className="text-xl font-semibold font-sans dark:text-gray-300">
                                            {viewMediaData?.title}
                                        </span>

                                        <button
                                            onClick={() => setShowViewMediaMOdal(false)}
                                            className="text-gray-700 opacity-50 transition hover:opacity-75 dark:text-white"
                                        >
                                            <i className="fa-solid fa-x" />
                                        </button>

                                    </div>

                                    <div className="flex flex-col md:flex-row max-w-[80rem] h-[50rem] bg-white border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                        {viewMediaData?.kind === "image" ? (
                                            <>
                                                <img
                                                    className="w-full md:w-1/2 bg-gray-600 p-1 cursor-pointer object-contain"
                                                    srcSet={`${viewMediaData?.data}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${viewMediaData?.data}?w=248&fit=crop&auto=format`}
                                                    alt="View Media"
                                                    loading="lazy"
                                                />
                                                <div className="w-full md:w-1/2 p-5 overflow-auto">
                                                    <p className="font-sans font-semibold text-gray-700 dark:text-gray-400 text-lg">
                                                        {viewMediaData?.content}
                                                    </p>
                                                    {/* <div className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                        Read more
                                                        <span className="ml-2">
                                                            <i className="fa-solid fa-arrow-right-long" />
                                                        </span>
                                                    </div> */}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <video className="w-full md:w-1/2 sm:h-auto h-[30rem] bg-gray-600 p-1 cursor-pointer" controls>
                                                    <source
                                                        srcSet={`${viewMediaData?.content_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        src={`${viewMediaData?.content_url}?w=248&fit=crop&auto=format`}
                                                        alt="View Media"
                                                        loading="lazy"
                                                        type="video/mp4"
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className="w-full md:w-1/2 p-5 overflow-auto">
                                                    <p className="font-sans font-semibold text-gray-700 dark:text-gray-400 text-lg">
                                                        {viewMediaData?.prompt}
                                                    </p>
                                                    {/* <div className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                Read more
                                                <span className="ml-2">
                                                    <i className="fa-solid fa-arrow-right-long" />
                                                </span>
                                            </div> */}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="flex font-semibold font-sans space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600 bg-gray-300 dark:bg-gray-700" />

                                    {/* Start */}
                                    {/* <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">

                                        <div className="flex font-semibold font-sans space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600 bg-gray-300 dark:bg-gray-700">

                                            <div className="flex items-center justify-end space-x-1 text-gray-800">

                                                <div
                                                    data-tooltip-id="VG-modal-prompt-editor-characters"
                                                    data-tooltip-content="Characters"
                                                    type="button"
                                                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                                >
                                                    <i className="fa-solid fa-font mr-1" />
                                                    <code>{promptKind === "idea" ? (contentValueIdea?.length).toLocaleString() : (contentValueScript?.length).toLocaleString()}</code>
                                                    <Tooltip id="VG-modal-prompt-editor-characters" />
                                                </div>

                                                <div
                                                    data-tooltip-id="VG-modal-prompt-editor-words"
                                                    data-tooltip-content="Words"
                                                    type="button"
                                                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                                >
                                                    <i className="fa-solid fa-arrow-up-z-a mr-1" />
                                                    <code>{(estimateAudioLength(promptKind === "idea" ? contentValueIdea : contentValueScript)?.count).toLocaleString()}</code>
                                                    <Tooltip id="VG-modal-prompt-editor-words" />
                                                </div>

                                                <div
                                                    data-tooltip-id="VG-modal-prompt-editor-estimated-time"
                                                    data-tooltip-content="Estimated video length"
                                                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                                >
                                                    <i className="fa-regular fa-clock mr-1" />
                                                    <code>{estimateAudioLength(promptKind === "idea" ? contentValueIdea : contentValueScript)?.length}</code>
                                                    <Tooltip id="VG-modal-prompt-editor-estimated-time" />
                                                </div>

                                                <ToolTipDisplay
                                                    label={`
                                                            <p className="break-words whitespace-pre-line -mt-5 ${isMobile ? "w-48" : "w-72"}">
                                                                ${voiceOwnAPI ?
                                                            "• Using own API key/auth cost 0 Credit(s)." :
                                                            `• Using this feature will cost you ${toolPrices("audio")} Credit(s) per 100 characters. However, you can use your own API key/auth for no credit cost.`
                                                        }

                                                                • Please note that all uploaded custom media files, such as images and videos, will not be stored permanently. They will be deleted from the database once the system restarts.
                                                            </p>
                                                        `}
                                                    id="customize-audio-info"
                                                    icon="fa-solid fa-circle-question text-gray-400 text-xl hover:text-gray-500"
                                                />


                                            </div>

                                            <div className="flex space-x-1">

                                                <div
                                                    data-tooltip-id="customize-audio-prompt-updating"
                                                    data-tooltip-content="Saving content data"
                                                    className="inline-flex items-center py-1 w-auto font-medium dark:text-gray-200 text-gray-700"
                                                >
                                                    <Tooltip id="customize-audio-prompt-updating" />
                                                    <i className="fa-solid fa-circle-notch fa-spin text-xl" />
                                                </div>

                                                <button
                                                    // disabled={isManualSaving ? true : false}
                                                    // onClick={handleManualSavePrompt}
                                                    data-tooltip-id="save-audio-prompt"
                                                    data-tooltip-content="Save Image Prompt"
                                                    type="submit"
                                                    className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                                >
                                                    <i className="fa-solid fa-floppy-disk text-lg mr-1" />
                                                    Save prompt
                                                    <Tooltip id="save-audio-prompt" />
                                                </button>

                                                <ToolTipDisplay
                                                    label={`
                                                            <p className="break-words whitespace-pre-line -mt-5 ${isMobile ? "w-48" : "w-72"}">
                                                                Note: The estimated video length may not be accurate. Sometimes, it might be slightly greater or less than the actual length.
                                                            </p>
                                                        `}
                                                    id="customize-audio-info"
                                                    icon="fa-solid fa-circle-question text-gray-400 text-2xl hover:text-gray-500"
                                                />

                                            </div>

                                        </div>

                                    </div> */}
                                    {/* End */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewMedia;