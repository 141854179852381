// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { Progress, Tooltip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

// custom components
import { generateAudio } from "../../API/toolsAPI";
import consoleLog from "../../../../../functions/consoleLog";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import { toolPrices } from "../../../../../Configs/toolPrices";
import { getCreditBalance, payVideo, recordCredits } from "../../../../../API/account";
import { adjustCreditsView } from "../../../../../functions/itemWithExpiry";
import { alertBeforeAction } from "../../Components/Transcriber/AlertBeforeAction";
import { addMarkerToData, handlePurchaseCredits, removeMarkedData, removeQuotes, updateArrayData } from "../../../../../functions/general";
import UserContext from "../../../../../components/UserContext";
import formatNumber from "../../../../../functions/calculations";

const VG_CustomMediaAudio = (props) => {

    // props
    const {
        setRefetchData,
        toolsDataAudio,
        enableVoice,
        enableLanguange,
        languageID,
        languageName,
        elevenLabsAPIKey,
        defaultVoiceName,
        voiceId,
        isOldUser,
        subscriptionData,
        voiceOwnAPI,
        isMobile,
        customAudioCreditCalculation,
        videoData,
        videos,
        setVideos,
        handleDownloadMediaFile,
        isDownloading,
        voiceModelList,
    } = props;

    // context method
    const {

        // Others
        appConfigData,
        toolsDataStatus,
        refetchData: refetchAudioData,

        // debounce updateContentData function
        debounceUpdateContentData,

    } = useContext(UserContext);

    // react variables
    const audioRef = useRef(null);
    const navigate = useNavigate();

    // appConfigData extracted values
    const config_audio = appConfigData[0]?.audio;

    // custom variables - extracted data 
    const videoID = videoData?._id;
    const videoPrompt = videoData?.prompt;
    const videoTitle = videoData?.title;
    const videoTaskID = videoData?.task_id;
    const email = videoData?.email;
    const audioURL = toolsDataAudio?.data;
    const audioName = videoData?.title;


    // react components
    const [newData, setNewData] = useState("");
    const [prompt, setPropmt] = useState(videoPrompt);
    const [title, setTitle] = useState(videoTitle);
    const [dataKind, setDataKind] = useState("");
    const [isProcessing, setIsProcessing] = useState({});
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
    const [filteredAudioStatus, setFilteredAudioStatus] = useState({});

    // console log activity
    // consoleLog("refetchData", refetchAudioData);
    // consoleLog("videoID", videoID);
    // consoleLog("isProcessing", isProcessing);

    useEffect(() => {
        if (!toolsDataAudio?.data) { return; }
        // If there's an audio file and the audio element exists, reload its source
        if (toolsDataAudio && audioRef.current) {
            const newSource = audioURL;
            audioRef.current.src = newSource; // Update the source directly
            audioRef.current.load(); // Reload the audio element
        }
    }, [toolsDataAudio]); // React to changes in audioFileName

    const calculationCost = (text) => {

        const characters = text?.length;

        const cost = (characters / 100) * config_audio;

        const roundedNumber = parseFloat(cost.toFixed(2));
        const formattedNumber = roundedNumber.toLocaleString();

        return formattedNumber;
    };

    // get final custom audio cost calculations
    const getFinalCustomAudioCostCalculation = () => {
        // audio cost calculation
        let audioCost = calculationCost(prompt);

        // calculation
        return parseFloat(audioCost) + parseFloat(customAudioCreditCalculation);
    };

    // handle auto save new data
    const handleAutoSaveNewData = async (e, kind) => {

        // check if videoData exist
        if (!videoData) { return; }

        // variables
        const newValue = e.target.value; // extracted new value

        // call marker for loading effect
        await addMarkerToData(setIsProcessing, videoID);

        // set values
        setNewData(newValue);
        setDataKind(kind);

        // set value into the designated kind.
        if (kind === "prompt") {
            setPropmt(newValue);
        } else {
            setTitle(newValue);
        }

        // check if value exist before executing update
        if (newValue.length > 0) {

            // console log call function to save the new data
            await saveNewData(newValue, kind, "autoSave");
        }
    };

    // manual save new data
    const handleManualSaveNewData = async () => {

        // check if videoData exist
        if (!videoData) { return; }

        // check if value exist before executing update
        if (newData.length > 0 && dataKind.length > 0) {

            // console log call function to save the new data
            await saveNewData(newData, dataKind, "manualSave");
        }
    };

    // function that will handle to save the new data
    const saveNewData = async (newValue, kind, action) => {

        // conditionally call debounceUpdateContentData to execute update
        if (action === "autoSave") {
            await debounceUpdateContentData(kind, videoID, kind, newValue); // asynchronous for auto save
        } else {
            debounceUpdateContentData(kind, videoID, kind, newValue); // normal for manual save
        }

        // update new data to the array
        const newUpdatedData = await updateArrayData(videos, videoID, kind, newValue);
        setVideos(newUpdatedData); // set the new updated data

        // remove data as marked if process is done
        await removeMarkedData(setIsProcessing, videoID);
    };

    // display audio process
    useEffect(() => {
        // filter get audio status from toolsDataStatus via task_id from videoData
        const audioStatus = toolsDataStatus.find((status) =>
            status?.tools_status?.task_id === videoTaskID && status?.tools_status?.type === "audio"
        );
        setFilteredAudioStatus(audioStatus?.tools_status);
    }, [toolsDataStatus]);

    // refetch audio data 
    useEffect(() => {
        const refetchAudioData = () => {
            setRefetchData(oldval => oldval + 1);
            setIsGeneratingAudio(false);
            setFilteredAudioStatus({});
        };
        refetchAudioData();
    }, [refetchAudioData]);

    // handle generate audio function
    const handleGenerateAudio = async () => {

        // check if videoData exist
        if (!videoData) { return; }

        // --------------------------------
        // --- audio cost calculation
        // --------------------------------
        let audioCost = getFinalCustomAudioCostCalculation();

        setIsGeneratingAudio(true);

        // check if no ElevenLabs API key is provided
        if (enableVoice && !elevenLabsAPIKey && voiceOwnAPI) {
            setIsGeneratingAudio(false);
            ToastEffect("error", "Please provide the ElevenLabs API key first.");
            return;
        }

        let user_voice = "";
        let language_id = "";
        let language_name = "";
        let voice_key = "";

        // check if voice is enable or disable
        if (enableVoice) {
            user_voice = voiceId;
        } else {
            user_voice = defaultVoiceName;
        }

        // check if own 11Labs API key
        if (voiceOwnAPI && enableVoice) {
            voice_key = elevenLabsAPIKey;
            audioCost = 0; // no cost if use own API key
        } else {
            voice_key = "";
        }

        // check if voice is on
        if (!enableVoice || !enableLanguange) {
            language_id = "";
            language_name = "";
        } else {
            language_id = languageID;
            language_name = languageName;
        }

        // --------------------------------
        // --- Check credit balance first
        // --------------------------------
        if (subscriptionData?.message !== "freeAccess") {

            const ceditBalance = await getCreditBalance(email);

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;
            // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            // console.log(totalCredits, totalPrice);

            if (totalCredits < audioCost) {
                setIsGeneratingAudio(false);
                // ToastEffect("error", "Not enough credits.");
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        // --------------------------------
        // --- payment process
        // --------------------------------
        if (subscriptionData?.message === "freeAccess" || (voiceOwnAPI && voiceModelList?.length > 0)) {
            let paymentKind = "freeAccess";

            if (subscriptionData?.message === "subscribed" && isOldUser) {
                paymentKind = "OldUser";
            }

            // record credits
            await recordCredits(email, videoTaskID, "VG_GenerateAudio", paymentKind, audioCost);

        } else {

            // pay video
            await payVideo(email, audioCost, "VG_GenerateAudio");

            // record payment
            await recordCredits(email, videoTaskID, "VG_GenerateAudio", "payment", audioCost);
            adjustCreditsView("tokenBalance", "pay", audioCost);
        }

        // required data
        const data = {
            media_id: toolsDataAudio?._id || "",
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            task_id: videoTaskID,
            kind: "audio",
            amount: audioCost,
            user_prompt: prompt,
            user_voice: user_voice,
            voice_key: voice_key,
            languageName: language_name,
            languageID: language_id,
        };

        // --------------------------------
        // --- show activity logs
        // --------------------------------
        // consoleLog("VG_GenerateAudio", data);
        // setIsGeneratingAudio(false);

        // --------------------------------
        // --- execute generation process
        // --------------------------------
        await generateAudio(data)
            .then(async (response) => {

                // const taskId = response?.data?.task_id;

                // // if no task id is being returned cancel process
                // if (!taskId) {
                //     ToastEffect("error", "No task ID received!");
                //     setIsGeneratingAudio(false);
                //     return;
                // }

                // --------------------------------
                // --- check if max request reach
                // --------------------------------
                if (response?.data?.detail == "request_limit") {
                    ToastEffect("error", "Maximum request limit reached.");
                    // functionRefundCredits({ action: "VG_GenerateAudio", taskID: videoData?.task_id });
                    setIsGeneratingAudio(false);
                    return;
                }

                // await saveTaskId(videoData?.email, process.env.REACT_APP_CUSTOM_AUDIO_ID, "taskId", taskId);
                // setCheckAudioStatus(oldval => oldval + 1);

            }).catch((error) => {
                // functionRefundCredits({ action: "VG_GenerateAudio", taskID: videoData?.task_id });
                ToastEffect("error", "Error generating audio!");
                consoleLog("#generateAudio", error);
                setIsGeneratingAudio(false);
            });
    };

    return (
        <div>

            {/* disable this section if there is no videoData */}
            {!videoData && (
                <div className="absolute inset-0 flex items-center justify-center z-10" />
            )}

            <div className={`${!videoData && "blur-sm"}`}>

                <div className="w-full mb-3 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">

                    <div className="px-4 py-2 rounded-t-lg dark:bg-gray-800">
                        <audio
                            className="w-full"
                            controls
                            ref={audioRef}
                        >
                            {toolsDataAudio?.data && (
                                <source
                                    src={audioURL}
                                    type="audio/mp3"
                                />
                            )}
                            Your browser does not support the audio element.
                        </audio>
                    </div>

                    <div className="flex flex-col space-y-4 px-3 py-2 border-t dark:border-gray-600">

                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center justify-end space-x-1 text-gray-800 order-2 md:order-1">

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Total characters"
                                >
                                    <div className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-200 dark:hover:text-white dark:bg-gray-600">
                                        {/* <i className="fa-solid fa-hashtag mr-1" /> */}
                                        <i className="fa-solid fa-arrow-up-9-1 mr-1 text-blue-400" />
                                        <code>{(prompt?.length)?.toLocaleString()}</code>

                                    </div>
                                </Tooltip>


                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Total cost"
                                >
                                    <div className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-200 dark:hover:text-white dark:bg-gray-600">
                                        <span className="mr-1 text-warning dark:text-yellow-200">
                                            <i className="fa-solid fa-coins" />
                                        </span>
                                        <code>{voiceOwnAPI && enableVoice ? "0.00" : formatNumber(getFinalCustomAudioCostCalculation())}</code>

                                    </div>
                                </Tooltip>

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content={
                                        <div className="px-1 py-2">
                                            <div className="text-small font-bold">Custom Audio Tip</div>
                                            <ul className="list-disc pl-5 space-y-1 break-words w-[30rem]">
                                                <li>Using this feature will cost you <b><i className="fa-solid fa-coins text-warning dark:text-yellow-300" /> {config_audio} Credit(s)</b> per 100 characters.</li>
                                                {customAudioCreditCalculation > 0 && (
                                                    <li>Additional <b><i className="fa-solid fa-coins text-warning dark:text-yellow-300" /> {customAudioCreditCalculation} Credit(s)</b> for using <b>Custom Voice</b> or <b> Language</b>.</li>
                                                )}
                                                <li>Alternatively, you can use your own API key at no cost by enabling the feature in <b>Settings &gt; Account</b> under <b>Advanced Settings</b> section.</li>
                                            </ul>
                                            {/* <p className="break-words whitespace-pre-line">
                                                {voiceOwnAPI ?
                                                    `Using own API key/auth cost 0 Credit(s).` :
                                                    `Using this feature will cost you ${config_audio} Credit(s) per 100 characters. 
                                                However, you can use your own API key/auth for no credit cost.`
                                                }
                                            </p> */}
                                        </div>
                                    }
                                >

                                    <span className="cursor-help text-warning hover:text-warning/80 dark:text-yellow-300 text-xl dark:hover:text-yellow-400">
                                        <i className="fa-solid fa-circle-question" />
                                    </span>

                                </Tooltip>

                            </div>

                            <div className="flex space-x-1 order-3 md:order-3">

                                {isProcessing[videoID] && (
                                    <>
                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Saving content data"
                                        >
                                            <div className="inline-flex items-center py-1 w-auto font-medium dark:text-gray-200 text-gray-700">
                                                <i className="fa-solid fa-circle-notch fa-spin text-xl" />
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content={dataKind === "content" ? "Save Prompt" : "Save Title"}
                                        >
                                            <button
                                                onClick={handleManualSaveNewData}
                                                type="submit"
                                                className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                            >
                                                <i className="fa-solid fa-floppy-disk text-lg" />
                                            </button>
                                        </Tooltip>
                                    </>
                                )}

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Generate Audio"
                                >
                                    <button
                                        disabled={isGeneratingAudio ? true : false}
                                        onClick={() => toolsDataAudio?.data ? alertBeforeAction(handleGenerateAudio, "function2", "re-generate-audio", {}) : handleGenerateAudio()}
                                        type="submit"
                                        className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                    >
                                        {isGeneratingAudio ? (
                                            <i className="fa-solid fa-sync fa-spin text-lg" />
                                        ) : (
                                            <i className="fa-solid fa-retweet text-lg" />
                                        )}
                                    </button>
                                </Tooltip>

                                <Tooltip
                                    color="primary"
                                    placement="top"
                                    showArrow={true}
                                    content="Download audio"
                                >
                                    <button
                                        disabled={audioURL ? false : true}
                                        onClick={() => handleDownloadMediaFile(audioURL, audioName, "mp3", videoID)}
                                        type="submit"
                                        className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                    >
                                        {isDownloading[videoID] ? (
                                            <i className="fa-solid fa-sync fa-spin text-lg" />
                                        ) : (
                                            <i className="fa-solid fa-download text-lg" />
                                        )}
                                    </button>
                                </Tooltip>

                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className="grid grid-cols-1 xl:grid-cols-1 xl:gap-4">

                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                            <i className="fa-solid fa-pen-to-square w-4 h-4 text-gray-500 dark:text-gray-400" />
                        </div>
                        <input
                            onChange={(e) => handleAutoSaveNewData(e, "title")}
                            // placeholder={removeQuotes(title)}
                            value={removeQuotes(title)}
                            type="text"
                            id="input-group-1"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>

                    <textarea
                        onChange={(e) => handleAutoSaveNewData(e, "prompt")}
                        value={prompt}
                        rows="25"
                        className="p-3 rounded-lg text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        placeholder="Transcription..."
                        required
                        autoFocus
                    />
                </div> */}

                <div className="grid grid-cols-1 xl:grid-cols-1 xl:gap-4">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                            <i className="fa-solid fa-pen-to-square w-4 h-4 text-gray-500 dark:text-gray-400" />
                        </div>
                        <input
                            onChange={(e) => handleAutoSaveNewData(e, "title")}
                            value={title}
                            type="text"
                            id="input-group-1"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>

                    <div className="relative">

                        {filteredAudioStatus && isGeneratingAudio && (
                            <div className="absolute flex px-10 rounded-lg inset-0 items-center justify-center bg-black bg-opacity-50 z-10 backdrop-blur-sm">
                                <Progress
                                    size="md"
                                    radius="md"
                                    classNames={{
                                        base: "max-w-full px-3",
                                        track: "drop-shadow-md border border-default",
                                        indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
                                        label: "tracking-wider font-medium text-default-600",
                                        value: "text-foreground/60",
                                    }}
                                    label={filteredAudioStatus?.message || "Processing"}
                                    value={filteredAudioStatus?.current || 0}
                                    maxValue={filteredAudioStatus?.total || 100}
                                    showValueLabel={true}
                                />
                            </div>
                        )}

                        <textarea
                            onChange={(e) => handleAutoSaveNewData(e, "prompt")}
                            value={prompt}
                            rows="25"
                            className="w-full p-3 rounded-lg text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                            placeholder="Transcription..."
                            required
                            autoFocus
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VG_CustomMediaAudio;