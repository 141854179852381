// libraries
import React, { useContext } from "react";

// custom components
import CreditsUsageGraph from "./MainPages/CreditsUsageGraph";
import UserMonitorSystem from "./MainPages/UserMonitorSystem";
import UserActivities from "./MainPages/UserActivities";
import OnlineUsers from "./MainPages/OnlineUsers";
import CreatedUsers from "./MainPages/CreatedUsers";
import PopularTools from "./MainPages/PopularTools";
import UserAccounts from "./MainPages/UserAccounts";
import AdminContext from "../../components/Admin/AdminContext";
import consoleLog from "../../functions/consoleLog";
import Subscribers from "./MainPages/Subscribers";
import BillingActivities from "./MainPages/BillingActivities";

const MainPage = (props) => {

    // props
    const {
        //
    } = props;

    return (
        <>

            {/* Card 1 */}
            <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3">

                {/* Credits Usage Graph */}
                <CreditsUsageGraph />

                {/* User monitor systtem here */}
                {/* <UserMonitorSystem /> */}

                {/* User Activities */}
                <UserActivities />

            </div>

            {/* Card 2 */}
            <div className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-3">

                {/* Online Users Status */}
                <OnlineUsers />

                {/* Created Users status */}
                <CreatedUsers />

                {/* Subscribers status */}
                <Subscribers />

                {/* Popular Tools */}
                {/* <PopularTools /> */}

            </div>

            {/* Card 3 */}
            <div className="grid grid-cols-1 my-4 xl:grid-cols-1 xl:gap-4">

                {/* User Accounts List */}
                {/* <UserAccounts /> */}

                {/* Billing activities */}
                <BillingActivities />

            </div>

        </>
    );
};

export default MainPage;