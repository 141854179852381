// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";

// custom components
import AdminContext from "../../../components/Admin/AdminContext";
import consoleLog from "../../../functions/consoleLog";
import { sortDataIntoWeeks } from "../../../functions/adminFunctions";

const Subscribers = () => {

    // context method
    const {

        // others
        isMobile,
        selectedMonth,
        selectedYear,

        // subscribers record
        activeSubscribers,
        isFetchingSubscribers,

    } = useContext(AdminContext);

    // react useRef
    const chartRef = useRef(null);

    // react useState
    const [accounts, setAccounts] = useState([]);
    const [totalAccounts, setTotalAccounts] = useState(0);

    // console log activity
    // consoleLog("accounts", accounts);

    // sort data and date-time
    useEffect(() => {
        const sortData = async () => {

            // call function sort into weeks
            const sortedData = sortDataIntoWeeks(
                activeSubscribers, // array data
                selectedMonth, // selected month
                selectedYear, // selected year
                "count", // data kind
            );

            // set the result value
            setAccounts(sortedData?.totalsArray);
            setTotalAccounts(sortedData?.totalValue);

            // cosole log activity
            // consoleLog("sortedData", sortedData);
            // consoleLog("mappedData", sortedData?.totalsArray?.map((data) => ({ x: data?.week, y: data?.total })));
        };
        sortData();
    }, [activeSubscribers, selectedMonth, selectedYear]);

    useEffect(() => {
        const options = {
            colors: ["#1A56DB", "#FDBA8C"],
            series: [
                {
                    name: "Subscribers",
                    color: "#1A56DB",
                    data: accounts?.map((data) => ({ x: data?.week, y: data?.total })),
                },
            ],
            // Other chart options...
            chart: {
                type: "bar",
                height: "150px",
                fontFamily: "Inter, sans-serif",
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "80%",
                    borderRadiusApplication: "end",
                    borderRadius: 5,
                },
            },
            xaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },
            // Include the rest of your chart options here
        };

        // Check if ApexCharts is available and the ref is attached to the DOM element
        if (chartRef.current && typeof ApexCharts !== 'undefined') {
            // Create a new chart instance
            const chart = new ApexCharts(chartRef.current, options);
            // Render the chart
            chart.render();

            // Cleanup function to destroy chart instance when component unmounts
            return () => chart.destroy();
        }
    }, [isMobile, accounts, selectedMonth, selectedYear]);

    return (

        <div className="relative items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm  dark:border-gray-700  dark:bg-gray-800">

            {/* Loading indicator */}
            {isFetchingSubscribers && (
                <div className="absolute text-5xl inset-0 flex items-center justify-center backdrop-blur-lg z-50">
                    <i className="fa-solid fa-cog fa-spin" />
                </div>
            )}

            {/* Top Label */}
            <div className="w-full">
                {/* <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">Current Online</h3> */}
                {/* <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">25</span> */}
                <span className="text-2xl font-bold leading-none text-gray-900 dark:text-white">
                    {(totalAccounts).toLocaleString()}
                </span>
                <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
                    <span className="flex items-center mr-1.5 text-sm text-warning">
                        <i className="fa-solid fa-crown" />
                    </span>
                    Subscribers
                </p>
            </div>

            {/* Chart container tye bar */}
            <div className="text-gray-800" ref={chartRef} />

        </div>

    );
};

export default Subscribers;