import React, { useContext, useState } from "react";
import LeftSideMenu from "./BotChatBox/LeftSideMenu";
import ChatBox from "./BotChatBox/ChatBox";
import AdminContext from "../../../components/Admin/AdminContext";
import consoleLog from "../../../functions/consoleLog";

const BotChatBox = () => {

    // context method
    const {

        // others
        userData,

        // admin discord
        botChatsData,
        isFetchingBotChats,
        setRefetchBotChats,

    } = useContext(AdminContext);

    // variables
    const adminEmail = userData?.UserInfo?.email;

    // react useState
    const [selectedBotChat, setSelectedBotChat] = useState(botChatsData[0]);

    // filtered chat convos
    const filterChatConvos = botChatsData?.find((data) => data?.channelID === selectedBotChat?.channelID);


    // console log activity
    // consoleLog("botChatsData", botChatsData);
    // consoleLog("selectedBotChat", selectedBotChat);
    // consoleLog("filterChatConvos", filterChatConvos);

    return (
        <div className="flex h-[80vh] antialiased text-gray-800">
            <div className="flex flex-row h-full w-full overflow-x-hidden">

                {/* Left side menu */}
                <LeftSideMenu
                    botChatsData={botChatsData}
                    setSelectedBotChat={setSelectedBotChat}
                    selectedBotChat={selectedBotChat}
                    setRefetchBotChats={setRefetchBotChats}
                />

                {/* chat box */}
                <ChatBox
                    filterChatConvos={filterChatConvos}
                    setRefetchBotChats={setRefetchBotChats}
                    adminEmail={adminEmail}
                />

            </div>
        </div>
    );
};

export default BotChatBox;