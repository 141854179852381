// libraries
import React from "react";
import { Kbd, Switch, Tooltip, cn } from "@nextui-org/react";

const AO_Switch = (props) => {

    // props
    const {
        label,
        isChecked,
        handleToggle,
        content,
        version,
        isDisabled,
    } = props;

    return (
        <Switch
            isDisabled={isDisabled}
            isSelected={isChecked}
            onValueChange={handleToggle}
            color="secondary"
            classNames={{
                base: cn(
                    "inline-flex flex-row-reverse w-full max-w-[100%] bg-gray-400/60 hover:bg-gray-400/50 dark:bg-gray-600 dark:hover:bg-gray-600/85 items-center mb-1",
                    "justify-between cursor-pointer rounded-lg gap-2 px-1 py-2.5 border-2 border-transparent",
                    "data-[selected=true]:border-blue-400",
                ),
                wrapper: "p-0 h-4 overflow-visible",
                thumb: cn("w-6 h-6 border-2 shadow-lg",
                    "group-data-[hover=true]:border-blue-400",
                    //selected
                    "group-data-[selected=true]:ml-6",
                    // pressed
                    "group-data-[pressed=true]:w-7",
                    "group-data-[selected]:group-data-[pressed]:ml-4",
                ),
            }}
        >
            <div className="flex flex-col gap-1">
                <div className="text-md font-semibold">
                    {label}
                    {version && (
                        <Kbd className="ml-1 bg-purple-500 text-gray-50 font-semibold" keys={["shift"]}>v{version}</Kbd>
                    )}
                    <div className="float-left p-[0.15rem] mr-1 text-gray-800">

                        <Tooltip
                            color="primary"
                            placement="top"
                            showArrow={true}
                            content={content}
                        >
                            <i className="fa-solid fa-circle-question text-yellow-300 hover:text-yellow-400" />
                        </Tooltip>

                    </div>
                </div>
                {/* <p className="text-tiny text-default-400">
                    Get access to new features before they are released.
                </p> */}
            </div>
        </Switch>
    );
};

export default AO_Switch;