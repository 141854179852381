
// libraries
import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { io } from "socket.io-client";

// components and helpers
import { getSubscription, validateSubscription, verifySubscription } from "../API/subsAPI";
import SideNav from "../components/SideNav";

// custom components
import { createSRAccess, getShifyUserData, signUpToNewsLetter, verifyToken } from "../API/authAPI";
import consoleLog from "../functions/consoleLog";
import { ToastEffect } from "../functions/ToastEffect";
import UserContext from "./UserContext";
import { fetchProductData, getProductDetails } from "../API/productsAPI";
import { getDiscordData, getUserConfig, appConfig, getLiveCreditBalance } from "../API/account";
import { getItemWithExpiry, setItemWithExpiry } from "../functions/itemWithExpiry";
import { LoadingEffect4 } from "./SideNavMenu";
import { cachedToolsConfiguration, countDataFromArray, debounce, updateStatusArray } from "../functions/general";
import { getConfig, saveConfig } from "../pages/shinefy/Tools/API/configDBAPI";
import { fetchAllToolsData, fetchGeneratedVideos, fetchNotificationData, getGeneratedImages, updateCaptionData, updateContentData, updateSourceMediaContentData } from "../pages/shinefy/Tools/API/toolsAPI";
import { fetchArtisanSessions, fetchOwnBGM } from "../API/server";

const Layout = (props) => {

    // props
    const {
        logout,
    } = props;

    // other prio use state data
    const [userData, setUserData] = useState(null);
    const [discordData, setDiscordData] = useState(null);
    const [totalSumVideos, setTotalSumVideos] = useState(0);
    const [totalSumTranscriberData, setTotalSumTranscriberData] = useState(0);
    // const [totalSumNotifData, setTotalSumNotifData] = useState(0);

    // avatar
    const defeultAvatar = "user.jpg";
    const discordAvatar = discordData?.discordData?.avatar;
    const discordSource = `${process.env.REACT_APP_DISCORD_ENDPOINT}/avatars/${discordData?.discordData?.discord_id}/${discordData?.discordData?.avatar}.png`;
    const accountAvatar = userData?.UserInfo?.avatar;
    const userAvatar = accountAvatar ? accountAvatar : discordAvatar ? discordSource : defeultAvatar;

    // Paginate page calculation
    const itemsPerPage = 12;
    const galleryItemsPerPage = 30;
    const pageCount = Math.ceil(totalSumVideos / itemsPerPage);
    const transcriberPageCount = Math.ceil(totalSumTranscriberData / itemsPerPage);

    const [toolsDataParam, setToolsDataParam] = useState({});
    const [transcriberParam, setTranscriberParam] = useState({});
    const [notifParam, setNotifParam] = useState({});
    const [transcriberData, setTranscriberData] = useState([]);
    const [notifData, setNotifData] = useState([]);
    const [videos, setVideos] = useState([]);

    // use states
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [refetchUserData, setRefetchUserData] = useState(0);
    const [refetchUserConfig, setRefetchUserConfig] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCredits, setTotalCredits] = useState(0);
    const [userLiveCreditBalance, setUserLiveCreditBalance] = useState([]);
    const [appConfigData, setAppConfigData] = useState([]);
    const [userConfig, setUserConfig] = useState([]);
    const [socket, setSocket] = useState(null);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [toolsDataStatus, setToolsDataStatus] = useState([]);
    const [refetchData, setRefetchData] = useState(0);
    const [refetchToolsData, setRefetchToolsData] = useState(0);
    const [configData, setConfigData] = useState([]);
    const [refetchToolsConfigData, setRefetchToolsConfigData] = useState(0);
    const [refetchTranscriberData, setRefetchTranscriberData] = useState(0);
    const [refetchNotifData, setRefetchNotifData] = useState(0);
    const [videoData, setVideoData] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [artisanSessions, setArtisanSessions] = useState(null);
    const [ownBGM, setOwnBGM] = useState(null);
    const [artisanSession, setArtisanSession] = useState({});
    const [appProcessStatus, setAppProcessStatus] = useState({});
    const [isFetchingArtisanSessions, setIsFetchingArtisanSessions] = useState(false);
    const [isFetchingOwnBGM, setIsFetchingOwnBGM] = useState(false);
    const [refetchArtisanSessions, setRefetchArtisanSessions] = useState(0);
    const [refetchOwnBGM, setRefetchOwnBGM] = useState(0);
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);

    // console log activity
    // consoleLog("userData", userData);
    // consoleLog("discordSource", discordSource);

    //Web Socket
    useEffect(() => {
        const webSocket = () => {
            setSocket(io.connect(process.env.REACT_APP_SHINEFY_SERVER));
        };
        webSocket();
    }, []);

    // emit new user function
    async function newOnlineUser(email, type) {
        await socket?.emit("new-user", email, type);
    }

    useEffect(() => {

        // Initialize a function to check login session and verify subscription
        const userData = async () => {

            try {

                // enable loding state
                setIsLoading(true);

                // Get the URLSearchParams object from the current URL
                const urlParams = new URLSearchParams(window.location.search);

                // params
                const access = urlParams.get("access");
                const refresh = urlParams.get("refresh");
                const action = urlParams.get("action");

                // remove tokens from URL
                if (access || refresh || action) {
                    window.history.pushState("Remove", "Link", "my_plan");
                }

                // check if action is discord else shineranker
                if (action === "discord") {
                    // Only set the local storage and state if the access and refresh tokens exist
                    if (access && refresh) {
                        localStorage.setItem('access', access);
                        localStorage.setItem('refresh', refresh);
                    }
                } else {

                    // if access token exist
                    if (access) {

                        // fetch data using access token
                        const response = await getShifyUserData(access);

                        // getShifyUserData extracted values
                        const response_accessToken = response?.data?.access_token;
                        const response_refreshToken = response?.data?.refresh_token;

                        // set new items in local storage
                        localStorage.setItem('access', response_accessToken);
                        localStorage.setItem('refresh', response_refreshToken);

                        // execute "promise" delay
                        await new Promise(resolve => setTimeout(resolve, 4000)); // 4 seconds delay
                    }
                }

                // access and refresh from local storage
                const accessToken = localStorage.getItem('access');
                const refreshToken = localStorage.getItem('refresh');

                // required data
                const requiredData = {
                    access: accessToken,
                    refresh: refreshToken,
                };

                // Verify token to check if the user is authorized
                const tokenResponse = await verifyToken(requiredData);

                // verifyToken extracted values
                const tokenResponseMessage = tokenResponse?.data?.message;
                const tokenResponseEmail = tokenResponse?.data?.UserInfo?.email;
                const tokenResponseData = tokenResponse?.data;
                const tokenResponseRole = tokenResponse?.data?.UserInfo?.role;
                const tokenResponseAccess = tokenResponse?.data?.access;
                const tokenResponseFname = tokenResponse?.data?.UserInfo?.first_name;
                const tokenResponseLname = tokenResponse?.data?.UserInfo?.last_name;

                // conditional function for "tokenResponse"
                if (tokenResponseMessage === "Unauthorized") {
                    return; // do nothing
                } else if (tokenResponseMessage === "refreshed") {
                    localStorage.setItem('access', tokenResponseAccess); // save new items to the local storage
                    localStorage.removeItem('tokenBalance'); // remove token balance
                } else if (tokenResponseMessage === "refreshExpired") {
                    // remove items to the local storage
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    window.location.href = "/"; // send the user back to the main page which is the login page
                }

                // fetch for image2video records
                setToolsDataParam({
                    email: tokenResponseEmail, // user email
                    start: itemsPerPage, // how many data per page
                    type: process.env.REACT_APP_VIDEO_GEN_ID, // data type ID
                    offset: 0, // offset always starts with 0
                });

                // fetch for transcriber records
                setTranscriberParam({
                    email: tokenResponseEmail, // user email
                    start: itemsPerPage, // how many data per page
                    type: process.env.REACT_APP_VIDEO_TRANSCRIBER_ID, // data type ID
                    offset: 0, // offset always starts with 0
                });

                // fetch for notification records
                setNotifParam({
                    auth_client: process.env.REACT_APP_AUTH, // user authentication
                    email: tokenResponseEmail, // user email
                    limit: itemsPerPage, // how many data per page
                    offset: 0 // offset always starts with 0
                });

                // emit the new user to the server
                await newOnlineUser(tokenResponseEmail, tokenResponseRole);

                // Set user data if the user is authorized
                setUserData(tokenResponseData);

                // Get user subscription from mongoDB using the user's email
                const subscriptionResponse = await getSubscription(tokenResponseEmail);

                // extracted value
                const subscriptionResponseStatus = subscriptionResponse?.data?.message;
                const subscriptionID = subscriptionResponse?.data?.status?.subscription_id;
                const subscriptionItemName = subscriptionResponse?.data?.status?.item_name;

                // create SR access token
                const SRAccesstoken = await createSRAccess({
                    email: tokenResponseEmail,
                    firstname: tokenResponseFname,
                    lastname: tokenResponseLname,
                    subscriptionID: subscriptionItemName
                });

                // save SR access token
                localStorage.setItem('SRAccesstoken', SRAccesstoken?.data?.access_token);

                // console log activity
                // consoleLog("subscriptionResponse", subscriptionResponse);
                // consoleLog("tokenResponseData", tokenResponseData);
                // consoleLog("SRAccesstoken", SRAccesstoken);

                // check if subscription is expired
                if (subscriptionResponseStatus === "expiredSubscription") {
                    await validateSubscription({ email: tokenResponseEmail }); // validate subscription
                }

                // Get live credit balance from server
                const liveCreditBalance = await getLiveCreditBalance(tokenResponseEmail);

                // Get discord info
                const discordData = await getDiscordData(tokenResponseEmail);

                // Get payment values
                const appConfigResponse = await appConfig();

                // set data value
                setAppConfigData(appConfigResponse?.data);
                setSubscriptionData(subscriptionResponse?.data);
                setDiscordData(discordData?.data);
                setTotalCredits(liveCreditBalance?.data?.totalBalance);
                setUserLiveCreditBalance(liveCreditBalance?.data);

                // console log events
                // consoleLog("liveCreditBalance", liveCreditBalance?.data);
                consoleLog("subscriptionData", subscriptionResponse?.data);

                // disable loding state
                setIsLoading(false);

            } catch (error) {
                // Catch and log any errors
                ToastEffect("error", "Something went wrong! #userData");
                consoleLog("#userData > error", error);
            }

        };

        // Call the function to initialize user session
        userData();

    }, [refetchUserData, socket]);

    // useEffect for fetching image2video records
    useEffect(() => {
        fetchToolsData();
    }, [refetchToolsData, refetchData, userData]);

    // useEffect for fetching image2video records
    useEffect(() => {
        fetchTranscriberData();
    }, [refetchTranscriberData, refetchData, userData]);

    // useEffect for fetching notification records
    useEffect(() => {
        fetchNotifications();
    }, [refetchNotifData, refetchData, userData]);

    // fetch image2video records
    const fetchToolsData = async () => {

        // extracted user email
        const email = userData?.UserInfo?.email;

        // check if email exist
        if (!email) { return; }

        // execute fetching data
        await fetchAllToolsData(toolsDataParam)
            .then((response) => {
                setTotalSumVideos(Number(response?.data?.total_count));
                setVideos(response?.data?.results);
                consoleLog("#fetchToolsData", response?.data);
            });
    };

    // fetch transcriber records
    const fetchTranscriberData = async () => {

        // extracted user email
        const email = userData?.UserInfo?.email;

        // check if email exist
        if (!email) { return; }

        // execute fetching data
        await fetchAllToolsData(transcriberParam)
            .then((response) => {
                setTotalSumTranscriberData(Number(response?.data?.total_count));
                setTranscriberData(response?.data?.results);
                consoleLog("#fetchTranscriberData", response?.data);
            });
    };

    // fetch notification records
    const fetchNotifications = async () => {

        // extracted user email
        const email = userData?.UserInfo?.email;

        // check if email exist
        if (!email) { return; }

        // execute fetching data
        await fetchNotificationData(notifParam)
            .then(async (response) => {
                // setTotalSumNotifData(Number(response?.data?.total_count));
                // const dataCount = await countDataFromArray(response?.data?.results, "isRead", false); // call fnction that counts the data conditionally
                // setTotalSumNotifData(dataCount); // set the counted value
                setNotifData(response?.data?.results);
                consoleLog("#fetchNotificationData", response?.data);
            });
    };

    // fetch videos for video gallery
    const getVideoData = async (requiredData) => {

        if (!requiredData) {
            // required param
            requiredData = {
                email: userData?.UserInfo?.email,
                start: galleryItemsPerPage,
                type: process.env.REACT_APP_VIDEO_GEN_ID,
                offset: 0,
                kind: "reload"
            };
        }

        // execute fetch data 
        await fetchGeneratedVideos(requiredData)
            .then((response) => {

                if (requiredData?.kind === "scroll") {
                    setVideoData((prev) => [...prev, ...response?.data]);
                } else {
                    setVideoData(response?.data);
                }

            }).catch((error) => {
                consoleLog("Error fetching video data:", error);
            });
    };

    // fetch image for video gallery
    const getImageData = async (requiredData) => {

        if (!requiredData) {
            requiredData = {
                email: userData?.UserInfo?.email,
                start: galleryItemsPerPage,
                media: "image",
                offset: 0,
                kind: "reload"
            };
        }

        // execute fetch data 
        await getGeneratedImages(requiredData)
            .then((response) => {

                if (requiredData?.kind === "scroll") {
                    setImageData((prev) => [...prev, ...response?.data]);
                } else {
                    setImageData(response?.data);
                }

            }).catch((error) => {
                consoleLog("Error fetching image data:", error);
            });
    };

    // emited data from socket
    useEffect(() => {

        // live online users
        const updateSocket = () => {
            socket?.on("onlineUsers", data => {
                if (data) {
                    // setOnlineUsers(data.onlineUsers);
                    // consoleLog("components > Layout.js > onlineUsers", data.onlineUsers);
                }
            });
        };

        // new token balance
        socket?.on("newCreditBalance", data => {
            if (data) {
                setRefetchUserData(oldVal => oldVal + 1);
            }
        });
        updateSocket();

        // cancelled subscription
        socket?.on("SubscriptionCancelled", data => {
            if (data) {
                localStorage.removeItem('tokenBalance');
                window.location.reload();
            }
        });

        // reload user if online
        socket?.on("ReloadUser", data => {
            if (data) {
                window.location.reload();
            }
        });

        // subscribing or rebilling
        socket?.on("SubscriptionPayment", data => {
            if (data) {
                localStorage.removeItem('tokenBalance');
                localStorage.setItem('access', data);
                window.location.reload();
            }
        });

        // Tools process status
        socket?.on("ToolsDataStatus", data => {
            if (data) {
                // process data from socket
                setToolsDataStatus(currentArray => updateStatusArray(currentArray, data, setRefetchData));

                // console log activity
                // consoleLog("ToolsDataStatus", data);
            }
        });

        // receive incoming status from apps
        socket?.on("app-data-status", data => {
            if (data) {
                // set data for global access
                setAppProcessStatus(data);

                // console log activity
                // consoleLog("app-data-status", data);
            }
        });

        // catch incoming user notification
        socket?.on("IncomingUserNotif", data => {
            if (data) {
                // refetch user notification data
                setRefetchNotifData((value) => value + 1);
            }
        });

        // receive incoming socket for "resolvedIssue"
        socket?.on("ResolvedIssue", data => {
            if (data) {
                setRefetchNotifData((value) => value + 1); // refetch user notification data
                setRefetchToolsData((value) => value + 1); // refetch tools data
            }
        });

        updateSocket();
    }, [socket]);

    // get userconfig
    useEffect(() => {

        if (!userData?.UserInfo?.email) { return; }

        const userConfig = async () => {
            // Get user config
            const userConfig = await getUserConfig(userData?.UserInfo?.email);

            setUserConfig(userConfig?.data);
            // consoleLog("", userConfig?.data);
        };
        userConfig();
    }, [userData, refetchUserConfig]);

    // fetch Tools Config Data
    useEffect(() => {
        // fetch config data from database
        const getConfigData = async () => {

            // variables
            const email = userData?.UserInfo?.email;

            // check first if email exist and return if not
            if (!email) { return; }

            // execute the fetch function
            await getConfig(email, "/general-purpose")
                .then(async (response) => { // .then response
                    // set the result data to useState to be reusable
                    setConfigData(response?.data);
                    // cached Tools Confgigurations
                    await cachedToolsConfiguration("configData", response?.data);
                }).catch((err) => { // catch error
                    consoleLog("Error! > #getConfigData", err); // console log for development only
                    ToastEffect("error", "Something went wrong! #getConfigData"); // trigger toast effect to display error
                });
        };
        getConfigData();
    }, [userData, refetchToolsConfigData]);

    // fetch subscription pricing
    useEffect(() => {
        const getPricing = async () => {

            // Try to get chat history from local storage first
            const cachedChatHistory = getItemWithExpiry("products");
            // consoleLog("components > Layout.js > getPricing", cachedChatHistory);

            if (cachedChatHistory) {
                setProductDetails(cachedChatHistory);
            } else {
                // Get product details
                const productIds = process.env.REACT_APP_PRODUCT_IDS.split(',');
                const products = await getProductDetails(productIds);
                setProductDetails(products);
                // consoleLog("components > Layout.js > getPricing", products?.productDetails);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry('products', products, 3600000);
            }

        };
        getPricing();
    }, [refetchUserData]);

    // fetch product data
    useEffect(() => {

        const getProductData = async () => {

            const result = await fetchProductData();
            setProductData(result?.data);

            // console log activity
            // consoleLog("ProductData", result?.data);

        };
        getProductData();

    }, []);

    // on reload credit balance update
    useEffect(() => {

        const tokenBalance = async () => {

            // Try to get chat history from local storage first
            const creditBalance = getItemWithExpiry("tokenBalance");

            if (creditBalance) {
                if (userLiveCreditBalance?.isPaidCreditsValid) {
                    // If cached data is available, use it
                    setTotalCredits(creditBalance);
                } else {
                    // If no cached data, re-fetch data
                    setRefetchUserData(oldVal => oldVal + 1);

                    // Store the fetched data in local storage with a 1-hour expiry time
                    setItemWithExpiry("tokenBalance", totalCredits, 3600000);
                }
            } else {
                // If no cached data, re-fetch data
                setRefetchUserData(oldVal => oldVal + 1);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry("tokenBalance", totalCredits, 3600000);
                // consoleLog("totalCredits", totalCredits);
            }
        };
        tokenBalance();
    }, [totalCredits]);


    // fetch artisan sessions
    useEffect(() => {
        const fetchData = async () => {

            // return if there is no "userData"
            if (!userData) return;

            // extracted userData value
            const email = userData?.UserInfo?.email;

            // set loading mark when fetching data
            setIsFetchingArtisanSessions(true);

            // call fetch function that will fetch the data
            const artisanSessions = await fetchArtisanSessions({ email, offset: 0 });
            setArtisanSessions(artisanSessions?.data);
            setArtisanSession(artisanSessions?.data?.message[0]);

            // console log activity
            consoleLog("artisanSessions", artisanSessions?.data);

            // disable loading mark if done fetching data
            setIsFetchingArtisanSessions(false);

        };
        fetchData();
    }, [userData, refetchArtisanSessions]);

    // fetch own BGM data
    useEffect(() => {
        const fetchData = async () => {

            // return if there is no "userData"
            if (!userData) return;

            // extracted userData value
            const email = userData?.UserInfo?.email;

            // set loading mark when fetching data
            setIsFetchingOwnBGM(true);

            // required data
            const requiredData = {
                email: email,
                kind: "own_BGM",
                refrence_id: "image2video",
            };

            // call fetch function that will fetch the data
            const ownBGMData = await fetchOwnBGM(requiredData);
            setOwnBGM(ownBGMData?.data);

            // console log activity
            consoleLog("artisanSessions", artisanSessions?.data);

            // disable loading mark if done fetching data
            setIsFetchingOwnBGM(false);

        };
        fetchData();
    }, [userData, refetchOwnBGM]);

    // Sign Up NewsLetter Function
    const handleSignUpNewsLetter = async () => {
        const user_email = userData?.UserInfo?.email;
        const first_name = userData?.first_name || "shinefy_user";

        const data = {
            email: user_email,
            name: first_name
        };

        // set signing up to true
        setIsSigningUp(true);

        await signUpToNewsLetter(data)
            .then((response) => {
                // consoleLog("response", response);
                setIsSigningUp(false);
                window.location.reload();
            })
            .catch((error) => {
                setIsSigningUp(false);
                ToastEffect("error", "Something went wrong!");
                consoleLog("Error! #signUpToNewsLetter", error);
            });
        // console.log("user_email", user_email, first_name);
    };

    // ----------------------------------- debounce methods -----------------------------------

    // debounce saveConfig function. this is to prevent spamming and abuse of buttons. delays 1000 = 1sec
    const debouncedSaveConfig = useCallback(debounce(async (email, pathname, kind, value) => {

        // console log activity
        consoleLog(kind, value);

        // save configuration to the database
        saveConfig(email, pathname, kind, value);

    }, 1000), []);

    // debounce updateContentData function. this is to prevent spamming and abuse of buttons. delays 1000 = 1sec
    const debounceUpdateContentData = useCallback(debounce(async (dataID, kind, value) => {

        // required data
        const requiredData = {
            data_id: dataID,
            user_auth: process.env.REACT_APP_AUTH,
            kind: kind,
            value: value,
        };

        // console log activity
        consoleLog(kind, value);
        // consoleLog("requiredData", requiredData);

        // execute function
        return await updateContentData(requiredData);

    }, 1000), []);

    // debounce updateSourceMediaContentData function. this is to prevent spamming and abuse of buttons. delays 1000 = 1sec
    const debounceUpdateSourceMediaContentData = useCallback(debounce(async (dataID, kind, value) => {

        // required data
        const requiredData = {
            data_id: dataID,
            user_auth: process.env.REACT_APP_AUTH,
            kind: kind,
            value: value,
        };

        // console log activity
        consoleLog(kind, value);

        // execute function
        return await updateSourceMediaContentData(requiredData);

    }, 1000), []);

    // debounce updateCaptionData function. this is to prevent spamming and abuse of buttons. delays 1000 = 1sec
    const debounceUpdateCaptionData = useCallback(debounce(async (task_id, kind, type, idx, newValue) => {

        // required data
        const requiredData = {
            task_id: task_id,
            user_auth: process.env.REACT_APP_AUTH,
            data_kind: kind,
            data_type: type,
            data_idx: idx,
            new_value: newValue,
        };

        // console log activity
        consoleLog("requiredData", requiredData);

        // execute function
        return await updateCaptionData(requiredData);

    }, 1000), []);

    return (
        <div>

            {/* side nav */}
            <SideNav
                isLoading={isLoading}
                userData={userData}
                userAvatar={userAvatar}
                subscriptionData={subscriptionData}
                discordData={discordData}
                logout={logout}
                notifData={notifData}
                // totalSumNotifData={totalSumNotifData}
                isLoadingIframe={isLoadingIframe}
            />

            {/* context main page */}
            <div className="sm:ml-64 h-screen relative">
                <section className="bg-gray-900 min-h-screen backgroundCustom">
                    <div className="mx-auto">
                        <UserContext.Provider value={{

                            // Others
                            userData,
                            userAvatar,
                            subscriptionData,
                            userLiveCreditBalance,
                            productDetails,
                            productData,
                            discordData,
                            appConfigData,
                            configData,
                            userConfig,
                            setRefetchUserData,
                            setRefetchUserConfig,
                            setRefetchToolsConfigData,
                            newsLetter: { handleSignUpNewsLetter, isSigningUp },
                            toolsDataStatus,
                            refetchData, // this will trigger other update states
                            isLoading,

                            // For image2video data params
                            setToolsDataParam,
                            toolsDataParam,
                            setVideos,
                            videos,
                            itemsPerPage,
                            totalSumVideos,
                            pageCount,
                            setRefetchToolsData,

                            // For transcriber data params
                            transcriberData,
                            setTranscriberData,
                            totalSumTranscriberData,
                            setTranscriberParam,
                            transcriberParam,
                            transcriberPageCount,
                            setRefetchTranscriberData,

                            // For notification data params
                            notifData,
                            setNotifData,
                            // totalSumNotifData,
                            // setTotalSumNotifData,
                            setNotifParam,
                            notifParam,
                            refetchNotifData,
                            setRefetchNotifData,

                            // For gallery Videos
                            videoData,
                            setVideoData,
                            getVideoData,

                            // For gallery Images
                            imageData,
                            setImageData,
                            getImageData,

                            // debounce saveConfig function
                            debouncedSaveConfig,

                            // debounce updateContentData function
                            debounceUpdateContentData,

                            // debounce updateSourceMediaContentData function
                            debounceUpdateSourceMediaContentData,

                            // debounce updateCaptionData function
                            debounceUpdateCaptionData,

                            // content artisan
                            artisanSession,
                            setArtisanSession,
                            artisanSessions,
                            setArtisanSessions,
                            isFetchingArtisanSessions,
                            setRefetchArtisanSessions,

                            // app process progress status
                            appProcessStatus,

                            // own BGM
                            ownBGM,
                            isFetchingOwnBGM,
                            setRefetchOwnBGM,

                            // iframe
                            setIsLoadingIframe,
                            isLoadingIframe,

                        }}>
                            {isLoading ? (
                                < LoadingEffect4 />
                            ) : (
                                <>
                                    <Outlet />
                                    {/* news letter popup disabled */}
                                    {/* {!userData?.UserInfo?.news_letter && (
                                        <PopUpSweetAlert
                                            handleFunction1={``}
                                            handleFunction2={``}
                                            kind={"News-Letter"}
                                            others={``}
                                        />
                                    )} */}
                                </>
                            )}
                        </UserContext.Provider>
                        {/* button modal component that handles the clearing of local storage */}
                        {/* <ClearStorageModalButton currentPage={pathname.replace(/^\//, '')} /> */}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Layout;
