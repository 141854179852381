// libraries
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Tab, Tabs } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import UserContext from "../../../../../components/UserContext";
import { fetchMediaData } from "../../API/toolsAPI";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import VG_CustomMediaAudio from "./VG_CustomMediaAudio";
import { addMarkerToData, cachedToolsConfiguration, getCachedToolsConfiguration, getCurrentTimestamp, removeMarkedData } from "../../../../../functions/general";
import handleDownload from "../../functions/download";
import VG_CustomMediaSource from "./VG_CustomMediaSource";
import VG_CustomMediaRebuild from "./VG_CustomMediaRebuild";
import ErrorImage from "../../Images/ErrorImage.png";
import VG_CustomMediaCaption from "./VG_CustomMediaCaption";

// Navigating here has only two options: through clicking "customize" or via URL.
const VG_CustomMedia = (props) => {

    // props
    const {
        mediaSoureceData,
        enableVoice,
        enableLanguange,
        languageID,
        languageName,
        elevenLabsAPIKey,
        defaultVoiceName,
        voiceId,
        isOldUser,
        subscriptionData,
        voiceOwnAPI,
        isMobile,
        customAudioCreditCalculation,
        voiceModelList,
        accuracyOwnAPI,
        enableAccuracy,
        midjourneyAPIKey,
        imageTheme,
        customImageThemePrompt,
        setIsCustomMedia,
        selectedTalkingPhoto,
        avatarOption,
        avatarId,
        enableAvatar,
        toolsDataParam,
        setRefetchToolsData,
        backgroundMusic,
        addAnimation,
        enableCaption,
        videoAspectRatio,
        createCreditCalculation,
        creditCalculation,
        isCheckedAccuracy,
        setSelected,
        selected,
        setDisabledOptions,
        videoSlideOption,
        isCheckedCustomBGM,
        filteredOwnBGMData,
    } = props;

    // context method
    const {

        // Others
        userData,
        configData,

        // For image2video data params
        setVideos,
        videos,

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // react components
    const location = useLocation();
    const navigate = useNavigate();

    // extract path name
    const { pathname, search } = location;

    // Use URLSearchParams with the `search` string from location
    const queryParams = new URLSearchParams(search);

    // extract the "p" and "id" for custom media
    const URLPage = queryParams.get('p');
    const URLTaskID = queryParams.get('id'); // from URL when access via URL or reload
    const { task_id } = mediaSoureceData; // extract task id

    // variables
    const userEmail = userData?.UserInfo?.email; // extract the email
    const taskID = URLTaskID ? URLTaskID : task_id; // conditional task_id filter

    // filter videoData
    const excludedStatuses = ['error', 'fail', 'failed', 'accuracy_video'];
    // const videoData = videos.filter(video => video.task_id === taskID)[0];
    const videoData = videos.filter(video =>
        video?.task_id === taskID &&
        !excludedStatuses.includes(video?.status) &&
        !excludedStatuses.includes(video?.content_url)
    )[0]; // filter the specific data and [0] to fetch the first array

    // react components
    // const [selected, setSelected] = useState(
    //     getCachedToolsConfiguration("CM_SelectedTab", configData)?.config || "audio"
    // );

    const [refetchData, setRefetchData] = useState(0);
    const [toolsDataAudio, setToolsDataAudio] = useState([]);
    const [toolsDataImage, setToolsDataImage] = useState([]);
    const [isDownloading, setIsDownloading] = useState({});
    const [showImageRedMark, setShowImageRedMark] = useState(false);
    const [tabTitle, setTabTitle] = useState("");
    const [imagesLoading, setImagesLoading] = useState(true);
    // const [images, setImages] = useState([]);

    // console log activity
    // consoleLog("pathname", pathname);
    // consoleLog("userEmail", userEmail);
    // consoleLog("URLTaskID", URLTaskID);
    // consoleLog("toolsDataAudio", toolsDataAudio);
    // consoleLog("videoData", videoData);
    // consoleLog("imagesLoading", imagesLoading);

    /** Audio source and Filtered images */
    const audioSrc = toolsDataAudio?.data;

    // filter images and get images that has error state
    const images = toolsDataImage.map(image => image.data === "error" ? {
        image: ErrorImage,
        image_name: "",
        total_seconds: parseFloat(image.duration) || 3,
        text: image?.content || "",
        number: image?.number || ""

    } : {
        image: image?.data,
        image_name: "",
        total_seconds: parseFloat(image.duration),
        text: image?.content,
        number: image?.number
    });

    // handle selected tab
    const handleSelectedTab = (e) => {

        // extracted data and variables
        const selectedTab = e;
        // const kind = "CM_SelectedTab";

        // set selected tab value
        setSelected(selectedTab);

        // cached config to localstorage
        // cachedToolsConfiguration(kind, selectedTab);

        // // Call the debounced function
        // debouncedSaveConfig(kind, userEmail, pathname, kind, selectedTab);

    };

    // fetch media data via email and task_id
    useEffect(() => {

        // dont execute if email and task ID does not exist
        if (!userEmail || (!URLTaskID && !task_id)) { return; }

        // function to fetch media data
        const handleFetchMediaData = async () => {

            setImagesLoading(true);

            // required data
            const requiredData = {
                user_auth: process.env.REACT_APP_AUTH,
                user_email: userEmail,
                task_id: taskID
            };

            // console activity log
            // consoleLog("requiredData", requiredData);

            await fetchMediaData(requiredData)
                .then((response) => {

                    // console log activity
                    // consoleLog("fetchMediaData", response?.data);

                    // Filter the response data for items where kind is 'audio'
                    const audioData = response?.data.filter(item => item.kind === "audio");

                    // Filter the response data for items where kind is 'image', 'video', 'custom_video' and 'custom_image'
                    const imageData = response?.data.filter(item => (
                        item.kind === "image"
                        || item.kind === "video"
                        || item.kind === "custom_video"
                        || item.kind === "custom_image"
                    ));

                    if (videoData) {
                        setToolsDataAudio(audioData[0] || ""); // Set filtered audio data
                        setToolsDataImage(imageData); // set filtered image data
                    }

                    setImagesLoading(false);

                }).catch((error) => {
                    ToastEffect("error", "Something went wrong! #handleFetchMediaData");
                    consoleLog("VG_CustomMedia.jsx - handleFetchMediaData", error);
                });
        };

        handleFetchMediaData(); // call and execute the function

    }, [mediaSoureceData, refetchData]); // Dependency array is empty, so this effect runs once after the initial render

    // handle download media file
    const handleDownloadMediaFile = async (mediaURL, mediaName, kind, dataID) => {

        // check if videoData exist
        if (!videoData) { return; }

        // new name
        const newName = `${mediaName}_${getCurrentTimestamp()}`;

        // call marker function and insert data
        await addMarkerToData(setIsDownloading, dataID);

        // execute download process
        await handleDownload(mediaURL, newName, kind)
            .then(async () => {
                // call marker to remove marked data
                await removeMarkedData(setIsDownloading, dataID);
            }).catch(async (error) => {
                // call marker to remove marked data
                await removeMarkedData(setIsDownloading, dataID);
                ToastEffect("error", "Error downloading media #handleDownloadMediaFile");
                consoleLog("Error downloading media", error);
            });
    };

    // navigates back to main page
    const handleGoBack = () => {
        navigate("/general-purpose");
        // window.location.href = "/";
        setIsCustomMedia(false);
        setDisabledOptions({});
    };

    useEffect(() => {
        // handle selected key
        const selectedTabKey = () => {
            switch (selected) {
                case "audio": return setTabTitle("Customize Audio Source");
                case "media": return setTabTitle("Media Source (Videos & Images)");
                case "caption": return setTabTitle("Customize & Rebuild Caption");
                default: return setTabTitle("Unknown Selected tab");
            }
        };
        selectedTabKey();
    }, [selected]);

    return (
        <div className="flex w-full flex-col p-5 h-screen overflow-auto">

            <div className="flex items-center justify-between bg-gray-200 dark:bg-gray-800 w-full p-[0.50rem] mb-3 rounded-lg">
                <button onClick={handleGoBack} className="flex items-center space-x-2 hover:bg-gray-300 dark:hover:bg-gray-700 p-2 rounded-lg">
                    <i className="fa-solid fa-angle-left" />
                    <span>Back</span>
                </button>
                <p className="font-semibold text-xl">{tabTitle}</p>
                <div></div>
            </div>

            <Tabs
                aria-label="Options"
                color="primary"
                variant="solid"
                selectedKey={selected}
                onSelectionChange={handleSelectedTab}
                placement="top"
            >
                {/* <Tab
                    key="back"
                    title={
                        <div onClick={handleGoBack} className="flex items-center space-x-2">
                            <i className="fa-solid fa-angle-left" />
                            <span>Back</span>
                        </div>
                    }
                >
                    <Card>
                        <CardBody>
                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </CardBody>
                    </Card>
                </Tab> */}

                <Tab
                    key="audio"
                    title={
                        <div className="flex items-center space-x-2">
                            <i className="fa-solid fa-volume-high" />
                            <span>Audio</span>
                        </div>
                    }
                >
                    <Card>
                        <CardBody>
                            <VG_CustomMediaAudio
                                setRefetchData={setRefetchData}
                                toolsDataAudio={toolsDataAudio}
                                enableVoice={enableVoice}
                                enableLanguange={enableLanguange}
                                languageID={languageID}
                                languageName={languageName}
                                elevenLabsAPIKey={elevenLabsAPIKey}
                                defaultVoiceName={defaultVoiceName}
                                voiceId={voiceId}
                                isOldUser={isOldUser}
                                subscriptionData={subscriptionData}
                                voiceOwnAPI={voiceOwnAPI}
                                isMobile={isMobile}
                                customAudioCreditCalculation={customAudioCreditCalculation}
                                videoData={videoData}
                                videos={videos}
                                setVideos={setVideos}
                                handleDownloadMediaFile={handleDownloadMediaFile}
                                isDownloading={isDownloading}
                                voiceModelList={voiceModelList}
                            />
                        </CardBody>
                    </Card>

                </Tab>
                <Tab
                    key="media"
                    title={
                        <div className="flex items-center space-x-2">
                            <i className="fa-solid fa-photo-film" />
                            <span>Media</span>
                        </div>
                    }
                >
                    <Card>
                        <CardBody>
                            <VG_CustomMediaSource
                                setRefetchData={setRefetchData}
                                accuracyOwnAPI={accuracyOwnAPI}
                                subscriptionData={subscriptionData}
                                videoData={videoData}
                                isOldUser={isOldUser}
                                enableAccuracy={enableAccuracy}
                                midjourneyAPIKey={midjourneyAPIKey}
                                toolsDataImage={toolsDataImage}
                                isMobile={isMobile}
                                showImageRedMark={showImageRedMark}
                                imageTheme={imageTheme}
                                customImageThemePrompt={customImageThemePrompt}
                                setToolsDataImage={setToolsDataImage}
                                handleDownloadMediaFile={handleDownloadMediaFile}
                                isDownloading={isDownloading}
                                images={images}
                                audioSrc={audioSrc}
                                toolsDataAudio={toolsDataAudio}
                                setShowImageRedMark={setShowImageRedMark}
                                avatarOption={avatarOption}
                                enableAvatar={enableAvatar}
                                avatarId={avatarId}
                                selectedTalkingPhoto={selectedTalkingPhoto}
                                enableVoice={enableVoice}
                                enableLanguange={enableLanguange}
                                languageID={languageID}
                                languageName={languageName}
                                createCreditCalculation={createCreditCalculation}
                                videoAspectRatio={videoAspectRatio}
                                addAnimation={addAnimation}
                                enableCaption={enableCaption}
                                backgroundMusic={backgroundMusic}
                                setRefetchToolsData={setRefetchToolsData}
                                videoSlideOption={videoSlideOption}
                                imagesLoading={imagesLoading}
                                isCheckedCustomBGM={isCheckedCustomBGM}
                                filteredOwnBGMData={filteredOwnBGMData}
                            />
                        </CardBody>
                    </Card>
                </Tab>
                <Tab
                    key="caption"
                    title={
                        <div className="flex items-center space-x-2">
                            <i className="fa-solid fa-closed-captioning" />
                            <span>Caption</span>
                        </div>
                    }
                >
                    <Card>
                        <CardBody>
                            <VG_CustomMediaCaption
                                videoData={videoData}
                                toolsDataAudio={toolsDataAudio}
                                setRefetchData={setRefetchData}
                                handleDownloadMediaFile={handleDownloadMediaFile}
                                isDownloading={isDownloading}
                                subscriptionData={subscriptionData}
                                isOldUser={isOldUser}
                                backgroundMusic={backgroundMusic}
                            />
                            {/* <p>This feature is not yet available. It will allow you to edit, adjust, and change caption fonts.</p> */}
                        </CardBody>
                    </Card>
                </Tab>
                {/* <Tab
                    key="rebuild"
                    title={
                        <div className="flex items-center space-x-2">
                            <i className="fa-solid fa-screwdriver-wrench" />
                            <span>Rebuild</span>
                        </div>
                    }
                >
                    <Card>
                        <CardBody>
                            <VG_CustomMediaRebuild
                                creditCalculation={creditCalculation}
                                createCreditCalculation={createCreditCalculation}
                                toolsDataAudio={toolsDataAudio}
                                toolsDataImage={toolsDataImage}
                                videoData={videoData}
                                videoAspectRatio={videoAspectRatio}
                                enableCaption={enableCaption}
                                addAnimation={addAnimation}
                                backgroundMusic={backgroundMusic}
                                isOldUser={isOldUser}
                                setRefetchToolsData={setRefetchToolsData}
                                toolsDataParam={toolsDataParam}
                                enableAvatar={enableAvatar}
                                avatarId={avatarId}
                                avatarOption={avatarOption}
                                selectedTalkingPhoto={selectedTalkingPhoto}
                                enableVoice={enableVoice}
                                enableLanguange={enableLanguange}
                                languageID={languageID}
                                languageName={languageName}
                                // containsUrl={containsUrl}
                                // handlePlayVideo={handlePlayVideo}
                                setShowImageRedMark={setShowImageRedMark}
                                images={images}
                                audioSrc={audioSrc}
                            />
                        </CardBody>
                    </Card>
                </Tab> */}

            </Tabs>

        </div>
    );
};

export default VG_CustomMedia;