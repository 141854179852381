// libraries
import React from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Tooltip } from "@nextui-org/react";

// images
import NotAvailableImage from "../../pages/shinefy/Tools/Images/NotAvailable.png";

// custom components
import consoleLog from "../../functions/consoleLog";

const MediaView = (props) => {

    // props
    const {
        handleDownloadMediaFile,
        isOpen,
        onClose,
        isDownloading,
        modalData,
        videoData,
    } = props;

    // variables
    let mediaSrc;
    let title;
    let dataID;
    let aspectRatio;
    let kind;

    // react components
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasError, setHasError] = React.useState(false);

    // console log activity
    // consoleLog("modalData", modalData);
    // consoleLog("videoData", videoData);

    // aspectRatio Checker
    const aspectRatioData = videoData?.aspect_ratio || modalData?.ratio || modalData?.aspect_ratio || (modalData?.orientation ?? '').toLowerCase();

    // function check media kind
    const checkMediaKind = (kind) => {
        switch (kind) {
            case "custom_video": return { kind: "video", extension: "mp4" };
            case "custom_image": return { kind: "image", extension: "png" };
            case "video": return { kind: "video", extension: "mp4" };
            case "image": return { kind: "image", extension: "png" };
            default: return "unknown";
        }
    };

    // check if data source is from Tools_Data or Tools_Media
    if (modalData?.artisanSession) {
        mediaSrc = modalData?.videoSource;
        title = "Artisan video";
        dataID = modalData?.artisanSession?._id;
        aspectRatio = (modalData?.orientation ?? '').toLowerCase();
        kind = "video";
    } else if (modalData?.content_url) { // for Video List table from Video Gen
        mediaSrc = modalData?.content_url;
        title = modalData?.title ? modalData?.title : modalData?.task_id;
        dataID = modalData?._id;
        aspectRatio = modalData?.aspect_ratio;
        kind = "video";
    } else { // for custom media source
        mediaSrc = modalData?.data;
        title = modalData?.task_id;
        dataID = modalData?._id;
        aspectRatio = modalData?.aspect_ratio;
        kind = checkMediaKind(modalData?.kind)?.kind;
    }

    // handles if image is on load state
    const handleImageLoaded = () => {
        setIsLoading(false);
        setHasError(false);
    };

    // handles if image is on error state
    const handleImageError = () => {
        setIsLoading(false);
        setHasError(true);
    };

    return (
        <Modal
            size={aspectRatioData === "portrait" ? "lg" : "4xl"}
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-800"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
                        <ModalBody>

                            {/* video tag */}
                            <div className={`${aspectRatio === "portrait" ? "h-[40rem]" : "h-[30rem]"} flex justify-center items-center px-1`}>
                                {kind === "video" ? (
                                    <video className="w-auto h-[100%] border-0 rounded-lg" controls>
                                        <source src={mediaSrc} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (<>

                                    {isLoading && (
                                        <div className="flex items-center justify-center w-[30rem] h-[15rem]">
                                            <i className="fa-solid fa-image fa-bounce fa-2xl text-gray-100" />
                                        </div>
                                    )}

                                    {hasError && (
                                        <div className="flex items-center justify-center w-[30rem] h-[15rem]">
                                            <img src={NotAvailableImage} alt="error" />
                                        </div>
                                    )}

                                    {!hasError && (
                                        <img
                                            src={mediaSrc}
                                            onLoad={handleImageLoaded}
                                            onError={handleImageError}
                                            className="max-w-full max-h-full h-auto border-0 rounded-lg"
                                            alt="shinefy"
                                            style={{ display: isLoading ? 'none' : 'block' }}
                                        />
                                    )}
                                </>
                                )}

                            </div>

                        </ModalBody>
                        <ModalFooter className="flex items-start justify-start">
                            {/* <Button
                                onPress={onClose}
                                color="danger"
                                variant="flat"
                                className="w-auto h-auto text-base"
                            >
                                Close
                            </Button> */}
                            <Tooltip
                                color="primary"
                                showArrow={true}
                                content="Download Media"
                            >
                                <Button
                                    isIconOnly
                                    size="sm"
                                    color="secondary"
                                    onClick={() => handleDownloadMediaFile(mediaSrc, title, checkMediaKind(kind)?.extension, dataID)}
                                    endContent={<i className={`fa-solid fa-download ${isDownloading[dataID] && "fa-bounce"} text-lg`} />}
                                />
                            </Tooltip>

                            <Tooltip
                                color="primary"
                                showArrow={true}
                                content="Open in new link"
                            >
                                <Button
                                    isIconOnly
                                    size="sm"
                                    color="primary"
                                    onClick={() => window.open(modalData?.data || modalData?.content_url || modalData?.videoSource, "_blank")}
                                    endContent={<i className="fa-solid fa-link text-lg" />}
                                />
                            </Tooltip>

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default MediaView;