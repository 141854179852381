// libraries
import { Tooltip } from "@nextui-org/react";
import React from "react";

// custom components

const AO_tab2 = (props) => {

    // props
    const {
        config_avatar,
        isCheckedAvatar,
        avatarOwnAPI,
        setHeyGenAPIKey,
        heyGenAPIKey,
        showShowHeyGenAPIKEy,
        isAPIKeyAvatarErr,
        handleShowHeyGenAPI,
        isAvatarAPIKeyOkay,
        isAvatarAPIKeyChecking,
        avatarOption,
        onSelecTalkingPhoto,
        selectedTalkingPhoto,
        selectedTalkingPhotoURL,
        talkingPhoto,
        isFileUploading,
        isImageShow,
        inputKey,
        fileRef,
        handleSelectedAvatar,
        avatarId,
        avatarURL,
        avatars,
        isAvatarShow,
    } = props;

    return (
        <>
            <div>
                <div className="flex justify-between">
                    <div className="fontKarla pb-[0px] mr-2.5 text-white">
                        Avatar
                        <div className="float-right p-[0.15rem] ml-1 text-gray-800">

                            <Tooltip
                                color="primary"
                                placement="top"
                                showArrow={true}
                                content={
                                    <div className="px-1 py-2">
                                        <div className="text-small font-bold">Video Generator Tip</div>
                                        <p className="break-words whitespace-pre-line">
                                            Using this feature will cost you {config_avatar} Credit(s).
                                        </p>
                                    </div>
                                }>
                                <i className="fa-solid fa-circle-question text-yellow-300 hover:text-yellow-400" />
                            </Tooltip>

                        </div>
                    </div>
                    {/* <ToggleButton
                        onClick={handleToggleAvatar}
                        isChecked={isCheckedAvatar}
                    /> */}
                </div>

                {isCheckedAvatar && avatarOwnAPI && (
                    <div>
                        <h4 className="fontKarla text-white">
                            HeyGen API Key
                        </h4>
                        <div className="flex items-center">
                            <div className="relative w-full">
                                <input
                                    // onChange={handleHeyGenAPIKeyOnChange}
                                    onChange={(e) => setHeyGenAPIKey(e.target.value)}
                                    value={heyGenAPIKey || ""}
                                    type={showShowHeyGenAPIKEy ? "text" : "password"}
                                    className={`apiKeyTextInput pr-8 ${isAPIKeyAvatarErr
                                        ? "border-1 rounded-md border-red-600"
                                        : "border-0 rounded-md"
                                        }`}
                                    placeholder="Heygen API Key"
                                    autoComplete="off"
                                />
                                <div
                                    onClick={handleShowHeyGenAPI}
                                    className="cursor-pointer"
                                >
                                    <i className={`${showShowHeyGenAPIKEy ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"} absolute right-2 top-[59%] transform -translate-y-1/2`} />
                                </div>
                            </div>
                            <div
                                // onClick={handleCheckAvatarAPI}
                                title="Check api"
                                className={`relative ml-2 border rounded-md mt-1.5 px-2 py-[0.05rem] cursor-pointer w-10 flex items-center justify-center ${isAvatarAPIKeyOkay
                                    ? "bg-green-600 hover:bg-green-500"
                                    : "bg-gray-600 hover:bg-gray-500"
                                    }`}
                            >
                                {isAvatarAPIKeyChecking ? (
                                    <i className="fa-solid fa-spinner fa-spin-pulse text-lg text-gray-100" />
                                ) : isAvatarAPIKeyOkay ? (
                                    <i className="fa-solid fa-check-double text-lg text-gray-100" />
                                ) : (
                                    <i className="fa-solid fa-check text-lg text-gray-100" />
                                )}
                            </div>
                        </div>

                        {isAPIKeyAvatarErr && (
                            <p className="text-sm font-semibold text-red-600">
                                You need to provide HeyGen API key first before
                                turning on the feature.
                            </p>
                        )}
                    </div>
                )}
                {isCheckedAvatar && !avatarOwnAPI && (
                    <div className="speech-bubble mr-auto ml-auto mt-[5px] border rounded-md border-l-[3px] border-r-[3px] border-blue-500">
                        <i className="float-left fa-solid fa-circle-info mr-1 text-blue-600" />
                        <p className="animationPrompt text-gray-700 pl-5">
                            You can use your own
                            <a
                                className="text-blue-600 underline mx-1"
                                href="https://www.heygen.com"
                                target="_blank"
                            >
                                HeyGen API Key
                            </a>
                            for 0 credit cost.
                            <a className="text-blue-600 underline mx-1" href="/account" >
                                Click here.
                            </a>
                        </p>
                    </div>
                )}
                <div className="sectionDivider pb-[0px]">
                    <hr />
                </div>

                {isCheckedAvatar && (
                    <div className="mt-3">
                        <div className="flex justify-between">
                            <div className="fontKarla pb-[0px] mr-2.5 text-white">
                                <div className="mb-0 space-y-4">
                                    <div className="flex justify-between items-center">
                                        <div className="flex bg-gray-200 hover:bg-gray-300/[.7] rounded-lg transition p-1">
                                            <nav className="flex space-x-2" aria-label="Tabs" role="tablist">

                                                <Tooltip
                                                    color="primary"
                                                    placement="top"
                                                    showArrow={true}
                                                    content="Use avatars"
                                                >
                                                    <button
                                                        // onClick={() => handleAvatarOption("avatar")}
                                                        type="button"
                                                        className={`${avatarOption === "avatar" ? "bg-white" : "bg-gray-300/[.3]"} w-20 shadow-sm text-xs text-gray-500 hover:text-gray-700 font-medium rounded-md py-2 px-3`}
                                                        id="ctc-component-5-tab-1-item"
                                                        data-hs-tab="#ctc-component-5-tab-1"
                                                        aria-controls="ctc-component-5-tab-1"
                                                        role="tab"
                                                    >
                                                        Avatar
                                                    </button>
                                                </Tooltip>

                                                <Tooltip
                                                    color="primary"
                                                    placement="top"
                                                    showArrow={true}
                                                    content="Use talking photos."
                                                >
                                                    <button
                                                        // onClick={() => handleAvatarOption("image")}
                                                        type="button"
                                                        className={`${avatarOption === "image" ? "bg-white" : "bg-gray-300/[.3]"} w-20 shadow-sm text-xs text-gray-500 hover:text-gray-700 font-medium rounded-md py-2 px-3`}
                                                        id="ctc-component-5-tab-2-item"
                                                        data-hs-tab="#ctc-component-5-tab-2"
                                                        aria-controls="ctc-component-5-tab-2"
                                                        role="tab"
                                                    >
                                                        Image
                                                    </button>
                                                </Tooltip>

                                            </nav>
                                        </div>
                                        <div className="float-right p-[0.15rem] ml-1 text-gray-800">

                                            <Tooltip
                                                color="primary"
                                                placement="top"
                                                showArrow={true}
                                                content={
                                                    <div className="px-1 py-2">
                                                        <div className="text-small font-bold">Video Generator Tip</div>
                                                        <p className="break-words whitespace-pre-line">
                                                            Toggle to select between the two options: 'avatar' and 'talking photo'.
                                                        </p>
                                                    </div>
                                                }>
                                                <i className="fa-solid fa-circle-question text-yellow-300 hover:text-yellow-400" />
                                            </Tooltip>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {avatarOption === "image" ? (
                            <>
                                <div>
                                    <label className="block mb-0 text-sm font-light text-white"></label>
                                    <div className="flex items-center">
                                        <select
                                            onChange={onSelecTalkingPhoto}
                                            className="mt-2 font-semibold bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            value={`${selectedTalkingPhoto}|${selectedTalkingPhotoURL}`}
                                        >
                                            <option className="font-semibold" value="|" index={0}>
                                                Select image
                                            </option>
                                            {talkingPhoto?.length > 0 &&
                                                talkingPhoto.map((data, index) => (
                                                    <option
                                                        className="font-semibold"
                                                        value={`${data.id}|${data.image_url}`}
                                                        key={index + 1}
                                                    >
                                                        {data.id}
                                                    </option> // Concatenate id and url with a delimiter
                                                ))}
                                        </select>
                                        <div
                                            // onClick={handleImagePreview}
                                            title="Image preview"
                                            className="relative ml-2 border rounded-md mt-1.5 px-2 py-[0.115rem] cursor-pointer w-10 flex items-center justify-center bg-blue-600 hover:bg-blue-500"
                                        >
                                            {isFileUploading ? (
                                                <i className="fa-solid fa-spinner fa-spin-pulse text-2xl text-gray-100" />
                                            ) : (
                                                <i className={`fa-solid fa-image text-2xl text-gray-100 ${isImageShow && "fa-bounce"}`} />
                                            )}
                                        </div>
                                    </div>

                                    {avatarOwnAPI && (
                                        <>
                                            <input
                                                disabled={isFileUploading ? true : false}
                                                // onChange={handleFileUpload}
                                                key={inputKey}
                                                ref={fileRef}
                                                className="block w-full text-sm text-gray-900 border-gray-300 rounded-md border-0 bg-gray-300 mt-2"
                                                type="file"
                                                accept=".jpg,.png"
                                                autoComplete="off"
                                            />

                                            <div className="speech-bubble mr-auto ml-auto mt-[5px] border rounded-md border-l-[3px] border-r-[3px] border-blue-500">
                                                <i className="float-left fa-solid fa-circle-info mr-1 text-blue-600" />
                                                <p className="animationPrompt text-gray-700 pl-5">
                                                    Upload your own image - .jpg & .png supported
                                                    (Use .png for best results).
                                                </p>
                                            </div>
                                        </>
                                    )}

                                </div>

                                {isImageShow && selectedTalkingPhotoURL && (
                                    <div className="border rounded-md border-gray-600 mt-1 justify-center flex">
                                        <img
                                            className="w-auto h-48"
                                            src={selectedTalkingPhotoURL}
                                            alt="Description"
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="flex items-center">
                                    <select
                                        onChange={handleSelectedAvatar}
                                        className="mt-2 font-semibold bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        value={`${avatarId}|${avatarURL}` || ""}
                                    >
                                        <option className="font-semibold" value="|" index={0}>
                                            Select avatar
                                        </option>
                                        {avatars?.length > 0 &&
                                            avatars?.map((data, index) => (
                                                <option
                                                    className="font-semibold"
                                                    value={`${data.id}|${data.normal_preview}`}
                                                    key={index + 1}
                                                >
                                                    {data.avatar_name
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        data.avatar_name.slice(1)}
                                                    -({data.gender})
                                                </option> // Concatenate id and url with a delimiter
                                            ))}
                                    </select>
                                    <div
                                        // onClick={handleAvatarPreview}
                                        title="Avatar preview"
                                        className="relative ml-2 border rounded-md mt-1.5 px-2 py-[0.115rem] cursor-pointer w-10 flex items-center justify-center bg-blue-600 hover:bg-blue-500"
                                    >
                                        <i className={`fa-solid fa-user text-2xl text-gray-100 ${isAvatarShow && "fa-bounce"}`} />
                                    </div>
                                </div>
                                {isAvatarShow && avatarURL && (
                                    <div className="border rounded-md border-gray-600 mt-1 justify-center flex">
                                        <img
                                            className="w-auto h-48"
                                            src={avatarURL}
                                            alt="Description"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        <div className="speech-bubble mr-auto ml-auto mt-[5px] border rounded-md border-l-[3px] border-r-[3px] border-blue-500">
                            <i className="float-left fa-solid fa-circle-info mr-1 text-blue-600" />
                            <p className="animationPrompt text-gray-700 pl-5">
                                {isCheckedAvatar && !avatarOwnAPI ? "These are all default Avatars & Talking Photos." : "These are all avatars and talking photos from your API, including the default ones."}
                            </p>
                        </div>
                    </div>
                )}
                <div>
                    <div className="speech-bubble mr-auto ml-auto mt-[5px] border rounded-md border-l-[3px] border-r-[3px] border-blue-500">
                        <i className="float-left fa-solid fa-circle-info mr-1 text-blue-600" />
                        <p className="animationPrompt text-gray-700 pl-5">
                            About this feature
                        </p>
                        <ul className="list-disc px-5">
                            <li>
                                <p className="animationPrompt text-gray-700">
                                    <b>"Avatar"</b> is a feature that incorporates a talking avatar into your video.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AO_tab2;