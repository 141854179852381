// libraries
import React from "react";

const VG_TableTopContent = (props) => {

    // props
    const {
        Input,
        filterValue,
        onClear,
        onSearchChange,
        Dropdown,
        DropdownTrigger,
        Button,
        DropdownMenu,
        statusFilter,
        setStatusFilter,
        statusOptions,
        DropdownItem,
        capitalize,
        visibleColumns,
        setVisibleColumns,
        columns,
        videos,
        onRowsPerPageChange,
        rowsPerPage,
    }=props

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between gap-3 items-end">
                <Input
                    isClearable
                    className="w-full sm:max-w-[44%]"
                    placeholder="Search by title..."
                    startContent={<i className="fa-solid fa-magnifying-glass" />}
                    value={filterValue}
                    onClear={() => onClear()}
                    onValueChange={onSearchChange}
                />
                <div className="flex gap-3">
                    <Dropdown>
                        <DropdownTrigger className="hidden sm:flex">
                            <Button endContent={<i className="fa-solid fa-chevron-down" />} variant="flat">
                                Status
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            disallowEmptySelection
                            aria-label="Table Columns"
                            closeOnSelect={false}
                            selectedKeys={statusFilter}
                            selectionMode="multiple"
                            onSelectionChange={setStatusFilter}
                        >
                            {statusOptions.map((status) => (
                                <DropdownItem key={status.uid} className="capitalize">
                                    {capitalize(status.name)}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger className="hidden sm:flex">
                            <Button endContent={<i className="fa-solid fa-chevron-down" />} variant="flat">
                                Columns
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            disallowEmptySelection
                            aria-label="Table Columns"
                            closeOnSelect={false}
                            selectedKeys={visibleColumns}
                            selectionMode="multiple"
                            onSelectionChange={setVisibleColumns}
                        >
                            {columns.map((column) => (
                                <DropdownItem key={column.uid} className="capitalize">
                                    {capitalize(column.name)}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    {/* <Button color="primary" endContent={<i className="fa-solid fa-plus" />}>
                            Add New
                        </Button> */}
                </div>
            </div>
            <div className="flex justify-between items-center">
                <span className="text-gray-100 text-small">Total {videos.length} data</span>
                <label className="flex items-center text-gray-100 text-small">
                    Rows per page:
                    <select
                        className="bg-transparent outline-none text-gray-100 text-small"
                        onChange={onRowsPerPageChange}
                        defaultValue={rowsPerPage}
                    >
                        <option className="text-default-400" value="5">5</option>
                        <option className="text-default-400" value="10">10</option>
                        <option className="text-default-400" value="15">15</option>
                    </select>
                </label>
            </div>
        </div>
    );
};

export default VG_TableTopContent;