// libraries
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Progress } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import { handleSubmitReport } from "../../API/toolsAPI";
import { updateArrayData } from "../../../../../functions/general";

export default function ReportVideoEditor(props) {

    // props
    const {
        isOpen,
        onClose,
        reportedVideoData,
        setVideos,
        videos,
    } = props;

    // react components
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [errorKind, setErrorKind] = useState([]);
    const [mediaFile, setMediaFile] = useState(null);
    const [reportContent, setReportContent] = useState("");
    const [uploadingAttachedFile, isUploadingAttachedFile] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedMediaFile, setSelectedMediaFile] = useState([]);

    // cosole log activity
    // consoleLog("errorKind", errorKind);

    // drop zone method
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
    } = useDropzone({
        accept: {
            // accepted MIME types
            'video/mp4': ['.mp4', '.MP4'],
            'image/png': ['.png', '.PNG'],
            'image/jpeg': ['.jpg', '.jpeg', '.JPG', '.JPEG'],
        },
        noClick: true,
        noKeyboard: true,
        multiple: false,
    });

    // use effect that will set the selected media  file
    useEffect(() => {
        setSelectedMediaFile(acceptedFiles);
    }, [acceptedFiles]);

    // handle accepted files
    const files = selectedMediaFile?.map((file) => {
        const fileSizeInMB = (file.size) / (1024 * 1024);
        const formattedSize = fileSizeInMB.toFixed(2).toLocaleString();
        return (
            <p
                className="flex flex-col items-center justify-center truncate w-[10rem] md:w-[30rem] lg:w-[40rem]"
                key={file.path}
            >
                {file.path} - {formattedSize} MB
            </p>
        );
    });

    // use effect check file 
    useEffect(() => {
        const checkMediaFile = async () => {

            // reset variables
            setErrorKind([]); // error kind data
            setUploadProgress(0); // upload progress
            setMediaFile(null); // attached media file

            // extracted media file
            const mediaFile = acceptedFiles[0];

            // process upload
            if (mediaFile) {

                // supported file extension
                const supportedMediaFileExtension = ["mp4", "png", "jpg", "jpeg",];

                // extract media file size
                const sizeInMB = mediaFile?.size / (1024 * 1024); // check file size

                // Extract file extension
                const fileExtension = mediaFile?.name?.split(".").pop().toLowerCase();

                // Convert fileExtension to lowercase
                const extension = fileExtension.toLowerCase();

                // cosole log activity
                // consoleLog(!supportedMediaFileExtension.includes(extension));
                // consoleLog("extension", extension);

                // Check if file is not included in supportedMediaFileExtension
                if (!supportedMediaFileExtension.includes(extension)) {
                    setErrorKind(["fileType", "The media file is invalid."]);
                    setInputKey(Math.random().toString(36)); // This will clear the file input
                    return;
                }

                // check if file size
                if (sizeInMB > 25) {
                    setErrorKind(["fileSize", "The file size exceeds the 25MB limit."]);
                    setInputKey(Math.random().toString(36)); // This will clear the file input
                    return;
                }

                // set media file for upload
                setMediaFile(mediaFile);
            }
        };
        checkMediaFile();
    }, [acceptedFiles]);

    // handle submit report
    const handlesubmitReport = async () => {

        // console log activity
        consoleLog("mediaFile", mediaFile);
        consoleLog("reportedVideoData", reportedVideoData);

        // variables
        // let mediaFileName;

        // check if content report is empty
        if (!reportContent) {
            ToastEffect("error", "Please include content to the report.");
            return;
        }

        // // process upload if there is an attached file
        // if (mediaFile) {

        //     // updaload file to the server and return file path
        //     const response = await handleUploadAttachedFile(mediaFile, reportedVideoData?.email, isUploadingAttachedFile, (progress) => {
        //         setUploadProgress(progress); // upload progress
        //     });

        //     // file source path
        //     mediaFileName = response?.data?.file;
        // }

        // console log activity
        // consoleLog("mediaFileName", mediaFileName);

        const requiredData = {
            dataID: reportedVideoData?._id,
            user_auth: process.env.REACT_APP_AUTH,
            email: reportedVideoData?.email,
            task_id: reportedVideoData?.task_id,
            reportContent: reportContent,
            // mediaFileName: mediaFileName || "",
        };

        // capture upload progress 
        const onProgress = (progress) => {
            setUploadProgress(progress);
        };

        // submit the report
        await handleSubmitReport(mediaFile, requiredData, isUploadingAttachedFile, onProgress)
            .then(async (response) => {

                // call the function that will modify and update the data from array
                const updated_data = await updateArrayData(videos, reportedVideoData?._id, "report_id", response?.data);
                setVideos(updated_data); // set updated data

                // console log activity
                consoleLog("updated_data", updated_data);

                // reset variables
                setErrorKind([]); // error kind data
                setUploadProgress(0); // upload progress
                setMediaFile(null); // attached media file
                setReportContent(""); // report content
                onClose(); // close modal ReportVideoEditor
                setInputKey(Math.random().toString(36)); // clear selected files
                setSelectedMediaFile([]); // clear displayed selected file
            });

    };

    return (
        <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            className="bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Report Video</ModalHeader>
                        <ModalBody>
                            {/* <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4"> */}
                            {/* <Textarea
                                label="Description"
                                placeholder="Enter your description (Default autosize)"
                                minRows={15}
                                className="shadow-none"
                            /> */}
                            <textarea
                                onChange={(e) => setReportContent(e.target.value)}
                                value={reportContent}
                                rows="15"
                                className="block p-2.5 w-full text-lg text-gray-900 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Write your report or attach media link here..."
                            />
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-between -mt-2">

                            {/* <input
                                className="block w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                id="default_size"
                                type="file"
                            /> */}

                            <div className="flex flex-col items-center justify-center w-full">
                                <label {...getRootProps()} htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-auto border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

                                    {selectedMediaFile?.length && uploadingAttachedFile ? (
                                        // <div className="flex flex-col items-center justify-center pt-2 pb-2">
                                        <Progress
                                            classNames={{
                                                // base: "max-w-md",
                                                // track: "drop-shadow-md border border-default",
                                                // indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
                                                label: "tracking-wider font-medium text-default-600",
                                                value: "text-foreground/60",
                                            }}
                                            label={files}
                                            size="sm"
                                            value={uploadProgress}
                                            maxValue={100}
                                            color="primary"
                                            // formatOptions={{ style: "currency", currency: "ARS" }}
                                            showValueLabel={true}
                                            className="p-2"
                                        />
                                        // </div>
                                    ) : (
                                        <div className="flex flex-col items-center justify-center pt-2 pb-2">
                                            <span className={"text-sm font-semibold font-sans text-gray-500 dark:text-gray-400"}>{errorKind?.length ? <span className="text-red-500">{errorKind[1]}</span> : files?.length ? files : (<p><span className="font-bold">Click or drag and drop</span> to attach media file</p>)}</span>
                                            <p className="text-xs font-semibold font-sans text-gray-500 dark:text-gray-400"><span className={errorKind[0] === "fileType" ? "text-red-500" : ""}>PNG, JPG or MP4</span> (<span className={errorKind[0] === "fileSize" ? "text-red-500" : ""}>MAX. 25MB</span>)</p>
                                        </div>
                                    )}

                                    <input
                                        id="dropzone-file"
                                        // disabled={checkingLink || transcribing ? true : false}
                                        type="file"
                                        className="hidden"
                                        key={inputKey}
                                        {...getInputProps()}
                                    />
                                </label>
                            </div>

                            {/* <Button
                                onPress={onClose}
                                color="danger"
                                variant="flat"
                                className="w-auto h-auto text-base"
                            >
                                Close
                            </Button> */}
                            <Button
                                onClick={handlesubmitReport}
                                color="primary"
                                className="h-full w-full lg:md:sm:w-auto py-3.5 px-7 text-base"
                            >
                                Submit Report
                            </Button>

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}
