// libraries
import React from "react";
import moment from "moment";
import { CircularProgress, Tooltip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import { containsUrl } from "../../functions/general";
import { convertDateTime, convertToCustomerLocalTime } from "../../../../../functions/general";

const VG_TableCell1 = (props) => {

    // props
    const {
        idx,
        data,
        handlePlayVideo,
        handleCustomMedia,
        filtered_generateVideoStatus,
    } = props;

    // console log activity
    // consoleLog("localTimeString", localTimeString);
    // consoleLog("dateMoment", dateMoment);
    // consoleLog("fromNow", fromNow);

    return (
        <>
            {/* <User
                // avatarProps={{ radius: "lg", src: data.avatar }} // avatar
                description={moment(data?.created).fromNow()}
                name={data?.title || data?.task_id}
            >
                {data?.email}
            </User> */}

            <div className="flex flex-row items-center">
                <Tooltip
                    color="primary"
                    // className="bg-gray-300 dark:bg-gray-700"
                    key="left-start"
                    placement="left-start"
                    showArrow={true}
                    content={containsUrl(data?.content_url) ? "Play video" :
                        data?.report_id ? "Reported" :
                            data?.content_url === "custom_video" ? "Customize Video" :
                                data?.status === "failed" || data?.status === "error" ? "Failed" :
                                    data?.content_url === "accuracy_video" ? "Done" :
                                        "Processing..."
                    }
                >
                    <span onClick={() => containsUrl(data?.content_url) ? handlePlayVideo(data) : data?.content_url === "custom_video" ? handleCustomMedia(data) : null}>
                        {containsUrl(data?.content_url) ? (
                            <i className="fa-solid fa-film text-4xl text-green-500 cursor-pointer mr-3" />
                        ) : data?.report_id ? (
                            <i className="fa-regular fa-flag text-4xl text-purple-400 mr-3" />
                        ) : data?.content_url === "custom_video" ? (
                            <i className="fa-solid fa-clapperboard text-4xl text-blue-500 cursor-pointer mr-3" />
                        ) : data?.status === "failed" || data?.status === "error" ? (
                            <i className="fa-regular fa-circle-xmark text-4xl text-red-500 mr-3" />
                        ) : data?.content_url === "accuracy_video" && data?.status === "completed" ? (
                            <i className="fa-solid fa-sliders text-4xl text-yellow-300 mr-3" />
                        ) : (
                            <CircularProgress
                                aria-label="Processing..."
                                size="lg"
                                value={filtered_generateVideoStatus?.tools_status?.current || 0}
                                maxValue={filtered_generateVideoStatus?.tools_status?.total || 100}
                                classNames={{ value: "text-xs font-semibold", }}
                                color={filtered_generateVideoStatus?.tools_status?.process_type === "sub_process" ? "secondary" : "warning"}
                                // strokeWidth={3}
                                showValueLabel={true}
                            />
                        )}
                    </span>
                </Tooltip>
                <div>
                    <p className="font-semibold text-base capitalize truncate">
                        {/* {data?.title || data?.task_id} */}
                        {data?.title || "AI Video Generator"}
                    </p>
                    <p className="font-semibold text-sm capitalize text-default-400">
                        <Tooltip
                            color="primary"
                            // className="bg-gray-300 dark:bg-gray-700"
                            key="right-start"
                            placement="right-start"
                            showArrow={true}
                            content={convertToCustomerLocalTime(data?.created).dateTime}
                        >
                            <time>
                                {/* {moment(data?.created).fromNow()} */}
                                {convertToCustomerLocalTime(data?.created)?.moment}
                            </time>
                        </Tooltip>
                    </p>
                </div>
            </div>
        </>
    );
};

export default VG_TableCell1;