// libraries
import React, { useState } from "react";
import { Button, Divider, Tooltip } from "@nextui-org/react";

// custom components
import { convertToCustomerLocalTime } from "../../../functions/general";
import consoleLog from "../../../functions/consoleLog";
import { markNotificationAsRead, removeNotification } from "../../../API/server";

const NotificationList = (props) => {

    // props
    const {
        adminNotifications,
        setSelectedNotificationID,
        selectedNotificationID,
        setRefetchNotifications,
    } = props;

    // react useState
    const [isRemovingNotif, setIsRemovingNotif] = useState(false);
    const [removeID, setRemoveID] = useState([]);

    // console log activity
    // consoleLog("adminNotifications", adminNotifications);
    // consoleLog("selectedNotificationID", selectedNotificationID);

    // handle clicked notification
    const handleClickNotificationID = async (data) => {

        // set selected data
        setSelectedNotificationID(data?.linked_id);

        // required data
        const requiredData = {
            notifID: data?._id
        };

        // just execute if isRead is false
        if (!data?.isRead) {

            // update notification status marked as read
            await markNotificationAsRead(requiredData)
                .then((response) => {

                    // refetch message interactions
                    setRefetchNotifications(oldVal => oldVal + 1);

                    // console log activity
                    consoleLog("response", response?.data);
                });
        }

        // console log activity
        // consoleLog("data", data);
    };

    // handle delete notification
    const handleDeleteNotification = async (notifID) => {

        // set "isRemovingNotif" to true
        setIsRemovingNotif(true);
        setRemoveID((prevRemoveID) => [...prevRemoveID, notifID]);

        await removeNotification({ notifID })
            .then((response) => {

                // refetch message interactions
                setRefetchNotifications(oldVal => oldVal + 1);

                // console log activity
                consoleLog("response", response?.data);
            });

        // disable marking when done executing
        setIsRemovingNotif(false);

    };

    return (
        <div className="w-full max-h-[88vh] overflow-y-auto rounded-lg border border-gray-200 dark:border-gray-700 lg:max-w-xl xl:max-w-3xl">

            {adminNotifications?.map((data, idx) => {
                return (
                    <div key={idx}>

                        <Divider className="-my-0.5" />

                        <div
                            onClick={() => handleClickNotificationID(data)}
                            className={`${selectedNotificationID === data?.linked_id && "bg-gray-300 dark:bg-gray-800"} space-y-2 px-2 mx-2 py-2 my-2 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-md cursor-pointer`}
                        >
                            <div className="flex items-center gap-6">
                                {/* <a href="#" className="h-14 w-14 shrink-0">
                                    <img className="h-full w-full dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front.svg" alt="imac image" />
                                    <img className="hidden h-full w-full dark:block" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg" alt="imac image" />
                                </a> */}

                                <p className={`${data?.isRead ? "text-gray-400 dark:text-gray-500" : "text-gray-900 dark:text-gray-200"} min-w-0 flex-1 font-medium truncate`}>
                                    {data?.main_content}
                                </p>
                            </div>

                            <div className="flex items-center justify-between gap-4">
                                <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    {convertToCustomerLocalTime(data?.createdAt)?.moment}
                                </p>

                                <div className="flex items-center justify-end gap-1">
                                    {/* <Tooltip showArrow={true} color="primary" content="Mark as resolve">
                                        <Button
                                            isIconOnly
                                            // color="danger"
                                            variant="faded"
                                            radius="sm"
                                            aria-label="Take a photo"
                                            className="text-success bg-gray-100 dark:bg-gray-800"
                                        >
                                            <span className="text-lg">
                                                <i className="fa-solid fa-circle-check" />
                                            </span>
                                        </Button>
                                    </Tooltip> */}
                                    <Tooltip showArrow={true} color="primary" content="Remove notification">
                                        <Button
                                            isIconOnly
                                            isLoading={isRemovingNotif && removeID?.includes(data?._id) ? true : false}
                                            // color="danger"
                                            variant="faded"
                                            radius="sm"
                                            aria-label="Take a photo"
                                            onClick={() => handleDeleteNotification(data?._id)}
                                            className="text-red-400 bg-gray-100 dark:bg-gray-800"
                                        >
                                            <span className="text-lg">
                                                <i className="fa-solid fa-trash-can" />
                                            </span>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Divider className="-my-0.5" />
        </div >
    );
};

export default NotificationList;