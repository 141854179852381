// libraries
import React from "react";

const VG_TableBottomContent = (props) => {

    // props
    const {
        selectedKeys,
        filteredItems,
        Pagination,
        page,
        pages,
        setPage,
        Button,
        onPreviousPage,
        onNextPage,
    } = props;

    return (
        <div className="py-2 px-2 flex justify-between items-center">
            {/* <span className="w-[30%] text-small text-gray-100">
                {selectedKeys === "all"
                    ? "All items selected"
                    : `${selectedKeys.size} of ${filteredItems.length} selected`}
            </span> */}
            <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                page={page}
                total={pages}
                onChange={setPage}
            />
            <div className="hidden sm:flex w-[30%] justify-end gap-2">
                <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                    Previous
                </Button>
                <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default VG_TableBottomContent;