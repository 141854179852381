// libraries
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, CircularProgress, Divider, Image, Tooltip, useDisclosure } from "@nextui-org/react";

// custom image
import NotAvailableLandscapeImage from "../../Images/no_image_landscape.png";

// custom components
import consoleLog from "../../../../../functions/consoleLog";

const VG_CustomMediaGallery = (props) => {

    // props
    const {
        width,
        toolsDataImage,
        setImageData,
        onOpenMV,
        isGeneratingImage,
        handleClickUpload,
        sourceMdiaStatus,
        handleUploadSourceFile,
        inputRef,
        showImageRedMark,
        handleEditImageData,
        handleGenerateImage,
        alertBeforeAction,
    } = props;

    // react useRef
    // const videoRef = useRef(null);

    // react useState
    const [column, setColumn] = useState(1);
    const [key, setKey] = useState(0);

    // console log activity
    // consoleLog("screenSize", width);
    // consoleLog("toolsDataImage", toolsDataImage);
    // consoleLog("isGeneratingImage", isGeneratingImage);

    // reload video if changes happens
    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [toolsDataImage]);

    // check the container width all the time
    useEffect(() => {
        const detectSize = () => {
            // conditional to check for screen size
            if (width <= 257) {
                setColumn(1);
            } else if (width >= 258 && width <= 395) {
                setColumn(2);
            } else if (width >= 396 && width <= 625) {
                setColumn(3);
            } else {
                setColumn(4);
            }
        };
        detectSize();
    }, [width]);

    // handle view image function
    const handleViewImage = (data) => {

        // condition
        if (data?.data === "" || data?.data === "error" || data?.status === "processing") {
            return;
        }

        // execute function
        setImageData(data);
        onOpenMV(); // open modal
    };

    return (
        <div className={`gap-1 grid grid-cols-1 md:grid-cols-${column} lg:grid-cols-${column}`}>
            {toolsDataImage?.sort((a, b) => a.number - b.number)?.map((data, idx) => {

                // filter data by task_id
                const imageStatus = sourceMdiaStatus?.find((status) =>
                    status?.tools_status?.task_id === data?.task_id
                    && status?.tools_status?.type === data?.kind
                    && Number(status?.tools_status?.number) === Number(data?.number)
                );

                // print activity log
                // consoleLog("imageStatus", imageStatus);

                return (
                    <Card shadow="sm" key={idx}>

                        {/* <CardHeader className="flex gap-3">
                            <div className="flex flex-col">
                                <p className="text-md">NextUI</p>
                                <p className="text-small text-default-500">nextui.org</p>
                            </div>
                        </CardHeader>
                        <Divider /> */}

                        {/* upload input */}
                        <input
                            onChange={(e) => handleUploadSourceFile(e.target.files)}
                            type="file"
                            className="hidden"
                            id="up_file"
                            ref={inputRef}
                        />

                        <CardBody
                            onClick={() => handleViewImage(data)}
                            className={`overflow-visible p-0 cursor-pointer ${(data?.data === "" || data?.data === "error" || data?.status === "processing") && showImageRedMark && "bg-red-600/60"}`}
                        >

                            {imageStatus?.tools_status?.status === "processing" && (
                                <div className="absolute inset-0 flex items-center justify-center text-white bg-gray-950/50 backdrop-blur-sm z-40">
                                    <CircularProgress
                                        aria-label="Processing..."
                                        size="lg"
                                        value={imageStatus?.tools_status?.current || 0}
                                        maxValue={imageStatus?.tools_status?.total || 100}
                                        classNames={{
                                            value: "text-xs font-semibold",
                                        }}
                                        color="primary"
                                        // strokeWidth={3}
                                        showValueLabel={true}
                                    />

                                </div>
                            )}

                            <div className="absolute flex items-center justify-center z-20 bg-gray-700 rounded-md p-1.5">
                                <code className="mr-1 text-xl font-semibold text-gray-200">
                                    {data?.number}
                                </code>

                                {/* icon kind */}
                                {data?.data === "error" || data?.data === "fail" || data?.data === "failed" ?
                                    (<i className="fa-regular fa-circle-xmark text-red-500 text-xl" />) :
                                    (data?.kind === "custom_image" || data?.kind === "image") && data?.data !== "" ?
                                        (<i className="fa-solid fa-image text-success text-xl" />) : // image type
                                        (data?.kind === "custom_video" || data?.kind === "video") && data?.data !== "" ?
                                            (<i className="fa-solid fa-film text-primary text-xl" />) : // video type
                                            (<></>)
                                }
                            </div>
                            {data?.kind === "custom_image" || data?.kind === "image" || data?.data === "error" ? (
                                <Image
                                    isZoomed
                                    shadow="sm"
                                    radius="lg"
                                    width="100%"
                                    loading="lazy"
                                    alt={data?.number}
                                    className="w-full object-cover h-[140px]"
                                    src={data?.kind === "custom_image" ? data?.data : data?.data === "error" ? NotAvailableLandscapeImage : data?.optimized_image}
                                />
                            ) : (
                                <video key={key} className="w-full object-cover h-[140px]">
                                    <source src={data?.data} type="video/mp4" />
                                </video>
                            )}
                        </CardBody>

                        <CardFooter className="flex gap-0.5 justify-between items-center bg-gray-600 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                            <Tooltip
                                color="primary"
                                showArrow={true}
                                content="Upload custom media"
                            >
                                <Button
                                    isIconOnly
                                    size="sm"
                                    variant="faded"
                                    aria-label="re-generate"
                                    color="default"
                                    className="text-tiny text-white bg-black/20 hover:bg-black/5 w-full"
                                    onClick={() => imageStatus?.tools_status?.status === "processing" || isGeneratingImage.includes(data?.number) ? null : handleClickUpload(data)}
                                    startContent={<i className="fa-solid fa-plus text-lg" />}
                                />
                            </Tooltip>

                            <Tooltip
                                color="primary"
                                showArrow={true}
                                content={`${data.data === "" ? "Generate Image" : "Re-generate Image"}`}
                            >
                                <Button
                                    isIconOnly
                                    size="sm"
                                    variant="faded"
                                    aria-label="re-generate"
                                    color="default"
                                    className="text-tiny text-white bg-black/20 hover:bg-black/5 w-full"
                                    isLoading={imageStatus?.tools_status?.status === "processing" ? true : false}
                                    isDisabled={imageStatus?.tools_status?.status === "processing" || isGeneratingImage.includes(data?.number) ? true : false}
                                    onClick={() => data.data === "" ? handleGenerateImage(data) : alertBeforeAction(handleGenerateImage, "function2", "re-generate-image", { data })}

                                    startContent={imageStatus?.tools_status?.status === "processing" ? "" : <i className="fa-solid fa-repeat text-lg" />}
                                />
                            </Tooltip>

                            <Tooltip
                                color="primary"
                                showArrow={true}
                                content="Edit Prompt"
                            >
                                <Button
                                    isIconOnly
                                    size="sm"
                                    variant="faded"
                                    aria-label="re-generate"
                                    color="default"
                                    className="text-tiny text-white bg-black/20 hover:bg-black/5 w-full"
                                    onClick={() => handleEditImageData(data)}
                                    startContent={<i className="fa-solid fa-pen-to-square text-lg" />}
                                />
                            </Tooltip>
                        </CardFooter>

                    </Card>
                );
            })}
        </div>
    );
};

export default VG_CustomMediaGallery;