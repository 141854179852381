// libraries
import React from "react";
import { useNavigate } from "react-router-dom";

// custom components
import AdminContext from "../../../components/Admin/AdminContext";
import consoleLog from "../../../functions/consoleLog";
import { convertToCustomerLocalTime } from "../../../functions/general";

const Notifications = (props) => {

    // props
    const {
        shinefy_img,
        adminNotifications,
    } = props;

    // react useNavigate
    const navigate = useNavigate();

    // console log activity
    // consoleLog("adminNotifications", adminNotifications);

    return (
        <>
            <div className="block px-4 py-2 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                Notifications
            </div>
            <div>
                {adminNotifications?.slice(0, 5)?.map((data, idx) => {

                    if (!data?.isRead) {
                        return (
                            <div key={idx} onClick={() => navigate("/admin-notif")} className="flex px-4 py-3 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600 cursor-pointer">
                                <div className="flex-shrink-0">
                                    <img decoding="async" data-nimg="intrinsic" src={shinefy_img} className="w-9 h-auto mr-2" alt="Image" />
                                    {/* <div className="absolute flex items-center justify-center w-6 h-6 ml-6 -mt-5 rounded-full bg-gray-100"> */}
                                    <div className="absolute flex items-center justify-center w-6 h-6 ml-6 -mt-5">
                                        <i className="fa-solid fa-circle-exclamation fa-md text-warning" />
                                    </div>
                                </div>
                                <div className="w-full pl-3">
                                    <div className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                        <span className="font-semibold text-gray-900 dark:text-white mr-1">
                                            {data?.notif_title}
                                        </span>
                                        {data?.notif_kind}
                                    </div>
                                    <div className="text-xs font-medium text-primary-700 dark:text-gray-400">
                                        {convertToCustomerLocalTime(data?.createdAt)?.moment}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}

                {/* <a href="#" className="flex px-4 py-3 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">
                    <div className="flex-shrink-0">
                        <img decoding="async" data-nimg="intrinsic" src={shinefy_img} className="w-9 h-auto mr-2" alt="Image" />
                        <div className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 rounded-full bg-primary-700">
                            <i className="fa-solid fa-triangle-exclamation fa-md text-yellow-300" />
                        </div>
                    </div>
                    <div className="w-full pl-3">
                        <div className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                            <span className="font-semibold text-gray-900 dark:text-white mr-1">
                                Problem report:
                            </span>
                            Transcriber
                        </div>
                        <div className="text-xs font-medium text-primary-700 dark:text-gray-400">10 minutes ago</div>
                    </div>
                </a> */}
            </div>
            <span className="block cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 py-2 text-base font-normal text-center text-gray-900 bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:underline">
                <div onClick={() => navigate("/admin-notif")} className="inline-flex items-center ">
                    View all
                </div>
            </span>
        </>
    );
};

export default Notifications;