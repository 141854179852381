// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from 'moment';
import { useDisclosure } from "@nextui-org/react";

// custom components
import { cancelSubscription, continueSubscription, updateSubscription } from "../../API/subsAPI";
import { getStatus, getStyle } from "../../functions/subscriptionStatus";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import { MyPlanRecord } from "../../components/MyPlanRecord";
import ResponseMessage from "../../components/ResponseMessage";
import PurchaseCredits from "../../components/ToBeDeleted/PurchaseCredits";
import { catchURL, getPlatFormKind } from "../../functions/general";
import { alertBeforeAction } from "./Tools/Components/Transcriber/AlertBeforeAction";
import UserContext from "../../components/UserContext";
import MyPlanDetails from "../../components/MyPlanDetails";
import MyPlanStatus from "../../components/MyPlanStatus";
import { CreditsView } from "../../components/Modals/CreditsView";

const MyPlan = () => {

    // context method
    const {

        // others
        productData,
        subscriptionData,
        userData,

    } = useContext(UserContext);

    // userefs, navigates and use location
    const modalBuyCreditsRef = useRef(null);

    // subscriptionData extracted values
    const subscriptionID = subscriptionData?.status?.subscription_id;
    const subscriptionItemID = subscriptionData?.status?.item_id;
    const subscriptionEmail = subscriptionData?.status?.email;

    // next ui components
    const { isOpen: isOpenCV, onOpen: onOpenCV, onClose: onCloseCV } = useDisclosure(); // CV - (Credits View)

    // react components
    const [isCanceling, setIsCanceling] = useState(false);
    const [isContinuing, setIsContinuing] = useState(false);
    const [responseMessage, setResponseMessage] = useState([]);
    const [showBuyCredits, setShowBuyCredits] = useState(false);
    const [urlCardValue, setURLCardValue] = useState("");
    const [selectedValue, setSelectedValue] = useState("");

    // filter "productData" and fetch only data that matched from "subscriptionData"
    const filteredProducts = productData.filter(product => product.productID === subscriptionItemID);

    // console logs activity
    // consoleLog("subscriptionData", subscriptionData);
    // consoleLog("productData", productData);
    // consoleLog("filteredProducts", filteredProducts);

    // set URL card value
    useEffect(() => {
        const handleURLCardValue = () => {
            setURLCardValue(catchURL());
        };
        handleURLCardValue();
    }, []);

    const closeResponseMessage = () => {
        setResponseMessage([]);
    };

    const handleBuyCredits = async () => {
        setSelectedValue("");
        onOpenCV(); // open credits view modal
        setURLCardValue("");
    };

    /** handles the process for subscription cancellation */
    const handleCancelPlan = async (selectedValue) => {

        // set canceling value to true
        setIsCanceling(true);

        // required data
        const requiredData = {
            email: subscriptionEmail,
            reason: selectedValue,
            subscriptionID: subscriptionID,
        };

        // call the "cancelSubscription" function to send the request
        await cancelSubscription(requiredData)
            .then(async (response) => {

                if (response?.data?.status === "canceled") {
                    await updateSubscription({ email: subscriptionEmail });
                    // setResponseMessage([{ kind: "success", message: "Cancelled successfully" }]);
                    ToastEffect("success", "Cancelled successfully");
                    setTimeout(() => { window.location.reload(); }, 3000);
                } else {
                    // setResponseMessage([{ kind: "error", message: "Something went wrong!" }]);
                    ToastEffect("error", "Something went wrong!");
                    setIsCanceling(false); // set canceling value to false
                }

                // console log activity
                consoleLog("#handleCancelPlan > response", response?.data);

            }).catch((error) => {
                setIsCanceling(false);
                ToastEffect("error", "Something went wrong!");
                consoleLog("MyPlan.jsx > #handleCancelPlan:", error);
            });


    };

    /** handles the process for subscription cancellation */
    // const handleContinuePlan = async () => {

    //     // set continuing value to true
    //     setIsContinuing(true);

    //     // required data
    //     const requiredData = {
    //         email: subscriptionEmail,
    //         subscriptionID: subscriptionID,
    //     };

    //     // call the "handleContinuePlan" function to send the request
    //     await continueSubscription(requiredData)
    //         .then(async (response) => {

    //             if (response?.data?.status) {
    //                 await updateSubscription({ email: subscriptionEmail });
    //                 setResponseMessage([{ kind: "success", message: "Continued successfully" }]);
    //                 setIsContinuing(false); // set canceling value to false
    //                 setTimeout(() => { window.location.reload(); }, 3000);
    //                 consoleLog("MyPlan.jsx > #handleContinuePlan:", response);
    //             } else {
    //                 setResponseMessage([{ kind: "error", message: "Something went wrong!" }]);
    //                 setIsCanceling(false); // set canceling value to false
    //             }

    //             // console log activity
    //             consoleLog("#continueSubscription > response", response?.data);

    //         }).catch((error) => {
    //             setIsCanceling(false);
    //             ToastEffect("error", "Something went wrong!");
    //             consoleLog("MyPlan.jsx > #handleContinuePlan:", error);
    //         });

    // };

    // handle click outside effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalBuyCreditsRef.current && !modalBuyCreditsRef.current.contains(event.target)) {
                onOpenCV(); // open credits view modal
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalBuyCreditsRef]);


    return (

        <>
            <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800">
                <h2 className="mb-3 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Subscription Details
                </h2>
                <p className="mb-3 text-gray-500 dark:text-gray-400">
                    Please note that credits will reset every month. Any remaining credits will be reset back to the base value of your subscription plan.
                </p>
            </div>

            <div className="space-y-8 p-4 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-5 lg:space-y-0">

                {/* My plan profile details */}
                <MyPlanDetails
                    handleBuyCredits={handleBuyCredits}
                    urlCardValue={urlCardValue}
                />

                {/* My current plan record */}
                <MyPlanRecord
                    subscriptionData={subscriptionData}
                    filteredProducts={filteredProducts}
                />

                {/* My curent plan status */}
                <MyPlanStatus
                    responseMessage={responseMessage}
                    closeResponseMessage={closeResponseMessage}
                    isCanceling={isCanceling}
                    isContinuing={isContinuing}
                    handleCancelPlan={handleCancelPlan}
                // handleContinuePlan={handleContinuePlan}
                />

            </div>

            {/* {showBuyCredits && (
                <PurchaseCredits
                    modalBuyCreditsRef={modalBuyCreditsRef}
                    handleBuyCredits={handleBuyCredits}
                    showBuyCredits={showBuyCredits}
                />
            )} */}

            {/* open "View Credits" modal for credits purchase */}
            <CreditsView
                isOpen={isOpenCV}
                onClose={onCloseCV}
                setSelectedValue={setSelectedValue}
                selectedValue={selectedValue}
                subscriptionEmail={subscriptionEmail}
            />

            {/* <div className=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">We invest in the world’s potential</h2>

                <ul role="list" className="space-y-4 text-gray-500 dark:text-gray-400">
                    <li className="flex space-x-2 items-center">
                        <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <span className="leading-tight">Dynamic reports and dashboards</span>
                    </li>
                    <li className="flex space-x-2 items-center">
                        <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <span className="leading-tight">Templates for everyone</span>
                    </li>
                    <li className="flex space-x-2 items-center">
                        <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <span className="leading-tight">Development workflow</span>
                    </li>
                    <li className="flex space-x-2 items-center">
                        <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <span className="leading-tight">Limitless business automation</span>
                    </li>
                </ul>
            </div> */}

            {/* <div className=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="statistics" role="tabpanel" aria-labelledby="statistics-tab">
                <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-6 dark:text-white sm:p-8">
                    <div className="flex flex-col">
                        <dt className="mb-2 text-3xl font-extrabold">73M+</dt>
                        <dd className="text-gray-500 dark:text-gray-400">Developers</dd>
                    </div>
                    <div className="flex flex-col">
                        <dt className="mb-2 text-3xl font-extrabold">100M+</dt>
                        <dd className="text-gray-500 dark:text-gray-400">Public repositories</dd>
                    </div>
                    <div className="flex flex-col">
                        <dt className="mb-2 text-3xl font-extrabold">1000s</dt>
                        <dd className="text-gray-500 dark:text-gray-400">Open source projects</dd>
                    </div>
                </dl>
            </div> */}

        </>
    );
};

export default MyPlan;